const positions = [
  { label: '', value: '' },
  { label: 'Angestellte/r', value: 'EMPLOYEE' },
  { label: 'Angestellte/r in Kurzarbeit', value: 'EMPLOYEE_REDUCED_HOURS' },
  { label: 'Arbeiter/in', value: 'WORKER' },
  {
    label: 'Angestellte/r im öffent. Dienst',
    value: 'EMPLOYEE_PUBLIC_SERVICE'
  },
  { label: 'Facharbeiter/in', value: 'CRAFTSMAN' },
  { label: 'Leitende/r Angestellte/r', value: 'MANAGER' },
  { label: 'Rentner/in', value: 'RETIREE' },
  { label: 'Pensionär/in', value: 'PENSIONER' },
  { label: 'Angestellte/r in Elternzeit', value: 'EMPLOYEE_IN_PARENTAL_LEAVE' },
  {
    label: 'Angestellte/r über Zeitarbeitsfirma',
    value: 'EMPLOYEE_TEMPORARY_WORK'
  },
  { label: 'Angestellte/r im Ausland', value: 'EMPLOYEE_ABROAD' },
  { label: 'Angestelltes ärztliches Fachpersonal', value: 'EMPLOYEE_DOCTOR' },
  { label: 'Angestellte/r (Minijob 450 EUR Basis)', value: 'EMPLOYEE_MINIJOB' },
  {
    label: 'Angestellte/r (im Krankenstand / Krankengeldbezug)',
    value: 'EMPLOYEE_SICK'
  },
  { label: 'Arbeiter/in im öffent. Dienst', value: 'WORKER_PUBLIC_SERVICE' },
  { label: 'Arbeiter/in in Elternzeit', value: 'WORKER_PARENTAL_LEAVE' },
  {
    label: 'Arbeitslose, Sozialhilfeempfänger, ohne Beschäftigung',
    value: 'UNEMPLOYED'
  },
  { label: 'Auszubildende/r', value: 'APPRENTICE' },
  { label: 'Beamte/r im einfachen Dienst', value: 'OFFICER_LOWER_SERVICE' },
  { label: 'Beamte/r im gehobenen Dienst', value: 'OFFICER_UPPER_SERVICE' },
  { label: 'Beamte/r im höheren Dienst', value: 'OFFICER_HIGHER_SERVICE' },
  { label: 'Beamte/r im mittleren Dienst', value: 'OFFICER_MIDDLE_SERVICE' },
  { label: 'Hausfrau/-mann', value: 'HOUSEWIFE' },
  { label: 'Hilfsarbeite/r', value: 'HELPER' },
  { label: 'Schüler/in', value: 'PUPIL' },
  { label: 'Selbst. Freiberufler/in', value: 'SELF_EMPLOYED_FREELANCER' },
  { label: 'Selbst. Geschäftsführer/in', value: 'SELF_EMPLOYED_CEO' },
  { label: 'Selbst. Gewerbetreibende/r', value: 'SELF_EMPLOYED_BUSINESSMAN' },
  { label: 'Soldat/in', value: 'SOLDIER' },
  { label: 'Soldat/in auf Zeit', value: 'SOLDIER_TEMPORARY' },
  { label: 'Studierende/r', value: 'STUDENT' },
  { label: 'Vorstand, Geschäftsführer/in', value: 'BOARD_MEMBER_OR_CEO' },
  { label: 'Wehrpflichtige/r', value: 'DRAFTEE' }
]

export default positions
