import TrustElement from 'components/DigitalAccountCheck/TrustElement'

export const TrustElements = () => (
  <TrustElement
    gap={2}
    tuevWidth="104px"
    sslHeight={56}
    text="TÜV-geprüfte und SSL-verschlüsselte Verbindung"
  />
)
