import { Debtor } from '../../containers/PersonalForm/PersonalForm.interface'

export enum AutoFillType {
  OFF = 'off',
  GIVEN_NAME = 'given-name',
  FAMILY_NAME = 'family-name',
  PENSION = 'section-pension',
  TEL = 'tel',
  EMAIL = 'email',
  COUNTRY = 'country',
  NAME = 'name',
  POSTAL_CODE = 'postal-code',
  ADDRESS_LEVEL_2 = 'address-level2',
  STREET_ADDRESS = 'street-address',
  BIRTHDAY = 'bday',
  DISABLED_HARD = 'random_placeholder'
}

export enum AutoFillDetailToken {
  MOBILE = 'mobile',
  HOME = 'home'
}

export enum AutoFillSection {
  PERSONAL_FORM = 'section-personalForm',
  PERSONAL = 'section-personal',
  PERSONAL_PREVIOUS = 'section-personal-previous',
  PREVIOUS_EMPLOYER = 'section-previousEmployer',
  SELF_EMPLOYMENT = 'section-selfEmployment',
  PENSION = 'section-pension',
  CURRENT_EMPLOYMENT = 'section-currentEmployment',
  PREVIOUS_EMPLOYMENT = 'section-previousEmployment',
  HOUSEHOLD = 'section-household'
}

export const getAutoFillSection = (
  section: AutoFillSection,
  debtor: Debtor = Debtor.PRIMARY
) => `${section}-${debtor}`
