import { Skeleton } from '@mui/material'
import CategoryLayout from 'components/Layout/Category'
import PageLayout from 'components/Layout/Page'
import VersionDisplay from 'components/VersionDisplay'
import ProgressIndicatorSkeleton from 'components/ProgressIndicator/Skeleton'
import NavigationButtonsSkeleton from './NavigationButtonsSkeleton'
import RadioButtonsSkeleton from './RadioButtonsSkeleton'
import InputSkeleton from './InputSkeleton'
import HeaderWithLogoSkeleton from './HeaderWithLogoSkeleton'

const SkeletonView = () => (
  <>
    <PageLayout>
      <CategoryLayout>
        <ProgressIndicatorSkeleton isRect />
        <HeaderWithLogoSkeleton />
        <InputSkeleton />
        <RadioButtonsSkeleton />
        <NavigationButtonsSkeleton />
        <Skeleton variant="rectangular" height={150} />
      </CategoryLayout>
    </PageLayout>
    <VersionDisplay />
  </>
)

export default SkeletonView
