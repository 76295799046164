const URL_PALETTE_KEY = 'palette'

const getUrlPalette = (): string | null => {
  const urlParams = new URLSearchParams(window.location.search)

  if (!urlParams.has(URL_PALETTE_KEY)) {
    return null
  }

  return urlParams.get(URL_PALETTE_KEY)
}

export default getUrlPalette
