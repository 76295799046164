import { useClientDetails } from 'stores/utils/hooks/useClientDetails'
import SchufaneutralSeal from '../../assets/logos/schufa.svg'
import Ekomi from './Ekomi'
import { SealProps } from './Seal'

const DEFAULT_HEIGHT = 55

export const TuevSealComponent = (height = DEFAULT_HEIGHT) => {
  const clientDetails = useClientDetails()
  if (!clientDetails?.urls?.tuev || !clientDetails?.logos?.tuev) {
    return null
  }

  return {
    name: 'tüv',
    url: clientDetails.urls.tuev,
    children: (
      <img
        src={clientDetails.logos.tuev}
        alt="TÜV geprüfter Beratungsprozess"
        height={height}
      />
    )
  }
}

const EkomiComponent = () => {
  const clientDetails = useClientDetails()

  const rating = 4.9

  if (!clientDetails?.displayName || !clientDetails?.urls?.ekomi) {
    return null
  }

  return {
    name: 'ekomi',
    url: clientDetails.urls.ekomi,
    children: <Ekomi caption={clientDetails.displayName} rating={rating} />
  }
}

const SchufaneutralSealComponent = (height = DEFAULT_HEIGHT) => {
  const url = 'https://www.finanzcheck.de/faq/schufaneutral/'
  return {
    name: 'schufaneutral',
    url,
    children: (
      <img
        src={SchufaneutralSeal}
        alt="100% Schufaneutral"
        width="auto"
        height={height}
      />
    )
  }
}

export const getFooterSeals = (isTuevSealHidden?: boolean) =>
  [
    ...(!isTuevSealHidden ? [TuevSealComponent()] : []),
    EkomiComponent(),
    SchufaneutralSealComponent()
  ] as SealProps[]

export const getFormSeals = () =>
  [SchufaneutralSealComponent(60)] as SealProps[]
