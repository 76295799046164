import { lib } from 'crypto-js/core'

import { logAndTrackError } from 'utils/log/logAndTrackError'
import { logInfo } from 'utils/log'

export type FcidItem = {
  id: string
  ttl: number
}
// eslint-disable-next-line prefer-regex-literals
const fcidRegexp = new RegExp('^[a-f0-9]{26}$')
const fcidByteSize = 13 // fcid must match ^[a-f0-9]{26}$, that's 13 bytes

export function isValid(item: FcidItem): boolean {
  if (!item.id) {
    logAndTrackError(new Error('fcid item without id in local storage'))
    return false
  }
  if (!item.id.match(fcidRegexp)) {
    logAndTrackError(
      new Error(`fcid item with invalid id '${item.id}' in local storage`)
    )
    return false
  }
  if (!item.ttl) {
    logAndTrackError(
      new Error(`fcid item id '${item.id}' without ttl in local storage`)
    )
    return false
  }
  if (item.ttl < Date.now()) {
    logInfo(`fcid item id '${item.id}' ttl expired in local storage`)
    return false
  }

  return true
}

export function generate(): FcidItem {
  // crypto-js doesn't have func RandomBytes(), WordArray.random() does the same
  const id = lib.WordArray.random(fcidByteSize).toString()
  const now = new Date()
  const item: FcidItem = {
    id,
    ttl: now.setFullYear(now.getFullYear() + 1)
  }
  return item
}
