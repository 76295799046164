export enum HttpStatusCode {
  Ok = 200,
  Created = 201,
  BadRequest = 400,
  Unauthorized = 401,
  NotFound = 404,
  Unavailable = 451,
  StatusGone = 410
}

export function isOk(code: number) {
  return code > 199 && code < 300
}
