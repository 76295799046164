export const MAX_MOBILE_WIDTH = 768

export function isMobileViewport() {
  return window.innerWidth <= MAX_MOBILE_WIDTH
}

export function isDeviceMotorollaGPower() {
  const language = navigator && navigator.languages[0]
  // We added english US, because in bugsnag maximum users where having this language preference
  return (
    navigator.userAgent.indexOf('moto g power') > -1 && language === 'en-US'
  )
}
