import Position from 'utils/formDependencies/statics/enums/Position'
import { CasesTwoTone } from '@mui/icons-material'
import { observer } from 'mobx-react'
import ConditionalWrapper from 'components/ConditionalWrapper'
import { Debtor } from 'containers/Smava/PersonalFormPrimary/PersonalForm.interface'
import { useValue } from 'stores/utils/hooks/useValue'
import Office from 'assets/icons/nudges/office.svg'
import { Nudge } from '../Nudge'

const getPositionPluralText = (position: Position): string | null => {
  switch (position) {
    case Position.EMPLOYEE_DOCTOR:
      return 'Ärzte'
    case Position.EMPLOYEE_PUBLIC_SERVICE:
      return 'Angestellte im öffentlichen Dienst'
    case Position.WORKER_PUBLIC_SERVICE:
      return 'Arbeiter im öffentlichen Dienst'
    case Position.OFFICER_LOWER_SERVICE:
    case Position.OFFICER_MIDDLE_SERVICE:
    case Position.OFFICER_HIGHER_SERVICE:
    case Position.OFFICER_UPPER_SERVICE:
      return 'Beamte'
    case Position.MANAGER:
      return 'Leitende Angestellte'
    case Position.SOLDIER:
    case Position.SOLDIER_TEMPORARY:
      return 'Soldaten'
    case Position.CRAFTSMAN:
      return 'Facharbeiter'
    default:
      return ''
  }
}

interface Props {
  dependson: string
  isAlternativeTextEnabled?: false
  isCustomIconEnabled?: boolean
}

export const EmploymentPositionNudge: React.FC<Props> = observer(
  ({ dependson, isAlternativeTextEnabled, isCustomIconEnabled }) => {
    const { value: currentPosition } = useValue<Position>(
      `debtors.${Debtor.PRIMARY}.currentEmployment.position`
    )
    const positionPlural = getPositionPluralText(currentPosition)

    const text = `Sehr gut! ${positionPlural} erhalten im Schnitt 35\u00A0% mehr Angebote von Banken.`
    const textAlternative = `Super! ${positionPlural} erhalten durchschnittlich 35\u00A0% mehr Angebote von den Banken.`

    return (
      <ConditionalWrapper dependson={dependson}>
        <Nudge
          text={isAlternativeTextEnabled ? textAlternative : text}
          Icon={CasesTwoTone}
          {...(isCustomIconEnabled && { customIcon: Office })}
        />
      </ConditionalWrapper>
    )
  }
)
