/* eslint-disable react/require-default-props */
import TextField, { FilledTextFieldProps } from '@mui/material/TextField'
import MuiInputAdornment, {
  InputAdornmentProps
} from '@mui/material/InputAdornment'
import { useTheme, Theme } from '@mui/material/styles'
import { InputLabel, useMediaQuery } from '@mui/material'
import { TooltipProps } from 'components/Tooltip/interface'
import { scrollTo } from 'utils/pollard/scroll'
import isNotAffiliate from 'utils/formDependencies/conditions/isNotAffiliate'
import { AutoFillType } from 'utils/autofill'
import { isInIframeContext } from 'stores/RootStore'
import { useUrlParams } from 'stores/utils/hooks/useUrlParams'
import {
  ReactText,
  KeyboardEvent,
  forwardRef,
  useCallback,
  useState,
  useMemo,
  ChangeEvent,
  FocusEvent,
  useRef,
  useEffect,
  memo,
  ReactNode
} from 'react'
import useEmotionStyles from 'hooks/useEmotionStyles'
import * as CSSTypes from 'csstype'
import useEmotionStyledComponent from 'hooks/useEmotionStyledComponent'
import TooltipButton from '../../Tooltip/Button'
import Tooltip from '../../Tooltip'
import { formInitialValues } from '../../../formInitialValues'

export enum Unit {
  EURO = '€',
  EURO_MONTH = '€/Monat',
  EURO_YEAR = '€/Jahr',
  KM = 'km',
  SQUARE_METER = 'qm',
  NONE = ''
}

const HOUSE_NUMBER_PATHS = [
  'debtors.primary.currentAddress.houseNumber',
  'debtors.secondary.currentAddress.houseNumber',
  'debtors.primary.previousAddress.houseNumber',
  'debtors.secondary.previousAddress.houseNumber'
]

interface OwnProps {
  outerLabel?: boolean
  acceptedInput?: RegExp
  clue?: string
  error?: string
  unit?: Unit
  value?: ReactText
  defaultValue?: ReactText
  onChange?: (value: string, event: ChangeEvent<HTMLElement>) => void
  onBlur?: (value: string, event: FocusEvent<HTMLElement>) => void
  onFocus?: (value: string, event: FocusEvent<HTMLElement>) => void
  endAdornment?: ReactNode
  path?: string
  smallTextField?: boolean
  showTooltipOnFieldClick?: boolean
  isTooltipHidden?: boolean
  scrollOnFocusEnabled?: boolean
}

export type TextProps = Omit<
  FilledTextFieldProps,
  | 'variant'
  | 'error'
  | 'value'
  | 'defaultValue'
  | 'onChange'
  | 'onBlur'
  | 'onFocus'
  | 'inputRef'
> &
  TooltipProps &
  OwnProps

const controlKeys = ['Enter', 'Backspace', 'Delete']

const dismissUnacceptedChars = (
  acceptedInput: RegExp,
  event: KeyboardEvent<HTMLInputElement>
) => {
  const { key } = event

  if (controlKeys.includes(key)) {
    return
  }

  if (acceptedInput.test(key)) {
    return
  }

  event.preventDefault()
}

const styles = (
  theme: Theme,
  { smallTextField }: { smallTextField?: boolean }
) => ({
  unitContainer: {
    marginRight: 8
  },
  container: {
    display: 'flex',
    flexDirection: 'column' as CSSTypes.Property.FlexDirection,
    position: 'relative' as CSSTypes.Property.Position
  },
  textFieldContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  labelStyle: {
    fontSize: '14px',
    color: theme.palette.grey[900],
    marginBottom: '8px'
  },
  root: {
    margin: 0,
    '& .MuiOutlinedInput-root': {
      background: theme.palette.common.white
    },
    '& p.Mui-error': {
      padding: '0'
    },
    '& div.Mui-disabled': {
      color: `${theme.palette.grey[500]}`,
      opacity: 0.6,
      boxSizing: 'border-box' as CSSTypes.Property.BoxSizing,
      height: smallTextField ? '48px' : '100%'
    }
  }
})

const InputAdornment = (props: InputAdornmentProps) => {
  const StyledInputAdornment = useEmotionStyledComponent<any>(
    (theme: Theme) => ({
      root: {
        right: theme.spacing(1)
      }
    }),
    MuiInputAdornment
  )
  return <StyledInputAdornment {...props} />
}

const Text = forwardRef(
  (
    {
      onKeyPress,
      acceptedInput,
      clue,
      error,
      fullWidth = true,
      color = 'primary',
      defaultValue,
      value = defaultValue,
      tooltip,
      onTooltip,
      showTooltip = false,
      unit = Unit.NONE,
      onChange,
      onBlur,
      onFocus,
      helperText,
      endAdornment,
      label,
      path,
      outerLabel,
      smallTextField,
      showTooltipOnFieldClick,
      isTooltipHidden,
      scrollOnFocusEnabled,
      id,
      name,
      onPaste,
      type,
      placeholder,
      disabled,
      inputProps,
      onKeyDown
    }: TextProps,
    ref
  ) => {
    const urlParams = useUrlParams()

    const theme = useTheme()

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
    const keypressHandler = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        if (onKeyPress) {
          onKeyPress(event)
        }

        if (acceptedInput) {
          dismissUnacceptedChars(acceptedInput, event)
        }
      },
      [acceptedInput, onKeyPress]
    )

    const initialValueIsZero =
      path &&
      Number(
        path
          .split('.')
          .reduce(
            (previous, current) => previous && previous[current],
            formInitialValues
          )
      ) === 0

    const [showHint, setHint] = useState<boolean>(false)

    const { unitContainer, textFieldContainer, container, labelStyle, root } =
      useEmotionStyles<ReturnType<typeof styles>, { smallTextField?: boolean }>(
        styles,
        { smallTextField }
      )

    const [isTooltipDisplayed, setTooltipDisplayed] = useState(showTooltip)
    const tooltipClickHandler = useCallback(() => {
      const isDisplayed = !isTooltipDisplayed

      setTooltipDisplayed(isDisplayed)

      if (onTooltip) {
        onTooltip(isDisplayed)
      }
    }, [isTooltipDisplayed, setTooltipDisplayed, onTooltip])

    const inputComponents = useMemo(
      () => ({
        endAdornment: (
          <InputAdornment position="end">
            {endAdornment}

            {!endAdornment && unit && (
              <span className={tooltip ? '' : unitContainer}>{unit}</span>
            )}
          </InputAdornment>
        )
      }),
      [endAdornment, tooltip, unit, unitContainer]
    )

    const textRef = useRef<HTMLDivElement | null>(null)
    const textValue = textRef.current?.getElementsByTagName('input')?.[0]?.value
    const labelText =
      textRef.current?.getElementsByTagName('label')?.[0]?.innerText

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const [shouldShrink, setShrink] = useState<boolean | undefined>(undefined)

    const changeHandler = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        setShrink(true)

        if (!onChange) {
          return
        }

        onChange(event.currentTarget.value, event)
      },
      [onChange]
    )

    const blurHandler = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        if (
          initialValueIsZero &&
          textRef.current?.getElementsByTagName('input')?.[0]?.value === ''
        ) {
          textRef.current.getElementsByTagName('input')[0].value = '0'
        }
        setShrink(
          event.target.value.length > 0 ||
            Boolean(isSmallScreen && labelText && labelText.length > 10)
        )
        setTimeout(() => {
          setHint(false)
        }, 300)

        if (!onBlur) {
          return
        }

        onBlur(event.currentTarget.value, event)
      },
      [initialValueIsZero, isSmallScreen, labelText, onBlur]
    )

    const focusHandler = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        if (
          initialValueIsZero &&
          textRef.current?.getElementsByTagName('input')?.[0]?.value === '0'
        ) {
          textRef.current.getElementsByTagName('input')[0].value = ''
        }

        if (
          textRef.current &&
          scrollOnFocusEnabled &&
          isMobile &&
          isNotAffiliate(urlParams?.context)
        ) {
          const scrollTop =
            window.scrollY ||
            window.pageYOffset ||
            document.body.scrollTop +
              ((document.documentElement &&
                document.documentElement.scrollTop) ||
                0)

          const pos = textRef.current.getBoundingClientRect().top + scrollTop
          scrollTo(pos - 200, isInIframeContext())
        }

        setShrink(true)

        setTimeout(() => {
          setHint(Boolean(showTooltipOnFieldClick))
        }, 300)

        if (!onFocus) {
          return
        }

        onFocus(event.currentTarget.value, event)
      },
      [
        initialValueIsZero,
        onFocus,
        isMobile,
        showTooltipOnFieldClick,
        scrollOnFocusEnabled,
        urlParams?.context
      ]
    )

    useEffect(() => {
      if (
        (textValue && textValue.length > 0) ||
        Boolean(isSmallScreen && labelText && labelText.length > 10)
      ) {
        setShrink(true)
      }
    }, [textValue, isSmallScreen, labelText, defaultValue])

    const inputLabelProps = useMemo(
      () => ({
        shrink: shouldShrink
      }),
      [shouldShrink]
    )

    const inputId =
      label && `text-input-${label.toString().toLowerCase().replace(/\s/g, '')}`

    const isHouseNumberPath = HOUSE_NUMBER_PATHS.includes(path || '')

    return (
      <>
        <div className={container}>
          {outerLabel && (
            <InputLabel htmlFor={inputId || ''} classes={{ root: labelStyle }}>
              {label}
            </InputLabel>
          )}
          {tooltip && (
            <Tooltip
              open={showHint}
              isAvailable={showTooltipOnFieldClick}
              isMinimalistic={showTooltipOnFieldClick}
            >
              {tooltip}
            </Tooltip>
          )}
          <div className={textFieldContainer}>
            <TextField
              className={root}
              size={smallTextField ? 'small' : undefined}
              id={inputId || id || ''}
              ref={textRef}
              inputRef={ref}
              variant="outlined"
              fullWidth={fullWidth}
              helperText={(!isHouseNumberPath && error) || clue || helperText}
              FormHelperTextProps={{ variant: 'standard' }}
              error={!!error}
              onKeyPress={keypressHandler}
              color={color}
              value={value}
              InputProps={inputComponents}
              // eslint-disable-next-line
              inputProps={inputProps}
              InputLabelProps={inputLabelProps}
              onChange={changeHandler}
              onBlur={blurHandler}
              onFocus={focusHandler}
              label={(!outerLabel && !isHouseNumberPath && label) || ''}
              name={name}
              onPaste={onPaste}
              type={type}
              placeholder={placeholder}
              autoComplete={AutoFillType.DISABLED_HARD}
              disabled={disabled}
              onKeyDown={onKeyDown}
            />
            {/* INFO ICON / TOOLTIP SWITCHER */}
            {tooltip &&
              !showTooltipOnFieldClick &&
              !isHouseNumberPath &&
              !isTooltipHidden && (
                <TooltipButton
                  onClick={tooltipClickHandler}
                  path={path}
                  isVisible={isTooltipDisplayed}
                />
              )}
          </div>
        </div>
        {tooltip && !showTooltipOnFieldClick && (
          <Tooltip open={isTooltipDisplayed}>{tooltip}</Tooltip>
        )}
      </>
    )
  }
)

Text.displayName = 'Text'

/* 
  This array is introduced due to a problem with memoization.
  For some reason, it doesn't update a value in an API call/field value.
*/
const PATH_PARTS_WITH_NO_DEPENDENCIES_IN_MEMO = [
  'streetAddress',
  'zipCode',
  'price',
  'initialPayment',
  'remainingAmount'
]

export default memo(Text, (prevProps, nextProps) => {
  if (
    prevProps.name &&
    PATH_PARTS_WITH_NO_DEPENDENCIES_IN_MEMO.some(
      (pathPartWithNoDependenciesInMemo) =>
        prevProps.name?.includes(pathPartWithNoDependenciesInMemo)
    )
  ) {
    return false
  }

  const areAllPropsTheSame =
    prevProps.inputProps?.value === nextProps.inputProps?.value &&
    prevProps.inputProps?.maxLength === nextProps.inputProps?.maxLength &&
    prevProps.value === nextProps.value &&
    prevProps.placeholder === nextProps.placeholder &&
    prevProps.error === nextProps.error &&
    prevProps.clue === nextProps.clue &&
    String(prevProps.onChange) === String(nextProps.onChange) &&
    String(prevProps.onFocus) === String(nextProps.onFocus) &&
    String(prevProps.onBlur) === String(nextProps.onBlur) &&
    String(prevProps.acceptedInput) === String(nextProps.acceptedInput)

  return areAllPropsTheSame
})
