import Paper, { PaperProps } from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Grow from '@mui/material/Grow'
import { memo } from 'react'
import { Theme } from '@mui/material/styles'
import { green } from '@mui/material/colors'
import useEmotionStyledComponent from 'hooks/useEmotionStyledComponent'

const SuccessPaper = (props: PaperProps) => {
  const SuccessPaperStyled = useEmotionStyledComponent<
    typeof Paper,
    PaperProps
  >(
    (theme: Theme) => ({
      root: {
        backgroundColor: green[100],
        color: theme.palette.success.dark,
        padding: theme.spacing(3)
      }
    }),
    Paper,
    props
  )
  return <SuccessPaperStyled {...props} />
}

const ErrorPaper = (props: PaperProps) => {
  const ErrorPaperStyled = useEmotionStyledComponent<typeof Paper>(
    (theme: Theme) => ({
      root: {
        backgroundColor: 'rgba(197, 14, 7, 0.15)',
        color: theme.palette.error.main,
        padding: theme.spacing(3)
      }
    }),
    Paper
  )
  return <ErrorPaperStyled {...props} />
}

export type SaveButtonDialogState = 'none' | 'success' | 'error'

interface SaveButtonDialogProps {
  state: SaveButtonDialogState
}

const SaveButtonDialog = ({ state }: SaveButtonDialogProps) => {
  if (state === 'success') {
    return (
      <Grow in>
        <div>
          <SuccessPaper elevation={0}>
            <Typography variant="h6" component="p" gutterBottom color="inherit">
              Eine E-Mail mit einem Link zu Ihren Daten wird versendet!
            </Typography>
            <Typography variant="body1" color="inherit">
              Sie können über die E-Mail Ihre Daten zu jedem Zeitpunkt und von
              jedem Gerät vervollständigen, um dann Ihre individuellen Angebote
              zu vergleichen.
            </Typography>
          </SuccessPaper>
        </div>
      </Grow>
    )
  }

  if (state === 'error') {
    return (
      <Grow in>
        <div>
          <ErrorPaper elevation={0}>
            <Typography variant="h6" component="p" gutterBottom color="inherit">
              Es ist leider etwas schiefgelaufen.
            </Typography>
            <Typography variant="body1" color="inherit">
              Bitte versuchen Sie es später erneut.
            </Typography>
          </ErrorPaper>
        </div>
      </Grow>
    )
  }

  return null
}

export default memo(SaveButtonDialog)
