import Rating from '@mui/material/Rating'
import { Typography, Grid } from '@mui/material'
import { useClientDetails } from 'stores/utils/hooks/useClientDetails'
import useEmotionStyles from 'hooks/useEmotionStyles'

const EKOMI_RATING = 4.9

const styles = () => ({
  rating: {
    fontSize: '0.625rem'
  },
  numberOfRatings: {
    fontSize: '0.625rem',
    lineHeight: 1.5
  },
  ratingStars: {
    '& svg': {
      height: '0.75rem',
      width: '0.75rem'
    }
  }
})

const Ekomi = () => {
  const clientDetails = useClientDetails()
  const { numberOfRatings, ratingStars } = useEmotionStyles(styles)

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <img
          src={clientDetails?.logos?.ekomi}
          alt="Ausgezeichnete Kundenzufriedenheit Logo"
          height="55px"
          data-testid="ekomi-image"
        />
      </Grid>
      <Grid item>
        <Typography
          className={numberOfRatings}
          variant="h5"
          color="textSecondary"
        >
          {EKOMI_RATING} / 5
        </Typography>
      </Grid>
      <Grid item>
        <Rating
          className={ratingStars}
          name="read-only"
          value={EKOMI_RATING}
          readOnly
        />
      </Grid>
    </Grid>
  )
}

export default Ekomi
