import { memo } from 'react'
import { useClientDetails } from 'stores/utils/hooks/useClientDetails'

interface Props {
  height?: number
}

const Ssl = ({ height }: Props) => {
  const clientDetails = useClientDetails()
  if (!clientDetails?.logos?.ssl) {
    return null
  }

  return (
    <img
      height={height || 90}
      alt="Payment Services Directive Logo"
      src={clientDetails.logos.ssl}
      data-testid="ssl-image"
    />
  )
}

export default memo(Ssl)
