import { Data } from 'interfaces/ApolloResponse.interface'
import { Debtor } from '../../../containers/PersonalForm/PersonalForm.interface'

const EU_COUNTRIES_LIST = [
  'BE',
  'BG',
  'DK',
  'DE',
  'EE',
  'FI',
  'FR',
  'GR',
  'IE',
  'IT',
  // 'HR', // Open CSI Issue
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'AT',
  'PL',
  'PT',
  'RO',
  'SE',
  'SK',
  'SI',
  'ES',
  'CZ',
  'HU',
  'CY'
]

const nationalityNotEu =
  (debtor: Debtor = Debtor.PRIMARY) =>
  (state: Data): boolean =>
    !EU_COUNTRIES_LIST.includes(state.debtors[debtor].personal.nationality)

export default nationalityNotEu
