const cards = [
  { label: 'keine', value: 'NONE' },
  { label: 'EC-Karte(n)', value: 'DEBITCARD' },
  { label: 'Kreditkarte(n)', value: 'CREDITCARD' },
  {
    label: 'EC-Karte(n) und Kreditkarte(n)',
    value: 'DEBITCARD_CREDITCARD'
  }
]

export default cards
