import { useEffect } from 'react'
import { observer } from 'mobx-react'
import {
  broadcastToParent,
  RegisteredEventName
} from '@finanzcheck/catalyst-pollard'
import { useStores } from 'stores/utils/hooks/useStores'

const QueryParamsUpdater = observer(() => {
  const { traversal } = useStores()

  useEffect(() => {
    broadcastToParent({
      eventName: RegisteredEventName.updateQueryParams,
      data: {
        amount: traversal.data.common.amount
      }
    })
  }, [traversal.data.common.amount])

  useEffect(() => {
    broadcastToParent({
      eventName: RegisteredEventName.updateQueryParams,
      data: {
        category: traversal.data.common.purpose
      }
    })
  }, [traversal.data.common.purpose])

  useEffect(() => {
    broadcastToParent({
      eventName: RegisteredEventName.updateQueryParams,
      data: {
        duration: traversal.data.common.term
      }
    })
  }, [traversal.data.common.term])

  useEffect(() => {
    broadcastToParent({
      eventName: RegisteredEventName.updateQueryParams,
      data: {
        initialPayment: traversal.data.common.vehicle.initialPayment
      }
    })
  }, [traversal.data.common.vehicle.initialPayment])

  useEffect(() => {
    broadcastToParent({
      eventName: RegisteredEventName.updateQueryParams,
      data: {
        vehiclePrice: traversal.data.common.vehicle.price
      }
    })
  }, [traversal.data.common.vehicle.price])

  return null
})

export default QueryParamsUpdater
