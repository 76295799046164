import { Choice } from 'components/Input/ChoiceGroup'

const carManufacturer = {
  audi: 'Audi',
  austin: 'Austin',
  barkas: 'Barkas',
  bmw: 'BMW',
  cadillac: 'Cadillac',
  chevrolet: 'Chevrolet',
  chrysler: 'Chrysler',
  citroen: 'Citroen',
  corvette: 'Corvette',
  dacia: 'Dacia',
  daewoo: 'Daewoo',
  daihatsu: 'Daihatsu',
  dodge: 'Dodge',
  fiat: 'Fiat',
  ford: 'Ford',
  hdpic: 'HDPIC',
  honda: 'Honda',
  hyundai: 'Hyundai',
  infiniti: 'Infiniti',
  isuzu: 'Isuzu',
  iveco: 'Iveco',
  jaguar: 'Jaguar',
  jeep: 'Jeep',
  kia: 'Kia',
  lada: 'Lada',
  lancia: 'Lancia',
  land_rover: 'Land Rover',
  ldv: 'LDV',
  lexus: 'Lexus',
  mazda: 'Mazda',
  mercedes_benz: 'Mercedes-Benz',
  mg: 'MG',
  mini_bmw: 'MINI (BMW)',
  mini_rover: 'MINI (Rover)',
  mitsubishi: 'Mitsubishi',
  nissan: 'Nissan',
  opel: 'Opel',
  peugeot: 'Peugeot',
  pontiac: 'Pontiac',
  porsche: 'Porsche',
  proton: 'Proton',
  renault: 'Renault',
  rover: 'Rover',
  saab: 'Saab',
  seat: 'Seat',
  skoda: 'Skoda',
  smart: 'Smart',
  ssangyong: 'Ssangyong',
  subaru: 'Subaru',
  suzuki: 'Suzuki',
  talbot: 'Talbot',
  tesla: 'Tesla',
  toyota: 'Toyota',
  volvo: 'Volvo',
  vw: 'Volkswagen'
}

const bikeManufacturer = {
  aprilia: 'Aprilia',
  betamotor: 'Betamotor',
  bmw: 'BMW',
  ducati: 'Ducati',
  gas_gas: 'Gas Gas',
  'harley-davidson': 'Harley-Davidson',
  honda: 'Honda',
  husaberg: 'Husaberg',
  husqvarna: 'Husqvarna',
  hyosung: 'Hyosung',
  indian_motorcycle: 'Indian Motorcycle',
  kawasaki: 'Kawasaki',
  ktm: 'KTM',
  'moto-guzzi': 'Moto-Guzzi',
  mv_agusta: 'MV Agusta',
  suzuki: 'Suzuki',
  triumph: 'Triumph',
  vespa: 'Vespa',
  victory: 'Victory',
  yamaha: 'Yamaha'
}

const transporterManufacturer = {
  citroen: 'Citroen',
  daihatsu: 'Daihatsu',
  fiat: 'Fiat',
  ford: 'Ford',
  hyundai: 'Hyundai',
  iveco: 'Iveco',
  kia: 'Kia',
  mercedes_benz: 'Mercedes-Benz',
  mitsubishi: 'Mitsubishi',
  nissan: 'Nissan',
  opel: 'Opel',
  peugeot: 'Peugeot',
  renault: 'Renault',
  suzuki: 'Suzuki',
  toyota: 'Toyota',
  vw: 'VW',
  volvo: 'Volvo'
}

const truckManufacturer = {
  daf: 'DAF',
  ford: 'Ford',
  hyundai: 'Hyundai',
  isuzu: 'Isuzu',
  iveco: 'Iveco',
  kenworth: 'Kenworth',
  liebherr: 'Liebherr',
  magirus_deutz: 'Magirus Deutz',
  man: 'MAN',
  mercedes_benz: 'Mercedes-Benz',
  mitsubishi: 'Mitsubishi',
  renault: 'Renault',
  scania: 'Scania',
  unimog: 'Unimog',
  ural: 'Ural',
  volvo: 'Volvo',
  vw: 'VW'
}

const mobileHomeManufacturer = {
  '2in1-Mobil': '2in1-Mobil',
  adria: 'Adria',
  ahorn: 'Ahorn',
  'auto-sleepers': 'Auto-Sleepers',
  bavaria_camp: 'Bavaria Camp',
  behl: 'Behl',
  bela: 'Bela',
  benimar: 'Benimar',
  bett_mobil: 'Bett-Mobil',
  bimobil: 'Bimobil',
  bravia_mobil: 'Bravia Mobil',
  bresler: 'Bresler',
  burow: 'Burow',
  bürstner: 'Bürstner',
  'c-tech': 'C-tech',
  campmobil_schwerin: 'Campmobil Schwerin',
  campereve: 'Campéreve',
  carado: 'Carado',
  carthago: 'Carthago',
  chausson: 'Chausson',
  challenger: 'Challenger',
  ci: 'CI',
  clever: 'Clever',
  citroen: 'Citroen',
  concorde: 'Concorde',
  cristall: 'Cristall',
  cs: 'CS',
  'custom-bus': 'Custom-Bus',
  dethleffs: 'Dethleffs',
  dipa: 'Dipa',
  domo: 'Domo',
  dopfer: 'Dopfer',
  dreamer: 'Dreamer',
  eifelland: 'Eifelland',
  elnagh: 'Elnagh',
  etrusco: 'Etrusco',
  eura_mobil: 'Eura Mobil',
  fendt: 'Fendt',
  fischer: 'Fischer',
  fleurette: 'Fleurette',
  flow_camper: 'Flow Camper',
  ford: 'Ford',
  forster: 'Forster',
  frankia: 'Frankia',
  'globe-traveller': 'Globe-Traveller',
  globecar: 'Globecar',
  hobby: 'Hobby',
  hrz: 'HRZ',
  hymer: 'Hymer',
  hymercar: 'HymerCar',
  hünerkopf: 'Hünerkopf',
  itineo: 'Itineo',
  joko: 'Joko',
  'ka-mobile': 'Ka-Mobile',
  kabe: 'Kabe',
  karmann: 'Karmann',
  karmann_mobil: 'Karmann Mobil',
  knaus: 'Knaus',
  knobloch: 'Knobloch',
  köhler: 'Köhler',
  la_strada: 'La Strada',
  laika: 'Laika',
  lmc: 'LMC',
  malibu: 'Malibu',
  mcloius: 'McLoius',
  mercedes_benz: 'Mercedes-Benz',
  miller: 'Miller',
  mobilvetta: 'Mobilvetta',
  mooveo: 'Mooveo',
  morelo: 'Morelo',
  'multi-bike-van': 'Multi-Bike-Van',
  natürliche_reisemobile: 'Natürliche Reisemobile',
  neureuther: 'Neureuther',
  niesmann: 'Niesmann',
  niesmann_bischoff: 'Niesmann + Bischoff',
  orangecamp: 'Orangecamp',
  peugeot: 'Peugeot',
  phoenix: 'Phoenix',
  pilote: 'Pilote',
  pla: 'PLA',
  polyroof: 'Polyroof',
  poessl: 'Poessl',
  rapido: 'Rapido',
  reimo: 'Reimo',
  rimor: 'Rimor',
  rmb: 'RMB',
  roadcar: 'Roadcar',
  roller_team: 'Roller Team',
  schwabenmobil: 'Schwabenmobil',
  spacecamper: 'Spacecamper',
  sterckeman: 'Sterckeman',
  summermobil: 'Summermobil',
  sun_living: 'Sun Living',
  sunlight: 'Sunlight',
  tabbert: 'Tabbert',
  't@b': 'T@B',
  tec: 'TEC',
  terracamper: 'Terracamper',
  tikro: 'Tikro',
  tonke: 'Tonke',
  tsl: 'TSL',
  urban_reisemobile: 'Urban Reisemobile',
  vantourer: 'VanTourer',
  vario_mobil: 'Vario Mobil',
  varius: 'Varius',
  vw: 'VW',
  wanner: 'Wanner',
  weinsberg: 'Weinsberg',
  werz: 'Werz',
  westfalia: 'Westfalia',
  wilk: 'Wilk',
  wingamm: 'Wingamm',
  wof: 'Wof',
  wsr: 'WSR',
  'x-go': 'X-Go',
  zooom: 'Zooom'
}

const travelTrailerManufacturer = {
  '2in1-Mobil': '2in1-Mobil',
  adria: 'Adria',
  ahorn: 'Ahorn',
  'auto-sleepers': 'Auto-Sleepers',
  bavaria_camp: 'Bavaria Camp',
  behl: 'Behl',
  bela: 'Bela',
  benimar: 'Benimar',
  bett_mobil: 'Bett-Mobil',
  bimobil: 'Bimobil',
  bravia_mobil: 'Bravia Mobil',
  bresler: 'Bresler',
  burow: 'Burow',
  bürstner: 'Bürstner',
  'c-tech': 'C-tech',
  campmobil_schwerin: 'Campmobil Schwerin',
  campereve: 'Campéreve',
  carado: 'Carado',
  carthago: 'Carthago',
  chausson: 'Chausson',
  challenger: 'Challenger',
  ci: 'CI',
  clever: 'Clever',
  citroen: 'Citroen',
  concorde: 'Concorde',
  cristall: 'Cristall',
  cs: 'CS',
  'custom-bus': 'Custom-Bus',
  dethleffs: 'Dethleffs',
  dipa: 'Dipa',
  domo: 'Domo',
  dopfer: 'Dopfer',
  dreamer: 'Dreamer',
  eifelland: 'Eifelland',
  elnagh: 'Elnagh',
  etrusco: 'Etrusco',
  eura_mobil: 'Eura Mobil',
  fendt: 'Fendt',
  fischer: 'Fischer',
  fleurette: 'Fleurette',
  flow_camper: 'Flow Camper',
  ford: 'Ford',
  forster: 'Forster',
  frankia: 'Frankia',
  'globe-traveller': 'Globe-Traveller',
  globecar: 'Globecar',
  hobby: 'Hobby',
  hrz: 'HRZ',
  hymer: 'Hymer',
  hymercar: 'HymerCar',
  hünerkopf: 'Hünerkopf',
  itineo: 'Itineo',
  joko: 'Joko',
  'ka-mobile': 'Ka-Mobile',
  kabe: 'Kabe',
  karmann: 'Karmann',
  karmann_mobil: 'Karmann Mobil',
  knaus: 'Knaus',
  knobloch: 'Knobloch',
  köhler: 'Köhler',
  la_strada: 'La Strada',
  laika: 'Laika',
  lmc: 'LMC',
  malibu: 'Malibu',
  mcloius: 'McLoius',
  mercedes_benz: 'Mercedes-Benz',
  miller: 'Miller',
  mobilvetta: 'Mobilvetta',
  mooveo: 'Mooveo',
  morelo: 'Morelo',
  'multi-bike-van': 'Multi-Bike-Van',
  natürliche_reisemobile: 'Natürliche Reisemobile',
  neureuther: 'Neureuther',
  niesmann: 'Niesmann',
  niesmann_bischoff: 'Niesmann + Bischoff',
  orangecamp: 'Orangecamp',
  peugeot: 'Peugeot',
  phoenix: 'Phoenix',
  pilote: 'Pilote',
  pla: 'PLA',
  polyroof: 'Polyroof',
  poessl: 'Poessl',
  rapido: 'Rapido',
  reimo: 'Reimo',
  rimor: 'Rimor',
  rmb: 'RMB',
  roadcar: 'Roadcar',
  roller_team: 'Roller Team',
  schwabenmobil: 'Schwabenmobil',
  spacecamper: 'Spacecamper',
  summermobil: 'Summermobil',
  sun_living: 'Sun Living',
  sunlight: 'Sunlight',
  't@b': 'T@B',
  tec: 'TEC',
  terracamper: 'Terracamper',
  tikro: 'Tikro',
  tonke: 'Tonke',
  tsl: 'TSL',
  urban_reisemobile: 'Urban Reisemobile',
  vantourer: 'VanTourer',
  vario_mobil: 'Vario Mobil',
  varius: 'Varius',
  vw: 'VW',
  wanner: 'Wanner',
  weinsberg: 'Weinsberg',
  werz: 'Werz',
  westfalia: 'Westfalia',
  wingamm: 'Wingamm',
  wof: 'Wof',
  wsr: 'WSR',
  'x-go': 'X-Go',
  zooom: 'Zooom'
}

const categories = [
  { cat: 'CAR', catOptions: carManufacturer },
  { cat: 'BIKE', catOptions: bikeManufacturer },
  { cat: 'TRANSPORTER', catOptions: transporterManufacturer },
  { cat: 'TRUCK', catOptions: truckManufacturer },
  { cat: 'MOBILE_HOME', catOptions: mobileHomeManufacturer },
  { cat: 'TRAVEL_TRAILER', catOptions: travelTrailerManufacturer }
]

export default Object.values(categories).reduce<Record<string, Choice[]>>(
  (manufacturers, { cat, catOptions }) => {
    // eslint-disable-next-line no-param-reassign
    manufacturers[cat] = Object.entries(catOptions).map(([value, label]) => ({
      label,
      value
    }))

    return manufacturers
  },
  {}
)
