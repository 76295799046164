const headline = {
  debtorsInfo: 'Informationen zum Kreditnehmer',
  contactInfo: 'Kontaktdaten',
  personalInfo: 'Persönliche Angaben',
  residentAlien: 'Genehmigungen bei ausländischen Bürgern',
  currentAddress: 'Aktuelle Wohnanschrift',
  previousAddress: 'Vorherige Wohnanschrift',
  household: 'Haushalt',
  rentalProperty: 'Vermietete Immobilie',
  selfUsedProperty: 'Selbstgenutzte Immobilie',
  occupation: 'Berufsgruppe',
  currentEmployment: 'Berufliche Tätigkeit',
  previousEmployment: 'Vorheriger Arbeitgeber',
  pension: 'Angaben zur Rente',
  firmInfo: 'Angaben zur Firma',
  firmFinancialData: 'Finanzdaten der Firma',
  incomeMinijob: 'Einkommen aus Aushilfs-/Minijob',
  incomePrimary: 'Einkommen aus Haupttätigkeit',
  incomeSecondary: 'Berufliche Nebentätigkeiten',
  incomeOthers: 'Sonstiges Einkommen',
  expenses: 'Ausgaben',
  activeLoans: 'Bestehende Kredite',
  activeLoan: 'Aktiver Kredit',
  amountAdaption: 'Kreditbetrag anpassen',
  rdi: 'Ratenschutzversicherung',
  loanPayout: 'Kreditinformationen',
  payoutAccount: 'Letzte Seite vor Ihren Angeboten',
  requestOffers: 'Jetzt Angebote anfordern'
}

export default headline
