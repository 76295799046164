import civilStatus from 'texts/common/lists/civil_status'
import householdLivingSituation from 'texts/common/lists/household_living_situation'
import property from 'texts/common/lists/property'
import position from 'texts/common/lists/position'

export const getCivilStatus = (civilSatusValue: string): string | undefined =>
  civilStatus.find(
    (civilStatusElement) => civilStatusElement.value === civilSatusValue
  )?.label

export const getHouseholdLivingSituation = (
  householdLivingSituationValue: string
): string | undefined =>
  householdLivingSituation.find(
    (householdLivingSituationElement) =>
      householdLivingSituationElement.value === householdLivingSituationValue
  )?.label

export const getRentalPropertyType = (
  rentalPropertyValue: string
): string | undefined =>
  property.find(
    (propertyElement) => propertyElement.value === rentalPropertyValue
  )?.label

export const getJob = (positionValue: string): string | undefined =>
  position.find((positionElement) => positionElement.value === positionValue)
    ?.label

export const getRentValue = (positionValue: string): string | undefined =>
  position.find((positionElement) => positionElement.value === positionValue)
    ?.label
