import { memo } from 'react'
import { Theme, Typography } from '@mui/material'
import useEmotionStyles from 'hooks/useEmotionStyles'

const styles = (theme: Theme) => ({
  title: {
    marginBottom: theme.spacing(3)
  }
})

type LoadingPageHeaderProps = {
  headerText?: string
}

const DEFAULT_TEXT = 'Bitte haben Sie einen Augenblick Geduld!'

const LoadingPageHeader = ({
  headerText = DEFAULT_TEXT
}: LoadingPageHeaderProps) => {
  const { title } = useEmotionStyles(styles)
  return (
    <Typography variant="h3" align="center" color="primary" className={title}>
      {headerText}
    </Typography>
  )
}

export default memo(LoadingPageHeader)
