import { Data } from 'interfaces/ApolloResponse.interface'

const YEAR_IN_MS = 1000 * 60 * 60 * 24 * 365

export const isOfRelevantAge = (state: Data) => {
  const difference =
    (Date.now() - new Date(state.debtors.primary.personal.birthday).getTime()) /
    YEAR_IN_MS
  return difference >= 35 && difference <= 64
}

export const isEmploymentTemporary = (state: Data) =>
  !state.debtors.primary.currentEmployment.isTemporary
