import { AppEnvironment } from 'utils/env'

export const clientDetails = {
  [AppEnvironment.Shared]: {
    uuid: 'f9343d8a-4bd3-48b8-a504-e99f102f463c',
    displayName: 'smava',
    street: 'Palisadenstraße 90',
    city: 'Berlin',
    country: 'DE',
    zipCode: '10243',
    phone: '0800 000 98 00',
    workingTimes: 'Mo - Fr 08 - 20 Uhr, Sa 10 - 15 Uhr',
    email: 'info@smava.de',
    company: 'smava GmbH',
    logos: {
      client: 'https://cdn.smava.de/assets/smava_logo.svg',
      ekomi: 'https://cdn.smava.de/assets/ekomi_badge.svg',
      focusMoneyBadge: 'https://cdn.smava.de/assets/focusmoney_vertical.svg',
      focusMoneyBadgeAlt:
        'https://cdn.smava.de/assets/focusmoney_alt_vertical.png',
      guenstigerGehtNicht: 'https://cdn.smava.de/assets/gunster_geht_nicht.svg',
      schufaNeutral: 'https://cdn.smava.de/assets/schufa_neutral.svg',
      ssl: 'https://cdn.smava.de/assets/ssl.png',
      tuev: 'https://cdn.smava.de/assets/tuev_horizontal_45969.png'
    },
    urls: {
      dac: 'https://home.smava-shared.de/mein-kontoblick/',
      dataProtection: 'https://website.smava-shared.de/datenschutz',
      declarationOfConsentAdvertising:
        'https://website.smava-shared.de/einwilligungserklarung',
      ekomi: 'https://www.ekomi.de/bewertungen-smava-gmbh.html',
      faq: 'https://website.smava-shared.de/kredit/kontoblick/',
      faqHome: 'https://home.smava-shared.de/faq',
      focusMoney: 'https://cdn.smava.de/docs/smava_focus_money.pdf',
      home: 'https://home.smava-shared.de/',
      imprint: 'https://website.smava-shared.de/impressum',
      logout: 'https://home.smava-shared.de/logout',
      mandatoryInformation:
        'https://website.smava-shared.de/darlehensvermittlung',
      precontractual: 'https://website.smava-shared.de/darlehensvermittlung',
      startPage: 'https://website.smava-shared.de/',
      termsAndConditions: 'https://website.smava-shared.de/agb',
      tuev: 'https://www.tuev-saar.de/zertifikat/tk45969/'
    }
  },
  [AppEnvironment.Stage]: {
    uuid: 'f9343d8a-4bd3-48b8-a504-e99f102f463c',
    displayName: 'smava',
    street: 'Palisadenstraße 90',
    city: 'Berlin',
    country: 'DE',
    zipCode: '10243',
    phone: '0800 000 98 00',
    workingTimes: 'Mo - Fr 08 - 20 Uhr, Sa 10 - 15 Uhr',
    email: 'info@smava.de',
    company: 'smava GmbH',
    logos: {
      client: 'https://cdn.smava.de/assets/smava_logo.svg',
      ekomi: 'https://cdn.smava.de/assets/ekomi_badge.svg',
      focusMoneyBadge: 'https://cdn.smava.de/assets/focusmoney_vertical.svg',
      focusMoneyBadgeAlt:
        'https://cdn.smava.de/assets/focusmoney_alt_vertical.png',
      guenstigerGehtNicht: 'https://cdn.smava.de/assets/gunster_geht_nicht.svg',
      schufaNeutral: 'https://cdn.smava.de/assets/schufa_neutral.svg',
      ssl: 'https://cdn.smava.de/assets/ssl.png',
      tuev: 'https://cdn.smava.de/assets/tuev_horizontal_45969.png'
    },
    urls: {
      dac: 'https://home.smava-stage.de/mein-kontoblick/',
      dataProtection: 'https://website.smava-stage.de/datenschutz',
      declarationOfConsentAdvertising:
        'https://website.smava-stage.de/einwilligungserklarung',
      ekomi: 'https://www.ekomi.de/bewertungen-smava-gmbh.html',
      faq: 'https://website.smava-stage.de/kredit/kontoblick/',
      faqHome: 'https://home.smava-stage.de/faq',
      focusMoney: 'https://cdn.smava.de/docs/smava_focus_money.pdf',
      home: 'https://home.smava-stage.de/',
      imprint: 'https://website.smava-stage.de/impressum',
      logout: 'https://home.smava-stage.de/logout',
      mandatoryInformation:
        'https://website.smava-stage.de/darlehensvermittlung',
      precontractual: 'https://website.smava-stage.de/darlehensvermittlung',
      startPage: 'https://website.smava-stage.de/',
      termsAndConditions: 'https://website.smava-stage.de/agb',
      tuev: 'https://www.tuev-saar.de/zertifikat/tk45969/'
    }
  },
  [AppEnvironment.Production]: {
    uuid: 'f9343d8a-4bd3-48b8-a504-e99f102f463c',
    displayName: 'smava',
    street: 'Palisadenstraße 90',
    city: 'Berlin',
    country: 'DE',
    zipCode: '10243',
    phone: '0800 000 98 00',
    workingTimes: 'Mo - Fr 08 - 20 Uhr, Sa 10 - 15 Uhr',
    email: 'info@smava.de',
    company: 'smava GmbH',
    logos: {
      client: 'https://cdn.smava.de/assets/smava_logo.svg',
      ekomi: 'https://cdn.smava.de/assets/ekomi_badge.svg',
      focusMoneyBadge: 'https://cdn.smava.de/assets/focusmoney_vertical.svg',
      focusMoneyBadgeAlt:
        'https://cdn.smava.de/assets/focusmoney_alt_vertical.png',
      guenstigerGehtNicht: 'https://cdn.smava.de/assets/gunster_geht_nicht.svg',
      schufaNeutral: 'https://cdn.smava.de/assets/schufa_neutral.svg',
      ssl: 'https://cdn.smava.de/assets/ssl.png',
      tuev: 'https://cdn.smava.de/assets/tuev_horizontal_45969.png'
    },
    urls: {
      dac: 'https://home.smava.de/mein-kontoblick/',
      dataProtection: 'https://www.smava.de/datenschutz',
      declarationOfConsentAdvertising:
        'https://www.smava.de/einwilligungserklarung',
      ekomi: 'https://www.ekomi.de/bewertungen-smava-gmbh.html',
      faq: 'https://www.smava.de/kredit/kontoblick/',
      faqHome: 'https://home.smava.de/faq',
      focusMoney: 'https://cdn.smava.de/docs/smava_focus_money.pdf',
      home: 'https://home.smava.de/',
      imprint: 'https://www.smava.de/impressum',
      logout: 'https://home.smava.de/logout',
      mandatoryInformation: 'https://www.smava.de/darlehensvermittlung',
      precontractual: 'https://www.smava.de/darlehensvermittlung',
      startPage: 'https://www.smava.de/',
      termsAndConditions: 'https://www.smava.de/agb',
      tuev: 'https://www.tuev-saar.de/zertifikat/tk45969/'
    }
  }
}
