/* eslint-disable no-console */
import { appEnvironment } from '../env'

interface LogStyle {
  bgColor: string
  textColor: string
}

interface LogMessageStyles {
  timestamp?: LogStyle
  text?: LogStyle
  leading?: string
}

const timestampStyleToString = ({ bgColor, textColor }: LogStyle) =>
  `background-color:${bgColor};color:${textColor};padding:4px;font-weight:bold`

const textStyleToString = ({ bgColor, textColor }: LogStyle) =>
  `background-color:${bgColor};color:${textColor};padding:4px 8px;font-weight:bold`

export type MessageStyle = 'info' | 'warning' | 'error' | 'success'

const styles: Record<MessageStyle, Required<LogMessageStyles>> = {
  info: {
    timestamp: { bgColor: '#3182CE', textColor: '#EBF8FF' },
    text: { bgColor: '#EBF8FF', textColor: '#2C5282' },
    leading: '💡'
  },
  warning: {
    timestamp: { bgColor: '#ECC94B', textColor: '#1A202C' },
    text: { bgColor: '#FAF089', textColor: '#744210' },
    leading: '⚠'
  },
  error: {
    timestamp: { bgColor: '#C53030', textColor: '#FFF5F5' },
    text: { bgColor: '#FEB2B2', textColor: '#742A2A' },
    leading: '☠'
  },
  success: {
    timestamp: { bgColor: '#38A169', textColor: '#F0FFF4' },
    text: { bgColor: '#9AE6B4', textColor: '#22543D' },
    leading: '✔'
  }
}

const suppressLogs = ['production', 'test'].includes(appEnvironment)

export const logMessage = (
  message: string,
  {
    timestamp = { bgColor: '#1A202C', textColor: '#CBD5E0' },
    text = { bgColor: '#BEE3F8', textColor: '#1A202C' },
    leading = 'ℹ'
  }: LogMessageStyles
): void => {
  if (suppressLogs) {
    return
  }

  console.log(
    `%c${new Date().toLocaleString()}  ${leading} %c${message}`,
    timestampStyleToString(timestamp),
    textStyleToString(text)
  )
}

export const logInfo = (message: string): void => {
  logMessage(message, styles.info)
}

export const logError = (message: string): void => {
  logMessage(message, styles.error)
}

export const logWarning = (message: string): void => {
  logMessage(message, styles.warning)
}

export const logSuccess = (message: string): void => {
  logMessage(message, styles.success)
}

export const logStackTrace = () => {
  if (suppressLogs) {
    return
  }

  console.groupCollapsed(`${new Date().toLocaleString()} | Stack trace`)
  console.trace()
  console.groupEnd()
}

export const logGroup = (
  name: string,
  messages: unknown[],
  style: MessageStyle = 'info'
): void => {
  if (suppressLogs) {
    return
  }

  const messageStyle = {
    timestamp: styles[style].timestamp,
    text: styles[style].text
  }

  console.groupCollapsed(
    `%c${new Date().toLocaleString()} %c${name}`,
    timestampStyleToString(messageStyle.timestamp),
    textStyleToString(messageStyle.text)
  )
  messages.map((message) =>
    typeof message === 'string'
      ? logMessage(message, messageStyle)
      : console.log(message)
  )
  console.groupEnd()
}
