import { pick } from 'dot-object'
import { Data } from 'interfaces/ApolloResponse.interface'
import { dateIsOlderThan, TimeDifference } from '../../date'

export const isOlderThan =
  (path: string, timeDifference: TimeDifference) =>
  (state: Data): boolean => {
    const value = pick(path, state)

    if (!value) {
      return false
    }

    return dateIsOlderThan(value, timeDifference)
  }

export const isNotOlderThan =
  (path: string, timeDifference: TimeDifference) => (state: Data) => {
    const value = pick(path, state)

    if (!value) {
      return false
    }

    return !dateIsOlderThan(value, timeDifference)
  }
