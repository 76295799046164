import BugsnagError, { Metadata } from './bugsnagError'

export default class DependencyError extends BugsnagError {
  public commandName: string
  public dependencies: Record<string, boolean>

  constructor(
    commandName: string,
    dependencies: Record<string, boolean>,
    message: string
  ) {
    super(`[${commandName}] ${message}`)

    this.name = 'DependencyError'
    this.commandName = commandName
    this.dependencies = dependencies
  }

  getMetadata(): Metadata {
    return {
      startupCommand: {
        name: this.commandName,
        dependencies: this.dependencies
      }
    }
  }
}
