import useEmotionStyles from 'hooks/useEmotionStyles'
import { memo, PropsWithChildren, useCallback } from 'react'
import { trackInAmplitude } from 'utils/tracking/amplitude'
import { AmplitudeEvent } from 'utils/tracking/amplitude/amplitude.interface'

export type SealProps = PropsWithChildren<{ url: string; name: string }>

const styles = () => ({
  seal: {
    '& a': {
      display: 'flex',
      textDecoration: 'none'
    }
  }
})

const Seal = ({ children, url, name }: SealProps) => {
  const { seal } = useEmotionStyles(styles)

  const onGoToUrl = useCallback(() => {
    trackInAmplitude(AmplitudeEvent.VisitSealUrl, {
      name,
      url
    })
  }, [name, url])

  return (
    <div className={seal}>
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={onGoToUrl}
      >
        {children}
      </a>
    </div>
  )
}

export default memo<SealProps>(Seal)
