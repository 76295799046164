import { observer } from 'mobx-react'
import { PropsWithChildren, memo } from 'react'
import { useError } from 'stores/utils/hooks/useError'

export const FORMFIELD_ERROR_CLASS_SELECTOR = '.Mui-error, .formfield.error'

interface ErrorWrapperProps {
  path: string
}

const ErrorWrapper = observer(
  ({ children, path }: PropsWithChildren<ErrorWrapperProps>) => {
    const { error } = useError(path)

    return (
      <div
        className={`formfield${error ? ' error' : ''}`}
        data-error-path={error ? path : undefined}
      >
        {children}
      </div>
    )
  }
)

export default memo(ErrorWrapper)
