import { Data } from 'interfaces/ApolloResponse.interface'
import isNotAffiliate from './isNotAffiliate'

const isNotMortgage = (traversalData: Data, context?: string) => {
  const {
    system: { affiliateId },
    common: { amount, purpose }
  } = traversalData
  const isMortgage =
    affiliateId === 200 &&
    isNotAffiliate(context) &&
    ((purpose === 'RENOVATION' && amount > 120000) ||
      (purpose === 'REAL_ESTATE_FINANCING' && amount > 75000))

  return !isMortgage
}

export default isNotMortgage
