import React, { FunctionComponent, memo } from 'react'
import { Link } from '@mui/material'

const TextLink: FunctionComponent<{
  link?: string
  children: string
}> = memo(({ link, children }) => (
  <Link
    href={link}
    target="_blank"
    rel="noopener noreferrer"
    underline="hover"
    data-testid="textlink"
    fontWeight="bold"
  >
    {children}
  </Link>
))

TextLink.displayName = 'TextLink'

export default TextLink
