import { AppEnvironment } from 'utils/env'

export const clientDetails = {
  [AppEnvironment.Shared]: {
    uuid: '2245defb-94aa-4c24-9bb1-ce9c06e097f8',
    displayName: 'FINANZCHECK.de',
    street: 'Admiralitätstraße 60',
    city: 'Hamburg',
    country: 'DE',
    zipCode: '11111',
    phone: '+49 (0) 800 433 88 77',
    workingTimes: 'Mo - So von 08 - 20 Uhr',
    email: 'test@finanzcheck.de',
    company: 'FFG FINANZCHECK Finanzportale GmbH',
    logos: {
      client: 'https://cdn.finanzcheck.de/assets/finanzcheck_logo.svg',
      ekomi: 'https://cdn.finanzcheck.de/assets/ekomi_badge.svg',
      schufaNeutral: 'https://cdn.finanzcheck.de/assets/schufa_neutral.svg',
      ssl: 'https://cdn.finanzcheck.de/assets/ssl.png',
      tuev: 'https://cdn.finanzcheck.de/assets/tuev_horizontal_45971.png'
    },
    urls: {
      dac: 'https://home.finanzcheck-shared.de/mein-kontoblick/',
      dataProtection:
        'https://website.finanzcheck-shared.de/uber-uns/datenschutz/',
      ekomi: 'https://www.ekomi.de/bewertungen-finanzcheck.html',
      faq: 'https://website.finanzcheck-shared.de/faq/kontoblick/',
      faqHome: 'https://home.finanzcheck-shared.de/faq',
      home: 'https://home.finanzcheck-shared.de/',
      imprint: 'https://website.finanzcheck-shared.de/uber-uns/impressum/',
      logout: 'https://home.finanzcheck-shared.de/logout',
      mandatoryInformation:
        'https://legal-docs.finanzcheck-shared.de/mandatoryInformation.pdf',
      precontractual:
        'https://website.finanzcheck-shared.de/uber-uns/pflichtinformation/darlehensvermittlung',
      startPage: 'https://website.finanzcheck-shared.de',
      termsAndConditions: 'https://website.finanzcheck-shared.de/uber-uns/agb/',
      tuev: 'https://www.tuev-saar.de/zertifikat/tk45971/'
    }
  },
  [AppEnvironment.Stage]: {
    uuid: '744e3a15-6238-4c2e-9c39-8411cf2b6a1b',
    displayName: 'FINANZCHECK.de',
    street: 'Admiralitätstraße 60',
    city: 'Hamburg',
    country: 'DE',
    zipCode: '20457',
    phone: '0800 433 88 77',
    workingTimes: 'Mo - So von 08 - 20 Uhr',
    email: 'mandant@mcc.finanzcheck.de',
    company: 'FFG FINANZCHECK Finanzportale GmbH',
    logos: {
      client: 'https://cdn.finanzcheck.de/assets/finanzcheck_logo.svg',
      ekomi: 'https://cdn.finanzcheck.de/assets/ekomi_badge.svg',
      schufaNeutral: 'https://cdn.finanzcheck.de/assets/schufa_neutral.svg',
      ssl: 'https://cdn.finanzcheck.de/assets/ssl.png',
      tuev: 'https://cdn.finanzcheck.de/assets/tuev_horizontal_45971.png'
    },
    urls: {
      dac: 'https://home.finanzcheck-stage.de/mein-kontoblick/',
      dataProtection:
        'https://website.finanzcheck-stage.de/uber-uns/datenschutz/',
      ekomi: 'https://www.ekomi.de/bewertungen-finanzcheck.html',
      faq: 'https://website.finanzcheck-stage.de/faq/kontoblick/',
      faqHome: 'https://home.finanzcheck-stage.de/faq',
      home: 'https://home.finanzcheck-stage.de/',
      imprint: 'https://website.finanzcheck-stage.de/uber-uns/impressum/',
      logout: 'https://home.finanzcheck-stage.de/logout',
      mandatoryInformation:
        'https://legal-docs.finanzcheck-stage.de/mandatoryInformation.pdf',
      precontractual:
        'https://website.finanzcheck-stage.de/uber-uns/pflichtinformation/darlehensvermittlung',
      startPage: 'https://website.finanzcheck-stage.de',
      termsAndConditions: 'https://website.finanzcheck-stage.de/uber-uns/agb/',
      tuev: 'https://www.tuev-saar.de/zertifikat/tk45971/'
    }
  },
  [AppEnvironment.Production]: {
    uuid: 'de0b41bd-175b-4b13-adf8-eef7450bf698',
    displayName: 'FINANZCHECK.de',
    street: 'Admiralitätstraße 60',
    city: 'Hamburg',
    country: 'DE',
    zipCode: '20459',
    phone: '+49 (0) 800 433 88 77',
    workingTimes: 'Mo - So von 08 - 20 Uhr',
    email: 'kredite@finanzcheck.de',
    company: 'FFG FINANZCHECK Finanzportale GmbH',
    logos: {
      client: 'https://cdn.finanzcheck.de/assets/finanzcheck_logo.svg',
      ekomi: 'https://cdn.finanzcheck.de/assets/ekomi_badge.svg',
      schufaNeutral: 'https://cdn.finanzcheck.de/assets/schufa_neutral.svg',
      ssl: 'https://cdn.finanzcheck.de/assets/ssl.png',
      tuev: 'https://cdn.finanzcheck.de/assets/tuev_horizontal_45971.png'
    },
    urls: {
      dac: 'https://home.finanzcheck.de/mein-kontoblick/',
      dataProtection: 'https://www.finanzcheck.de/uber-uns/datenschutz/',
      ekomi: 'https://www.ekomi.de/bewertungen-finanzcheck.html',
      faq: 'https://www.finanzcheck.de/faq/kontoblick/',
      faqHome: 'https://home.finanzcheck.de/faq',
      home: 'https://home.finanzcheck.de/',
      imprint: 'https://www.finanzcheck.de/uber-uns/impressum/',
      logout: 'https://home.finanzcheck.de/logout',
      mandatoryInformation:
        'https://legal-docs.finanzcheck.de/mandatoryInformation.pdf',
      precontractual:
        'https://finanzcheck.de/uber-uns/pflichtinformation/darlehensvermittlung',
      startPage: 'https://www.finanzcheck.de',
      termsAndConditions: 'https://www.finanzcheck.de/uber-uns/agb/',
      tuev: 'https://www.tuev-saar.de/zertifikat/tk45971/'
    }
  }
}
