import { logAndTrackError } from 'utils/log/logAndTrackError'
import ResumeError from 'error/resumeError'
import { isTenantSmava, UrlParams } from 'utils/url'
import { AFFILIATE } from 'constants/components'
import RootStore from 'stores/RootStore'
import TraversalStore from 'stores/TraversalStore'

import { NavigateFunction } from 'react-router-dom'
import { StartupCommand } from './command.interface'
import { isDeviceMotorollaGPower } from 'utils/device'

export class ResumeTraversalCommand implements StartupCommand {
  name = 'ResumeTraversal'
  dependencies = ['InitFcid', 'InitNavigation']
  timeout = 10000

  // eslint-disable-next-line class-methods-use-this
  private getResumeHash = (
    resumeParam: string | undefined,
    traversal: TraversalStore,
    context?: string
  ) => {
    const tenantSmava = isTenantSmava()
    const logResumeHashError =
      context !== AFFILIATE && !tenantSmava && !isDeviceMotorollaGPower()

    const { nextResumeHash, traversalId } = traversal

    if (nextResumeHash) {
      return nextResumeHash
    }

    if (resumeParam) {
      return resumeParam
    }

    /* affiliates and smava will never have resume hash initially */
    if (logResumeHashError) {
      logAndTrackError(
        new ResumeError(
          'Could not get resume hash from localStorage',
          traversalId
        )
      )
    }

    return undefined
  }

  execute = async (
    rootStore: RootStore,
    _navigate: NavigateFunction,
    urlParams: UrlParams
  ) => {
    const resumeHash = this.getResumeHash(
      urlParams.resume,
      rootStore.traversal,
      urlParams.context
    )
    const { purpose, amount, term, vehiclePrice } = urlParams

    const updateTraversalDataFormUrl = () => {
      rootStore.setResumeDetailsFetched(true)
      rootStore.traversal.updateDataInTraversal({
        common: {
          ...(amount && { amount }),
          ...(purpose && {
            purpose: purpose === 'OTHER_GEWERBE' ? 'OTHER' : purpose
          }),
          ...(term && { term }),
          ...(vehiclePrice && {
            vehicle: {
              price: vehiclePrice
            }
          })
        }
      })
    }

    if (!resumeHash) {
      updateTraversalDataFormUrl()
      return
    }

    try {
      await rootStore.traversal.resume(resumeHash)
      updateTraversalDataFormUrl()
    } catch (error) {
      logAndTrackError(error as Error)
    }
  }
}

export default ResumeTraversalCommand
