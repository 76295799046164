import { useCallback, useEffect, memo } from 'react'
import Grow from '@mui/material/Grow'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Banner, BannerType } from '@finanzcheck/teal-components'
import { AmplitudeEvent } from 'utils/tracking/amplitude/amplitude.interface'
import { trackInAmplitude } from 'utils/tracking/amplitude'
import { useClientDetails } from 'stores/utils/hooks/useClientDetails'

interface HotlineFallbackProps {
  message: string
  isIconVersionOutline?: boolean
}

const HotlineFallback = ({
  message,
  isIconVersionOutline
}: HotlineFallbackProps) => {
  const clientDetails = useClientDetails()

  const handleClick = useCallback(() => {
    trackInAmplitude(AmplitudeEvent.UseHotlineFallback)
  }, [])

  useEffect(() => {
    trackInAmplitude(AmplitudeEvent.ShowHotlineFallback)
  }, [])

  if (!clientDetails?.phone) {
    return null
  }

  return (
    <Grow in>
      <div onClick={handleClick}>
        <Banner
          type={BannerType.WARNING}
          isIconVersionOutline={isIconVersionOutline}
          title={message}
          desc={
            <>
              Unsere Kreditexperten helfen Ihnen gerne weiter, damit Sie die
              besten Angebote erhalten.
              <br />
              <br />
              Rufen Sie uns einfach kostenlos an:
            </>
          }
          linkHref={`tel:${clientDetails.phone}`}
          linkLabel={clientDetails.phone}
        />
      </div>
    </Grow>
  )
}

export default memo(HotlineFallback)
