import { FunctionComponent, memo, useEffect } from 'react'
import {
  RegisteredEventName,
  broadcastToParent
} from '@finanzcheck/catalyst-pollard'
import { FintecState } from '@finanzcheck/ti-fts-widget'
import { Box, Typography, Alert, AlertTitle } from '@mui/material'
import { AmplitudeEvent } from 'utils/tracking/amplitude/amplitude.interface'
import { COOKIE_KEY_FCID, getCookie } from 'utils/cookie'
import { getOptimizelyTrackingAttribute } from 'utils/tracking'
import { useData } from 'stores/utils/hooks/useData'
import { trackInAmplitude } from 'utils/tracking/amplitude'
import { DACEvent } from '.'

interface Props {
  state: FintecState
  widget: JSX.Element | null
}

const DacWidget: FunctionComponent<Props> = ({ state, widget }) => {
  const { data } = useData()
  const trackWithOrigin = (event: AmplitudeEvent, payload = {}) => {
    trackInAmplitude(event, { origin: 'taurine', ...payload })
  }

  const trackDacStepView = (
    event: DACEvent | null,
    amplitudeEvent: AmplitudeEvent
  ) => {
    trackWithOrigin(amplitudeEvent)

    if (event) {
      broadcastToParent({
        eventName: RegisteredEventName.tracking,
        data: {
          event,
          context: {
            Optimizely: { userId: getCookie(COOKIE_KEY_FCID) },
            ...getOptimizelyTrackingAttribute(data)
          }
        }
      })
    }
  }

  useEffect(() => {
    let isAccessConfirmationStepViewEventSent = false
    let isRegistrationSuccessfulStepViewEventSent = false

    const trackDacStepViews = (
      isAccessConfirmationStepTitleVisible: boolean | null,
      isRegistrationSuccessfulStepTitleVisible: boolean | null
    ) => {
      if (
        isAccessConfirmationStepTitleVisible &&
        !isAccessConfirmationStepViewEventSent
      ) {
        trackDacStepView(null, AmplitudeEvent.OnDacStepViewAccessConfirmation)
        isAccessConfirmationStepViewEventSent = true
      }

      if (
        isRegistrationSuccessfulStepTitleVisible &&
        !isRegistrationSuccessfulStepViewEventSent
      ) {
        trackDacStepView(
          null,
          AmplitudeEvent.OnDacStepViewRegistrationSuccessful
        )
        isRegistrationSuccessfulStepViewEventSent = true
      }
    }

    const callback = (mutationsList: MutationRecord[]) => {
      if (mutationsList.length > 0) {
        const headersList = document.querySelectorAll('h3')
        const headersArray = Array.from(headersList)
        const isAccessConfirmationStepTitleVisible = headersArray.some(
          (header) => header.innerHTML === 'Bestätigung des Kontozugriffs'
        )
        const isRegistrationSuccessfulStepTitleVisible = headersArray.some(
          (header) => header.innerHTML === 'Anmeldung erfolgreich'
        )

        trackDacStepViews(
          isAccessConfirmationStepTitleVisible,
          isRegistrationSuccessfulStepTitleVisible
        )
      }
    }

    const taurineFintecWidgetObserver = new MutationObserver(callback)
    const taurineFintecWidget = document.querySelector('#taurine-fintec-widget')
    const config = { childList: true, subtree: true }

    if (taurineFintecWidget) {
      taurineFintecWidgetObserver.observe(taurineFintecWidget, config)
    }

    return () => {
      taurineFintecWidgetObserver.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (state === 'success') {
    return (
      <Box mx={1} mb={2} data-testid="dac-widget-success">
        <Alert severity="success">
          <AlertTitle>
            <b>Super</b>
          </AlertTitle>
          <Typography variant="body2">
            Sie haben den Digitalen Kontoauszug bereits erfolgreich
            durchgeführt!
          </Typography>
        </Alert>
      </Box>
    )
  }

  return (
    <Box mb={1} mx={0} data-testid="dac-widget">
      {widget}
    </Box>
  )
}

export default memo(DacWidget)
