import Grid from '@mui/material/Grid'
import { Skeleton } from '@mui/material'

const RadiosSkeleton = () => (
  <Grid container spacing={1} direction="row">
    <Grid item xs={5}>
      <Skeleton variant="rectangular" height={24} />
    </Grid>
    <Grid item xs={7} />
    <Grid item xs={4}>
      <Skeleton variant="rectangular" height={24} />
    </Grid>
    <Grid item xs={8} />
    <Grid item xs={4}>
      <Skeleton variant="rectangular" height={24} />
    </Grid>
    <Grid item xs={8} />
  </Grid>
)

export default RadiosSkeleton
