export const excludeFromCloack = [
  'common.amount',
  'common.term',
  'common.purpose',
  'debtors.count',
  'debtors.primary.personal.gender',
  'debtors.secondary.personal.gender',
  'debtors.primary.personal.civilStatus',
  'debtors.secondary.personal.civilStatus',
  'debtors.primary.household.numberOfResidents',
  'debtors.secondary.household.numberOfResidents',
  'debtors.primary.income.numberOfChildrenWithChildBenefits',
  'debtors.secondary.income.numberOfChildrenWithChildBenefits',
  'debtors.primary.household.numberOfChildren',
  'debtors.secondary.household.numberOfChildren',
  'debtors.primary.household.livingSituation',
  'debtors.secondary.household.livingSituation',
  'debtors.primary.realEstate.rentalProperty.type',
  'debtors.secondary.realEstate.rentalProperty.type',
  'debtors.primary.realEstate.rentalProperty.size',
  'debtors.secondary.realEstate.rentalProperty.size',
  'debtors.primary.expenses.additionalHousingCosts',
  'debtors.secondary.expenses.additionalHousingCosts',
  'debtors.primary.income.spousalSupport',
  'debtors.secondary.income.spousalSupport',
  'debtors.primary.income.numberOfChildrenWithChildSupport',
  'debtors.secondary.income.numberOfChildrenWithChildSupport',
  'debtors.primary.currentEmployment.position',
  'debtors.secondary.currentEmployment.position',
  'debtors.primary.income.primary',
  'debtors.secondary.income.primary',
  'debtors.primary.income.secondaryEmployments',
  'debtors.secondary.income.secondaryEmployments',
  'debtors.primary.income.pension',
  'debtors.secondary.income.pension',
  'debtors.primary.personal.birthplace',
  'debtors.secondary.personal.birthplace',
  'debtors.primary.residentSince',
  'debtors.secondary.residentSince',
  'debtors.primary.currentEmployment.sector',
  'debtors.secondary.currentEmployment.sector',
  'debtors.primary.currentEmployment.workingTime',
  'debtors.secondary.currentEmployment.workingTime',
  'debtors.primary.currentAddress.country',
  'debtors.secondary.currentAddress.country',
  'debtors.primary.activeLoans',
  'debtors.secondary.activeLoans',
  'debtors.primary.income.secondaryEmployments[0].income',
  'debtors.secondary.income.secondaryEmployments[0].income',
  'debtors.primary.income.secondaryEmployments[1].income',
  'debtors.secondary.income.secondaryEmployments[1].income',
  'debtors.primary.income.secondaryEmployments[2].income',
  'debtors.secondary.income.secondaryEmployments[2].income',
  'debtors.primary.expenses.privateHealthInsuranceRate',
  'debtors.secondary.expenses.privateHealthInsuranceRate',
  'debtors.primary.personal.countryofBirth',
  'debtors.secondary.personal.countryofBirth',
  'debtors.primary.personal.nationality',
  'debtors.secondary.personal.nationality',
  'debtors.primary.realEstate.selfUsedProperty.type',
  'debtors.primary.realEstate.selfUsedProperty.size',
  'debtors.secondary.realEstate.selfUsedProperty.type',
  'debtors.secondary.realEstate.selfUsedProperty.size',
  'debtors.primary.currentAddress.zipCode',
  'debtors.secondary.currentAddress.zipCode',
  'debtors.primary.currentAddress.city',
  'debtors.secondary.currentAddress.city',
  'debtors.primary.currentEmployment.isTemporary',
  'debtors.secondary.currentEmployment.isTemporary',
  'debtors.primary.currentEmployment.employer.address.country',
  'debtors.secondary.currentEmployment.employer.address.country',
  'debtors.primary.currentEmployment.isGermanSalaryAccount',
  'debtors.secondary.currentEmployment.isGermanSalaryAccount',
  'common.rdi.type',
  'common.loanPayout',
  'common.rateDueOn'
]

export enum FieldInteraction {
  onChange = 'onChange',
  onBlur = 'onBlur',
  onFocus = 'onFocus'
}

interface Stringable {
  toString: () => string
}

function isStringable(value: unknown): value is Stringable {
  return (value as Stringable).toString !== undefined
}

function castToString(value: unknown): string {
  if (typeof value === 'string') {
    return value
  }

  if (isStringable(value)) {
    return value.toString()
  }

  return `${value}`
}

export function cloakData<T>(value: T, path?: string) {
  if (
    path &&
    excludeFromCloack.indexOf(path) > -1 &&
    typeof value !== 'object'
  ) {
    return value
  }
  if (typeof value === 'boolean') {
    return value ? '<TRUE>' : '<FALSE>'
  }

  if (
    (typeof value === 'string' && value.length === 0) ||
    value === undefined ||
    value === null
  ) {
    return '<EMPTY>'
  }

  return castToString(value).replace(/\w/g, 'x')
}
