import RootStore from 'stores/RootStore'
import { logAndTrackError } from 'utils/log/logAndTrackError'
import TraversalError from 'error/traversalError'
import { isTenantSmava, UrlParams } from 'utils/url'
import { AFFILIATE } from 'constants/components'
import { NavigateFunction } from 'react-router-dom'
import { StartupCommand } from './command.interface'
import { isDeviceMotorollaGPower } from 'utils/device'

class GetTraversalCommand implements StartupCommand {
  name = 'GetTraversal'
  dependencies = ['ResumeTraversal']
  timeout = 10000

  // eslint-disable-next-line class-methods-use-this
  execute = async (
    { traversal }: RootStore,
    _navigate: NavigateFunction,
    urlParams: UrlParams
  ) => {
    if (urlParams.resume || traversal.nextResumeHash) {
      return
    }

    const { get, traversalId } = traversal
    const tenantSmava = isTenantSmava()

    const logTraversalMissingError =
      urlParams.context !== AFFILIATE &&
      !tenantSmava &&
      !isDeviceMotorollaGPower()

    try {
      await get(logTraversalMissingError)
    } catch (traversalError) {
      logAndTrackError(
        (traversalError as Error) ||
          new TraversalError('GET request failed', traversalId),
        'info'
      )
    }
  }
}

export default GetTraversalCommand
