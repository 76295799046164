import { useEffect } from 'react'
import { NavigateFunction } from 'react-router-dom'
import { useRootStore } from 'stores/utils/hooks/useRootStore'

const useAutoProceedAfter = (
  proceedAfter: number,
  navigate: NavigateFunction
) => {
  const {
    rootStore: {
      navigation: { goToNextPage }
    }
  } = useRootStore()

  useEffect(() => {
    const timeout = setTimeout(async () => {
      await goToNextPage(navigate)
    }, proceedAfter)

    return () => {
      clearTimeout(timeout)
    }
  }, [goToNextPage, proceedAfter, navigate])
}

export const useAutoProceedPage = (
  proceedAfter: number,
  navigate: NavigateFunction
) => {
  useAutoProceedAfter(proceedAfter, navigate)
}
