import { HTMLProps, memo } from 'react'
import snarkdown from 'snarkdown'

const snarkdownWithExternalLinks = (markdown: string) =>
  snarkdown(markdown).replace(
    /<a /g,
    '<a target="_blank" rel="noopener noreferrer" '
  )

const Markdown = ({ children, className }: HTMLProps<HTMLDivElement>) => {
  if (typeof children !== 'string') {
    return <div>{children}</div>
  }

  return (
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: snarkdownWithExternalLinks(children) }}
      className={className}
    />
  )
}

export default memo(Markdown)
