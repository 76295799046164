import { PaletteOptions } from '@mui/material/styles'
import { logAndTrackError } from 'utils/log/logAndTrackError'

const castToPalette = (value: string | null): PaletteOptions | undefined => {
  if (!value) {
    return undefined
  }

  const decodedValue = decodeURIComponent(value)

  try {
    return JSON.parse(decodedValue) as PaletteOptions
  } catch {
    logAndTrackError(
      new Error(`Could not parse URL param palette: ${decodedValue}`)
    )
    return undefined
  }
}

export default castToPalette
