import { Theme } from '@mui/material/styles'
import * as CSSTypes from 'csstype'
import useEmotionStyles from 'hooks/useEmotionStyles'

export enum TagType {
  INFO = 'info',
  LIGHT = 'light'
}

interface StyleProps {
  type?: TagType
}

const getBackgroundColor = (theme: Theme, type?: TagType): string => {
  if (type === TagType.LIGHT) {
    return theme.palette.grey[100]
  }

  // blue doesn't exist in palette, using the exact hash value
  return '#E8EEF5'
}

const getColor = (theme: Theme, type?: TagType): string => {
  if (type === TagType.LIGHT) {
    return theme.palette.grey[600]
  }

  // blue doesn't exist in palette, using the exact hash value
  return '#165297'
}

const styles = (theme: Theme, { type }: StyleProps) => ({
  tagStyled: {
    width: 'fit-content',
    padding: theme.spacing(0.25, 1),
    borderRadius: theme.spacing(0.5),
    background: getBackgroundColor(theme, type),
    color: getColor(theme, type),
    fontSize: '9px',
    fontWeight: '600',
    letterSpacing: '0.4px',
    textTransform: 'uppercase' as CSSTypes.Property.TextTransform
  }
})

interface Props {
  children?: string
  type?: TagType
}

const Tag = ({ children, type }: Props): React.ReactElement => {
  const classes = useEmotionStyles<ReturnType<typeof styles>, StyleProps>(
    styles,
    { type }
  )
  return <div className={classes.tagStyled}>{children}</div>
}

export default Tag
