// 👇 Import this, if you want to check issues related to rendering.
// import './wdyr'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import AppContainer from './AppContainer'
import './polyfills'

declare global {
  interface Window {
    // This function is only for the pre-rendering build step
    renderCB: () => void
  }
}

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <Router>
    <AppContainer />
  </Router>
)
