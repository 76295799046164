import { DependencyCheckOrTree } from 'components/ConditionalField/dependencies'
import {
  SecondaryEmploymentsItem,
  Data
} from '../../interfaces/ApolloResponse.interface'
import { dateIsOlderThan } from '../../utils/date'
import { jobsSelfEmployed } from '../../utils/formDependencies/statics/lists'

function isMoreThan6Month(_: Data, item: SecondaryEmploymentsItem | null) {
  return item ? dateIsOlderThan(item.since, { month: 6 }) : false
}

function isProvable(_: Data, item: SecondaryEmploymentsItem | null): boolean {
  return item ? item.isIncomeProvable : false
}

function isSecondaryTemporary(
  state: Data,
  item: SecondaryEmploymentsItem | null
): boolean {
  if (!isProvable(state, item)) {
    return false
  }

  return item ? item.isTemporary : false
}

function isSelfEmployedInSecondaryEmployment(
  state: Data,
  item: SecondaryEmploymentsItem | null
): boolean {
  if (!isProvable(state, item)) {
    return false
  }

  return item ? jobsSelfEmployed.includes(item.type) : false
}

function isNotSelfEmployedInSecondaryEmployment(
  state: Data,
  item: SecondaryEmploymentsItem | null
): boolean {
  return !isSelfEmployedInSecondaryEmployment(state, item)
}

const secondaryEmploymentDependencies: DependencyCheckOrTree<Data> = {
  isExtended: (state, item: SecondaryEmploymentsItem | null) =>
    !!(
      !item?.isInProbationPeriod &&
      isSecondaryTemporary(state, item) &&
      isProvable(state, item)
    ),
  isGermanSalaryAccount: isProvable,
  isIncomeStable: (state, item: SecondaryEmploymentsItem | null) =>
    !!(
      !!item?.since &&
      isProvable(state, item) &&
      !isMoreThan6Month(state, item)
    ),
  isTemporary: (state, item: SecondaryEmploymentsItem | null) =>
    !!isProvable(state, item),
  numberOfYears: isProvable,
  type: isProvable,
  position: isSelfEmployedInSecondaryEmployment,
  occupation: isSelfEmployedInSecondaryEmployment,
  sector: isProvable,
  since: isProvable,
  until: (state, item: SecondaryEmploymentsItem | null) =>
    !!(isProvable(state, item) && isSecondaryTemporary(state, item)),
  isInProbationPeriod: (state, item: SecondaryEmploymentsItem | null) =>
    !!(
      !isMoreThan6Month(state, item) &&
      isProvable(state, item) &&
      isNotSelfEmployedInSecondaryEmployment(state, item)
    )
}

export default secondaryEmploymentDependencies
