import { executeAsync } from 'utils/performance'
import Bugsnag from '@bugsnag/js'
import { logAndTrackError } from 'utils/log/logAndTrackError'
import { RegisteredEventName } from '@finanzcheck/catalyst-pollard'
import PollardError from 'error/pollardError'
import { logSuccess } from 'utils/log'
import { FrameHostData } from 'stores/TraversalStore'
import pollard from 'utils/pollard'
import RootStore from 'stores/RootStore'
import { StartupCommand } from './command.interface'

export class InitFrameHostDataCommand implements StartupCommand {
  name = 'InitFrameHostData'
  dependencies = []
  timeout = 5000

  execute = async ({ traversal, fcid }: RootStore) => {
    executeAsync(() => {
      Bugsnag.addMetadata('user', {
        origin: document.referrer
      })
    })

    try {
      const data: FrameHostData | string | undefined = await pollard.request(
        {
          eventName: RegisteredEventName.updateCurrentImodTraversal,
          data: {
            requestLatestTraversal: true
          }
        },
        this.timeout
      )

      if (!data) {
        logAndTrackError(
          new PollardError(
            'Did not get any frame host data.',
            RegisteredEventName.updateCurrentImodTraversal
          )
        )
        return
      }

      const hostData = typeof data === 'string' ? JSON.parse(data) : data

      traversal.setTraversalDataFromFrameHost(hostData)
      fcid.setFcidFromFrameHost(hostData)

      logSuccess(`Received parent data: ${JSON.stringify(data, null, 2)}`)
    } catch (error) {
      logAndTrackError(error as Error)
    }
  }
}

export default InitFrameHostDataCommand
