import { useField } from 'react-final-form'
import { usePatchedField } from 'stores/utils/hooks/usePatchedField'
import AccountType from 'utils/formDependencies/statics/enums/AccountType'

export const IBAN_PATH = 'common.payoutAccount.iban'
const ACCOUNT_NUMBER_PATH = 'common.payoutAccount.accountNumber'
const BANKCODE_PATH = 'common.payoutAccount.bankCode'

export const usePayoutAccountValidation = (isTouchedIgnored?: boolean) => {
  const payoutAccountType = usePatchedField('common.payoutAccount.type', {})

  const ibanField = useField(IBAN_PATH, {})
  const ibanPatchedField = usePatchedField(IBAN_PATH, {})
  const accountNumberField = useField(ACCOUNT_NUMBER_PATH, {})
  const accountNumberPatchedField = usePatchedField(ACCOUNT_NUMBER_PATH, {})
  const bankCodePatchedField = usePatchedField(BANKCODE_PATH, {})
  const bankCodeField = useField(BANKCODE_PATH, {})

  const isIbanFieldReady =
    payoutAccountType.input.value === AccountType.IBAN_BIC &&
    // iban field meta data
    (ibanField.meta.touched || isTouchedIgnored) &&
    !ibanField.meta.active &&
    // iban field value
    ibanField.input.value &&
    !ibanPatchedField.error

  const isAccountNumberFieldReady =
    payoutAccountType.input.value === AccountType.ACCOUNTNUMBER_BANKCODE &&
    // account number field value
    accountNumberField.input.value &&
    !accountNumberPatchedField.error &&
    // bank code field value
    bankCodeField.input.value &&
    !bankCodePatchedField.error &&
    // account number field meta data
    (accountNumberField.meta.touched || isTouchedIgnored) &&
    !accountNumberField.meta.active &&
    // bank code field meta data
    (bankCodeField.meta.touched || isTouchedIgnored) &&
    !bankCodeField.meta.active

  return isIbanFieldReady || isAccountNumberFieldReady
}
