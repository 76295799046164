import BugsnagError, { Metadata } from './bugsnagError'

export default class DACInitializedError extends BugsnagError {
  public missingParameters: Record<string, string>

  constructor(missingParameters: Record<string, string>) {
    super('Parameters missing when DAC is Intialized')

    this.name = 'DACInitializedError'
    this.missingParameters = missingParameters
  }

  getMetadata(): Metadata {
    return {
      dacParameters: {
        missingParameters: this.missingParameters
      }
    }
  }
}
