import {
  TraversalError,
  TraversalErrorType
} from '../../interfaces/ApolloResponse.interface'
import { isDate, parseBackendDate } from './date'
import { getMinMaxError } from './getMinMaxError'

export type ErrorMap = Record<
  TraversalErrorType,
  (error: TraversalError) => string
>

export const DEFAULT_ERROR_MESSAGE_MAP: ErrorMap = {
  [TraversalErrorType.MISSING]: ({ actual }) => {
    if (isDate(actual)) {
      return 'Bitte geben Sie ein Datum an.'
    }

    return 'Bitte geben Sie etwas in dieses Feld ein.'
  },
  [TraversalErrorType.WRONG_TYPE]: ({ actual }) => {
    if (isDate(actual)) {
      return 'Bitte geben Sie ein Datum an.'
    }

    return 'Bitte benutzen Sie den richtigen Typ.'
  },
  [TraversalErrorType.WRONG_FORMAT]: ({ expected }) => {
    if (expected === 'date') {
      return 'Bitte benutzen Sie ein gültiges Datumsformat.'
    }

    return 'Bitte benutzen Sie das richtige Format.'
  },
  [TraversalErrorType.INVALID]: ({ actual }) => {
    if (isDate(actual)) {
      return 'Bitte geben Sie ein gültiges Datum an.'
    }

    return 'Bitte geben Sie einen gültigen Wert ein.'
  },
  [TraversalErrorType.OUT_OF_RANGE]: ({ actual, min, max }) => {
    if (isDate(actual)) {
      const minDate = parseBackendDate(min)
      const maxDate = parseBackendDate(max)

      return getMinMaxError(minDate, maxDate, {
        min: (minValue) => `Werte ab dem ${minValue} sind gültig.`,
        max: (maxValue) => `Werte bis zum ${maxValue} sind gültig.`,
        minMax: (minValue, maxValue) =>
          `Das Datum muss zwischen dem ${minValue} und dem ${maxValue} liegen.`,
        none: 'Bitte geben Sie ein gültiges Datum an.'
      })
    }

    return getMinMaxError(min, max, {
      min: (minValue) => `Der Wert muss mindestens ${minValue} betragen.`,
      max: (maxValue) => `Der Wert darf maximal ${maxValue} betragen.`,
      minMax: (minValue, maxValue) =>
        `Der Wert muss zwischen ${minValue} und ${maxValue} liegen.`,
      none: 'Bitte geben Sie einen zugelassenen Wert ein.'
    })
  },
  [TraversalErrorType.WRONG_LENGTH]: ({ actual }) => {
    if (isDate(actual)) {
      return 'Das Datum scheint eine ungültige Länge zu haben.'
    }

    return 'Bitte halten Sie sich an die vorgegebene Länge.'
  },
  [TraversalErrorType.CONTAINS_SPECIAL_CHARACTERS]: () => {
    return 'Sonderzeichen sind für dieses Feld nicht erlaubt.'
  }
}

export function mapErrorMessage(
  error: TraversalError,
  msgProviderMap?: Partial<Record<TraversalErrorType, string>>
): string {
  const providedMessage = msgProviderMap && msgProviderMap[error.id]

  if (providedMessage) {
    // TODO: Our own defined messages should support placeholders as well in the future :)
    return providedMessage
  }

  return DEFAULT_ERROR_MESSAGE_MAP[error.id](error)
}

/**
 * Executes mapErrorMessage function for more than one given errorType (ID)
 */
export function mapErrorMessages(
  errors: TraversalError[],
  msgProviderMap?: Partial<Record<TraversalErrorType, string>>
): string {
  return Array.from(
    new Set(errors.map((error) => mapErrorMessage(error, msgProviderMap)))
  ).join(' ')
}
