import { AxiosError, AxiosRequestConfig } from 'axios'

export function getFullUrl({ baseURL = '/', url = '' }: AxiosRequestConfig) {
  if (url.charAt(0) === '/') {
    return baseURL + url.substring(1)
  }

  return baseURL + url
}

export function isAxiosError(error: unknown): error is AxiosError {
  return (error as AxiosError).isAxiosError
}
