const loanPurpose = [
  { label: 'Umschuldung', value: 'REFINANCING' },
  { label: 'Gebrauchtfahrzeug', value: 'PRE_OWNED_CAR' },
  { label: 'Neufahrzeug', value: 'NEW_CAR' },
  { label: 'Einrichtung/Möbel', value: 'FURNITURE' },
  { label: 'Renovierung', value: 'RENOVATION' },
  { label: 'Urlaub', value: 'HOLIDAY' },
  { label: 'PC/TV/HiFi', value: 'ENTERTAINMENT_ELECTRONICS' },
  { label: 'Ausgleich Girokonto', value: 'ACCOUNT_BALANCE' },
  { label: 'Umzug', value: 'MOVE' },
  /**
   * Users can select this on the WS when the amount is <= 50k.
   * Otherwise, they will be redirected to a special LP.
   * However, we don't want to deal with this in Taurine..
   */
  // { label: 'Immobilienfinanzierung', value: 'real_estate_financing' },
  { label: 'Freie Verwendung', value: 'OTHER' }
]

export default loanPurpose
