const activeLoanType = [
  { label: 'Konsumentenkredit', value: 'CONSUMER_CREDIT' },
  { label: '0% Finanzierung', value: 'ZERO_PERCENT_FINANCING' },
  { label: 'Autokredit', value: 'CAR_LOAN' },
  { label: 'Rahmenkredit', value: 'CREDIT_LINE' },
  { label: 'Kreditkarte', value: 'CREDITCARD' },
  { label: 'Dispositionskredit', value: 'OVERDRAFT_LOAN' },
  { label: 'Geschäftskredit', value: 'BUSINESS_LOAN' },
  { label: 'Leasing', value: 'LEASING' },
  { label: 'Arbeitgeberdarlehnen', value: 'EMPLOYERS_LOAN' },
  {
    label: 'Ballonfinanzierung/Schlussratenfinanzierung',
    value: 'BALLOON_FINANCING'
  }
]

export default activeLoanType
