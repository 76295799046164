import { ReactText, useContext } from 'react'
import { FieldMetaWithOptions, MetaDataContext } from '../meta/metaDataProvider'

export function useMeta<T = ReactText>(path: string) {
  const meta = useContext(MetaDataContext) as Record<
    string,
    FieldMetaWithOptions<T>
  >

  return meta[path] || {}
}
