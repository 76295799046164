import Grid from '@mui/material/Grid'
import { Skeleton } from '@mui/material'
import CategoryLayout from 'components/Layout/Category'
import PageLayout from 'components/Layout/Page'
import ProgressIndicatorSkeleton from 'components/ProgressIndicator/Skeleton'
import NavigationButtonsSkeleton from './NavigationButtonsSkeleton'
import RadiosSkeleton from './RadiosSkeleton'
import InputSkeleton from './InputSkeleton'
import HeaderWithLogoSkeleton from './HeaderWithLogoSkeleton'

const GeneralSkeleton = () => (
  <PageLayout>
    <CategoryLayout>
      <ProgressIndicatorSkeleton />
      <HeaderWithLogoSkeleton isColumnDirection />
      <InputSkeleton />
      <InputSkeleton />
      <RadiosSkeleton />
      <NavigationButtonsSkeleton />
      <Grid container spacing={1} direction="row">
        <Grid item xs={3} />
        <Grid item xs={6}>
          <Skeleton variant="rectangular" height={40} width="100%" />
        </Grid>
        <Grid item xs={3} />
      </Grid>
    </CategoryLayout>
  </PageLayout>
)

export default GeneralSkeleton
