export const bankLogos = {
  bankOfScotland: 46, // Prio
  barclaycard: 7, // Prio
  ingDiba: 55, // Prio
  postbank: 26, // Prio
  santanderCar: 32, // Prio
  targobank: 34, // Prio
  ofina: 51, // Prio
  skp: 67,
  '1822direkt': 61,
  auxmoney: 2,
  creditPlus: 49,
  degussa: 56,
  deutscheBank: 17,
  dkb: 19,
  dslBank: 16,
  norisbank: 24,
  oyakAnkerBank: 25,
  qlick: 57,
  santanderBestCredit: 53,
  sberbankEuropaAg: 40,
  skg: 29,
  swk: 59,
  commerzbank: 63,
  barclays: 69
}

const getCdnUrl = (isUsingSmavaCdnUrl?: boolean) => {
  const COMPANY_DOMAIN = isUsingSmavaCdnUrl ? 'smava' : 'finanzcheck'

  return `https://cdn.${COMPANY_DOMAIN}.de/products/builtin/#id.svg`
}

interface LogoProps {
  logoId: string
  bankName: string
  isUsingSmavaCdnUrl?: boolean
}

export const Logo = ({ logoId, bankName, isUsingSmavaCdnUrl }: LogoProps) => (
  <img
    src={getCdnUrl(isUsingSmavaCdnUrl).replace('#id', logoId.toString())}
    alt={`logo-${bankName}`}
  />
)
