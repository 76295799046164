import { Grid } from '@mui/material'
import GuenstigerGehtNicht from 'components/DigitalAccountCheck/TrustElement/GuenstigerGehtNicht'
import Tuev from 'components/DigitalAccountCheck/TrustElement/Tuev'
import Ekomi from './Ekomi'

export const TrustCards = () => {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      flexWrap="wrap"
    >
      <Grid item>
        <Ekomi />
      </Grid>
      <Grid item>
        <GuenstigerGehtNicht height="60px" />
      </Grid>
      <Grid item>
        <Tuev width="100px" />
      </Grid>
    </Grid>
  )
}
