const vehicleType = [
  { label: 'PKW', value: 'CAR' },
  { label: 'Motorrad', value: 'BIKE' },
  { label: 'Transporter', value: 'TRANSPORTER' },
  { label: 'LKW', value: 'TRUCK' },
  { label: 'Wohnmobil', value: 'MOBILE_HOME' },
  { label: 'Wohnwagen', value: 'TRAVEL_TRAILER' }
]

export default vehicleType
