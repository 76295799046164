import { memo } from 'react'
import { useClientDetails } from 'stores/utils/hooks/useClientDetails'
import useEmotionStyles from 'hooks/useEmotionStyles'

const styles = () => ({
  link: {
    display: 'flex'
  }
})

interface Props {
  width?: string
}

const Tuev = ({ width }: Props) => {
  const { link } = useEmotionStyles(styles)
  const clientDetails = useClientDetails()

  return (
    <a
      href={clientDetails?.urls?.tuev}
      target="_blank"
      rel="noopener noreferrer"
      data-testid="tuev-link"
      className={link}
    >
      <img
        alt={`${clientDetails?.company} tuev`}
        src={clientDetails?.logos?.tuev}
        style={{
          width,
          minWidth: width ? 'unset' : '160px',
          maxWidth: '160px'
        }}
        data-testid="tuev-image"
      />
    </a>
  )
}

export default memo(Tuev)
