import { memo } from 'react'
import { styled } from '@mui/material/styles'
import Agent from '../../assets/icons/agent.svg'
import Check from '../../assets/icons/check.svg'
import Wallet from '../../assets/icons/wallet.svg'
import Clock from '../../assets/icons/clock.svg'

export interface BenefitProps {
  icon: string
  caption: string
  description: string
}

export const BENEFIT_LIST: BenefitProps[] = [
  {
    icon: Agent,
    caption: 'Persönliche Beratung',
    description:
      'Über 1 Mio. Menschen vertrauten bereits auf die persönliche Beratung unserer Kreditexperten.'
  },
  {
    icon: Wallet,
    caption: 'Kostenloser Kreditvergleich',
    description:
      'Unser Kreditvergleich ist für Sie immer kostenlos und unverbindlich.'
  },
  {
    icon: Check,
    caption: 'TÜV-geprüfte Sicherheit',
    description:
      'Unser Vergleich bietet 100% Sicherheit und TÜV-zertifizierten Datenschutz.'
  },
  {
    icon: Clock,
    caption: 'Schnelle Auszahlung',
    description:
      'Sofortauszahlung binnen 15 min bei einigen Kreditangeboten möglich, ansonsten je nach Bank in der Regel 1 bis 3 Tage.'
  }
]

const BenefitWrapper = styled('div')(({ theme }) => ({
  minWidth: 300,
  maxWidth: 420,
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.up('sm')]: {
    minWidth: 200,
    maxWidth: 220,
    width: 220,
    flexDirection: 'column'
  }
}))

const ImageWrapper = styled('img')(({ theme }) => ({
  display: 'block',
  width: 40,
  height: 40,
  marginRight: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    width: 52,
    height: 52,
    margin: `0 auto ${theme.spacing(1)}`
  }
}))

const Caption = styled('h5')(({ theme }) => ({
  color: theme.palette.primary.main,
  lineHeight: '20px',
  margin: 0,
  fontSize: '13px',
  [theme.breakpoints.up('sm')]: {
    fontSize: '16px',
    textAlign: 'center'
  }
}))

const Description = styled('p')(({ theme }) => ({
  color: '#718096',
  lineHeight: '15px',
  margin: '2px 0 0 0',
  fontSize: '12px',
  [theme.breakpoints.up('sm')]: {
    fontSize: '13px',
    textAlign: 'center'
  }
}))

const Benefit = ({ icon, caption, description }: BenefitProps) => {
  return (
    <BenefitWrapper>
      <ImageWrapper src={icon} alt={caption} width={60} height={60} />
      <div>
        <Caption>{caption}</Caption>
        <Description>{description}</Description>
      </div>
    </BenefitWrapper>
  )
}

export default memo<BenefitProps>(Benefit)
