import { memo } from 'react'
import Grid from '@mui/material/Grid'
import { Skeleton } from '@mui/material'

const HeaderWithLogoSkeleton = ({
  isColumnDirection
}: {
  isColumnDirection?: boolean
}) =>
  isColumnDirection ? (
    <Grid container spacing={3} direction="row">
      <Grid item xs={1} />
      <Grid item xs={10}>
        <Skeleton variant="rectangular" height={48} width="100%" />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={4} />
      <Grid item xs={4}>
        <Skeleton variant="rectangular" height={88} width="100%" />
      </Grid>
      <Grid item xs={4} />
    </Grid>
  ) : (
    <Grid container spacing={1} direction="row">
      <Grid item xs={10}>
        <Skeleton variant="rectangular" height={96} />
      </Grid>
      <Grid item xs={2}>
        <Skeleton variant="rectangular" height={96} />
      </Grid>
    </Grid>
  )

export default memo(HeaderWithLogoSkeleton)
