import Skeleton from '@mui/material/Skeleton'
import { styled } from '@mui/material/styles'

export const FormSealsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center'
})

const FormSealsSkeleton = () => (
  <FormSealsContainer>
    <Skeleton variant="rectangular" height={60} width={60} />
  </FormSealsContainer>
)

export default FormSealsSkeleton
