import CategoryLayout from 'components/Layout/Category'
import MultipleInputSkeleton from 'components/SkeletonView/MultipleInputSkeleton'

const TemporarySkeleton = () => (
  <CategoryLayout>
    <MultipleInputSkeleton count={6} />
  </CategoryLayout>
)

export default TemporarySkeleton
