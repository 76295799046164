import { Box, Typography } from '@mui/material'
import { memo } from 'react'
import Ssl from './Ssl'
import Tuev from './Tuev'

interface Props {
  tuevWidth?: string
  sslHeight?: number
  text?: string
  gap?: number
  justifyContent?: 'flex-start' | 'space-around'
}

const TrustElement = ({
  tuevWidth,
  sslHeight,
  text,
  gap,
  justifyContent
}: Props) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent={justifyContent || 'space-around'}
    pb={1}
    gap={gap}
    data-testid="trust-element-container"
  >
    <Tuev width={tuevWidth} />
    <Ssl height={sslHeight} />
    <Typography variant="caption" color="grey.600">
      {text}
    </Typography>
  </Box>
)

export default memo(TrustElement)
