import countryShort from '../lists/country_short'
import { generateNumberOptionList } from '../utils'
import yesNoEnum from '../lists/yes_no_enum'

const previousEmployment = {
  isGermanSalaryAccount: {
    label: 'Deutsches Gehaltskonto vorhanden?',
    tooltip: '',
    placeholder: 'Deutsches Gehaltskonto vorhanden?',
    enums: yesNoEnum,
    errorMap: {
      missing:
        'Bitte teilen Sie uns mit, ob Sie ein deutsches Gehaltskonto besitzen.'
    }
  },
  employer: {
    address: {
      city: {
        label: 'Ort',
        tooltip: '',
        placeholder: 'Ort auswählen',
        errorMap: {
          missing: 'Bitte geben Sie den Sitz Ihres vorherigen Arbeitgebers an.',
          wrong_format: 'Bitte geben Sie einen gültigen Ort an.'
        }
      },
      country: {
        label: 'Land',
        tooltip: '',
        placeholder: 'Land auswählen',
        options: countryShort,
        errorMap: {
          missing: 'Bitte wählen Sie ein Land aus.'
        }
      },
      houseNumber: {
        label: 'Hausnummer',
        tooltip: '',
        placeholder: 'z.B. 73',
        errorMap: {
          missing: 'Bitte geben Sie eine Hausnummer an.',
          invalid: 'Bitte korrigieren Sie die Hausnummer.',
          wrong_format: 'Bitte geben Sie eine gültige Hausnummer an.'
        }
      },
      street: {
        label: 'Straße und Hausnummer',
        tooltip: '',
        placeholder: 'z.B. Musterstraße 73',
        errorMap: {
          missing: 'Bitte geben Sie eine Straße an.',
          invalid: 'Bitte korrigieren Sie die Straße.',
          wrong_format: 'Bitte geben Sie einen gültigen Straßennamen an.'
        }
      },
      zipCode: {
        label: 'PLZ',
        tooltip: '',
        placeholder: 'z.B. 11011',
        errorMap: {
          missing: 'Bitte geben Sie eine Postleitzahl an.',
          invalid: 'Bitte korrigieren Sie die Postleitzahl.',
          wrong_format: 'Bitte geben Sie eine gültige Postleitzahl an.',
          wrong_length: 'Die Länge der Postleitzahl ist ungültig.'
        }
      }
    },
    name: {
      label: 'Name',
      tooltip: '',
      placeholder: 'z.B. Mustermann GmbH',
      errorMap: {
        missing: 'Bitte geben Sie den Namen Ihres vorherigen Arbeitgebers an.',
        wrong_format: 'Bitte geben Sie einen gültigen Namen an.'
      }
    }
  },
  numberOfYears: {
    label: 'Dauer vorherige Beschäftigung',
    tooltip:
      'Bitte geben Sie die Anzahl der Jahre an, die Sie bei Ihrem vorherigen Arbeitgeber beschäftigt waren.',
    placeholder: 'Dauer auswählen (Jahre)',
    options: generateNumberOptionList(0, 50),
    errorMap: {
      missing:
        'Bitte teilen Sie uns mit, wie lange Sie bei Ihrem vorherigen Arbeitgeber beschäftigt waren.'
    }
  }
}
export default previousEmployment
