import Grid from '@mui/material/Grid'
import { Skeleton } from '@mui/material'
import { styled } from '@mui/material/styles'
import Divider from '../Footer/Divider'

const SkeletonContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  maxWidth: 1200,
  margin: '0 auto'
}))

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  width: '100%',
  height: 56,
  [theme.breakpoints.up('sm')]: {
    height: 144
  }
}))

const FooterWithBoxesSkeleton = () => {
  return (
    <>
      <Divider />
      <SkeletonContainer>
        <Grid container spacing={3} direction={{ sm: 'row' }}>
          {[...Array(4)].map((value) => (
            <Grid item xs={12} sm={6} md={3} key={`grid-boxes-index-${value}`}>
              <StyledSkeleton variant="rectangular" />
            </Grid>
          ))}
        </Grid>
      </SkeletonContainer>
      <Divider />
      <SkeletonContainer>
        <Grid container spacing={3} direction={{ sm: 'row' }}>
          {[...Array(3)].map((value) => (
            <Grid item xs={4} key={`grid-lower-boxes-index-${value}`}>
              <Skeleton variant="rectangular" width="100%" height={64} />
            </Grid>
          ))}
        </Grid>
      </SkeletonContainer>
      <Divider />
    </>
  )
}

export default FooterWithBoxesSkeleton
