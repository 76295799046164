import { memo } from 'react'
import Markdown from 'components/Markdown'
import { Theme } from '@mui/material/styles'
import { Typography } from '@mui/material'
import useEmotionStyles from 'hooks/useEmotionStyles'
import * as CSSTypes from 'csstype'
import { css, keyframes } from '@emotion/react'

interface Props {
  children: string
  open?: boolean
  isAvailable?: boolean
  isMinimalistic?: boolean
}

const fadeInFromTop = keyframes({
  from: {
    opacity: '0',
    transform: 'translateY(-1.5rem)'
  },
  to: {
    opacity: '1',
    transform: 'translateY(0)'
  }
})

const styles = (theme: Theme, { open }: { open?: boolean }) => ({
  nose: css({
    width: '0',
    height: '0',
    margin: '0 auto',
    borderLeft: '0.5rem solid transparent',
    borderRight: '0.5rem solid transparent',
    borderBottom: '0.5rem solid',
    borderBottomColor: theme.palette.primary.light,
    animation: `${fadeInFromTop} 0.2s`
  }),
  tooltip: css({
    margin: '0',
    padding: '0.625rem 0.875rem',
    borderRadius: '0.125rem',
    textAlign: 'start' as CSSTypes.Property.TextAlign,
    animation: `${fadeInFromTop} 0.3s`,
    '& p': {
      margin: '0',
      lineHeight: '1.5rem'
    },
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    '& a': {
      color: theme.palette.primary.dark
    }
  }),
  minimalisticTooltip: {
    color: theme.palette.grey[800],
    padding: `0 0 ${open ? theme.spacing(1) : 0} 0`,
    fontSize: '0.75rem',
    lineHeight: '1.5rem',
    transition: 'all 0.2s ease-in-out',
    opacity: '0',
    maxHeight: 0,
    transform: 'translateY(-1.5rem)',
    pointerEvents: 'none' as CSSTypes.Property.PointerEvents
  },
  open: {
    opacity: '1 !important',
    maxHeight: '20rem !important',
    transform: 'translateY(0) !important',
    pointerEvents: 'auto !important' as CSSTypes.Property.PointerEvents
  }
})

const Tooltip = ({ children, open, isAvailable, isMinimalistic }: Props) => {
  const {
    nose,
    tooltip,
    minimalisticTooltip,
    open: openStyle
  } = useEmotionStyles<ReturnType<typeof styles>, { open?: boolean }>(styles, {
    open
  })

  if (!open && !isAvailable) {
    return null
  }

  if (isMinimalistic) {
    return (
      <Typography
        className={`${minimalisticTooltip}  ${open ? openStyle : ''}`}
      >
        <span>{children}</span>
      </Typography>
    )
  }

  return (
    <>
      <div className={nose} />
      <div className={tooltip}>
        <Markdown>
          <span>{children}</span>
        </Markdown>
      </div>
    </>
  )
}

export default memo(Tooltip)
