import { Tenant } from '@finanzcheck/teal-mui-theme'
import RootStore from 'stores/RootStore'
import { getEnv } from 'utils/env'
import { StartupCommand } from './command.interface'

export const SMAVA_THEME_UUID = 'f9343d8a-4bd3-48b8-a504-e99f102f463c'

class InitTheme implements StartupCommand {
  name = 'InitTheme'
  dependencies = []
  timeout = 10000

  // eslint-disable-next-line class-methods-use-this
  execute = async (rootStore: RootStore) => {
    const tenant =
      getEnv('REACT_APP_TENANT_UUID') === SMAVA_THEME_UUID
        ? Tenant.Smava
        : Tenant.Finanzcheck
    const { fetchTheme } = rootStore.theme
    fetchTheme(tenant)
  }
}

export default InitTheme
