import { AxiosError, AxiosResponse, AxiosInstance } from 'axios'
import {
  action,
  observable,
  reaction,
  toJS,
  makeObservable,
  runInAction
} from 'mobx'
import { mergeDeepRight } from 'rambdax'
import { FormApi } from 'final-form'
import { getClient } from 'api'
import { pick, remove, dot } from 'dot-object'
import { FintecState } from '@finanzcheck/ti-fts-widget'
import TraversalUpdater from 'api/interceptors/traversalUpdater'
import {
  broadcastToParent,
  RegisteredEventName
} from '@finanzcheck/catalyst-pollard'
import ConfigurationError from 'error/configurationError'
import FinalizeError from 'error/finalizeError'
import ApiError from 'error/apiError'
import { EntryPoint } from 'startup/command/loadEntryPoint'
import TraversalCreator from 'api/interceptors/traversalCreator'
import { setUserProperties, trackInAmplitude } from 'utils/tracking/amplitude'
import { trackFinal, trackTeal } from 'utils/tracking'
import { updateFfgConf } from 'startup/command/initFfgConf'
import TraversalRecovery from 'api/interceptors/traversalRecovery'
import { logInfo } from 'utils/log'
import { FcidItem } from 'utils/fcid'
import { isTenantSmava } from 'utils/url'
import { excludeFromCloack } from 'utils/tracking/amplitude/utils'
import isNotAffiliate from 'utils/formDependencies/conditions/isNotAffiliate'
import { getEnv } from 'utils/env'

import getInitialFormValues from '../formInitialValues'
import {
  FailuresItem,
  Patch,
  RootObject,
  Data,
  CreditChangeOption
} from '../interfaces/ApolloResponse.interface'
import {
  AffiliateEventName,
  redirectAffiliate,
  sendAffiliateEvent
} from '../utils/affiliate'
import { logAndTrackError } from '../utils/log/logAndTrackError'
import { buildObjFromPath, convertSlashToDotted } from '../utils/objectPath'
import {
  addConditionalPatches,
  generateSetPatch,
  getSafeValue
} from '../utils/patch'
import reactions from './reactions'
import { LoadingMode } from '../components/LoadingProvider'
import RootStore from './RootStore'
import {
  AmplitudeEvent,
  FinalRedirectStatus,
  ErrorType,
  PathToTracking,
  successTrackableFields
} from '../utils/tracking/amplitude/amplitude.interface'
import { HttpStatusCode } from '../utils/url/status'
import { getFailuresOfAxiosResponse } from './utils/validation'
import { Debtor } from 'containers/PersonalForm/PersonalForm.interface'

export enum DacWidgetTabEnum {
  QUICK = 'QUICK',
  SLOW = 'SLOW'
}

export interface FrameHostData {
  advertisementId: string | undefined
  traversalId: string | undefined
  resumeHash: string | undefined
  entryPoint: string | undefined
  FCID: FcidItem | undefined
}

export interface FieldChange<T> {
  path: string
  value:
    | string
    | number
    | Record<string, unknown>
    | undefined
    | unknown[]
    | T
    | T[]
  merge?: boolean
}

type TraversalResponse = RootObject<{
  resumeToken?: string
  entryPoint?: EntryPoint
}>

const allowedPathUpdates = [
  'system',
  'debtors.primary.personId',
  'debtors.secondary.personId'
]

const isSmava = isTenantSmava()
class TraversalStore {
  private rootStore: RootStore
  private apolloClient: AxiosInstance
  private form?: FormApi

  public traversalId: string | null = null
  public nextResumeHash?: string
  public data: Data = {} as Data
  public isTraversalCreationInProgress: boolean
  public initialPaymentErrorTriggered: boolean
  public resumeData: AxiosResponse | Record<string, string>
  public hasInteractedWithDebtorsCount = false // used in AddSecondDebtor page, left-over from CAT-1399
  public isDebtorsCountError = false // used in AddSecondDebtor page, left-over from CAT-1399
  public isGoToFinalSucceeded = false

  public dacWidgetState = 'initial' as FintecState
  public dacWidgetStep = ''
  public overMiddleAgeDacSkipped = false // CAT_3107 variable remvoe after test
  public overMiddleAge3107DacOption = '' // CAT_3107 variable remvoe after test
  public dacWidgetTab = DacWidgetTabEnum.QUICK
  public isDacStarted = false // DAC 5
  public isDacSkipped = false // DAC 5

  private setInterceptors = () => {
    TraversalCreator.create(this).apply(this.apolloClient)
    TraversalRecovery.create(this).apply(this.apolloClient)
    TraversalUpdater.create(this, allowedPathUpdates).apply(this.apolloClient)
  }

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      traversalId: observable,
      data: observable,
      hasInteractedWithDebtorsCount: observable,
      isDebtorsCountError: observable,
      isGoToFinalSucceeded: observable,
      dacWidgetState: observable,
      dacWidgetStep: observable,
      dacWidgetTab: observable,
      isDacStarted: observable,
      isDacSkipped: observable,
      overMiddleAgeDacSkipped: observable,
      overMiddleAge3107DacOption: observable,
      setTraversalDataFromFrameHost: action,
      create: action,
      setTraversalFromAPIResponse: action,
      setTraversalResponse: action,
      handlePathFormFieldChange: action,
      handleMultiplePathFormFieldChanges: action,
      addItemToPathList: action,
      removeItemFromPathList: action,
      updateItemFromPathList: action,
      patch: action,
      finalize: action,
      removeErrorsForPath: action,
      put: action,
      resume: action,
      setProductId: action
    })

    this.rootStore = rootStore
    this.data = getInitialFormValues(this.rootStore.urlParams)
    this.apolloClient = getClient('apollo')
    this.isTraversalCreationInProgress = false
    this.initialPaymentErrorTriggered = false
    this.resumeData = {}
    this.setInterceptors()
    this.hasInteractedWithDebtorsCount = false
    this.isDebtorsCountError = false
    this.isGoToFinalSucceeded = false
    this.dacWidgetState = 'initial'
    this.dacWidgetStep = ''
    this.dacWidgetTab = DacWidgetTabEnum.QUICK
    this.isDacStarted = false
    this.isDacSkipped = false
  }

  // TODO CAT-3051 We need to refactor this, this is ugly af
  setFormApi = (form: unknown) => {
    this.form = form as FormApi
  }

  setTraversalDataFromFrameHost = (data: FrameHostData) => {
    const { advertisementId, resumeHash, traversalId = null } = data

    this.traversalId = traversalId
    this.nextResumeHash = resumeHash

    if (advertisementId) {
      /**
       * CAT-3887 Prefer URL param advertisementId over parent frame advertisementId
       */
      this.data.system.advertisementId =
        this.rootStore.urlParams.advertisementId || advertisementId
    }
  }

  create = async () => {
    this.isTraversalCreationInProgress = true

    const response = await this.apolloClient.post<TraversalResponse>(
      `/traversal/${this.data.system.advertisementId}`,
      this.data
    )

    this.isTraversalCreationInProgress = false

    this.setTraversalFromAPIResponse(response)

    return response
  }

  get = async (logTraversalMissingError = true) => {
    const {
      system: { advertisementId }
    } = this.data

    /* affiliates and smava will never have traversalId initially */
    if (!this.traversalId && logTraversalMissingError) {
      throw new ConfigurationError(
        'The traversalId is missing.',
        advertisementId,
        this.traversalId
      )
    }

    if (this.traversalId) {
      this.setTraversalResponse(
        await this.apolloClient.get(
          `/traversal/${advertisementId}/${this.traversalId}`
        )
      )
    }
  }

  setTraversalFromAPIResponse = async (
    response: AxiosResponse<TraversalResponse>
  ) => {
    const {
      data: { result },
      config
    } = response

    if (!result) {
      logAndTrackError(new ApiError('Response is empty', config))
      return
    }

    const { id: traversalId, resumeToken, entryPoint } = result

    if (entryPoint) {
      this.rootStore.setInitialEntryPoint(entryPoint)
    }

    /* keep or remove after smava is moved to paas as well */
    if (!this.traversalId) {
      this.trackTraversalEvent(traversalId || '')
    }

    this.traversalId = traversalId || null
    this.setTraversalResponse(response)

    if (resumeToken) {
      this.nextResumeHash = resumeToken
    }

    this.writeDataToFrameHost()
  }

  setProductId = (productId: number) => {
    this.data.common.productId = productId
  }

  writeDataToFrameHost = () => {
    const data = {
      advertisementId: this.data.system.advertisementId,
      traversalId: this.traversalId,
      resumeHash: this.nextResumeHash
    }

    logInfo(`Sending data to host: ${JSON.stringify(data, null, 2)}`)

    broadcastToParent({
      eventName: RegisteredEventName.updateCurrentImodTraversal,
      data
    })
  }

  setTraversalResponse = (response: AxiosResponse<RootObject>) => {
    const {
      status,
      data: { result },
      config
    } = response

    if (status !== HttpStatusCode.Created && status !== HttpStatusCode.Ok) {
      logAndTrackError(
        new ApiError('Traversal call returned error', config, response)
      )

      return
    }

    if (!result || !result.data) {
      logAndTrackError(
        new ApiError('Traversal does not contain any data', config, response)
      )

      return
    }

    this.data = mergeDeepRight(this.data, result.data) as Data
  }

  initReactions() {
    reactions.forEach((getReaction) => {
      const [conditionFn, reactionFn] = getReaction(this)
      reaction(conditionFn, reactionFn)
    })
  }

  handlePathFormFieldChange = <T = string>(
    path: string,
    fieldValue?: T | T[],
    patchTraversal = true
  ) => {
    const value = getSafeValue(fieldValue)

    // we remove the observable pattern temporarily, due to failing merge on object
    this.data = buildObjFromPath<Data>(path, value, toJS(this.data))

    if (this.form && value) {
      this.form.change(path, toJS(value))
    }

    // TODO we should BATCH here (in case of many changes at once)???
    const fieldPatch = generateSetPatch(path, value)

    /* avoid patch calls if isTraversalCreationInProgress, as it would create more traversal creation requests */
    if (
      patchTraversal &&
      !this.isTraversalCreationInProgress &&
      path !== 'lists.brandTracking'
    ) {
      // CAT-3162 don't patch this path
      this.patch([fieldPatch])
    }
  }

  handleMultiplePathFormFieldChanges = <T>(
    payload: FieldChange<T>[],
    patchTraversal = true
  ) => {
    const fieldPatches: Patch[] = []
    payload.forEach(({ path, value: original, merge }) => {
      const value = getSafeValue(original)

      // we remove the observable pattern temporarily, due to failing merge on object
      this.data = buildObjFromPath<Data>(path, value, toJS(this.data), merge)

      if (this.form) {
        this.form.change(path, toJS(value))
      }

      fieldPatches.push(generateSetPatch(path, value))
    })

    /* avoid patch calls if isTraversalCreationInProgress, as it would create more traversal creation requests */
    if (patchTraversal && !this.isTraversalCreationInProgress) {
      this.patch(fieldPatches)
    }
  }

  addItemToPathList<P>(
    path: string,
    item: P,
    counterPath: string,
    patch = true
  ): void {
    const itemCount = pick(counterPath, this.data) || 0
    const items: P[] = [
      ...(pick(path, this.data) || []).slice(0, itemCount),
      item
    ]

    this.handlePathFormFieldChange(path, items, patch)
    this.handlePathFormFieldChange(counterPath, items.length)
  }

  removeItemFromPathList<P>(
    path: string,
    counterPath: string,
    patch = true
  ): void {
    const itemCount = pick(counterPath, this.data) || 0
    const items: P[] = (pick(path, this.data) || []).slice(0, itemCount)
    items.splice(-1, 1)

    this.handlePathFormFieldChange(counterPath, items.length)
    this.handlePathFormFieldChange(path, items, patch)
  }

  getItemFromPathList = <T = string>(
    path: string,
    idx: number
  ): T | undefined => {
    const list = pick(path, this.data) || []

    return list[idx]
  }

  updateItemFromPathList = <T extends Record<string, unknown>>(
    path: string,
    idx: number,
    item: T,
    patchTraversal = true
  ): void =>
    this.handlePathFormFieldChange(`${path}.${idx}`, item, patchTraversal)

  // eslint-disable-next-line class-methods-use-this
  trackValidationErrors = (failures: FailuresItem[], patches: Patch[] = []) => {
    const patchFields = patches.map((item) => item.path)

    failures.forEach(({ fields, id: errors }) => {
      fields.forEach((field) => {
        if (patchFields.includes(field)) {
          trackInAmplitude(AmplitudeEvent.ValidateTraversal, {
            type: ErrorType.Field,
            path: convertSlashToDotted(field),
            errors
          })
        }
      })
    })
  }

  // eslint-disable-next-line class-methods-use-this
  trackValidationSuccess = (
    failures: FailuresItem[],
    patches: Patch[] = []
  ) => {
    const successTrackedFields = patches
      .filter((item) => successTrackableFields.indexOf(item.path) > -1)
      .map((item) => item.path)

    const erroredPath: string[] = []
    if (successTrackedFields.length) {
      failures.forEach(({ fields }) => {
        fields.forEach((field) => {
          erroredPath.push(field)
        })
      })
    }
    successTrackedFields.forEach((path) => {
      if (erroredPath.indexOf(path) === -1) {
        trackInAmplitude(PathToTracking[path])
      }
    })
  }

  updateDataInTraversal = (data: Record<string, unknown>) => {
    runInAction(() => {
      this.data = mergeDeepRight(this.data, data) as Data
    })
  }

  patch = async (patches: Patch[]) => {
    const withConditionalPatches = addConditionalPatches(patches, this.data)
    const response = await this.apolloClient.patch(
      `/traversal/${this.data.system.advertisementId}/${this.traversalId}`,
      withConditionalPatches
    )

    const failuresOfAxiosResponse = getFailuresOfAxiosResponse(response)

    this.rootStore.fieldErrors.setFieldErrorsFromAxiosResponse(response, true)
    this.trackValidationSuccess(failuresOfAxiosResponse, patches)
    this.trackValidationErrors(failuresOfAxiosResponse, patches)
  }

  setDacTransactionDetails = (dacTransactionId: string) => {
    const timeStamp = Math.round(new Date().getTime() / 1000)
    const { payoutAccountHolder } = this.data.common
    const payoutAccountHolderLowerCase = payoutAccountHolder.toLowerCase()
    if (!dacTransactionId) {
      trackInAmplitude(AmplitudeEvent.OnDacTranscationalIdNull)
      return
    }
    if (
      payoutAccountHolderLowerCase === Debtor.PRIMARY ||
      payoutAccountHolderLowerCase === Debtor.SECONDARY
    ) {
      this.handleMultiplePathFormFieldChanges([
        {
          path: `debtors.${payoutAccountHolderLowerCase}.digitalAccountCheck.transactionId`,
          value: dacTransactionId
        },
        {
          path: `debtors.${payoutAccountHolderLowerCase}.digitalAccountCheck.createdAt`,
          value: timeStamp
        }
      ])
      return
    }
    this.handleMultiplePathFormFieldChanges([
      {
        path: 'debtors.primary.digitalAccountCheck.transactionId',
        value: dacTransactionId
      },
      {
        path: 'debtors.primary.digitalAccountCheck.createdAt',
        value: timeStamp
      },
      {
        path: 'debtors.secondary.digitalAccountCheck.transactionId',
        value: dacTransactionId
      },
      {
        path: 'debtors.secondary.digitalAccountCheck.createdAt',
        value: timeStamp
      }
    ])
  }

  finalize = async (
    onError?: (error: Error | AxiosError<RootObject> | undefined) => void
  ) => {
    const { setLoadingMode } = this.rootStore
    const { directToOffers } = this.rootStore.urlParams

    setLoadingMode(LoadingMode.Blocking)

    try {
      const finalResponse = await this.apolloClient.post(
        `/finalize/${this.data.system.advertisementId}/${this.traversalId}`,
        this.data
      )

      this.rootStore.fieldErrors.setIsFinalizeError(false)
      await this.goToFinal(finalResponse)
    } catch (error) {
      await trackInAmplitude(AmplitudeEvent.GoToFinal, {
        status: FinalRedirectStatus.Failed,
        directToOffers: directToOffers ? 'true' : 'false'
      })
      setLoadingMode(LoadingMode.None)
      this.rootStore.fieldErrors.setIsFinalizeError(true)

      if (!onError) {
        throw error
      }

      onError(error as AxiosError)
    }
  }

  goToFinal = async (
    response: AxiosResponse<{ result: { uri: Location } }>
  ) => {
    const {
      data: { result }
    } = response

    if (!result || !result.uri) {
      throw new FinalizeError(
        'Could not redirect to final. URI missing!',
        response.config,
        response
      )
    }
    const { directToOffers } = this.rootStore.urlParams

    try {
      trackFinal(this.rootStore)
      sendAffiliateEvent(
        AffiliateEventName.FinalConversion,
        this,
        this.rootStore
      )

      updateFfgConf('step', 'final')

      const {
        traversal: {
          data: {
            debtors: { count },
            system: { advertisementId }
          }
        },
        experimentAndVariation: { runningExperiments, runningVariations }
      } = this.rootStore

      await trackInAmplitude(AmplitudeEvent.GoToFinal, {
        status: FinalRedirectStatus.Succeeded,
        advertisementId,
        debtorCount: count,
        ...(runningVariations.length && {
          experiments: runningExperiments
        }),
        ...(runningVariations.length && {
          variations: runningVariations
        }),
        directToOffers: directToOffers ? 'true' : 'false'
      })
    } catch (error) {
      logAndTrackError(error as AxiosError)
    } finally {
      // If present, append taurine url query palette to final src url. This is necessary for custom affiliate theming.
      const palette = this.rootStore.urlParams.palette
        ? `${result.uri.search ? '&' : '?'}palette=${encodeURIComponent(
            this.rootStore.urlParams.palette
          )}`
        : undefined

      const finalUrl = `${result.uri}${palette || ''}&context=${
        this.rootStore.urlParams?.context
      }`
      logInfo(`[goToFinal] Redirect to ${finalUrl}`)

      this.rootStore.traversal.setIsGoToFinalSucceeded(true)

      const notAffiliate = isNotAffiliate(this.rootStore.urlParams?.context)

      const queryParams = finalUrl.split('?')[1]
      const redirectUrl = `${getEnv(
        'REACT_APP_OFFER_PAGE_URL'
      )}/?${queryParams}${palette || ''}`

      if (!notAffiliate) {
        if (
          this.rootStore.urlParams?.affiliateLinkout === 'true' ||
          this.rootStore.urlParams?.embedded !== 'true'
        ) {
          this.rootStore.setLoadingMode(LoadingMode.None)
          redirectAffiliate(redirectUrl, RegisteredEventName.redirect)
          return
        }

        // continue within iframe
        window.setTimeout(
          () => {
            window.location.replace(finalUrl)
          },
          isSmava ? 500 : 0
        )

        return
      }

      // continue with no iframe
      redirectAffiliate(redirectUrl, RegisteredEventName.redirect)
      this.rootStore.setLoadingMode(LoadingMode.None)
    }
  }

  removeErrorsForPath = (path: string): void => {
    this.rootStore.fieldErrors.removeErrors(path)
  }

  removeErrorsForMultiplePaths = (paths: string[]): void => {
    paths.forEach((path) => this.rootStore.fieldErrors.removeErrors(path))
  }

  getErrorsForPath = (path: string) => this.rootStore.fieldErrors.get(path)

  getFieldValueByPath = <T = string>(path: string): T | undefined =>
    pick(path, this.data)

  removePathsFromPayload = (path: string[]) => {
    const dataClone = JSON.parse(JSON.stringify(this.data))
    path.forEach((deletePath) => {
      remove(deletePath, dataClone)
    })
    return dataClone
  }

  removablePathsFromPayload = () => {
    const path = isSmava ? ['common.vehicle.initialPayment'] : []

    if (!this.data.debtors.primary.currentEmployment.isTemporary) {
      path.push('debtors.primary.currentEmployment.until')
    }
    if (!this.data.debtors.secondary.currentEmployment.isTemporary) {
      path.push('debtors.secondary.currentEmployment.until')
    }
    return path
  }

  put = async (): Promise<AxiosResponse<RootObject>> => {
    const path = this.removablePathsFromPayload()

    if (!this.data.debtors.primary.currentEmployment.isTemporary) {
      path.push('debtors.primary.currentEmployment.until')
    }
    if (!this.data.debtors.secondary.currentEmployment.isTemporary) {
      path.push('debtors.secondary.currentEmployment.until')
    }
    if (!this.data.debtors.primary.hasPreviousEmployment) {
      path.push('debtors.primary.previousEmployment')
    }
    if (!this.data.debtors.secondary.hasPreviousEmployment) {
      path.push('debtors.secondary.previousEmployment')
    }

    const isDebtConversionWantedInAtLeastOneLoanPrimary =
      this.data.debtors.primary.activeLoans?.some(
        (activeLoan) => activeLoan.isDebtConversionWanted
      )

    const isDebtConversionWantedInAtLeastOneLoanSecondary =
      this.data.debtors.secondary.activeLoans?.some(
        (activeLoan) => activeLoan.isDebtConversionWanted
      )

    if (
      this.rootStore.navigation.currentPageInSession === 'activeLoans' &&
      (this.data.debtors.primary.numberOfActiveLoans === 0 ||
        !isDebtConversionWantedInAtLeastOneLoanPrimary) &&
      (this.data.debtors.secondary.numberOfActiveLoans === 0 ||
        !isDebtConversionWantedInAtLeastOneLoanSecondary)
    ) {
      this.data.common.amountAdaption = CreditChangeOption.nothing
    }

    const data = this.removePathsFromPayload(path)

    this.data = data

    return this.apolloClient.put(
      `/traversal/${this.data.system.advertisementId}/${this.traversalId}`,
      data
    )
  }

  acceptTermsAndConditions = () =>
    this.handlePathFormFieldChange('common.hasAcceptedTerms', true, false)

  hasAcceptedTermsAndConditions = () => this.data.common.hasAcceptedTerms

  resume = async (resumeHash: string) => {
    const response = await this.apolloClient.post(`/resume/${resumeHash}`)
    /* remove me after auto weiter experiment */
    this.resumeData = response as unknown as Record<string, string>
    this.setTraversalFromAPIResponse(response)
  }

  checkTenantForEmail = async (email: string) => {
    const response = await this.apolloClient.post(`/cso`, { email })

    return response.data
  }

  setInitalPaymentErrorTriggered = (value: boolean) => {
    this.initialPaymentErrorTriggered = value
  }

  setUserPropertiesOnPut = async () => {
    const userPropertyObject = {}
    const arrayOfPathsOnThePage = await this.rootStore.page.getAllPathsOnPage()
    const traversalDataObject = dot(toJS(this.data))
    const updatedPathsInPut = excludeFromCloack.filter((value) =>
      arrayOfPathsOnThePage.includes(value)
    )

    updatedPathsInPut.forEach((property) => {
      if (
        property.indexOf('debtors.secondary') > -1 &&
        traversalDataObject['debtors.count'] < 2
      ) {
        return
      }

      if (traversalDataObject[property]) {
        userPropertyObject[property] = traversalDataObject[property]
      }
    })

    setUserProperties(userPropertyObject)
  }
  setHasInteractedWithDebtorsCount = (value: boolean) => {
    this.hasInteractedWithDebtorsCount = value
  }

  setIsDebtorsCountError = (value: boolean) => {
    this.isDebtorsCountError = value
  }

  setIsGoToFinalSucceeded = (isGoToFinalSucceeded: boolean) => {
    this.isGoToFinalSucceeded = isGoToFinalSucceeded
  }

  setDacWidgetState = (dacWidgetState: FintecState) => {
    this.dacWidgetState = dacWidgetState
  }

  setDacWidgetStep = (dacWidgetStep: string) => {
    this.dacWidgetStep = dacWidgetStep
  }

  setDacWidgetTab = (dacWidgetTab: DacWidgetTabEnum) => {
    this.dacWidgetTab = dacWidgetTab
  }

  setIsDacStarted = (isDacStarted: boolean) => {
    this.isDacStarted = isDacStarted
  }

  setIsDacSkipped = (isDacSkipped: boolean) => {
    this.isDacSkipped = isDacSkipped
  }

  setOverMiddleAgeDacSkipped = (isDacSkipped: boolean) => {
    this.overMiddleAgeDacSkipped = isDacSkipped
  }

  setOverMiddleAge3107DacOption = (option: string) => {
    this.overMiddleAge3107DacOption = option
  }

  trackTraversalEvent = (traversalId?: string) => {
    const {
      common: { purpose, amount, term },
      system: { subId, refId, configurationId, affiliateId },
      debtors: {
        primary: { personId: traversalPersonId }
      }
    } = this.data

    const tealEventData = {
      event: 'traversal',
      label: '/kreditantrag/debtorCount',
      traversalId: this.traversalId || traversalId,
      amount,
      purpose,
      term,
      category: 'loan_status'
    }

    trackTeal({
      transaction: tealEventData,
      affiliateId,
      subId,
      refId,
      configurationId,
      traversalPersonId
    })
  }
}

export default TraversalStore
