import { pick, dot } from 'dot-object'
import { Dependencies, FlatDependencies } from '.'
import {
  getValuePath,
  convertToDotted,
  getDependencyPaths,
  flattenDependencies
} from './path'

function resolveDependencies<T>(
  path: string,
  dependencyPath: string,
  state: T,
  dependencies: FlatDependencies<T>
): boolean {
  const depFuncOrTree = dot(dependencies)[dependencyPath]

  if (!depFuncOrTree) {
    return true
  }

  const value = pick(getValuePath(path, dependencyPath), state)

  if (typeof depFuncOrTree === 'function') {
    return depFuncOrTree(state, value)
  }

  return resolveDependencies(path, dependencyPath, value, depFuncOrTree)
}

export function checkDependencies<T>(
  path: string,
  state: T,
  dependencies: Dependencies<T>
): boolean {
  const dottedPath = convertToDotted(path)
  const flatDependencies = flattenDependencies(dependencies)

  for (const depPath of getDependencyPaths(dottedPath)) {
    if (!resolveDependencies(dottedPath, depPath, state, flatDependencies)) {
      return false
    }
  }

  return true
}
