import { AxiosResponse } from 'axios'
import { pick } from 'dot-object'
import { FailuresItem, RootObject } from 'interfaces/ApolloResponse.interface'

export const VALIDATION_ERRORS_KEY = 'data.result.validation.result.failures'
export const VALIDATION_ERRORS_KEY_FINALIZE = 'data.errors'

/**
 * Get all the failed validation fields as a flat array
 */
export function getFailuresOfAxiosResponse<R = AxiosResponse<RootObject>>(
  response: R
): FailuresItem[] {
  const errorForFinalize =
    (pick(VALIDATION_ERRORS_KEY_FINALIZE, response) || [])[0]?.failures || []

  const patchErrors = pick(VALIDATION_ERRORS_KEY, response) || []

  return [...errorForFinalize, ...patchErrors]
}
