import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useStores } from 'stores/utils/hooks/useStores'
import Typography from '@mui/material/Typography'
import { trackInAmplitude } from 'utils/tracking/amplitude'
import { AmplitudeEvent } from 'utils/tracking/amplitude/amplitude.interface'

const Backlink = () => {
  const {
    page: { isFirstDisplayedPage, getFirstDisplayedPageName },
    navigation: { currentPageInSession },
    history
  } = useStores()

  const firstPageUrl = useMemo(() => {
    if (isFirstDisplayedPage(currentPageInSession)) {
      return null
    }

    return getFirstDisplayedPageName()
  }, [currentPageInSession, getFirstDisplayedPageName, isFirstDisplayedPage])

  if (!firstPageUrl) {
    return null
  }

  const onBackLinkClick = () => {
    trackInAmplitude(AmplitudeEvent.BackLinkIsClicked)
    history.replace(firstPageUrl)
  }

  return (
    <Typography variant="body1" color="grey.900">
      Wenn Sie Ihre bisherigen Eingaben noch einmal prüfen wollen, klicken Sie{' '}
      <Link to={`../${firstPageUrl}`} onClick={onBackLinkClick}>
        hier
      </Link>
      .
    </Typography>
  )
}

export default Backlink
