import { dot } from 'dot-object'
import { Dependencies, FlatDependencies, DependencyCheckOrTree } from '.'

export function getPathWithoutIndices(indexedPath: string): string {
  return indexedPath.replace(/(\.\d$)|(\d+\.)|\d+/g, '')
}

export function getParentPath(indexedPath: string): string {
  const match = /\.\d+(?!.*\.\d+)/.exec(indexedPath)
  if (!match) {
    return indexedPath
  }

  return indexedPath.substring(0, match.index + match[0].length)
}

export function convertToDotted(path: string): string {
  return `${path.replace(/\[/g, '.').replace(/\]\./g, '.')}`
}

export function getValuePath(path: string, dependencyPath: string): string {
  const parts = path.split('.')

  let valuePath = ''
  for (const part of parts) {
    if (valuePath.length > 0) {
      valuePath += '.'
    }

    valuePath += part

    if (getPathWithoutIndices(valuePath) === dependencyPath) {
      return getParentPath(valuePath)
    }
  }

  return valuePath
}

export function getDependencyPaths(path: string): string[] {
  let currentPath = getPathWithoutIndices(path)

  const paths = [currentPath]

  while (currentPath.length > 0) {
    currentPath = currentPath.substring(0, currentPath.lastIndexOf('.'))

    if (currentPath.length > 0) {
      paths.push(currentPath)
    }
  }

  return paths.reverse()
}

export function flattenArray<T>(
  key: string,
  arr: DependencyCheckOrTree<T>[]
): FlatDependencies<T> {
  return arr.reduce<FlatDependencies<T>>((previous, current) => {
    if (typeof current === 'function') {
      return {
        ...previous,
        [key]: current
      }
    }

    return {
      ...previous,
      ...Object.entries(dot(current)).reduce(
        (prevPath, [childKey, value]) => ({
          ...prevPath,
          [`${key}.${childKey}`]: value
        }),
        {}
      )
    }
  }, {})
}

export function flattenDependencies<T>(
  dependencies: Dependencies<T>
): FlatDependencies<T> {
  return Object.entries(dependencies).reduce<FlatDependencies<T>>(
    (previous, [key, dependency]) => {
      if (typeof dependency === 'function') {
        return {
          ...previous,
          [key]: dependency
        }
      }

      if (Array.isArray(dependency)) {
        return { ...previous, ...flattenArray(key, dependency) }
      }

      return {
        ...previous,
        ...Object.entries(dependency).reduce(
          (prevTree, [treeKey, treeItem]) => {
            const combinedKey = `${key}.${treeKey}`

            if (Array.isArray(treeItem)) {
              return {
                ...prevTree,
                ...flattenArray(combinedKey, treeItem)
              }
            }

            return {
              ...prevTree,
              ...dot({
                [combinedKey]:
                  typeof treeItem === 'function'
                    ? treeItem
                    : flattenDependencies(treeItem)
              })
            }
          },
          {}
        )
      }
    },
    {}
  )
}
