import {
  broadcastToParent,
  RegisteredEventName
} from '@finanzcheck/catalyst-pollard'
import RootStore from 'stores/RootStore'
import { getCookie, COOKIE_KEY_FCID } from 'utils/cookie'
import isNotAffiliate from 'utils/formDependencies/conditions/isNotAffiliate'
import { logInfo } from 'utils/log'
import { logAndTrackError } from 'utils/log/logAndTrackError'
import { isTenantSmava } from 'utils/url'
import TraversalStore from '../../stores/TraversalStore'

export const AFFILIATE_NAMESPACE = '/affiliate:'

export enum AffiliateEventName {
  Visit = 'visit',
  Pageview = 'pageview',
  Conversion = 'conversion',
  FinalConversion = 'finalConversion'
}

interface AffiliatePayload {
  event: string
  amount: number
  term: number
  purpose: string
  advertisementId: string
  configurationId: string
  fcId: string
  subId?: string
  team?: string
  refId?: string
}

export const AFFILIATE_ID_FFG = 1000
export const AFFILIATE_ID_SMAVA = 200

export function isFinanzcheck(affiliateId: number) {
  return affiliateId === AFFILIATE_ID_FFG
}

export function isSmava(affiliateId: number) {
  return affiliateId === AFFILIATE_ID_SMAVA
}

function withNamespace(event: AffiliateEventName) {
  return AFFILIATE_NAMESPACE.concat(event)
}

export function sendAffiliateEvent(
  event: AffiliateEventName,
  {
    data: {
      common: { amount, term, purpose },
      system: { configurationId, advertisementId, subId }
    }
  }: TraversalStore,
  rootStore: RootStore
) {
  const {
    traversal: { data },
    fcid
  } = rootStore

  const { refId } = data.system

  const notAffiliate = isNotAffiliate(rootStore.urlParams?.context)

  /* if it's not affiliate we don;t fire affiliate calls */
  if (notAffiliate) {
    return
  }

  const fcId = getCookie(COOKIE_KEY_FCID) || fcid.getFcid()
  if (!fcId) {
    logAndTrackError(
      new Error(`fcid cookie missing for tracking event ${event}`)
    )
    return
  }

  const isSmavaTenant = isTenantSmava()

  const payload: AffiliatePayload = {
    event: withNamespace(event),
    amount,
    term,
    purpose,
    advertisementId,
    configurationId,
    fcId,
    subId,
    refId,
    ...(isSmavaTenant && { team: 'anchor' })
  }

  try {
    broadcastToParent({
      eventName: RegisteredEventName.tracking,
      data: payload
    })
  } catch (error) {
    logAndTrackError(error as Error)
  }

  logInfo(`[Affiliate Event] ${JSON.stringify(payload, null, 2)}`)
}

export const redirectAffiliate = (
  redirectUrl: string,
  eventName: RegisteredEventName
) => {
  window.location.replace(redirectUrl)
  broadcastToParent({
    eventName,
    data: {
      url: redirectUrl
    }
  })
}
