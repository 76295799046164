import FormControl, { FormControlProps } from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import FormHelperText from '@mui/material/FormHelperText'
import { styled, Theme } from '@mui/material/styles'
import Tooltip from 'components/Tooltip'
import TooltipButton from 'components/Tooltip/Button'
import { TooltipProps } from 'components/Tooltip/interface'
import {
  useMemo,
  ChangeEvent,
  FocusEvent,
  useState,
  useCallback,
  useEffect,
  memo
} from 'react'
import RadioSmava from 'components/Smava/Radio'
import * as CSSTypes from 'csstype'
import useEmotionStyles from 'hooks/useEmotionStyles'
import useEmotionStyledComponent from 'hooks/useEmotionStyledComponent'
import Tag from 'components/Tag'

type CastFunc<T> = (value: T | undefined) => unknown

const castFunc: Record<string, CastFunc<string | undefined>> = {
  number: (value) => {
    if (value === undefined) {
      return undefined
    }

    if (typeof value === 'string') {
      return +value
    }

    return value
  },
  boolean: (value) => (value ? value === 'true' : false)
}

export interface Choice<T = string> {
  label: string
  value: T
  hint?: string
  tag?: string
  mostSelected?: boolean
}

interface ChoiceGroupProps<T = string> extends TooltipProps {
  name: string
  label?: string
  value?: T
  choices: Choice<T>[]
  onChange?: (value: T) => void
  error?: string
  clue?: string
  forceFullWidth?: boolean
  forceFullWidthDesktop?: boolean
  onFocus?: (event: FocusEvent<HTMLFieldSetElement>) => void
  onBlur?: (event: FocusEvent<HTMLFieldSetElement>) => void
  outlinedChoice?: boolean
  showTooltipOnFieldClick?: boolean
  isTooltipHidden?: boolean
  horizontalRadio?: boolean
}

const TagContainer = styled('div')(({ theme }) => ({
  position: 'absolute' as CSSTypes.Property.Position,
  top: `50%`,
  right: theme.spacing(1),
  transform: `translateY(calc(-50% - ${theme.spacing(0.5)}))`,
  pointerEvents: 'none'
}))

const LabelWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  margin: '0.5rem 0',
  width: '100%',
  userSelect: 'none',

  '& span:first-of-type': {
    flex: '1',
    flexBasis: 'auto',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    textAlign: 'left',
    cursor: 'pointer',
    paddingRight: '1rem',
    color: '#2d3748'
  },

  '& span ~ span': {
    color: '#718096',
    fontSize: '0.875rem'
  }
})
type StyleProps = {
  amountOfOptions: number
  forceFullWidth: boolean
  forceFullWidthDesktop: boolean
}

const styles = (
  theme: Theme,
  { amountOfOptions, forceFullWidth, forceFullWidthDesktop }: StyleProps
) => ({
  labelContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  formLabel: {
    fontWeight: 'bold',
    paddingRight: theme.spacing(1)
  },
  outlinedFormLabel: {
    paddingRight: theme.spacing(1),
    fontSize: '14px',
    color: theme.palette.grey[900],

    '&.Mui-error': {
      color: theme.palette.grey[900]
    }
  },
  horizontalRadioGroup: {
    flexDirection: 'row' as CSSTypes.Property.FlexDirection,
    display: 'flex',
    justifyContent: 'space-between'
  },
  outlinedRadioGroup: {
    flexDirection: 'row' as CSSTypes.Property.FlexDirection,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    margin: `${theme.spacing(1)} 0 -${theme.spacing(1)}`
  },
  outlinedControlLabel: {
    position: 'relative' as CSSTypes.Property.Position,
    width: forceFullWidthDesktop ? '100%' : '49%',
    height: forceFullWidth ? 'auto%' : '40px',
    margin: `${theme.spacing(1)} 0`,
    '@media (max-width: 768px)': {
      width: amountOfOptions > 2 || forceFullWidth ? '100%' : '49%'
    }
  }
})

const StyledFormControl = (props: FormControlProps<any>) => {
  const FormControlStyled = useEmotionStyledComponent<typeof FormControl>(
    () => ({
      root: {
        width: '100%'
      }
    }),
    FormControl
  )

  return <FormControlStyled {...props} />
}

function ChoiceGroup<T = string>({
  name,
  label = '',
  value,
  onChange,
  choices: options,
  error,
  clue,
  tooltip,
  showTooltip = false,
  onFocus,
  onBlur,
  forceFullWidth = false,
  forceFullWidthDesktop = false,
  outlinedChoice = false,
  showTooltipOnFieldClick = false,
  isTooltipHidden = false,
  horizontalRadio = false
}: ChoiceGroupProps<T>) {
  const cast: CastFunc<string | undefined> | undefined = useMemo(
    () => options && castFunc[typeof options[0]?.value],
    [options]
  )
  const [currentValue, setCurrentValue] = useState<T | string | undefined>(
    value
  )
  const handleChange = useCallback(
    ({ target: { value: newValue } }: ChangeEvent<HTMLInputElement>) => {
      setCurrentValue(newValue)

      if (onChange) {
        onChange((cast ? cast(newValue) : newValue) as T)
      }
    },
    [onChange, cast]
  )

  useEffect(() => {
    if (value === currentValue) {
      return
    }

    setCurrentValue(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const outlineChoiceLabelFormatter = useCallback((choice: Choice<T>) => {
    if (!choice.tag) {
      return ''
    }

    return (
      <TagContainer>
        <Tag>{choice.tag}</Tag>
      </TagContainer>
    )
  }, [])

  const labelFormatter = useCallback((choice: Choice<T>) => {
    if (!choice.hint) {
      return choice.label
    }

    return (
      <LabelWrapper>
        <span>{choice.label}</span>
        <span>{choice.hint}</span>
      </LabelWrapper>
    )
  }, [])

  const [isTooltipDisplayed, setTooltipDisplayed] = useState(showTooltip)
  const handleTooltipButtonClick = useCallback(() => {
    setTooltipDisplayed(!isTooltipDisplayed)
  }, [isTooltipDisplayed])

  const {
    labelContainer,
    formLabel,
    outlinedRadioGroup,
    outlinedFormLabel,
    outlinedControlLabel,
    horizontalRadioGroup
  } = useEmotionStyles<ReturnType<typeof styles>, StyleProps>(styles, {
    amountOfOptions: options?.length,
    forceFullWidth,
    forceFullWidthDesktop
  })

  return (
    <StyledFormControl
      component="fieldset"
      error={!!error}
      onFocus={onFocus}
      onBlur={onBlur}
      color="primary"
    >
      <div className={labelContainer}>
        <FormLabel
          color="primary"
          component="legend"
          className={outlinedChoice ? outlinedFormLabel : formLabel}
        >
          {label}
        </FormLabel>
        {tooltip && !isTooltipHidden && (
          <TooltipButton
            onClick={handleTooltipButtonClick}
            path={name}
            isVisible={isTooltipDisplayed}
          />
        )}
      </div>
      {tooltip && (
        <Tooltip
          open={isTooltipDisplayed}
          isAvailable={showTooltipOnFieldClick}
          isMinimalistic={showTooltipOnFieldClick}
        >
          {tooltip}
        </Tooltip>
      )}
      <RadioGroup
        aria-label={label}
        name={name}
        value={currentValue !== undefined ? `${currentValue}` : undefined}
        onChange={handleChange}
        className={
          outlinedChoice
            ? outlinedRadioGroup
            : horizontalRadio
              ? horizontalRadioGroup
              : ''
        }
      >
        {options?.map((choice) => (
          <FormControlLabel
            key={`choice-${choice.label}-${choice.value}`}
            value={choice.value !== undefined ? `${choice.value}` : undefined}
            className={outlinedChoice ? outlinedControlLabel : ''}
            control={
              !outlinedChoice ? (
                <Radio />
              ) : (
                <RadioSmava
                  choice={choice}
                  isSelected={choice.value === currentValue}
                  hasError={!!error}
                />
              )
            }
            label={
              !outlinedChoice
                ? labelFormatter(choice)
                : outlineChoiceLabelFormatter(choice)
            }
          />
        ))}
      </RadioGroup>
      {(error || clue) && <FormHelperText>{error || clue}</FormHelperText>}
    </StyledFormControl>
  )
}

export default memo(
  ChoiceGroup,
  (prevProps, nextProps) =>
    JSON.stringify(prevProps) === JSON.stringify(nextProps)
) as typeof ChoiceGroup
