import { Debtor } from '../../../containers/PersonalForm/PersonalForm.interface'
import {
  LivingSituation,
  Data
} from '../../../interfaces/ApolloResponse.interface'

export const isSelfUsedProperty =
  (debtor: Debtor = Debtor.PRIMARY) =>
  (state: Data) =>
    state.debtors[debtor].household.livingSituation === LivingSituation.Property

export const hasRentalProperty =
  (debtor: Debtor = Debtor.PRIMARY) =>
  (state: Data): boolean =>
    !!(
      state.debtors[debtor].realEstate.rentalProperty.type &&
      state.debtors[debtor].realEstate.rentalProperty.type !== 'NONE'
    )

export const hasAnyProperty =
  (debtor: Debtor = Debtor.PRIMARY) =>
  (state: Data) =>
    state.debtors[debtor].household.livingSituation ===
      LivingSituation.Property ||
    !!(
      state.debtors[debtor].realEstate.rentalProperty.type &&
      state.debtors[debtor].realEstate.rentalProperty.type !== 'NONE'
    )

export const paysRent =
  (debtor: Debtor = Debtor.PRIMARY) =>
  (state: Data) => {
    const situationsPayRent: LivingSituation[] = [
      LivingSituation.Renting,
      LivingSituation.Parents
    ]
    const { livingSituation } = state.debtors[debtor].household

    return livingSituation ? situationsPayRent.includes(livingSituation) : false
  }

export const paysAddCost =
  (debtor: Debtor = Debtor.PRIMARY) =>
  (state: Data) => {
    const { livingSituation } = state.debtors[debtor].household

    return (
      livingSituation === LivingSituation.RentFree ||
      isSelfUsedProperty(debtor)(state)
    )
  }
