import { Theme, Typography } from '@mui/material'
import { useStores } from 'stores/utils/hooks/useStores'
import useEmotionStyles from 'hooks/useEmotionStyles'
import * as CSSTypes from 'csstype'
import {
  getCivilStatus,
  getHouseholdLivingSituation,
  getJob,
  getRentalPropertyType
} from './helpers'

interface LineProps {
  label: string
  value?: string | number
}

export const Line = ({
  label,
  value
}: LineProps): React.ReactElement | null => {
  const { line: lineStyle, value: valueStyle } = useEmotionStyles(styles)

  if (!value && value !== 0) {
    return null
  }

  return (
    <div className={lineStyle}>
      <Typography variant="body2">{label}</Typography>
      <Typography variant="subtitle2" className={valueStyle}>
        {String(value)}
      </Typography>
    </div>
  )
}

const Summary = (): React.ReactElement => {
  const {
    traversal: {
      data: { debtors }
    }
  } = useStores()

  const { summary, summaryTitle } = useEmotionStyles(styles)

  return (
    <div>
      <div className={summary}>
        <Typography variant="body2" className={summaryTitle}>
          Ihre Angaben:
        </Typography>
        <Line label="Anzahl Kreditnehmer" value={debtors.count} />
        <Line
          label="Familienstand"
          value={getCivilStatus(debtors.primary.personal.civilStatus)}
        />
        <Line
          label="Wohnsituation"
          value={getHouseholdLivingSituation(
            debtors.primary.household.livingSituation
          )}
        />
        <Line
          label="Erwachsene im Haushalt (ab 18 Jahre)"
          value={
            debtors.primary.household.numberOfResidents -
            debtors.primary.household.numberOfChildren
          }
        />
        <Line
          label="Kinder im Haushalt (unter 18 Jahre)"
          value={debtors.primary.household.numberOfChildren}
        />
        <Line
          label="Vermietete Immobilie"
          value={getRentalPropertyType(
            debtors.primary.realEstate.rentalProperty.type
          )}
        />
        <Line
          label="Beruf"
          value={getJob(debtors.primary.currentEmployment.position)}
        />
        {/* @TODO TBD what values should we display here */}
        <Line label="Nettoeinkommen" value={debtors.primary.income.primary} />
        {/* <Line
          label="Ausgaben"
          value={calculateExpenses(debtors.primary.expenses)}
        /> */}
      </div>

      {debtors.count === 2 && (
        <div className={summary}>
          <Typography variant="body2" className={summaryTitle}>
            2. Kreditnehmer:
          </Typography>
          <Line
            label="Familienstand"
            value={getCivilStatus(debtors.secondary.personal.civilStatus)}
          />
          <Line
            label="Wohnsituation"
            value={getHouseholdLivingSituation(
              debtors.secondary.household.livingSituation
            )}
          />
          {!debtors.isCommonHousehold && (
            <>
              <Line
                label="Erwachsene im Haushalt (ab 18 Jahre)"
                value={
                  debtors.secondary.household.numberOfResidents -
                  debtors.secondary.household.numberOfChildren
                }
              />
              <Line
                label="Kinder im Haushalt (unter 18 Jahre)"
                value={debtors.secondary.household.numberOfChildren}
              />
            </>
          )}
          <Line
            label="Vermietete Immobilie"
            value={getRentalPropertyType(
              debtors.secondary.realEstate.rentalProperty.type
            )}
          />
          <Line
            label="Beruf"
            value={getJob(debtors.secondary.currentEmployment.position)}
          />
          <Line
            label="Nettoeinkommen"
            value={debtors.secondary.income.primary}
          />
          {/* <Line
            label="Ausgaben"
            value={calculateExpenses(debtors.secondary.expenses)}
          /> */}
        </div>
      )}
    </div>
  )
}

export default Summary

const styles = (theme: Theme) => ({
  summary: {
    marginBottom: theme.spacing(3),

    '&:last-child': {
      marginBottom: 0
    }
  },
  summaryTitle: {
    marginBottom: theme.spacing(2)
  },
  line: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(0.75)
  },
  value: {
    textAlign: 'right' as CSSTypes.Property.TextAlign,
    fontWeight: 'bold'
  }
})
