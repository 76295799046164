import { getClientDetails } from './helpers/clientDetailsData/getClientDetails'

interface Logos {
  client: string
  tuev: string
  ekomi: string
  ssl: string
  schufaNeutral: string
  guenstigerGehtNicht?: string
  focusMoneyBadge?: string
  focusMoneyBadgeAlt?: string
}

interface Urls {
  ekomi: string
  imprint: string
  dataProtection: string
  termsAndConditions: string
  faq: string
  tuev: string
  precontractual: string
  declarationOfConsentAdvertising?: string
  dac: string
  faqHome: string
  focusMoney?: string
  home: string
  logout: string
  mandatoryInformation: string
  startPage: string
}

interface ClientDetails {
  uuid: string
  email: string
  company: string
  street: string
  city: string
  country: string
  displayName: string
  zipCode: string
  phone: string
  workingTimes: string
  logos: Logos
  urls: Urls
}

class ClientDetailsStore {
  public clientDetails: ClientDetails | null = null

  constructor() {
    this.clientDetails = getClientDetails()
  }
}

export default ClientDetailsStore
