import { Data } from 'interfaces/ApolloResponse.interface'
import { Debtor } from '../../../containers/PersonalForm/PersonalForm.interface'
import canHaveMinijob from './canHaveMinijob'
import { CivilStatus } from '../statics/enums/CivilStatus'
import isRetired from './isRetired'

export const hasFurtherIncome =
  (debtor: Debtor = Debtor.PRIMARY) =>
  (state: Data) =>
    state.debtors[debtor].income.hasFurtherIncome

const isMarried =
  (debtor: Debtor = Debtor.PRIMARY) =>
  (state: Data) =>
    state.debtors[debtor].personal.civilStatus === CivilStatus.MARRIED

export const canObtainSpousalSupport =
  (debtor: Debtor = Debtor.PRIMARY) =>
  (state: Data) =>
    hasFurtherIncome(debtor)(state) && !isMarried(debtor)(state)

// it seems that hasNoPrimaryIncome returns the opposite, please be careful when using it, or, even better, refactor it
export const hasNoPrimaryIncome =
  (debtor: Debtor = Debtor.PRIMARY) =>
  (state: Data) =>
    !(canHaveMinijob(debtor)(state) || isRetired(debtor)(state))
