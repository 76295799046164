import {
  resetCurrentEmploymentFirstDebtor,
  resetCurrentEmploymentSecondDebtor
} from './_resetCurrentEmploymentUntilErrors'

const reactions = [
  resetCurrentEmploymentFirstDebtor,
  resetCurrentEmploymentSecondDebtor
]

export default reactions
