import { AxiosRequestConfig, AxiosResponse } from 'axios'
import ApiError from './apiError'

export default class FinalizeError<T> extends ApiError<T> {
  constructor(
    message: string,
    config: AxiosRequestConfig,
    response?: AxiosResponse<T>
  ) {
    super(message, config, response)

    this.name = 'FinalizeError'
  }
}
