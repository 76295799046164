import { Typography } from '@mui/material'
import { memo } from 'react'
import { styled } from '@mui/material/styles'
import { Choice } from './radio.interface'

const LabelWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  margin: '0.5rem 0',
  width: '100%',
  userSelect: 'none'
})

interface RadioLabelProps<T> {
  choice: Choice<T>
}

function TealRadioLabel<T>({ choice }: RadioLabelProps<T>) {
  if (!choice.hint) {
    return <div>{choice.label}</div>
  }

  return (
    <LabelWrapper>
      <Typography variant="subtitle1">{choice.label}</Typography>
      <Typography variant="subtitle2">{choice.hint}</Typography>
    </LabelWrapper>
  )
}

export const RadioLabel = memo(TealRadioLabel) as typeof TealRadioLabel
