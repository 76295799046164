const occupation = [
  { label: 'Altenpfleger/in', value: 'CARE' },
  { label: 'Ambulante/r Krankenpfleger/in', value: 'HOME_CARE_NURSE' },
  { label: 'Anwalt/Anwältin', value: 'LAWYER' },
  { label: 'Apotheker/in', value: 'PHARMACIST' },
  { label: 'Architekt/in', value: 'ARCHITECT' },
  { label: 'Arzt/Ärztin', value: 'DOCTOR' },
  { label: 'Bauleiter/in', value: 'CONSTRUCTION_SUPERVISOR' },
  { label: 'Bestatter/in', value: 'FUNERAL_DIRECTOR' },
  { label: 'Datenschutzbeauftragte/r', value: 'DATA_SECURITY_OFFICIAL' },
  { label: 'Dekorateur/in', value: 'DECORATOR' },
  { label: 'Diätassistent/in', value: 'DIETICIAN' },
  { label: 'Dolmetscher/in', value: 'INTERPRETER' },
  { label: 'EDV-Berater/in', value: 'IT_CONSULTANT' },
  { label: 'Ergotherapeut/in', value: 'OCCUPATIONAL_THERAPIST' },
  { label: 'Ernährungsberater/in', value: 'NUTRITIONIST' },
  { label: 'Fotograf/in', value: 'PHOTOGRAPHER' },
  { label: 'Geograf/in', value: 'GEOGRAPHER' },
  { label: 'Grafiker/in', value: 'GRAPHIC_ARTIST' },
  { label: 'Hausverwalter/in', value: 'CARETAKER' },
  { label: 'Hebamme', value: 'MIDWIFE' },
  { label: 'Heilmasseur/in', value: 'MASSAGE_THERAPIST' },
  { label: 'Heilpraktiker/in', value: 'HEALER' },
  { label: 'Historiker/in', value: 'HISTORIAN' },
  { label: 'Informatiker/in', value: 'COMPUTER_SCIENTIST' },
  { label: 'Ingenieur/in', value: 'ENGINEER' },
  {
    label: 'Ingenieur/in (öffentlich bestellt für Vermessungen)',
    value: 'CIVIL_ENGINEER'
  },
  { label: 'Insolvenzverwalter/in', value: 'LIQUIDATOR' },
  { label: 'Journalist/in', value: 'JOURNALIST' },
  { label: 'Konstrukteur/in', value: 'DESIGNING_ENGINEER' },
  { label: 'Klassische/r Konzertmusiker/in', value: 'CONCERT_MUSICIAN' },
  { label: 'Krankengymnastiker/in', value: 'PHYSICAL_THERAPIST' },
  { label: 'Krankenpfleger/in', value: 'MALE_NURSE' },
  { label: 'Krankenschwester', value: 'NURSE' },
  { label: 'Logopäde/Logopädin', value: 'SPEECH_THERAPIST' },
  {
    label: 'Medizinisch-technische/r Assistent/in',
    value: 'MEDICAL_TECHNICAL_ASSISTANT'
  },
  { label: 'Notar/in', value: 'NOTARY' },
  { label: 'Opernsänger/in', value: 'OPERA_SINGER' },
  { label: 'Personalberater/in', value: 'HR_CONSULTANT' },
  { label: 'Physiotherapeut/in', value: 'PHYSIOTHERAPIST' },
  { label: 'Psychologe/Psychologin', value: 'PSYCHOLOGIST' },
  { label: 'Raumausstatter/in', value: 'INTERIOR_DECORATOR' },
  { label: 'Rundfunksprecher/in', value: 'BROADCASTER' },
  { label: 'Sachverständige/r', value: 'SPECIALIST' },
  { label: 'Stadtplaner/in', value: 'URBAN_PLANNER' },
  { label: 'Statiker/in', value: 'STRUCTURAL_ENGINEER' },
  { label: 'Steuerberater/in, Wirtschaftsprüfer/in', value: 'ACCOUNTANT' },
  { label: 'Tierarzt/Tierärztin', value: 'VET' },
  { label: 'Unternehmensberater/in', value: 'CORPORATE_CONSULTANT' },
  { label: 'Vermittler/in der ING-DiBa', value: 'BROKER' },
  { label: 'Wirtschaftliche/r Buchprüfer/in, Revisor/in', value: 'REVISOR' },
  { label: 'Zahnarzt/Zahnärztin', value: 'DENTIST' },
  { label: 'Zahntechniker/in', value: 'DENTAL_TECHNICIAN' },
  { label: 'Sonstiges', value: 'OTHER' }
]

export default occupation
