import InfoIcon from '@mui/icons-material/Info'
import IconButton from '@mui/material/IconButton'
import { memo, MouseEvent, TouchEvent, useCallback } from 'react'
import useEmotionStyles from 'hooks/useEmotionStyles'
import { Theme } from '@mui/material'
import { trackInAmplitude } from 'utils/tracking/amplitude'
import {
  AmplitudeEvent,
  InformationElement
} from 'utils/tracking/amplitude/amplitude.interface'

interface Props {
  onClick: (
    event: MouseEvent<HTMLButtonElement> | TouchEvent<HTMLButtonElement>
  ) => void
  path?: string
  isVisible?: boolean
}

const styles = (theme: Theme) => ({
  button: {
    color: 'rgba(113, 128, 150, 0.7)',
    alignSelf: 'start',
    marginTop: theme.spacing(0.5)
  }
})

const TooltipButton = ({ onClick, path, isVisible }: Props) => {
  const { button } = useEmotionStyles(styles)

  const handleMouseDown = useCallback(
    (event: TouchEvent<HTMLButtonElement> | MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      event.preventDefault()
      onClick(event)

      trackInAmplitude(AmplitudeEvent.ToggleInformation, {
        path,
        type: InformationElement.Tooltip,
        /* 
          Please notice that `isVisible` value is a status BEFORE click,
          that's why we need to take the opposite value and assign `!isVisible`.
          
          That means, if we have this event reported and isVisible in amplitude is true, 
          a user clicked the button and sees the tooltip content.
          When isVisible === false, that means a user clicked the button 
          and the tooltip content has been hidden.
        */
        isVisible: !isVisible
      })
    },
    [onClick, path, isVisible]
  )

  return (
    <IconButton
      className={button}
      aria-label="toggle tooltip"
      onTouchEnd={handleMouseDown}
      onMouseDown={handleMouseDown}
      size="large"
    >
      <InfoIcon />
    </IconButton>
  )
}

export default memo(TooltipButton)
