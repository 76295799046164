import { Data } from 'interfaces/ApolloResponse.interface'

export const IBAN_MIN_LENGTH = 15

const isValidPayoutAccount = ({
  common: {
    payoutAccount: { iban, accountNumber }
  }
}: Data): boolean =>
  !!(
    (iban && iban.length >= IBAN_MIN_LENGTH) ||
    (accountNumber && accountNumber.length > 0)
  )

export default isValidPayoutAccount
