import { createContext, PropsWithChildren, useContext, useMemo } from 'react'
import { pick } from 'dot-object'
import { logWarning } from 'utils/log'
import { Choice } from 'components/Input/ChoiceGroup'
import { TraversalErrorType } from 'interfaces/ApolloResponse.interface'
import { useText } from '../hooks/useText'

export interface FieldMetaWithOptions<T> {
  castToNumber?: boolean
  options?: Choice<T>[]
  label?: string
  labelOpposite?: string
  selectLabel?: string
  infoLabel?: string
  singleInfoLabel?: string
  placeholder?: string
  errors?: string[]
  tooltip?: string
  errorMap?: Record<TraversalErrorType, string>
}

export interface MetaOptions<T> {
  meta?: FieldMetaWithOptions<T>
  labelPath?: string
}

export const MetaDataContext = createContext<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Record<string, FieldMetaWithOptions<any>>
>({})

interface Props {
  path: string
  labelPath?: string
}

const MetaDataProvider = ({
  children,
  path,
  labelPath = path
}: PropsWithChildren<Props>) => {
  const metaContext = useContext(MetaDataContext)
  const { text } = useText()

  const meta: Record<string, unknown> = useMemo(() => {
    let value = pick(path, text)

    if (!value && labelPath.length > 0) {
      value = pick(labelPath, text)
    }

    if (!value) {
      logWarning(`No metadata for ${path}`)

      return {}
    }

    return value
  }, [path, labelPath, text])

  const context = useMemo(
    () => ({ ...metaContext, [path]: meta }),
    [path, meta, metaContext]
  )

  return (
    <MetaDataContext.Provider value={context}>
      {children}
    </MetaDataContext.Provider>
  )
}

export default MetaDataProvider
