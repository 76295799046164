import { useField, UseFieldConfig, FieldRenderProps } from 'react-final-form'

import { getRulesForPath } from 'validation'
import { Data } from 'interfaces/ApolloResponse.interface'
import { useRootStore } from './useRootStore'
import { useError } from './useError'

export type FinalFormFieldProps<T> = FieldRenderProps<T, HTMLElement> & {
  error?: string
}

/**
 * The `useField` hook from FinalForm is more or less unchanged.
 * The `validate` method of the passed in fieldConfig is
 * used to trigger the handlePathFormFieldChange.
 *
 * @param {string} fieldPath The path in the Traversal, e.g. `debtor.primary.expenses.rent`
 * @param {UseFieldConfig<any>} fieldConfig see FinalForm documentation for `UseFieldConfig`
 * @param {boolean} patch Indicating wether to execute the PATCH request, defaults to `true`
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function usePatchedField<T = any>(
  fieldPath: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldConfig: UseFieldConfig<any>,
  // eslint-disable-next-line default-param-last
  patch = true,
  internalValidation?: (value: string) => boolean
): FinalFormFieldProps<T> {
  const {
    rootStore: {
      page: { config },
      traversal: { getFieldValueByPath, handlePathFormFieldChange }
    }
  } = useRootStore()

  const field = useField(fieldPath, {
    ...fieldConfig,
    subscription: {
      value: true,
      error: true,
      submitError: true,
      dirtySinceLastSubmit: true,
      touched: true
    },
    validate: (value, allValues, meta) => {
      const globalRules = getRulesForPath(fieldPath)
      const globalValidationResults = globalRules.map((validationFunction) =>
        validationFunction(value, allValues as Data, config, meta)
      )

      const hasInternalValidationErrors =
        internalValidation && value ? !internalValidation(value) : false

      const hasGlobalValidationErrors =
        globalValidationResults.filter(Boolean).length !== 0

      /**
       * Only patch the field if we do not have any global validation results (aka frontend validation errors)
       * and field got modified.
       */
      if (
        !hasInternalValidationErrors &&
        !hasGlobalValidationErrors &&
        meta?.modified
      ) {
        const performPatch = patch && getFieldValueByPath(fieldPath) !== value

        handlePathFormFieldChange(fieldPath, value, performPatch)
      }

      const externalValidation = fieldConfig.validate
      const externalValidationResult = []

      if (externalValidation) {
        const tmp = externalValidation(value, allValues, meta)

        if (!Array.isArray(tmp)) {
          externalValidationResult.push(tmp)
        } else {
          externalValidationResult.push(...tmp)
        }
      }

      const errors = [...externalValidationResult, ...globalValidationResults]
      const errorsWithoutNullishValues = errors.filter(Boolean)

      if (errorsWithoutNullishValues.length === 0) {
        return undefined
      }

      return errorsWithoutNullishValues
    }
  })

  const { error } = useError(fieldPath)
  return {
    ...field,
    error
  }
}
