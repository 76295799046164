const loanTerms = [
  { label: '12 Monate', value: 12 },
  { label: '24 Monate', value: 24 },
  { label: '36 Monate', value: 36 },
  { label: '48 Monate', value: 48 },
  { label: '60 Monate', value: 60 },
  { label: '72 Monate', value: 72 },
  { label: '84 Monate', value: 84 },
  { label: '96 Monate', value: 96 },
  { label: '108 Monate', value: 108 },
  { label: '120 Monate', value: 120 }
]

export default loanTerms
