import { memo } from 'react'

import { Theme, Typography, useMediaQuery, useTheme } from '@mui/material'

import FakeOffersSkeletons from 'components/FakeOffers'
import useEmotionStyles from 'hooks/useEmotionStyles'

const DEFAULT_FAKE_BANK_LIST_NAMES = ['ingDiba', 'dkb', 'targobank']

const FakeOffers = ({
  showHeader,
  fakeBankListNames,
  isLogoCentered,
  areSkeletonsWithLabels,
  skeletonBorderRadius,
  isUsingSmavaCdnUrl
}: {
  showHeader?: boolean
  fakeBankListNames?: string[]
  isLogoCentered?: boolean
  areSkeletonsWithLabels?: boolean
  skeletonBorderRadius?: number
  isUsingSmavaCdnUrl?: boolean
}) => {
  const { title } = useEmotionStyles(styles)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <div>
      {showHeader && (
        <Typography variant="h1" align="center" className={title}>
          Bitte haben Sie einen Augenblick Geduld!
        </Typography>
      )}
      <FakeOffersSkeletons
        fakeOffersNumber={5}
        skeletonsNumber={isMobile ? 3 : 5}
        fakeBankListNames={fakeBankListNames || DEFAULT_FAKE_BANK_LIST_NAMES}
        areSkeletonsWithLabels={areSkeletonsWithLabels}
        isLogoCentered={isLogoCentered}
        skeletonBorderRadius={skeletonBorderRadius}
        isUsingSmavaCdnUrl={isUsingSmavaCdnUrl}
        isAnimated
      />
    </div>
  )
}

export default memo(FakeOffers)

const styles = (theme: Theme) => ({
  title: {
    marginBottom: theme.spacing(5)
  }
})
