import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { getFullUrl } from 'api/utils'
import ApiError from './apiError'

export default class ResponseError<T> extends ApiError<T> {
  constructor(config: AxiosRequestConfig, response?: AxiosResponse<T>) {
    const methodText = `[${config.method || 'Response error'}]`
    const status = response?.status
      ? `[Response status: ${response.status}]`
      : ''

    const message = `${methodText}${status} ${getFullUrl(config)}`

    super(message, config, response)

    this.name = 'ResponseError'
  }
}
