import { AxiosError } from 'axios'

interface ErrorResponse {
  message: string
  onRecover?: () => boolean
  recoveryTime?: number
}

const responseErrorMap: Partial<
  Record<number, (error: AxiosError) => ErrorResponse>
> = {
  429: () => ({
    recoveryTime: 10000,
    message:
      'Es wurden zu viele Serveranfragen auf einmal gesendet. Bitte versuchen Sie es später erneut.'
  })
}

export function getErrorResponse(error: AxiosError | undefined): ErrorResponse {
  if (error && error.response) {
    const response = responseErrorMap[error.response.status]
    if (response) {
      return response(error)
    }
  }

  if (!window.navigator.onLine) {
    return {
      message: 'Leider besteht momentan keine Verbindung zum Internet.',
      onRecover: () => window.navigator.onLine,
      recoveryTime: 2000
    }
  }

  return { message: 'Leider ist bei Ihrer Anfrage etwas schief gelaufen.' }
}
