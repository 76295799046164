import SvgIcon from '@mui/material/SvgIcon'
import { PermIdentityTwoTone } from '@mui/icons-material'
import { observer } from 'mobx-react'
import ConditionalWrapper from 'components/ConditionalWrapper'
import Customer from 'assets/icons/nudges/customer.svg'
import { Nudge } from '../Nudge'

const defaultText =
  'Super! Mit einem zweiten Kreditnehmer erhöhen sich Ihre Chancen auf einen günstigen Kredit.'

interface Props {
  dependson: string
  text?: string
  isCustomIconEnabled?: boolean
  Icon?: typeof SvgIcon
  isForced?: boolean
}

export const TwoDebtorsNudge = observer(
  ({ dependson, text, isCustomIconEnabled, Icon, isForced }: Props) => (
    <ConditionalWrapper dependson={dependson}>
      <Nudge
        text={text || defaultText}
        Icon={Icon || PermIdentityTwoTone}
        {...(isCustomIconEnabled && { customIcon: Customer })}
        skipDebtorsCountCheck={false}
        isForced={isForced}
      />
    </ConditionalWrapper>
  )
)
