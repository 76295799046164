import { memo } from 'react'
import { styled } from '@mui/material/styles'

const PageLayout = styled('form')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  '&> *:not(:empty)': {
    marginTop: theme.spacing(4)
  }
}))

export default memo(PageLayout)
