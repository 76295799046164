import { format } from 'date-fns'
import { formatToInteger } from 'utils/number'

function hasValue<T>(value: T): value is NonNullable<T> {
  return value !== undefined && value !== null
}

export function getMinMaxError<T>(
  minValue: T,
  maxValue: T,
  {
    min,
    max,
    minMax,
    none
  }: {
    min: (vMin: string) => string
    max: (vMax: string) => string
    minMax: (vMin: string, vMax: string) => string
    none: string
  }
) {
  const formatValue = (value: T) => {
    if (typeof value === 'number') {
      return formatToInteger(value)
    }

    if (value instanceof Date) {
      return format(value, 'dd.MM.yyyy')
    }

    return `${value}`
  }

  if (hasValue(minValue) && hasValue(maxValue)) {
    return minMax(formatValue(minValue), formatValue(maxValue))
  }

  if (hasValue(minValue)) {
    return min(formatValue(minValue))
  }

  if (hasValue(maxValue)) {
    return max(formatValue(maxValue))
  }

  return none
}
