export const getButtonSizes = (
  isBackButtonHidden: boolean,
  isSaveButtonVisible?: boolean | string,
  isContinueButtonHidden?: boolean
) => {
  if (isContinueButtonHidden) {
    return {
      sizeOfContinueButton: 12,
      sizeOfBackButton: 12,
      sizeOfSaveButton: 12
    }
  }

  const sizeOfContinueButton =
    isBackButtonHidden && !isSaveButtonVisible ? 12 : 8
  const sizeOfBackButton = isSaveButtonVisible ? 12 : 4
  const sizeOfSaveButton = 4
  const sizeOfContinueWithDacButton = 0
  const sizeOfContinueWithoutDacButton = 0

  return {
    sizeOfContinueButton,
    sizeOfBackButton,
    sizeOfSaveButton,
    sizeOfContinueWithDacButton,
    sizeOfContinueWithoutDacButton
  }
}
