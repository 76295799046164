import DependencyError from './dependencyError'

export default class DependencyCollectError extends DependencyError {
  constructor(commandName: string, dependencies: Record<string, boolean>) {
    super(
      commandName,
      dependencies,
      `At least one dependency was not found. Check if all the names are spelt correctly.`
    )

    this.name = 'DependencyCollectError'
  }
}
