import countryShort from '../lists/country_short'
import position from '../lists/position'
import sector from '../lists/sector'
import occupation from '../lists/occupation'
import legalForm from '../lists/legal_form'
import numberOfEmployees from '../lists/number_of_employees'
import workingTime from '../lists/working_time'
import yesNoEnum from '../lists/yes_no_enum'

const primaryEmployment = {
  employer: {
    address: {
      city: {
        label: 'Ort Arbeitgeber',
        tooltip: '',
        placeholder: 'z.B. Musterstadt',
        errorMap: {
          missing: 'Bitte geben Sie den Sitz Ihres Arbeitgebers an.',
          wrong_format: 'Bitte geben Sie einen gültigen Ort an.'
        }
      },
      country: {
        label: 'Land Arbeitgeber',
        tooltip: '',
        placeholder: 'Land auswählen',
        options: countryShort,
        errorMap: {
          missing: 'Bitte wählen Sie ein Land aus.'
        }
      },
      houseNumber: {
        label: 'Hausnummer Arbeitgeber',
        tooltip: '',
        placeholder: 'z.B. 73',
        errorMap: {
          missing: 'Bitte geben Sie eine Hausnummer an.',
          invalid: 'Bitte korrigieren Sie die Hausnummer.',
          wrong_format: 'Bitte geben Sie eine gültige Hausnummer an.'
        }
      },
      street: {
        label: 'Straße und Hausnummer Arbeitgeber',
        tooltip: '',
        placeholder: 'z.B. Musterstraße 73',
        errorMap: {
          missing: 'Bitte geben Sie eine Straße an.',
          invalid: 'Bitte korrigieren Sie die Straße.',
          wrong_format: 'Bitte geben Sie einen gültigen Straßennamen an.'
        }
      },
      zipCode: {
        label: 'PLZ Arbeitgeber',
        tooltip: '',
        placeholder: 'z.B. 11011',
        errorMap: {
          missing: 'Bitte geben Sie eine Postleitzahl an.',
          invalid: 'Bitte korrigieren Sie die Postleitzahl.',
          wrong_format: 'Bitte geben Sie eine gültige Postleitzahl an.',
          wrong_length: 'Die Länge der Postleitzahl ist ungültig.'
        }
      }
    },
    name: {
      label: 'Name Arbeitgeber',
      tooltip: '',
      placeholder: 'z.B. Mustermann GmbH',
      errorMap: {
        missing: 'Bitte geben Sie den Namen Ihres Arbeitgebers an.',
        wrong_format: 'Bitte geben Sie einen gültigen Namen an.'
      }
    }
  },
  isExtended: {
    label:
      'Wurde Ihr Arbeitsvertrag beim aktuellen Arbeitgeber schon mindestens einmal verlängert?',
    tooltip: '',
    placeholder:
      'Wurde Ihr Arbeitsvertrag beim aktuellen Arbeitgeber schon mindestens einmal verlängert?',
    enums: yesNoEnum,
    errorMap: {
      missing:
        'Bitte teilen Sie uns mit, ob Ihr Arbeitsvertrag beim aktuellen Arbeitgeber schon mindestens einmal verlängert wurde.'
    }
  },
  isGermanSalaryAccount: {
    label: 'Deutsches Gehaltskonto vorhanden?',
    tooltip: '',
    placeholder: 'Deutsches Gehaltskonto vorhanden?',
    enums: yesNoEnum,
    errorMap: {
      missing:
        'Bitte teilen Sie uns mit, ob Sie ein deutsches Geschäftskonto besitzen.'
    }
  },
  isInProbationPeriod: {
    label: 'Befinden Sie sich in der Probezeit?',
    tooltip: '',
    placeholder: '',
    enums: yesNoEnum,
    errorMap: {
      missing:
        'Bitte teilen Sie uns mit, ob Sie sich in der Probezeit befinden.'
    }
  },
  isTemporary: {
    label: 'Ist Ihr Arbeitsverhältnis befristet?',
    labelOpposite: 'Ist Ihr Arbeitsverhältnis unbefristet?',
    tooltip: '',
    placeholder: 'Ist Ihr Arbeitsverhältnis befristet?',
    enums: yesNoEnum,
    errorMap: {
      missing:
        'Bitte teilen Sie uns mit, ob Ihr Arbeitsverhältnis befristet ist.'
    }
  },
  position: {
    label: 'Berufsgruppe',
    tooltip: '',
    placeholder: 'Berufsgruppe auswählen',
    options: position,
    errorMap: {
      missing: 'Bitte wählen Sie eine Berufsgruppe aus.'
    }
  },
  sector: {
    label: 'Branche',
    tooltip: '',
    placeholder: 'Branche auswählen',
    options: sector,
    errorMap: {
      missing: 'Bitte wählen Sie eine Branche aus.'
    }
  },
  selfEmployment: {
    address: {
      city: {
        label: 'Ort Firma',
        tooltip: '',
        placeholder: 'z.B. Musterstadt',
        errorMap: {
          missing: 'Bitte teilen Sie uns den Sitz Ihrer Firma mit.',
          wrong_format: 'Bitte geben Sie einen gültigen Ort ein.'
        }
      },
      country: {
        label: 'Land Firma',
        tooltip: '',
        placeholder: 'Land auswählen',
        options: countryShort,
        errorMap: {
          missing: 'Bitte wählen Sie ein Land aus.'
        }
      },
      houseNumber: {
        label: 'Hausnummer Firma',
        tooltip: '',
        placeholder: 'z.B. 73',
        errorMap: {
          missing: 'Bitte geben Sie eine Hausnummer an.',
          invalid: 'Bitte korrigieren Sie die Hausnummer.',
          wrong_format: 'Bitte geben Sie eine gültige Hausnummer an.'
        }
      },
      street: {
        label: 'Straße und Hausnummer Firma',
        tooltip: '',
        placeholder: 'z.B. Musterstraße 73',
        errorMap: {
          missing: 'Bitte geben Sie eine Straße an.',
          invalid: 'Bitte korrigieren Sie die Straße.',
          wrong_format: 'Bitte geben Sie einen gültigen Straßennamen an.'
        }
      },
      zipCode: {
        label: 'PLZ Firma',
        tooltip: '',
        placeholder: 'z.B. 11011',
        errorMap: {
          missing: 'Bitte geben Sie eine Postleitzahl an.',
          invalid: 'Bitte korrigieren Sie die Postleitzahl.',
          wrong_format: 'Bitte geben Sie eine gültige Postleitzahl an.',
          wrong_length: 'Die Länge der Postleitzahl ist ungültig.'
        }
      }
    },
    deductions: {
      label: 'Abschreibungen',
      tooltip:
        'Bitte tragen Sie hier eine Schätzung Ihrer Abschreibungen für das laufende Jahr ein.',
      placeholder: 'z.B. 10.000',
      errorMap: {
        missing: 'Bitte geben Sie eine Schätzung ab.',
        invalid: 'Bitte korrigieren Sie den Betrag.',
        wrong_format: 'Bitte geben Sie einen gültigen Betrag ein.'
      }
    },
    isDoubleBookkeepingRequired: {
      label: 'Bilanzierungspflichtig?',
      tooltip: '',
      placeholder: 'Bilanzierungspflichtige Firma',
      enums: yesNoEnum,
      errorMap: {
        missing:
          'Bitte teilen Sie uns mit, ob Ihre Firma bilanzierungspflichtig ist.'
      }
    },
    legalForm: {
      label: 'Rechtsform Firma',
      tooltip: '',
      placeholder: 'Rechtsform auswählen',
      options: legalForm,
      errorMap: {
        missing: 'Bitte teilen Sie uns die Rechtsform Ihrer Firma mit.'
      }
    },
    name: {
      label: 'Name Firma',
      tooltip: '',
      placeholder: 'z.B. Mustermann GmbH',
      errorMap: {
        missing: 'Bitte teilen Sie uns den Namen Ihrer Firma mit.',
        wrong_format: 'Bitte geben Sie einen gültigen Namen an.'
      }
    },
    numberOfEmployees: {
      label: 'Anzahl Mitarbeiter',
      tooltip:
        'Bitte wählen Sie aus, wie viele Mitarbeiter Sie in Ihrem Unternehmen beschäftigen.',
      placeholder: 'Anzahl auswählen',
      options: numberOfEmployees,
      errorMap: {
        missing: 'Bitte teilen Sie uns die Anzahl Ihrer Mitarbeiter mit.'
      }
    },
    occupation: {
      label: 'Tätigkeit',
      tooltip:
        'Bitte wählen Sie aus der Liste das Tätigkeitsfeld aus, das Ihren aktuellen Beruf möglichst genau beschreibt.',
      placeholder: 'Tätigkeit auswählen',
      options: occupation,
      _name: 'occupation.json',
      errorMap: {
        missing: 'Bitte wählen Sie ein Tätigkeitsfeld aus.'
      }
    },
    profitBeforeLastYear: {
      label: 'Gewinn vor Steuern vorletztes Jahr',
      tooltip:
        'Tipp:\nSie finden die Kennzahl in Ihrer Einnahmen- und Überschussrechnung als Gewinn (Betriebsergebnis) sowie in Ihrer betriebswirtschaftlichen Auswertung (BWA) unter Vorläufiges Ergebnis.',
      placeholder: 'z.B. 25.150',
      errorMap: {
        missing: 'Bitte teilen Sie uns den Gewinn mit.',
        invalid: 'Bitte korrigieren Sie den Betrag.',
        wrong_format: 'Bitte geben Sie einen gültigen Betrag ein.'
      }
    },
    profitLastYear: {
      label: 'Gewinn vor Steuern letztes Jahr',
      tooltip:
        'Tipp:\nSie finden die Kennzahl in Ihrer Einnahmen- und Überschussrechnung als Gewinn (Betriebsergebnis) sowie in Ihrer betriebswirtschaftlichen Auswertung (BWA) unter Vorläufiges Ergebnis.',
      placeholder: '(Voraussichtlich) z.B. 28.450',
      errorMap: {
        missing: 'Bitte teilen Sie uns den Gewinn mit.',
        invalid: 'Bitte korrigieren Sie den Betrag.',
        wrong_format: 'Bitte geben Sie einen gültigen Betrag ein.'
      }
    },
    revenueBeforeLastYear: {
      label: 'Umsatz vorletztes Jahr',
      tooltip:
        'Bitte geben Sie an, wie hoch der Umsatz Ihrer Firma im vorletzten Jahr war. Sie finden die Kennzahl in Ihrem Jahresabschluss, Ihrer Einnahmen- und Überschussrechnung sowie Ihrer betriebswirtschaftlichen Auswertung (BWA). Diese Information ist zusammen mit den weiteren Angaben bezüglich Ihrer monatlichen Einnahmen und Ausgaben wichtig für Ihre Haushaltsrechnung.',
      placeholder: 'Umsatz vor 2 Jahren bei Selbständigen',
      errorMap: {
        missing: 'Bitte teilen Sie uns den Umsatz mit.',
        invalid: 'Bitte korrigieren Sie den Betrag.',
        wrong_format: 'Bitte geben Sie einen gültigen Betrag ein.'
      }
    },
    revenueLastYear: {
      label: 'Umsatz letztes Jahr',
      tooltip:
        'Sie finden die Kennzahl in Ihrem Jahresabschluss, Ihrer Einnahmen- und Überschussrechnung sowie Ihrer betriebswirtschaftlichen Auswertung (BWA). Hinweis: Selbstständige geben hier den Umsatz vor 2 Jahren an.',
      placeholder: 'z.B. 64.550',
      errorMap: {
        missing: 'Bitte teilen Sie uns den Umsatz mit.',
        invalid: 'Bitte korrigieren Sie den Betrag.',
        wrong_format: 'Bitte geben Sie einen gültigen Betrag ein.'
      }
    }
  },
  since: {
    label: 'Beschäftigt seit',
    tooltip:
      'Wenn Sie Ihre aktuelle berufliche Tätigkeit seit weniger als 2 Jahren ausüben, möchten die Banken zusätzlich die Angaben zu Ihrer vorherigen Beschäftigung kennen.',
    placeholder: 'z.B. 07/2005',
    errorMap: {
      missing: 'Bitte geben Sie ein Datum an.',
      invalid: 'Bitte korrigieren Sie das Datum.',
      wrong_format: 'Bitte geben Sie ein gültiges Datum an (MM.JJJJ).'
    }
  },
  until: {
    label: 'Beschäftigung befristet bis',
    tooltip:
      'Wieso fragen wir das?\nAufgrund dessen, kann ein optimales Angebot für Sie ermittelt werden.',
    placeholder: 'Beschäftigung befristet bis',
    errorMap: {
      missing: 'Bitte geben Sie ein Datum an.',
      invalid: 'Bitte korrigieren Sie das Datum.',
      wrong_format: 'Bitte geben Sie ein gültiges Datum an (MM.JJJJ).',
      out_of_range: 'Das Datum für die Befristung muss in der Zukunft liegen.'
    }
  },
  workingTime: {
    label: 'Arbeiten Sie in Teilzeit?',
    tooltip: '',
    placeholder: 'Beschäftigungstyp auswählen',
    options: workingTime,
    errorMap: {
      missing: 'Bitte teilen Sie uns mit, ob Sie Voll- oder Teilzeit arbeiten.'
    }
  }
}

export default primaryEmployment
