import { Data } from 'interfaces/ApolloResponse.interface'
import { Debtor } from '../../../containers/PersonalForm/PersonalForm.interface'
import { jobsWithMinijobOptions } from '../statics/lists'

const canHaveMinijob =
  (debtor: Debtor = Debtor.PRIMARY) =>
  (state: Data): boolean =>
    jobsWithMinijobOptions.includes(
      state.debtors[debtor].currentEmployment.position
    )

export default canHaveMinijob
