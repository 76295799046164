import { useRef, useEffect, useCallback, memo } from 'react'
import { observer } from 'mobx-react'

import { useUser } from 'context/User/hook'
import { useProgress } from 'stores/utils/hooks/useProgress'
import { useStores } from 'stores/utils/hooks/useStores'
import { logInfo } from 'utils/log'
import { useInteraction } from 'hooks/useInteraction'

const DEFAULT_INTERVAL_DELAY = 30000 // 30 seconds

function getFirstInput(): HTMLInputElement | null {
  return document.querySelector('input,textarea')
}

const IdleDetector = observer(() => {
  const {
    navigation: { currentPageInSession }
  } = useStores()
  const {
    touchedInput: { last }
  } = useProgress()
  const intervalRef = useRef<number | null>(null)
  const hasInteractedRef = useRef(false)
  const { hasInteracted, setHasInteracted } = useInteraction()

  const { idleState, setIdleState } = useUser()

  useEffect(() => {
    setIdleState(false)

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [setIdleState])

  // we need the latest value when the timeout fires :)
  const lastInputRef = useRef<string | undefined>(undefined)
  useEffect(() => {
    lastInputRef.current = last
  }, [last])

  const startInterval = useCallback(() => {
    intervalRef.current = window.setInterval(() => {
      logInfo(`[IDLE] User is idle: ${!hasInteractedRef.current}`)

      if (hasInteractedRef.current) {
        hasInteractedRef.current = false

        return
      }

      const input = lastInputRef.current || getFirstInput()?.name
      if (!input) {
        return
      }

      setIdleState({ input })
    }, DEFAULT_INTERVAL_DELAY)
    // eslint-disable-next-line
  }, [setIdleState, DEFAULT_INTERVAL_DELAY])

  const resetInterval = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }
    startInterval()
  }, [startInterval])

  useEffect(() => {
    if (hasInteracted) {
      hasInteractedRef.current = true
      resetInterval()
      setHasInteracted(false)
    }
  }, [hasInteracted, setHasInteracted, resetInterval])

  useEffect(() => {
    setIdleState(false)
    resetInterval()
  }, [currentPageInSession, resetInterval, setIdleState])

  useEffect(() => {
    if (idleState && intervalRef.current) {
      clearInterval(intervalRef.current)
    }
  }, [idleState])

  return null
})

export default memo(IdleDetector)
