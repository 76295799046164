import { Debtor } from '../../containers/PersonalForm/PersonalForm.interface'
import TraversalStore from '../TraversalStore'

import { TraversalReaction } from './reactions.interface'

const resetCurrentEmploymentUntil = (
  traversalStore: TraversalStore,
  debtor: Debtor,
  isTemporary: boolean
) => {
  if (isTemporary) {
    return
  }

  traversalStore.removeErrorsForPath(
    `debtors.${debtor}.currentEmployment.until`
  )
}

export const resetCurrentEmploymentFirstDebtor = (
  traversalStore: TraversalStore
): TraversalReaction<boolean> => [
  () => traversalStore.data.debtors.primary.currentEmployment.isTemporary,
  (isTemporary) =>
    resetCurrentEmploymentUntil(traversalStore, Debtor.PRIMARY, isTemporary)
]

export const resetCurrentEmploymentSecondDebtor = (
  traversalStore: TraversalStore
): TraversalReaction<boolean> => [
  () =>
    traversalStore.data.debtors.count === 2 &&
    traversalStore.data.debtors.secondary.currentEmployment.isTemporary,
  (isTemporary) =>
    resetCurrentEmploymentUntil(traversalStore, Debtor.SECONDARY, isTemporary)
]
