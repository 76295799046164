export function getSkeletonElements(form: HTMLElement) {
  return form.getElementsByClassName('MuiSkeleton-root')
}

export function getFirstInput(): HTMLInputElement | null {
  return document.body.querySelector('input,textarea')
}

export function getAllInputs(): HTMLInputElement[] {
  return Object.values(document.body.querySelectorAll('input,textarea'))
}

export function getNextUntouchedInput(
  lastInputName: string,
  touchedInputs: string[] = []
) {
  const allInputs = getAllInputs()
  const lastIndex = allInputs.findIndex((input) => input.name === lastInputName)

  return allInputs.slice(lastIndex).find(
    (input) =>
      // For radio inputs all of the options have the same name 🙈
      input.name !== lastInputName && !touchedInputs.includes(input.name)
  )
}
