import { NavigateFunction } from 'react-router'
import FFGFormNavigation from './FFGFormNavigation'
import SmavaFormNavigation from 'components/Smava/FormNavigation'

interface Props {
  isSmava?: boolean
  onBack?: () => void
  onNext: () => void
  navigate: NavigateFunction
  isOfferLabel?: boolean
}

const FormNavigationSwitcher = (props: Props) => {
  const sharedProps = {
    onNext: props.onNext,
    onBack: props.onBack,
    navigate: props.navigate
  }

  if (props.isSmava) {
    return (
      <SmavaFormNavigation
        {...sharedProps}
        legalContact="info@smava.de"
        isOfferLabel={props.isOfferLabel}
      />
    )
  }

  return (
    <FFGFormNavigation {...sharedProps} legalContact="kredite@finanzcheck.de" />
  )
}
export { FormNavigationSwitcher }
