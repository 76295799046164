import { memo } from 'react'
import Skeleton from '@mui/material/Skeleton'
import { Theme, Typography } from '@mui/material'
import * as CSSTypes from 'csstype'

import useEmotionStyles from 'hooks/useEmotionStyles'

const SKELETONS_NUMBER = 5
const DEFAULT_HEIGHT = 34

const getSkeletonRandomWidth = (): string => {
  const MIN_WIDTH = 60
  const MAX_WIDTH = 100
  const randomWidthValue = Math.random() * (MAX_WIDTH - MIN_WIDTH) + MIN_WIDTH

  return `${randomWidthValue}%`
}

interface Props {
  isAnimated?: boolean
  borderRadius?: number
  areSkeletonsWithLabels?: boolean
  skeletonsNumber?: number
  areSkeletonsSingle?: boolean
  isBorderDisabled?: boolean
}

const SkeletonFakeOffer = ({
  isAnimated,
  borderRadius,
  areSkeletonsWithLabels,
  skeletonsNumber,
  areSkeletonsSingle,
  isBorderDisabled
}: Props) => {
  const {
    skeleton,
    skeletonsContainer,
    skeletonBlock,
    skeletonsWithLabelsContainer,
    singleSkeleton,
    singleSkeletons
  } = useEmotionStyles<
    ReturnType<typeof styles>,
    {
      borderRadius?: number
      isBorderDisabled?: boolean
    }
  >(styles, {
    borderRadius,
    isBorderDisabled
  })
  const skeletonAnimation = isAnimated ? 'wave' : false

  if (areSkeletonsWithLabels) {
    return (
      <div className={skeletonsWithLabelsContainer}>
        <Typography variant="body2" color="primary" fontWeight={500}>
          Monatliche Rate
        </Typography>
        <Skeleton
          className={skeleton}
          width="100%"
          height={DEFAULT_HEIGHT}
          animation={skeletonAnimation}
        />

        <Typography variant="body2" color="primary" fontWeight={500}>
          eff. Jahreszins
        </Typography>
        <Skeleton
          className={skeleton}
          width="100%"
          height={DEFAULT_HEIGHT}
          animation={skeletonAnimation}
        />
      </div>
    )
  }

  const arrayTemplate = [...Array(skeletonsNumber || SKELETONS_NUMBER)] // Note this create an array of 5 times undefined on fallback

  if (areSkeletonsSingle) {
    return (
      <div className={singleSkeletons}>
        {arrayTemplate.map((element, index) => (
          <div
            className={singleSkeleton}
            key={`single-skeletonblock-${element || index}`}
          >
            <Skeleton
              className={skeleton}
              width={getSkeletonRandomWidth()}
              height={DEFAULT_HEIGHT}
              animation={skeletonAnimation}
            />
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className={skeletonsContainer}>
      {arrayTemplate.map((element, index) => (
        <div
          className={skeletonBlock}
          key={`skeletonblock-${element || index}`}
        >
          <Skeleton
            className={skeleton}
            width={getSkeletonRandomWidth()}
            height={DEFAULT_HEIGHT}
            animation={skeletonAnimation}
          />
          <Skeleton
            className={skeleton}
            width={getSkeletonRandomWidth()}
            height={20}
            animation={skeletonAnimation}
          />
        </div>
      ))}
    </div>
  )
}

export default memo(SkeletonFakeOffer)

const styles = (
  theme: Theme,
  {
    isBorderDisabled,
    borderRadius
  }: {
    borderRadius?: number
    isBorderDisabled?: boolean
  }
) => ({
  skeleton: {
    borderRadius: borderRadius ?? '20px/40px'
  },

  skeletonsWithLabelsContainer: {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, calc(50% - ${theme.spacing(
      0.25
    )}))`,
    columnGap: theme.spacing(0.25),
    width: '100%',
    maxWidth: '360px',
    alignItems: 'center',
    justifyItems: 'start',
    margin: 'auto',
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`
  },

  skeletonsContainer: {
    display: 'grid',
    width: '100%',
    border: isBorderDisabled ? 'none' : `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '5px',

    [theme.breakpoints.up('xs')]: {
      gridTemplateColumns: 'repeat(auto-fill, 50%)'
    },

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(auto-fill, calc(100%/3))'
    },

    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(auto-fill, 20%)'
    }
  },
  skeletonBlock: {
    padding: theme.spacing(2.5),
    zIndex: -1,

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(7.5)
    },

    '&:last-child': {
      display: 'none',

      [theme.breakpoints.up('sm')]: {
        display: 'block'
      },

      [theme.breakpoints.up('md')]: {
        background: theme.palette.grey[50]
      }
    }
  },

  singleSkeleton: {
    padding: theme.spacing(2.5),
    background: theme.palette.common.white,
    zIndex: -1
  },
  singleSkeletons: {
    display: 'grid',
    position: 'relative' as CSSTypes.Property.Position,
    gridTemplateColumns: 'repeat(auto-fill, 50%)',
    width: '100%',
    zIndex: 1
  }
})
