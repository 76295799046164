import { memo } from 'react'
import { Box } from '@mui/material'
import { Banner, BannerType } from '@finanzcheck/teal-components'

interface Props {
  isBannerIconOutline?: boolean
}

const DisagreeInformation = ({ isBannerIconOutline }: Props) => (
  <Box pb={1} my={1} data-testid="disagreeinformation">
    <Banner
      desc={
        <>
          Um Angebote <strong>mit Sofortauszahlung</strong> erhalten zu können,
          nutzen Sie bitte den <strong>Digitalen Kontoauszug</strong>.
        </>
      }
      type={BannerType.INFO}
      isIconVersionOutline={isBannerIconOutline}
    />
  </Box>
)

export default memo(DisagreeInformation)
