import { Data } from 'interfaces/ApolloResponse.interface'
import { Debtor } from '../../../containers/PersonalForm/PersonalForm.interface'
import { hasSameWorkForLongerThanTwoYears } from 'utils/formDependencies/conditions/employmentConditions'

export const isEmployedLessThan2Years =
  (debtor: Debtor = Debtor.PRIMARY) =>
  (state: Data): boolean => {
    const since = state.debtors[debtor].currentEmployment.since
    return !!since && !hasSameWorkForLongerThanTwoYears(since)
  }
