import { FunctionComponent, useState } from 'react'
import { useStores } from 'stores/utils/hooks/useStores'
import isNotMortgage from 'utils/formDependencies/conditions/isNotMortgage'
import isNotAffiliate from 'utils/formDependencies/conditions/isNotAffiliate'
import { isSingleDebtor } from 'utils/formDependencies/conditions/isSingleDebtor'
import hasSecondDebtor from 'utils/formDependencies/conditions/hasSecondDebtor'
import { hasPositionNudgeRelevant } from 'utils/formDependencies/conditions/hasPositionNudgeRelevant'
import { reaction } from 'mobx'
import { hasFurtherIncome } from 'utils/formDependencies/conditions/incomeConditions'
import { hasRentalProperty } from 'utils/formDependencies/conditions/propertyConditions'
import { isEmploymentTemporary, isOfRelevantAge } from 'components/Nudges/utils'
import { isEmployedLessThan2Years } from 'utils/formDependencies/conditions/isEmployedLessThan2Years'

const conditionalObject = {
  isNotMortgage,
  isNotAffiliate,
  isSingleDebtor,
  hasSecondDebtor,
  hasPositionNudgeRelevant,
  hasFurtherIncome: hasFurtherIncome(),
  hasRentalProperty: hasRentalProperty(),
  isOfRelevantAge,
  isEmploymentTemporary,
  isEmployedLessThan2Years: isEmployedLessThan2Years()
}
interface WrapperProps {
  children: React.ReactElement
  dependson?: string
}

const Wrapper: FunctionComponent<WrapperProps> = ({ dependson, children }) => {
  const {
    traversal,
    navigation: { tenantContext }
  } = useStores()
  const [traversalData, setCommonObject] = useState(traversal.data)

  reaction(
    () => traversal.data,
    (data) => {
      setCommonObject(data)
    }
  )
  if (dependson) {
    return conditionalObject[dependson](traversalData, tenantContext)
      ? children
      : null
  }

  return children
}

export default Wrapper
