import { useCallback, useEffect, memo } from 'react'
import { AmplitudeEvent } from 'utils/tracking/amplitude/amplitude.interface'
import { formatRFC3339 } from 'date-fns'
import { useRootStore } from 'stores/utils/hooks/useRootStore'
import { getClient } from 'api'
import { useProgress } from 'stores/utils/hooks/useProgress'
import { observer } from 'mobx-react'
import { useStores } from 'stores/utils/hooks/useStores'
import { trackInAmplitude } from 'utils/tracking/amplitude'
import { Data } from 'interfaces/ApolloResponse.interface'

interface AbortRequest {
  advertisementId: string
  traversalId: string
  data: {
    abortedAt: string
    entryPoint: string
    progress: number
    lastPageSeen: string
    lastInputTouched: string | undefined
    nextInputUntouched: string | undefined
    traversalData: Data
  }
}

async function postAbort({ advertisementId, traversalId, data }: AbortRequest) {
  const client = getClient('apollo')
  const url = `abort/${advertisementId}/${traversalId}`

  // Fallback to normal request in IE11 and Opera Mini
  if (navigator.sendBeacon === undefined) {
    return client.post(url, data)
  }

  const isQueued = navigator.sendBeacon(
    client.defaults.baseURL + url,
    JSON.stringify(data)
  )

  if (isQueued) {
    return Promise.resolve()
  }

  return Promise.reject()
}

const AbortDetector = observer(() => {
  const {
    rootStore: { initialEntryPoint }
  } = useRootStore()
  const {
    navigation: { currentPageInSession },
    traversal: {
      isGoToFinalSucceeded,
      traversalId,
      data: {
        system: { advertisementId }
      },
      data: traversalData
    }
  } = useStores()
  const { percentage, touchedInput } = useProgress()

  const onTabVisiblityChange = useCallback(() => {
    trackInAmplitude(AmplitudeEvent.ChangeTabVisibility, {
      tabVisibility: document.visibilityState
    })
  }, [])

  useEffect(() => {
    document.addEventListener('visibilitychange', onTabVisiblityChange)

    return () => {
      document.removeEventListener('visibilitychange', onTabVisiblityChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(
    () => {
      const onAbort = async (event: WindowEventMap['pagehide']) => {
        if (isGoToFinalSucceeded) {
          return
        }

        if (event.persisted) {
          trackInAmplitude(AmplitudeEvent.ChangeTabVisibility, {
            tabVisibility: 'frozen'
          })

          return
        }

        const data: AbortRequest['data'] = {
          abortedAt: formatRFC3339(new Date()),
          entryPoint: initialEntryPoint as string,
          progress: percentage,
          lastPageSeen: currentPageInSession || '',
          lastInputTouched: touchedInput.last,
          nextInputUntouched: touchedInput.next,
          traversalData: traversalData
        }

        trackInAmplitude(AmplitudeEvent.DetectAbort, data)

        if (!advertisementId || !traversalId) {
          return
        }

        await postAbort({ advertisementId, traversalId, data })
      }

      window.addEventListener('pagehide', onAbort)

      return () => {
        window.removeEventListener('pagehide', onAbort)
      }
    },
    // eslint-disable-next-line
    [
      currentPageInSession,
      percentage,
      touchedInput,
      advertisementId,
      traversalId,
      initialEntryPoint,
      isGoToFinalSucceeded,
      traversalData
    ]
  )

  return null
})

export default memo(AbortDetector)
