import { Data } from 'interfaces/ApolloResponse.interface'
import { Debtor } from '../../../containers/PersonalForm/PersonalForm.interface'
import { jobsWithoutEmployer } from '../statics/lists'
import isEmployedOrHasMinijob from './isEmployedOrHasMinijob'

const showEmployer =
  (debtor: Debtor = Debtor.PRIMARY) =>
  (state: Data): boolean => {
    if (!isEmployedOrHasMinijob(debtor)(state)) {
      return false
    }

    return !jobsWithoutEmployer.includes(
      state.debtors[debtor].currentEmployment.position
    )
  }

export default showEmployer
