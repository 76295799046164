/* eslint-disable no-param-reassign */
import Bugsnag from '@bugsnag/js'
import { logError, logInfo, logStackTrace, logWarning } from 'utils/log'
import { executeAsync } from 'utils/performance'
import BugsnagError from '../../error/bugsnagError'

type Severity = 'info' | 'warning' | 'error'

const methodForSeverity: Record<Severity, (message: string) => void> = {
  info: logInfo,
  warning: logWarning,
  error: logError
}

export function logAndTrackError(
  error: Error | undefined = new Error('Unknown Error'),
  severity: Severity = 'error'
) {
  executeAsync(() => {
    Bugsnag.notify(error, (event) => {
      event.severity = severity

      if (error && error instanceof BugsnagError) {
        for (const [section, metadata] of Object.entries(error.getMetadata())) {
          event.addMetadata(section, metadata)
        }
      }
    })
  })

  if (['warning', 'error'].includes(severity)) {
    logStackTrace()
  }

  methodForSeverity[severity](error.message)
}
