/* eslint react/prop-types: 0 */
import { forwardRef, memo, PropsWithChildren } from 'react'
import { Theme, useTheme } from '@mui/material/styles'
import { SMAVA_THEME_UUID } from 'startup/command/initTheme'
import { getEnv } from 'utils/env'
import { isTenantSmava } from 'utils/url'
import useEmotionStyles from 'hooks/useEmotionStyles'

const CATEGORY_MAX_WIDTH_DEFAULT =
  getEnv('REACT_APP_TENANT_UUID') === SMAVA_THEME_UUID ? 532 : 460
const CATEGORY_MAX_WIDTH_LARGE = 600

type StyleProps = {
  maxWidth: number
  marginBottom: string
}

const styles = (theme: Theme, { maxWidth, marginBottom }: StyleProps) => ({
  layout: {
    '& > h2': { marginTop: theme.spacing(4) },
    '& > *:not(:nth-child(0))': {
      marginBottom
    },
    overflow: 'visible',
    margin: '0 auto',
    maxWidth
  }
})

interface CategoryLayoutProps {
  largeCategory?: boolean
}

const CategoryLayout = forwardRef<
  HTMLDivElement | null,
  PropsWithChildren<CategoryLayoutProps>
>(
  (
    { largeCategory = false, children }: PropsWithChildren<CategoryLayoutProps>,
    ref
  ) => {
    const theme = useTheme()
    const isSmava = isTenantSmava()
    // @TODO refactor and get smallSpacing from CategoryLayout props
    const smallSpacing = isSmava

    const { layout } = useEmotionStyles<ReturnType<typeof styles>, StyleProps>(
      styles,
      {
        maxWidth: largeCategory
          ? CATEGORY_MAX_WIDTH_LARGE
          : CATEGORY_MAX_WIDTH_DEFAULT,
        marginBottom: smallSpacing ? theme.spacing(2) : theme.spacing(3)
      }
    )

    return (
      <div ref={ref} className={layout}>
        {children}
      </div>
    )
  }
)

export default memo(CategoryLayout)
