const sector = [
  { label: 'Architektur oder Ingenieurwesen', value: 'ARCHITECT' },
  { label: 'Ärztliches Fachpersonal', value: 'DOCTOR' },
  { label: 'Banken- oder Finanzwesen', value: 'BANK' },
  { label: 'Baugewerbe', value: 'CONSTRUCTION' },
  { label: 'Bergbau', value: 'MINING' },
  { label: 'Bundeswehr', value: 'MILITARY' },
  { label: 'Chemieindustrie', value: 'CHEMISTRY' },
  { label: 'Dienstleistungen', value: 'SERVICES' },
  { label: 'Diplomatischer Dienst', value: 'FOREIGN_SERVICE' },
  { label: 'Energie/Wasser', value: 'ENERGY_WATER' },
  { label: 'Farb-, Druck- und Papierindustrie', value: 'PAPER' },
  { label: 'Fischerei', value: 'FISHING' },
  { label: 'Gastronomie', value: 'GASTRONOMY' },
  { label: 'Groß- und Einzelhandel', value: 'RETAIL' },
  { label: 'Handwerk', value: 'CRAFT' },
  { label: 'Holzindustrie', value: 'TIMBER' },
  { label: 'Hotelgewerbe', value: 'HOTEL' },
  { label: 'Hütten- und Stahlindustrie', value: 'STEEL' },
  { label: 'Journalist', value: 'JOURNALISM' },
  { label: 'KFZ-Herstellung', value: 'AUTOMOBILE' },
  { label: 'Kindergarten', value: 'KINDERGARTEN' },
  { label: 'Kirchliche und soziale Einrichtungen', value: 'SOCIAL_FACILITY' },
  { label: 'Kranken- und Pflegeanstalten', value: 'CARE' },
  { label: 'Land- und Forstwirtschaft', value: 'FARMING' },
  { label: 'Maschinenbau', value: 'ENGINEERING' },
  { label: 'Medien', value: 'MEDIA' },
  { label: 'Metallindustrie', value: 'METAL' },
  { label: 'Nahrungs- und Genussmittelindustrie', value: 'FOOD' },
  { label: 'Öffentlicher Dienst', value: 'PUBLIC_SERVICE' },
  { label: 'Organisation ohne Erwerbszweck', value: 'NON_PROFIT' },
  { label: 'Pharmazie', value: 'PHARMACIST' },
  { label: 'Psychologie', value: 'PSYCHOLOGY' },
  { label: 'Rundfunk- und Fernsehanstalten', value: 'TELEVISION' },
  { label: 'Schifffahrt', value: 'SEAFARING' },
  { label: 'Transportwesen', value: 'SHIPPING' },
  { label: 'Tankstellen', value: 'FILLING_STATION' },
  { label: 'Software/IT', value: 'TELECOMMUNICATION' },
  { label: 'Textilindustrie', value: 'TEXTILE' },
  { label: 'Tourismus', value: 'TOURISM' },
  { label: 'Verarbeitendes Gewerbe', value: 'PROCESSING' },
  { label: 'Verkehr Personen', value: 'TRANSPORTATION' },
  { label: 'Verlags- und Zeitungswesen', value: 'PUBLISHING' },
  { label: 'Versicherungwesen', value: 'INSURANCE' },
  { label: 'Walzwerke', value: 'MILL' },
  { label: 'Zeitarbeitsfirma', value: 'TEMPORARY_EMPLOYMENT' },
  { label: 'Sonstige', value: 'OTHER' }
]

export default sector
