import { pick } from 'dot-object'
import { Choice } from 'components/Input/ChoiceGroup'
import { formatToDeAmount } from 'utils/number'
import { Debtor } from '../../containers/PersonalForm/PersonalForm.interface'
import {
  Data,
  ActiveLoansItem,
  CreditChangeOption
} from '../../interfaces/ApolloResponse.interface'

export function creditAmountEditSelectOptions(
  amount: number,
  remainingSum = amount
): Choice<CreditChangeOption>[] {
  return [
    {
      label: formatToDeAmount(amount),
      hint: 'Kreditbetrag nicht anpassen',
      value: CreditChangeOption.nothing
    },
    {
      label: formatToDeAmount(remainingSum),
      hint: 'Ursprünglich ausgewählten Kreditbetrag um Restschuld der umzuschuldenden Kredite erhöhen',
      value: CreditChangeOption.running
    },
    {
      label: 'Anderen Kreditbetrag angeben',
      value: CreditChangeOption.other
    }
  ]
}

export const getRemainingAmount = (state: Data): number =>
  [Debtor.PRIMARY, Debtor.SECONDARY].reduce((sum, debtor) => {
    const loans: ActiveLoansItem[] =
      pick(`debtors.${debtor}.activeLoans`, state) || []

    loans
      .filter((loan) => loan && loan.isDebtConversionWanted)
      .forEach((loan: ActiveLoansItem) => {
        sum += loan.remainingAmount || 0
      })

    return sum
  }, 0)
