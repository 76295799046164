import { NavigateFunction } from 'react-router-dom'
import { LoadedConfig } from 'config/config.interface'
import ConfigError from 'error/configError'
import UnknownConfigError from 'error/unknownConfigError'
import {
  getActiveConfig,
  getConfig,
  doesConfigExist,
  ConfigName
} from 'config/utils/config'
import { getDefaultAffiliateId, UrlParams } from 'utils/url'
import RootStore from 'stores/RootStore'
import { getStorage } from 'utils/storage'
import { SessionStorage } from 'utils/storage/sessionStorage'
import { logAndTrackError } from 'utils/log/logAndTrackError'
import { StartupCommand } from './command.interface'

export class LoadConfigurationCommand implements StartupCommand {
  name = 'LoadConfiguration'
  dependencies = ['GetTraversal', 'InitNavigation', 'ResumeTraversal']
  timeout = 10000

  // eslint-disable-next-line class-methods-use-this
  private beforeLoad = (config: LoadedConfig, rootStore: RootStore) => {
    if (!config.onLoad) {
      return true
    }

    return config.onLoad(rootStore)
  }

  // eslint-disable-next-line class-methods-use-this
  private getDefaultConfig = async (rootStore: RootStore) => {
    const {
      traversal: {
        data: {
          system: { affiliateId = getDefaultAffiliateId() }
        }
      }
    } = rootStore

    const config = await getActiveConfig(affiliateId)

    if (config.onLoad && !config.onLoad(rootStore)) {
      /**
       * If the onLoad function of the default config fails,
       * something is seriously wrong!
       */
      logAndTrackError(
        new ConfigError('onLoad of default config failed', config)
      )
    }

    return config
  }

  private getConfig = async (
    configName: ConfigName | null,
    rootStore: RootStore
  ) => {
    if (!configName) {
      return this.getDefaultConfig(rootStore)
    }

    if (!doesConfigExist(configName)) {
      logAndTrackError(new UnknownConfigError(configName))

      return this.getDefaultConfig(rootStore)
    }

    const config = await getConfig(configName)
    if (this.beforeLoad(config, rootStore)) {
      return config
    }

    const error = new ConfigError(
      `Loading default configuration because onLoad condition failed..`,
      config
    )

    logAndTrackError(error)

    return this.getDefaultConfig(rootStore)
  }

  execute = async (
    rootStore: RootStore,
    _navigate: NavigateFunction,
    urlParams: UrlParams
  ) => {
    const {
      page: { setConfiguration },
      navigation: {
        setCurrentPageInSession,
        setInitiallySeenPage,
        currentConfigInSession
      }
    } = rootStore

    const { formConfig } = urlParams

    const configLoaderConfig = getStorage('sessionStorage').getItem(
      SessionStorage.ConfigLoaderConfig
    ) as ConfigName

    // Taurine loaded with another config during the same session, currentNavigation should be reset
    if (
      formConfig &&
      (currentConfigInSession !== formConfig ||
        configLoaderConfig === formConfig)
    ) {
      setCurrentPageInSession(null)
      setInitiallySeenPage(null)
    }

    const configName =
      formConfig || configLoaderConfig || currentConfigInSession

    await setConfiguration(
      await this.getConfig(configName as ConfigName, rootStore)
    )
  }
}

export default LoadConfigurationCommand
