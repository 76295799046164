import { Config } from 'config/config.interface'
import BugsnagError, { Metadata } from './bugsnagError'

export function getConfigMetadata(config: Config) {
  if (!config.version) {
    return {
      name: 'unknown',
      version: 'unknown',
      pages: []
    }
  }

  return {
    name: config.name,
    version: config.version,
    pages: Object.keys(config.pages)
  }
}

export default class ConfigError extends BugsnagError {
  public config: Config

  constructor(message: string, config: Config = { version: false }) {
    super(`[${config.version ? config.name : 'Unknown'}]: ${message}`)

    this.name = 'ConfigError'
    this.config = config
  }

  getMetadata(): Metadata {
    return {
      taurineConfig: getConfigMetadata(this.config)
    }
  }
}
