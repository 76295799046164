import { styled } from '@mui/material/styles'
import { appEnvironment, getEnv } from 'utils/env'
import packageInfo from '../../../package.json'

const Container = styled('div')(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.grey[400],
  fontSize: '0.75rem',
  marginTop: '1rem'
}))

export const getVersion = () => {
  const gitInfo = getEnv('REACT_APP_GIT_INFO') || 'N/A'
  const prefixedVersion = `v${packageInfo.version}`

  if (appEnvironment === 'production') {
    return prefixedVersion
  }

  return `${prefixedVersion} - ${gitInfo} - ${appEnvironment}`
}

const VersionDisplay = (): React.ReactElement | null => {
  const versionLabel = getVersion()

  if (appEnvironment === 'production') {
    // eslint-disable-next-line no-console
    console.log(`Version: ${versionLabel}`)
    return null
  }

  return <Container>{versionLabel}</Container>
}

export default VersionDisplay
