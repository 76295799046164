import { AxiosRequestConfig } from 'axios'
import ApiError from './apiError'

export default class RetriesExhaustedError<T> extends ApiError<T> {
  constructor(config: AxiosRequestConfig) {
    super('Retries exhausted', config)

    this.name = 'RetriesExhaustedError'
  }
}
