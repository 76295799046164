import { memo } from 'react'
import { NavigateFunction } from 'react-router-dom'
import LoadingPageHeader from 'components/LoadingLead/LoadingPageHeader'
import FakeOffers from 'components/LoadingLead/FakeOffers'
import Summary from 'components/LoadingLead/BankScanner/Summary'
import { useAutoProceedPage } from 'hooks/useAutoProceedPage'
import { PageTitle } from './PageTitle'

export const TIMEOUT_GO_TO_NEXT_PAGE_DEFAULT = 6000

export enum LoadingLeadVariantEnum {
  HEADER_AND_FAKE_OFFERS,
  SUMMARY_AND_FAKE_OFFERS
}

interface LoadingLeadVariantProps {
  variant?: LoadingLeadVariantEnum
  isUsingSmavaCdnUrl?: boolean
}

const LoadingLeadVariant = ({
  variant,
  isUsingSmavaCdnUrl
}: LoadingLeadVariantProps): React.ReactElement => {
  switch (variant) {
    case LoadingLeadVariantEnum.SUMMARY_AND_FAKE_OFFERS:
      return (
        <>
          <PageTitle />
          <Summary />
          <FakeOffers isUsingSmavaCdnUrl={isUsingSmavaCdnUrl} />
        </>
      )
    case LoadingLeadVariantEnum.HEADER_AND_FAKE_OFFERS:
    default:
      return (
        <>
          <LoadingPageHeader />
          <FakeOffers
            fakeBankListNames={['targobank', 'ingDiba', 'santanderBestCredit']}
            skeletonBorderRadius={4}
            isUsingSmavaCdnUrl={isUsingSmavaCdnUrl}
            areSkeletonsWithLabels
            isLogoCentered
          />
        </>
      )
  }
}

interface LoadingLeadProps {
  variant?: LoadingLeadVariantEnum
  isUsingSmavaCdnUrl?: boolean
  navigate: NavigateFunction
}

const LoadingLead = ({
  variant,
  isUsingSmavaCdnUrl,
  navigate
}: LoadingLeadProps): React.ReactElement => {
  /**
   * Since this page has no footer buttons,
   * the user cannot navigate back/forth on his own.
   */
  useAutoProceedPage(TIMEOUT_GO_TO_NEXT_PAGE_DEFAULT, navigate)

  return (
    <LoadingLeadVariant
      variant={variant}
      isUsingSmavaCdnUrl={isUsingSmavaCdnUrl}
    />
  )
}

export default memo(LoadingLead)
