import { useEffect, useState } from 'react'
import { isTenantSmava } from 'utils/url'
import isNotAffiliate from 'utils/formDependencies/conditions/isNotAffiliate'
import { useUrlParams } from 'stores/utils/hooks/useUrlParams'
import { CONFIG_MOBILEDE } from 'config/utils/config'
import property from 'texts/common/partials/property'

import countryDeOther from '../../../texts/common/lists/country_de_other'
import countryShort from '../../../texts/common/lists/country_short'
import countryLong from '../../../texts/common/lists/country_long'
import occupation from '../../../texts/common/lists/occupation'
import secondaryEmploymentType from '../../../texts/common/lists/secondary_employment_type'
import position from '../../../texts/common/lists/position'
import sector from '../../../texts/common/lists/sector'
import bankAccountType from '../../../texts/common/lists/bank_account_type'
import commonPartial from '../../../texts/common/partials/common'
import brandTracking from '../../../texts/common/lists/brand_tracking'
import debtorPartial, {
  MALE_FIRSTNAME_PLACEHOLDER
} from '../../../texts/common/partials/debtor'
import pension from '../../../texts/common/lists/pension'
import headline from '../../../texts/common/copy/headline'
import clue from '../../../texts/common/copy/clue'
import introduction from '../../../texts/common/copy/introduction'
import relationship from '../../../texts/common/lists/relationship'
import countEnum from '../../../texts/common/lists/count_enum'
import yesNoEnum from '../../../texts/common/lists/yes_no_enum'
import healthInsuranceEnum from '../../../texts/common/lists/health_insurance_enum'
import debtorsCount from '../../../texts/common/lists/debtors_count'
import { loanSelections } from '../../../texts/smava/loan_selector'
import { useRootStore } from './useRootStore'

export const FEMALE_FIRST_NAME_PLACEHOLDER = 'z.B. Maria'

export const getFirstNamePlaceholder = (gender: string): string => {
  if (gender === 'FEMALE') {
    return FEMALE_FIRST_NAME_PLACEHOLDER
  }

  return MALE_FIRSTNAME_PLACEHOLDER
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getDebtorData = (clonedDebatorPartial: any, gender: string) => ({
  ...clonedDebatorPartial,
  personal: {
    ...clonedDebatorPartial.personal,
    firstname: {
      ...clonedDebatorPartial.personal.firstname,
      placeholder: getFirstNamePlaceholder(gender)
    }
  }
})

export function useText() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [textContent, setTextContent] = useState<any>({
    common: {},
    copy: {},
    debtors: {},
    lists: {}
  })

  const {
    rootStore: {
      navigation: { tenantContext },
      page: {
        config: { name }
      },
      traversal: {
        data: {
          debtors: {
            primary: {
              personal: { gender: primaryGender }
            },
            secondary: {
              personal: { gender: secondaryGender }
            }
          }
        }
      }
    }
  } = useRootStore()
  const urlParams = useUrlParams()
  const isSmava = isTenantSmava()

  useEffect(() => {
    if (!isSmava) {
      return
    }

    const common = { ...commonPartial }
    common.loanPayout.label = 'Auszahlungstermin'
    common.purpose.options = [
      { label: 'Freie Verwendung', value: 'OTHER' },
      { label: 'Auto / Motorrad', value: 'PRE_OWNED_CAR' },
      { label: 'Umschuldung', value: 'REFINANCING' },
      { label: 'Wohnen / Modernisierung', value: 'RENOVATION' },
      {
        label: 'Bau-/ Immobilienfinanzierung',
        value: 'REAL_ESTATE_FINANCING'
      }
    ]

    common.vehicle.initialPayment = {
      label: 'Anzahlung',
      placeholder: 'z.B. 5.000',
      tooltip:
        'Falls Sie einen Teil des Kaufpreises sofort anzahlen möchten, können Sie sich bessere Konditionen bei unseren Partnerbanken sichern.'
    }

    const clonedHeadline = { ...headline }
    clonedHeadline.payoutAccount = 'Auszahlungskonto'

    const clonedDebatorsCount = [...debtorsCount]
    clonedDebatorsCount[0].label = '1 Person'
    clonedDebatorsCount[1].label = '2 Personen'

    const clonedDebatorPartial = { ...debtorPartial }

    clonedDebatorPartial.contact.email.errorMap.missing =
      'E-Mail ist ein Pflichtfeld.'
    clonedDebatorPartial.contact.email.errorMap.wrong_format =
      'E-Mail hat nicht das richtige Format für eine E-Mail-Adresse.'

    if (isNotAffiliate(urlParams?.context)) {
      clonedDebatorPartial.contact.email.tooltip =
        'An diese E-Mail Adresse werden alle wichtigen Anträge, Unterlagen zu Abschlüssen und Vergleichen geschickt. Bitte stellen Sie sicher, dass Sie die richtige E-Mail-Adresse angeben und überprüfen Sie diese gegebenenfalls noch einmal. Eine korrekte E-Mail-Adresse wird für die Bereitstellung aller Dienstleistungen von smava benötigt.'
    }

    clonedDebatorPartial.personal.firstname.label = 'Vorname(n)'
    clonedDebatorPartial.personal.firstname.errorMap.missing =
      'Vorname(n) ist ein Pflichtfeld.'

    clonedDebatorPartial.personal.birthday.errorMap.missing =
      'Bitte geben Sie hier ein gültiges Datum ein.'

    clonedDebatorPartial.personal.gender.errorMap.missing =
      'Anrede ist ein Pflichtfeld.'

    clonedDebatorPartial.personal.lastname.errorMap.missing =
      'Nachname ist ein Pflichtfeld.'

    clonedDebatorPartial.contact.phoneNumber.label =
      'Mobilfunknummer (alternativ Festnetznummer)'

    clonedDebatorPartial.activeLoans.isDebtConversionWanted.tooltip = ''

    common.purpose.label = 'Verwendung'

    common.amount.label = 'Nettokreditbetrag'

    common.manuallyAdaptedAmount.tooltip = ''

    if (isSmava) {
      clonedDebatorPartial.currentAddress.street.label = 'Straße'
      clonedDebatorPartial.currentAddress.street.placeholder =
        'z.B. Musterstraße'

      clonedDebatorPartial.previousAddress.street.label = 'Straße'
      clonedDebatorPartial.previousAddress.street.placeholder =
        'z.B. Musterstraße'
    }

    clonedDebatorPartial.income.primary.label = 'Nettoeinkommen'
    clonedDebatorPartial.activeLoans.bankAccount.type.label =
      'Angabe zur Bankverbindung'

    clonedDebatorPartial.currentAddress.houseNumber.tooltip = ''

    if (name === CONFIG_MOBILEDE) {
      clonedDebatorPartial.realEstate = {
        rentalProperty: property('vermietete', true),
        selfUsedProperty: property('selbstgenutzte')
      }
    }

    const debtors = {
      count: {
        tooltip: '',
        enums: countEnum,
        options: clonedDebatorsCount
      },
      isCommonHousehold: {
        label: 'Gemeinsame Haushaltsführung?',
        tooltip: '',
        enums: yesNoEnum,
        placeholder: 'Gemeinsame Haushaltsführung'
      },
      isSameAddress: {
        label: 'Gleiche Adresse?',
        tooltip: '',
        placeholder: 'gleiche Adresse für KN 2',
        enums: yesNoEnum
      },
      isSameContactInformation: {
        label: 'Gleiche Kontaktdaten wie 1. Kreditnehmer?',
        tooltip: '',
        placeholder: 'gleiche Kontaktdaten für KN 2'
      },
      primary: getDebtorData(clonedDebatorPartial, primaryGender),
      relationship: {
        label: 'Verhältnis der Kreditnehmer',
        tooltip: '',
        placeholder: 'Verhältnis der Kreditnehmer',
        options: relationship
      },
      secondary: getDebtorData(clonedDebatorPartial, secondaryGender)
    }

    debtors.primary.currentEmployment.workingTime.alternative =
      'Teilzeit oder Kurzarbeit?'

    const lists = {
      countries: countryShort,
      countriesShort: countryDeOther,
      nationalities: countryLong,
      occupations: occupation,
      pension,
      positions: position,
      secondaryEmploymentType,
      sectors: sector,
      bankInfos: bankAccountType,
      healthInsurance: healthInsuranceEnum,
      loanSelections,
      brandTracking: brandTracking
    }

    const clonedClue = { ...clue }
    clonedClue.occupation.profession =
      'Die Angabe dient der Bank zum Abgleich mit Ihrem Einkommensnachweis. Ihr Arbeitgeber wird über Ihre Kreditabsichten zu keinem Zeitpunkt von smava informiert.'

    const copy = {
      headline: clonedHeadline,
      introduction,
      clue: clonedClue,
      dac: 'Ich bin ausdrücklich damit einverstanden, dass smava meine Kontoauszüge (einschließlich ggf. darin enthaltener besonderer Kategorien personenbezogener Daten) verarbeitet und zur Kreditprüfung an mögliche Partnerbanken bzw. Kreditvergabepartner weiterleitet. Diese Einwilligung kann jederzeit mit Wirkung für die Zukunft widerrufen werden.'
    }

    if (!isNotAffiliate(urlParams?.context)) {
      const currentOptions = [...common.purpose.options]
      const updatedOptions = currentOptions.filter(
        ({ value }: { value: string }) =>
          !['REAL_ESTATE_FINANCING'].includes(value)
      )
      common.purpose.options = updatedOptions

      const currentLoanSelections = [...lists.loanSelections]
      const updatedLoanSelections = currentLoanSelections.filter(
        (selection: { isMortgage: boolean }) => {
          return !selection.isMortgage
        }
      )
      lists.loanSelections = updatedLoanSelections
    }

    setTextContent({ common, debtors, copy, lists })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantContext, primaryGender, secondaryGender])

  useEffect(() => {
    if (isSmava) {
      return
    }

    const clonedDebatorPartial = { ...debtorPartial }

    if (isNotAffiliate(urlParams?.context)) {
      clonedDebatorPartial.contact.email.tooltip =
        'An diese E-Mail Adresse werden alle wichtigen Anträge, Unterlagen zu Abschlüssen und Vergleichen geschickt. Bitte stellen Sie sicher, dass Sie die richtige E-Mail-Adresse angeben und überprüfen Sie diese gegebenenfalls noch einmal. Eine korrekte E-Mail-Adresse wird für die Bereitstellung aller Dienstleistungen von Finanzcheck benötigt.'
    }

    clonedDebatorPartial.contact.phoneNumber.tooltip =
      'Die Telefonnummer wird für die Beratung und Unterstützung bei der Antragstellung verwendet.'

    const debtors = {
      count: {
        label: 'Anzahl Kreditnehmer',
        tooltip: '',
        placeholder: 'Anzahl Kreditnehmer',
        enums: countEnum,
        options: debtorsCount
      },
      isCommonHousehold: {
        label: 'Gemeinsame Haushaltsführung?',
        tooltip: '',
        enums: yesNoEnum,
        placeholder: 'Gemeinsame Haushaltsführung'
      },
      isSameAddress: {
        label: 'Gleiche Adresse?',
        tooltip: '',
        placeholder: 'gleiche Adresse für KN 2',
        enums: yesNoEnum
      },
      isSameContactInformation: {
        label: 'Gleiche Kontaktdaten wie 1. Kreditnehmer?',
        tooltip: '',
        placeholder: 'gleiche Kontaktdaten für KN 2'
      },
      primary: getDebtorData(clonedDebatorPartial, primaryGender),
      relationship: {
        label: 'Verhältnis der Kreditnehmer',
        tooltip: '',
        placeholder: 'Verhältnis der Kreditnehmer',
        options: relationship
      },
      secondary: getDebtorData(clonedDebatorPartial, secondaryGender)
    }

    const lists = {
      countries: countryShort,
      countriesShort: countryDeOther,
      nationalities: countryLong,
      occupations: occupation,
      pension,
      positions: position,
      secondaryEmploymentType,
      sectors: sector,
      bankInfos: bankAccountType,
      healthInsurance: healthInsuranceEnum,
      loanSelections: []
    }

    const copy = {
      headline,
      introduction,
      clue,
      dac: 'Ich bin ausdrücklich damit einverstanden, dass FINANZCHECK meine Kontoauszüge (einschließlich ggf. darin enthaltener besonderer Kategorien personenbezogener Daten) verarbeitet und zur Kreditprüfung an mögliche Partnerbanken bzw. Kreditvergabepartner weiterleitet. Diese Einwilligung kann jederzeit mit Wirkung für die Zukunft widerrufen werden.'
    }

    setTextContent({ common: { ...commonPartial }, debtors, copy, lists })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryGender, secondaryGender])

  return { text: textContent }
}
