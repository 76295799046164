import { memo } from 'react'
import MaterialDivider from '@mui/material/Divider'
import { Theme } from '@mui/material'
import useEmotionStyles from 'hooks/useEmotionStyles'

interface DividerProps {
  spacing?: number
}

const DIVIDER_SPACING_DEFAULT = 32

const styles = (_theme: Theme, { spacing }: { spacing: number }) => ({
  divider: {
    margin: `${spacing}px 0`
  }
})

const Divider = ({ spacing = DIVIDER_SPACING_DEFAULT }: DividerProps) => {
  const { divider } = useEmotionStyles<
    ReturnType<typeof styles>,
    { spacing: number }
  >(styles, { spacing })

  return <MaterialDivider className={divider} />
}

export default memo(Divider)
