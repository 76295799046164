import propertyList from '../lists/property'
import { Field } from '../types'

const property = (
  immo: string,
  showAllOptions?: boolean
): Record<string, Field> => {
  const label =
    immo === 'vermietete'
      ? 'Besitzen Sie Wohneigentum, das Sie vermieten?'
      : `${immo.charAt(0).toUpperCase() + immo.substring(1)} Immobilie`

  const selectLabel =
    immo === 'vermietete'
      ? 'Welche Art von Immobilie vermieten Sie?'
      : 'Welche Art von Immobilie besitzen Sie?'

  const options = showAllOptions ? propertyList : [...propertyList.slice(1)]

  return {
    size: {
      label: `Fläche ${immo} Immobilie`,
      tooltip: '',
      placeholder: 'z.B. 82',
      errorMap: {
        missing: 'Bitte teilen Sie uns die Fläche der Immobilie mit.'
      }
    },
    type: {
      label,
      selectLabel,
      tooltip: '',
      placeholder: 'Immobilienbesitz',
      options,
      errorMap: {
        missing: 'Bitte teilen Sie uns mit, ob Sie Immobilien besitzen.'
      }
    }
  }
}

export default property
