import { Patch, Data } from '../../interfaces/ApolloResponse.interface'

export const SET_OP = 'set'

const additionalPatchMap: Record<string, (state: Data) => Patch> = {
  '/debtors/primary/currentAddress/zipCode': (state: Data) => ({
    op: SET_OP,
    path: '/debtors/primary/currentAddress/country',
    value: state.debtors.primary.currentAddress.country
  }),
  '/debtors/secondary/currentAddress/zipCode': (state: Data) => ({
    op: SET_OP,
    path: '/debtors/secondary/currentAddress/country',
    value: state.debtors.secondary.currentAddress.country
  }),
  '/debtors/primary/previousAddress/zipCode': (state: Data) => ({
    op: SET_OP,
    path: '/debtors/primary/previousAddress/country',
    value: state.debtors.primary.previousAddress.country
  }),
  '/debtors/secondary/previousAddress/zipCode': (state: Data) => ({
    op: SET_OP,
    path: '/debtors/secondary/previousAddress/country',
    value: state.debtors.secondary.previousAddress.country
  }),
  '/debtors/primary/currentEmployment/employer/address/zipCode': (
    state: Data
  ) => ({
    op: SET_OP,
    path: '/debtors/primary/currentEmployment/employer/address/country',
    value: state.debtors.primary.currentEmployment.employer.address.country
  }),
  '/debtors/secondary/currentEmployment/employer/address/zipCode': (
    state: Data
  ) => ({
    op: SET_OP,
    path: '/debtors/secondary/currentEmployment/employer/address/country',
    value: state.debtors.secondary.currentEmployment.employer.address.country
  })
}

export default additionalPatchMap
