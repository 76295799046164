export function generateNumberOptionList(start: number, end: number) {
  const result: Array<{ label: string; value: number }> = []
  for (let i = start; i <= end; i += 1) {
    result.push({ label: `${i}`, value: i })
  }

  return result
}

/**
 * Function used to interpolate text and replace the matching keys in the text
 * @param text = 'bla bla {contact} bla {contact} bla {postcode} bla'
 * @param replacers = [{contact: 'test@gmail.com'}, {postcode: '10'}]
 * @return 'bla bla test@gmail.com bla test@gmail.com bla 10 bla'
 */
export function interpolateText(
  text: string,
  ...replacers: Record<string, string>[]
): string {
  let result = text
  replacers.forEach((replacer) => {
    const key = Object.keys(replacer)[0]
    const matcherRegex = new RegExp(`{${key}}`, 'g')
    result = result.replace(matcherRegex, replacer[key])
  })

  return result
}
