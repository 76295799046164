import { memo, FunctionComponent } from 'react'
import ChoiceGroup, { Choice } from 'components/Input/ChoiceGroup'

const FINTEC_CHOICES: Choice<boolean>[] = [
  {
    label: 'Ja, mit Digitalem Kontoauszug fortfahren (empfohlen)',
    value: true
  },
  { label: 'Nein, weiter zu den Angeboten', value: false }
]

interface Props {
  onChange: (isAgreed: boolean) => void
}

const ChoiceComponent: FunctionComponent<Props> = ({ onChange }) => (
  <ChoiceGroup<boolean>
    name="dac-choices"
    choices={FINTEC_CHOICES}
    onChange={onChange}
    value={undefined}
    forceFullWidth
  />
)

export default memo(ChoiceComponent)
