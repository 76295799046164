import { memo } from 'react'
import { Theme } from '@mui/material/styles'
import { Container } from '@mui/material'
import useEmotionStyles from 'hooks/useEmotionStyles'
import * as CSSTypes from 'csstype'
import Seal, { SealProps } from './Seal'

interface SealsProps {
  seals: SealProps[]
  itemMargin?: number | string
}
type GridProps = { itemMargin: number | string }

const gridStyles = (theme: Theme, { itemMargin }: GridProps) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap' as CSSTypes.Property.FlexWrap,
    maxWidth: theme.breakpoints.values.md,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 420
    },
    '&>*': {
      margin: itemMargin
    }
  }
})

const Seals = ({ seals, itemMargin = 0 }: SealsProps) => {
  const { container } = useEmotionStyles<
    ReturnType<typeof gridStyles>,
    GridProps
  >(gridStyles, { itemMargin })

  return (
    <Container className={container}>
      {/* 
        `seals` seems to be null or array with null occurrences (accourding to bugsnag log),
        optional chaining and filter(Boolean) is here to protect the code from these issues.
      */}
      {seals?.filter(Boolean).map(({ name, url, children }) => (
        <Seal name={name} url={url} key={name}>
          {children}
        </Seal>
      ))}
    </Container>
  )
}

export default memo(Seals)
