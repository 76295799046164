import { memo } from 'react'
import Rating from '@mui/material/Rating'
import useEmotionStyles from 'hooks/useEmotionStyles'
import EkomiSeal from '../../assets/logos/ekomi-stamp@2x.png'

const styles = () => ({
  ekomi: {
    display: 'flex'
  },
  image: {
    marginRight: 4
  },
  text: {
    color: '#718096'
  },
  caption: {
    fontSize: '9px',
    lineHeight: '20px',
    fontWeight: 400,
    margin: 0
  },
  textRating: {
    fontSize: '13px',
    lineHeight: '16px',
    margin: 0
  }
})

const ratingStyles = () => ({
  icon: {
    fontSize: '0.8rem'
  }
})

interface Props {
  caption: string
  rating: number
}

const Ekomi = ({ caption: captionContent, rating }: Props) => {
  const { ekomi, image, text, caption, textRating } = useEmotionStyles(styles)
  const ratingClasses = useEmotionStyles(ratingStyles)

  return (
    <div className={ekomi}>
      <img
        className={image}
        src={EkomiSeal}
        alt="Höchste Kundenzufriedenheit"
        width={55}
        height={55}
      />
      <div className={text}>
        <h5 className={caption}>{captionContent}</h5>
        <Rating
          name="read-only"
          size="small"
          value={rating}
          readOnly
          classes={ratingClasses}
        />
        <p className={textRating}>{`${rating}`.replace('.', ',')} / 5</p>
      </div>
    </div>
  )
}

export default memo(Ekomi)
