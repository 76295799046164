export default class ConfigurationError extends Error {
  public advertisementId: string | undefined
  public traversalId: string | null

  constructor(
    message: string,
    advertisementId?: string,
    traversalId: string | null = null
  ) {
    super(message)

    this.name = 'ConfigurationError'
    this.advertisementId = advertisementId
    this.traversalId = traversalId
  }
}
