import { Theme, createTheme } from '@mui/material'
import createGenerateClassName from '@mui/styles/createGenerateClassName'
import castToPalette from 'utils/url/castToPalette'
import getUrlPalette from 'utils/url/getUrlPalette'

const getCastPallete = () => castToPalette(getUrlPalette())
const palette = getCastPallete()

export const THEME_OPTIONS = palette ? { palette } : undefined

/* REMOVE_ME this is needed to run tests and support skeleton
We should kill it soon */
export const THEME: Theme = createTheme({})

export const generateClassName = createGenerateClassName({
  seed: 'taurine'
})
