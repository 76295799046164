export const getFormattedPurpose = (purpose: string) => {
  const purposeUpperCase = purpose.toUpperCase()

  // CAT-2186 fix
  if (
    purposeUpperCase === 'UNDEFINED' ||
    purposeUpperCase === 'OTHER_GEWERBE'
  ) {
    return 'OTHER'
  }

  return purposeUpperCase
}
