import {
  broadcastToParent,
  RegisteredEventName
} from '@finanzcheck/catalyst-pollard'
import ResizeObserver from 'resize-observer-polyfill'
import { executeAsync } from 'utils/performance'
import { logAndTrackError } from 'utils/log/logAndTrackError'
import pollard from '.'

function addResizeObserver() {
  const ro = new ResizeObserver((entries: ResizeObserverEntry[]) => {
    const [{ contentRect }] = entries
    const { height } = contentRect

    broadcastToParent({
      eventName: RegisteredEventName.changeViewport,
      data: { height }
    })
  })

  ro.observe(document.documentElement)
}

export const initPollardCommunication = async (timeout: number) => {
  try {
    await pollard.request(
      {
        eventName: RegisteredEventName.ready,
        data: null,
        options: { handleImmediately: true }
      },
      timeout
    )

    executeAsync(() => {
      addResizeObserver()
    })
  } catch (error) {
    logAndTrackError(error as Error)
  }
}
