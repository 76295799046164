import { Theme } from '@mui/material/styles'
import Lock from '@mui/icons-material/Lock'
import Typography from '@mui/material/Typography'
import useEmotionStyles from 'hooks/useEmotionStyles'
import * as CSSTypes from 'csstype'

const SSL_TEXT_CAPTION = 'Wir schützen Ihre Daten'
const SSL_TEXT_HINT = 'Sichere SSL-Verschlüsselung'

const styles = (theme: Theme) => ({
  ssl: {
    color: '#339E33',
    textAlign: 'center' as CSSTypes.Property.TextAlign,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(6)
  },
  text: {
    marginLeft: theme.spacing(1)
  }
})

const SSL = () => {
  const { ssl, text } = useEmotionStyles(styles)

  return (
    <div className={ssl}>
      <Lock color="inherit" />
      <div className={text}>
        <Typography variant="body2" color="inherit" align="left" mb={0}>
          <strong>{SSL_TEXT_CAPTION}</strong>
        </Typography>
        <Typography variant="body2" color="inherit" align="left" mb={0}>
          {SSL_TEXT_HINT}
        </Typography>
      </div>
    </div>
  )
}

export default SSL
