import { TraversalError } from 'interfaces/ApolloResponse.interface'

export function isDate(value: TraversalError['actual']): value is string {
  if (typeof value !== 'string') {
    return false
  }

  return /(\d){4}-(\d){2}-(\d){2}T(\d){2}:(\d){2}:(\d){2}Z/.test(value)
}

export function parseBackendDate(
  date: string | number | null | undefined
): Date | null {
  if (typeof date !== 'string') {
    return null
  }

  const dateWithoutTime = date.substring(0, date.indexOf('T'))
  if (dateWithoutTime.length === 0) {
    return null
  }

  return new Date(dateWithoutTime)
}
