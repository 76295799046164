import SvgIcon from '@mui/material/SvgIcon'
import { observer } from 'mobx-react'
import { useStores } from 'stores/utils/hooks/useStores'
import { isTenantSmava } from 'utils/url'
import { NudgeWithIcon } from './NudgeWithIcon'

interface NudgeProps {
  text: string | React.ReactElement
  skipDebtorsCountCheck?: boolean
  Icon: typeof SvgIcon
  isForced?: boolean
  customIcon?: string
}

/* 
  Nudge components could be improved. When CAT-1696 experiment is over, we could do as follows:
  - use Nudges directly from config files
  - pass text as prop
  - pass Icon as prop
*/
export const Nudge: React.FC<NudgeProps> = observer(
  ({ text, Icon, customIcon, skipDebtorsCountCheck = true, isForced }) => {
    const {
      traversal: {
        data: {
          debtors: { count }
        }
      }
    } = useStores()

    // dirty fix: isTenantSmava() should be replaced with a prop
    const isSmava = isTenantSmava()

    if (!isSmava && !isForced) {
      return <></>
    }

    if (skipDebtorsCountCheck && count > 1) {
      return <></>
    }

    return <NudgeWithIcon text={text} Icon={Icon} customIcon={customIcon} />
  }
)
