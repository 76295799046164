import { AxiosError } from 'axios'
import { formatRFC3339 } from 'date-fns'
import { useCallback, useMemo } from 'react'
import { useProgress } from 'stores/utils/hooks/useProgress'
import { useRootStore } from 'stores/utils/hooks/useRootStore'
import { useStores } from 'stores/utils/hooks/useStores'
import { AFFILIATE_ID_FFG, isFinanzcheck, isSmava } from 'utils/affiliate'
import { getClient } from 'api'
import { logAndTrackError } from 'utils/log/logAndTrackError'
import TraversalError from 'error/traversalError'
import { trackInAmplitude } from 'utils/tracking/amplitude'
import { AmplitudeEvent } from 'utils/tracking/amplitude/amplitude.interface'
import { isFeatureEnabled } from 'utils/features'
import { useCallbackFunction } from './useCallbackFunction'
import { isConfigWithoutSaveButton } from 'config/utils/config'
import {
  VALIDATOR_DOB,
  VALIDATOR_PHONE,
  progressiveDisclosureValidator
} from 'validation'

interface SaveRequest {
  advertisementId: string
  traversalId: string
}

const postSave = async ({ advertisementId, traversalId }: SaveRequest) =>
  getClient('apollo').post(`save/${advertisementId}/${traversalId}`)

export interface UseSaveButtonProps {
  onSuccess?: () => void
  onError?: () => void
}

export function useSaveButton({ onSuccess, onError }: UseSaveButtonProps = {}) {
  const {
    rootStore: { initialEntryPoint }
  } = useRootStore()
  const {
    page: { config },
    navigation: { currentPageInSession },
    fieldErrors,
    traversal: {
      traversalId,
      data,
      data: {
        debtors: {
          primary: {
            contact: { phoneNumber },
            personal: { birthday }
          }
        }
      }
    }
  } = useStores()

  const {
    common: { hasAcceptedTerms },
    system: { affiliateId = AFFILIATE_ID_FFG }
  } = data

  const phoneError = fieldErrors.errors['debtors.primary.contact.phoneNumber']
  const birthdayError = fieldErrors.errors['debtors.primary.personal.birthday']
  const minimumValidationForPhone =
    progressiveDisclosureValidator(VALIDATOR_PHONE)(phoneNumber)
  const minimumValidationForBirthday =
    progressiveDisclosureValidator(VALIDATOR_DOB)(birthday)

  const { percentage, touchedInput } = useProgress()

  const isSaveButtonVisible = useMemo(() => {
    if (!(isFinanzcheck(affiliateId) || isSmava(affiliateId))) {
      return false
    }

    if (!isFeatureEnabled('save')) {
      return false
    }

    if (!config.version) {
      return false
    }

    if (isConfigWithoutSaveButton(config.name)) {
      return false
    }

    return hasAcceptedTerms
  }, [affiliateId, config.version, config.name, hasAcceptedTerms])

  const isSaveButtonEnabled = useMemo(() => {
    if (
      (phoneNumber && !phoneError && minimumValidationForPhone) ||
      (birthday && !birthdayError && minimumValidationForBirthday)
    ) {
      return true
    }

    return false
  }, [
    phoneNumber,
    birthday,
    phoneError,
    birthdayError,
    minimumValidationForPhone,
    minimumValidationForBirthday
  ])

  const successHandler = useCallbackFunction(onSuccess)
  const errorHandler = useCallbackFunction(onError)

  const save = useCallback(async () => {
    if (!traversalId) {
      logAndTrackError(
        new TraversalError(
          'Could not send save request because traversalId is missing.',
          traversalId
        )
      )

      return
    }

    try {
      await postSave({
        advertisementId: data.system.advertisementId,
        traversalId
      })

      if (successHandler.current) {
        successHandler.current()
      }

      trackInAmplitude(AmplitudeEvent.UseSaveButton, {
        savedAt: formatRFC3339(new Date()),
        entryPoint: initialEntryPoint,
        progress: percentage,
        lastPageSeen: currentPageInSession,
        lastInputTouched: touchedInput.last,
        nextInputUntouched: touchedInput.next
      })
    } catch (error) {
      if (errorHandler.current) {
        errorHandler.current()
      }

      logAndTrackError(error as AxiosError)
    }
  }, [
    currentPageInSession,
    data.system.advertisementId,
    errorHandler,
    initialEntryPoint,
    percentage,
    successHandler,
    touchedInput.last,
    touchedInput.next,
    traversalId
  ])

  return {
    save,
    isSaveButtonVisible,
    isSaveButtonEnabled
  }
}
