import { Theme } from '@mui/material/styles'
import { getStorage } from 'utils/storage'

export enum SessionStorage {
  CurrentTheme = 'ffg_dev_theme_editor_current',
  ConfigLoaderConfig = 'CONFIG_LOADER_CONFIG'
}

export function getCurrentTheme() {
  const theme = getStorage('sessionStorage').getItem(
    SessionStorage.CurrentTheme
  )
  if (!theme) {
    return null
  }

  return JSON.parse(theme) as Theme
}
