const countryLong = [
  { label: 'Afghanistan', value: 'AF' },
  { label: 'Ägypten', value: 'EG' },
  { label: 'Albanien', value: 'AL' },
  { label: 'Algerien', value: 'DZ' },
  { label: 'Andorra', value: 'AD' },
  { label: 'Angola', value: 'AO' },
  { label: 'Antigua und Barbuda', value: 'AG' },
  { label: 'Äquatorialguinea', value: 'GQ' },
  { label: 'Argentinien', value: 'AR' },
  { label: 'Armenien', value: 'AM' },
  { label: 'Aserbaidschan', value: 'AZ' },
  { label: 'Äthiopien', value: 'ET' },
  { label: 'Australien', value: 'AU' },
  { label: 'Bahamas', value: 'BS' },
  { label: 'Bahrain', value: 'BH' },
  { label: 'Bangladesch', value: 'BD' },
  { label: 'Barbados', value: 'BB' },
  { label: 'Belarus (vormals Weißrussland)', value: 'BY' },
  { label: 'Belgien', value: 'BE' },
  { label: 'Belize', value: 'BZ' },
  { label: 'Benin', value: 'BJ' },
  { label: 'Bhutan', value: 'BT' },
  { label: 'Bolivien', value: 'BO' },
  { label: 'Bosnien und Herzegowina', value: 'BA' },
  { label: 'Botsuana', value: 'BW' },
  { label: 'Brasilien', value: 'BR' },
  { label: 'Brunei Darussalam', value: 'BN' },
  { label: 'Bulgarien', value: 'BG' },
  { label: 'Burkina Faso', value: 'BF' },
  { label: 'Burundi', value: 'BI' },
  { label: 'Chile', value: 'CL' },
  { label: 'China', value: 'CN' },
  { label: 'Costa Rica', value: 'CR' },
  { label: "Côte d'Ivoire (vormals Elfenbeinküste)", value: 'CI' },
  { label: 'Dänemark', value: 'DK' },
  { label: 'Deutschland', value: 'DE', mostSelected: true },
  { label: 'Dominica', value: 'DM' },
  { label: 'Dominikanische Republik', value: 'DO' },
  { label: 'Dschibuti', value: 'DJ' },
  { label: 'Ecuador', value: 'EC' },
  { label: 'El Salvador', value: 'SV' },
  { label: 'Eritrea', value: 'ER' },
  { label: 'Estland', value: 'EE' },
  { label: 'Fidschi', value: 'FJ' },
  { label: 'Finnland', value: 'FI' },
  { label: 'Frankreich', value: 'FR' },
  { label: 'Gabun', value: 'GA' },
  { label: 'Gambia', value: 'GM' },
  { label: 'Georgien', value: 'GE' },
  { label: 'Ghana', value: 'GH' },
  { label: 'Grenada', value: 'GD' },
  { label: 'Griechenland', value: 'GR' },
  { label: 'Großbritannien und Nordirland', value: 'GB' },
  { label: 'Guatemala', value: 'GT' },
  { label: 'Guinea', value: 'GN' },
  { label: 'Guinea-Bissau', value: 'GW' },
  { label: 'Guyana', value: 'GY' },
  { label: 'Haiti', value: 'HT' },
  { label: 'Honduras', value: 'HN' },
  { label: 'Indien', value: 'IN' },
  { label: 'Indonesien', value: 'ID' },
  { label: 'Irak', value: 'IQ' },
  { label: 'Iran', value: 'IR' },
  { label: 'Irland', value: 'IE' },
  { label: 'Island', value: 'IS' },
  { label: 'Israel', value: 'IL' },
  { label: 'Italien', value: 'IT' },
  { label: 'Jamaika', value: 'JM' },
  { label: 'Japan', value: 'JP' },
  { label: 'Jemen', value: 'YE' },
  { label: 'Jordanien', value: 'JO' },
  { label: 'Kambodscha', value: 'KH' },
  { label: 'Kamerun', value: 'CM' },
  { label: 'Kanada', value: 'CA' },
  { label: 'Kap Verde', value: 'CV' },
  { label: 'Kasachstan', value: 'KZ' },
  { label: 'Katar', value: 'QA' },
  { label: 'Kenia', value: 'KE' },
  { label: 'Kirgisistan', value: 'KG' },
  { label: 'Kiribati', value: 'KI' },
  { label: 'Kolumbien', value: 'CO' },
  { label: 'Komoren', value: 'KM' },
  { label: 'Kongo (Demokratische Republik)', value: 'CD' },
  { label: 'Kongo (Republik)', value: 'CG' },
  { label: 'Korea (Nord, Dem. Volksrepublik)', value: 'KP' },
  { label: 'Korea (Süd, Republik)', value: 'KR' },
  { label: 'Kosovo', value: 'XK' },
  { label: 'Kroatien', value: 'HR' },
  { label: 'Kuba', value: 'CU' },
  { label: 'Kuwait', value: 'KW' },
  { label: 'Laos', value: 'LA' },
  { label: 'Lesotho', value: 'LS' },
  { label: 'Lettland', value: 'LV' },
  { label: 'Libanon', value: 'LB' },
  { label: 'Liberia', value: 'LR' },
  { label: 'Libyen', value: 'LY' },
  { label: 'Liechtenstein', value: 'LI' },
  { label: 'Litauen', value: 'LT' },
  { label: 'Luxemburg', value: 'LU' },
  { label: 'Madagaskar', value: 'MG' },
  { label: 'Malawi', value: 'MW' },
  { label: 'Malaysia', value: 'MY' },
  { label: 'Malediven', value: 'MV' },
  { label: 'Mali', value: 'ML' },
  { label: 'Malta', value: 'MT' },
  { label: 'Marokko', value: 'MA' },
  { label: 'Marshallinseln', value: 'MH' },
  { label: 'Mauretanien', value: 'MR' },
  { label: 'Mauritius', value: 'MU' },
  { label: 'Mazedonien', value: 'MK' },
  { label: 'Mexiko', value: 'MX' },
  { label: 'Mikronesien', value: 'FM' },
  { label: 'Moldau, Republik', value: 'MD' },
  { label: 'Monaco', value: 'MC' },
  { label: 'Mongolei', value: 'MN' },
  { label: 'Montenegro', value: 'ME' },
  { label: 'Mosambik', value: 'MZ' },
  { label: 'Myanmar (vormals Birma)', value: 'MM' },
  { label: 'Namibia', value: 'NA' },
  { label: 'Nauru', value: 'NR' },
  { label: 'Nepal', value: 'NP' },
  { label: 'Neuseeland', value: 'NZ' },
  { label: 'Nicaragua', value: 'NI' },
  { label: 'Niederlande', value: 'NL' },
  { label: 'Niger', value: 'NE' },
  { label: 'Nigeria', value: 'NG' },
  { label: 'Norwegen', value: 'NO' },
  { label: 'Oman', value: 'OM' },
  { label: 'Österreich', value: 'AT' },
  { label: 'Osttimor', value: 'TL' },
  { label: 'Pakistan', value: 'PK' },
  { label: 'Palau (vormals Belau)', value: 'PW' },
  { label: 'Panama', value: 'PA' },
  { label: 'Papua-Neuguinea', value: 'PG' },
  { label: 'Paraguay', value: 'PY' },
  { label: 'Peru', value: 'PE' },
  { label: 'Philippinen', value: 'PH' },
  { label: 'Polen', value: 'PL', mostSelected: true },
  { label: 'Portugal', value: 'PT' },
  { label: 'Ruanda', value: 'RW' },
  { label: 'Rumänien', value: 'RO', mostSelected: true },
  { label: 'Russische Föderation', value: 'RU' },
  { label: 'Sahara, Demokratische Arabische Republik', value: 'EH' },
  { label: 'Salomonen', value: 'SB' },
  { label: 'Sambia', value: 'ZM' },
  { label: 'Samoa', value: 'WS' },
  { label: 'San Marino', value: 'SM' },
  { label: 'São Tomé und Príncipe', value: 'ST' },
  { label: 'Saudi-Arabien', value: 'SA' },
  { label: 'Schweden', value: 'SE' },
  { label: 'Schweiz', value: 'CH' },
  { label: 'Senegal', value: 'SN' },
  { label: 'Serbien', value: 'RS' },
  { label: 'Seychellen', value: 'SC' },
  { label: 'Sierra Leone', value: 'SL' },
  { label: 'Simbabwe', value: 'ZW' },
  { label: 'Singapur', value: 'SG' },
  { label: 'Slowakei', value: 'SK' },
  { label: 'Slowenien', value: 'SI' },
  { label: 'Somalia', value: 'SO' },
  { label: 'Spanien', value: 'ES' },
  { label: 'Sri Lanka', value: 'LK' },
  { label: 'St. Kitts und Nevis', value: 'KN' },
  { label: 'St. Lucia', value: 'LC' },
  { label: 'St. Vincent und die Grenadinen', value: 'VC' },
  { label: 'Südafrika', value: 'ZA' },
  { label: 'Sudan', value: 'SD' },
  { label: 'Suriname', value: 'SR' },
  { label: 'Swasiland', value: 'SZ' },
  { label: 'Syrien', value: 'SY' },
  { label: 'Tadschikistan', value: 'TJ' },
  { label: 'Taiwan', value: 'TW' },
  { label: 'Tansania', value: 'TZ' },
  { label: 'Thailand', value: 'TH' },
  { label: 'Togo', value: 'TG' },
  { label: 'Tonga', value: 'TO' },
  { label: 'Trinidad und Tobago', value: 'TT' },
  { label: 'Tschad', value: 'TD' },
  { label: 'Tschechische Republik', value: 'CZ' },
  { label: 'Tunesien', value: 'TN' },
  { label: 'Türkei', value: 'TR', mostSelected: true },
  { label: 'Turkmenistan', value: 'TM' },
  { label: 'Tuvalu', value: 'TV' },
  { label: 'Uganda', value: 'UG' },
  { label: 'Ukraine', value: 'UA' },
  { label: 'Ungarn', value: 'HU' },
  { label: 'Uruguay', value: 'UY' },
  { label: 'USA', value: 'US' },
  { label: 'Usbekistan', value: 'UZ' },
  { label: 'Vanuatu', value: 'VU' },
  { label: 'Vatikanstadt', value: 'VA' },
  { label: 'Venezuela', value: 'VE' },
  { label: 'Vereinigte Arabische Emirate', value: 'AE' },
  { label: 'Vietnam', value: 'VN' },
  { label: 'Zentralafrikanische Republik', value: 'CF' },
  { label: 'Zypern', value: 'CY' }
]

export default countryLong
