export enum NetworkType {
  Slow2G = 'slow-2g',
  '2G' = '2g',
  '3G' = '3g',
  '4G' = '4g',
  Unknown = 'unknown',
  Offline = 'offline'
}

export interface NetworkInfo {
  downlink: number
  effectiveType: NetworkType
  rtt: number
  saveData: boolean
}

declare global {
  interface Navigator {
    connection?: NetworkInfo
  }
}

export function getNetworkType(): NetworkType {
  if (!window.navigator.onLine) {
    return NetworkType.Offline
  }

  if (!window.navigator.connection) {
    return NetworkType.Unknown
  }

  return window.navigator.connection.effectiveType || NetworkType.Offline
}
