import EnvironmentVariableNotSet from '../../error/environmentVariableNotSet'
import EnvironmentVariableIsEmpty from '../../error/environmentVariableIsEmpty'
import InvalidBooleanEnvironmentVariable from '../../error/invalidBooleanEnvironmentVariable'

function getError(name: string, value: string | undefined) {
  if (value === undefined || value === null) {
    return new EnvironmentVariableNotSet(name)
  }

  if (value.length === 0) {
    return new EnvironmentVariableIsEmpty(name)
  }

  return undefined
}

export function getEnv<T extends string>(
  name: string,
  required?: boolean
): T | undefined
export function getEnv<T extends string>(
  name: string,
  required?: false
): T | undefined
export function getEnv<T extends string>(name: string, required: true): T
export function getEnv<T extends string>(
  name: string,
  required = true
): T | undefined {
  const value = process.env[name] as T | undefined
  if (required) {
    const error = getError(name, value)
    if (error) {
      throw error
    }
  }

  return value
}

export function getEnvMulti(list: string[]): string[]
export function getEnvMulti(list: {
  [name: string]: boolean | undefined
}): Array<string | undefined>
export function getEnvMulti(
  list: string[] | { [name: string]: boolean | undefined }
) {
  if (Array.isArray(list)) {
    return list.map((name) => getEnv(name, true))
  }

  return Object.entries(list).map(([name, required]) => getEnv(name, required))
}

type BooleanValues = Array<string | undefined>
const looseValues: BooleanValues = [undefined, '']
const exactValues: BooleanValues = ['0', '1']

function isBooleanValid(value: string | undefined, strict: boolean) {
  const validValues = strict ? exactValues : exactValues.concat(looseValues)

  return validValues.includes(value)
}

export function isTruthy(name: string, strict = false) {
  const value = getEnv(name, false)
  if (!isBooleanValid(value, strict)) {
    throw new InvalidBooleanEnvironmentVariable(name, value)
  }

  return value === '1'
}

export function hasValue<T extends string>(name: string, value: T | T[]) {
  const envValue = getEnv<T>(name, false)
  if (!envValue) {
    return false
  }

  if (Array.isArray(value)) {
    return value.includes(envValue)
  }

  return envValue === value
}

export type NodeEnvironment = 'development' | 'production'
export enum AppEnvironment {
  Test = 'test',
  Local = 'local',
  Shared = 'shared',
  Stage = 'stage',
  Production = 'production'
}

export const appEnvironment: AppEnvironment = getEnv('REACT_APP_ENV', true)
export const isProductionEnvironment =
  hasValue<NodeEnvironment>('NODE_ENV', 'production') &&
  appEnvironment === 'production'

function getParentQuery() {
  if (document.referrer.includes('localhost')) {
    return window.parent.location.search.substring(1)
  }

  return document.referrer.substring(document.referrer.lastIndexOf('?') + 1)
}

function getDevMenuSecret() {
  const params = new URLSearchParams(
    `${window.location.search}&${getParentQuery()}`
  )

  return params.get('devtools')
}

export const isDevMenuEnabled = () => {
  if (isProductionEnvironment) {
    return false
  }

  if (appEnvironment !== 'stage') {
    return true
  }

  const secret = getDevMenuSecret()
  if (secret && hasValue('REACT_APP_DEV_TOOLS_SECRET', secret)) {
    return true
  }

  return false
}
