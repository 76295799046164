import { TuevLink } from './TuevLink'
import { observer } from 'mobx-react'
import { useStores } from 'stores/utils/hooks/useStores'
import { LegalText } from 'components/DigitalAccountCheck/IntroText/LegalText'
import { TrustCards } from './TrustCards'
import { DAC_IDLE_STEPS } from 'components/DigitalAccountCheck/helpers'
import { YOUNG_AGE } from 'constants/components'

export const isDacFooterWrapperMovedBelowFormNavigation = (
  debtorsAge: number
) => debtorsAge >= YOUNG_AGE

const DacFooterWrapper = observer(() => {
  const {
    traversal: { dacWidgetStep }
  } = useStores()

  const isDacInProgress = !DAC_IDLE_STEPS.includes(dacWidgetStep)

  return (
    <>
      <TuevLink />
      <TrustCards />
      {isDacInProgress && <LegalText />}
    </>
  )
})

export { DacFooterWrapper }
