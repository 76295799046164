import { styled } from '@mui/material/styles'
import SvgIcon from '@mui/material/SvgIcon'

const NudgeWithIconContainer = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  alignItems: 'flex-start',
  padding: theme.spacing(2),
  borderRadius: theme.spacing(0.5),
  boxShadow: `0px 2px ${theme.spacing(2)} ${theme.palette.grey[100]}`,
  borderLeft: `${theme.spacing(1)} solid ${theme.palette.primary.main}`,
  fontSize: '12px',
  lineHeight: '20px'
}))

export interface NudgeWithIconProps {
  text: string | React.ReactElement
  Icon: typeof SvgIcon
  customIcon?: string
}

export const NudgeWithIcon: React.FC<NudgeWithIconProps> = ({
  text,
  Icon,
  customIcon
}) => (
  <NudgeWithIconContainer>
    {customIcon ? (
      <img alt="icon" src={customIcon} />
    ) : (
      <Icon color="primary" />
    )}
    {text}
  </NudgeWithIconContainer>
)
