import BugsnagError, { Metadata } from './bugsnagError'

export default class CommandError extends BugsnagError {
  public commandName: string

  constructor(commandName: string, message: string) {
    super(`[${commandName}] ${message}`)

    this.name = 'CommandError'
    this.commandName = commandName
  }

  getMetadata(): Metadata {
    return {
      startupCommand: {
        name: this.commandName
      }
    }
  }
}
