import RootStore from 'stores/RootStore'
import { StartupCommand } from './command.interface'

export class InitReactionsCommand implements StartupCommand {
  name = 'InitReactions'
  dependencies = ['GetTraversal']
  timeout = 5000

  // eslint-disable-next-line class-methods-use-this
  execute = async (rootStore: RootStore) => rootStore.traversal.initReactions()
}

export default InitReactionsCommand
