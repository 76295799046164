import styled from '@emotion/styled'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Button } from '@mui/material'
import Wave from 'components/Wave'
import { observer } from 'mobx-react'
import { useStores } from 'stores/utils/hooks/useStores'

const StyledButton = styled(Button)(({ theme }) => ({
  width: '95%',
  margin: `${theme.spacing(1)}`
}))

export const NonDacEmbeddedStep = observer(
  ({ ctaText }: { ctaText: string }) => {
    const setOverMiddleAgeDac = () => {
      setOverMiddleAgeDacSkipped(true)
    }

    const {
      traversal: { setOverMiddleAgeDacSkipped, overMiddleAgeDacSkipped }
    } = useStores()

    return (
      <StyledButton
        fullWidth
        id="next-submit"
        name="next-submit"
        variant="contained"
        color={'primary'}
        onClick={setOverMiddleAgeDac}
        endIcon={<ArrowForwardIcon />}
      >
        {overMiddleAgeDacSkipped ? <Wave /> : <>{ctaText}</>}
      </StyledButton>
    )
  }
)
