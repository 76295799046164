import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import RetriesExhaustedError from 'error/retriesExhaustedError'

const MS_DEFAULT_RETRY_INTERVAL = 150
const DEFAULT_MAX_RETRY_COUNT = 30

interface RetryConfig<T> {
  condition: {
    response?: (response: AxiosResponse<T>) => boolean
    error?: (error: AxiosError<T> | undefined) => boolean
  }
  maxRetryCount?: number
  retryInterval?: number
}

export async function retry<T>(
  instance: AxiosInstance,
  request: AxiosRequestConfig,
  {
    condition,
    maxRetryCount = MS_DEFAULT_RETRY_INTERVAL,
    retryInterval = DEFAULT_MAX_RETRY_COUNT
  }: RetryConfig<T>
): Promise<AxiosResponse<T>> {
  return new Promise((resolve, reject) => {
    let currentRetryCount = 0

    const run = async () => {
      try {
        currentRetryCount += 1

        if (currentRetryCount > maxRetryCount) {
          reject(new RetriesExhaustedError(request))
          return
        }

        const response = await instance.request(request)

        if (condition.response && condition.response(response)) {
          setTimeout(run, retryInterval)
          return
        }
        resolve(response)
      } catch (error) {
        if (
          condition.error &&
          condition.error(error as AxiosError<T, unknown>)
        ) {
          setTimeout(run, retryInterval)
          return
        }

        reject(error)
      }
    }

    run()
  })
}
