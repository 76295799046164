import { del, set } from 'dot-object'

/**
 * Creates an object out of a dot notation string and sets the value to the last key
 * e.g.: "te.s.t, 1" will end up in {te: {s: {t: 1}}}
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function buildObjFromPath<
  // eslint-disable-next-line @typescript-eslint/ban-types
  P extends object,
  R extends P = P,
  V = unknown
>(path: string, value: V, existingObj?: P, merge = false): R {
  const objToUpdate = existingObj || ({} as P)

  if (value === undefined) {
    del(path, objToUpdate)
  } else {
    set(path, value, objToUpdate, merge)
  }

  return objToUpdate as R
}

/**
 * Convert a slashed path to a dotted
 * also replace the leading slash
 */
export function convertSlashToDotted(slashed: string): string {
  const dotted = slashed.replace(/\/(\d)\//, '[$1].').replace(/\//g, '.')

  return dotted[0] === '.' ? dotted.substring(1) : dotted
}
