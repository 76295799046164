import { logAndTrackError } from 'utils/log/logAndTrackError'
import { nanoid } from 'nanoid'
import { trackInAmplitude } from 'utils/tracking/amplitude'
import { AmplitudeEvent } from 'utils/tracking/amplitude/amplitude.interface'
import { setCookie } from 'utils/cookie'

class OptimizelyStore {
  public optimizelyEndUserId: string | null = null

  getOptimizelyEndUserId = (): string | null => {
    if (!this.optimizelyEndUserId) {
      this.ensureOptimizelyEndUserId()
    }

    return this.optimizelyEndUserId
  }

  private setOptimizelyEndUserId = (optimizelyEndUserId: string | null) => {
    this.optimizelyEndUserId = optimizelyEndUserId
  }

  ensureOptimizelyEndUserId(): string | null {
    const id = this.optimizelyEndUserId

    if (!id) {
      return this.createNew()
    }

    return id
  }

  private createNew(): string | null {
    const id = nanoid()

    try {
      this.setOptimizelyEndUserId(id)

      setCookie('optimizelyEndUserId', id)

      trackInAmplitude(AmplitudeEvent.OnNewOptimizelyEndUserIdCreated)
    } catch (error) {
      logAndTrackError(
        new Error(`error ${error} persisting optimizelyEndUserId`)
      )
      return null
    }
    return id
  }
}

export default OptimizelyStore
