import { Config } from 'config/config.interface'
import { CONFIG_LEAD_FORM } from 'config/utils/config'
import { Data } from 'interfaces/ApolloResponse.interface'
import { getRemainingAmount } from 'utils/activeLoans'
import { formatToDeAmount } from 'utils/number'
import { trackInAmplitude } from 'utils/tracking/amplitude'
import { AmplitudeEvent } from 'utils/tracking/amplitude/amplitude.interface'

import { ValidationFunction } from '.'
import manufacturer from '../containers/KfzForm/kfz.statics'

export const min = (minimumValue: number) => (actualValue: number) =>
  actualValue < minimumValue
    ? `Der Wert muss mindestes ${minimumValue} betragen.`
    : null

export const max = (maximumValue: number) => (actualValue: number) =>
  actualValue > maximumValue
    ? `Der Wert darf maximal ${maximumValue} betragen.`
    : null

const CONFIG_DEPENDENT_MAX_AMOUNT = 100000

/**
 * Rules for **field-level** validations.
 * see [FinalForm Documentation](https://final-form.org/docs/react-final-form/types/FieldProps#validate).
 *
 * Wildcard paths (e.g. debtors.*.expenses.rent) can be specified. Only use ONE wildcard!
 * Use `getRule` function to get the path for e.g. debtors.primary.expenses.rent, where `*` has been replaced.
 *
 */
export const ALL_RULES: Record<string, ValidationFunction[]> = {
  'common.manuallyAdaptedAmount': [
    (value: number, state: Data) => {
      const remainingAmount = getRemainingAmount(state)

      if (remainingAmount && value < remainingAmount) {
        return `Der Kreditbetrag ist niedriger als Ihre Restschuld (${formatToDeAmount(
          remainingAmount
        )})`
      }

      return null
    }
  ],
  'common.vehicle.price': [
    (vehiclePriceValue: number, state: Data) => {
      const remainingAmount = getRemainingAmount(state)
      const loanAmount =
        vehiclePriceValue - (state.common.vehicle.initialPayment || 0)

      if (remainingAmount && loanAmount < remainingAmount) {
        return `Die Differenz zwischen Kaufpreis und Anzahlung muss mindestens so groß sein wie die angegebene Restschuld für Ihre umgeschuldeten Kredite (${formatToDeAmount(
          remainingAmount
        )}).`
      }

      return null
    }
  ],
  'common.amount': [
    (value: number, state: Data) => {
      const remainingAmount = getRemainingAmount(state)

      if (remainingAmount && value < remainingAmount) {
        trackInAmplitude(AmplitudeEvent.RemainingAmountErrorShown)
        return `Der Wunschbetrag muss mindestens so groß sein wie die angegebene Restschuld für Ihre umgeschuldeten Kredite (${formatToDeAmount(
          remainingAmount
        )}).`
      }

      return null
    },
    (value: number, _, config: Config) => {
      if (
        config.version &&
        [CONFIG_LEAD_FORM].filter((configName) => configName === config.name)
          .length > 0 &&
        value > CONFIG_DEPENDENT_MAX_AMOUNT
      ) {
        return `Der Kreditbetrag darf maximal ${formatToDeAmount(
          CONFIG_DEPENDENT_MAX_AMOUNT
        )} betragen.`
      }

      return null
    }
  ],
  'common.vehicle.manufacturer': [
    (value: string, allValues: Data) => {
      const selectedManufacturer = value

      if (!selectedManufacturer) {
        return null
      }

      const selectedCategory = allValues.common.vehicle.type
      const manufacturersForCategory = manufacturer[selectedCategory].map(
        (man) => man.value
      )

      if (!manufacturersForCategory.includes(selectedManufacturer)) {
        return 'Bitte wählen Sie einen gültigen Hersteller für die gewählte Fahrzeugart.'
      }

      return null
    }
  ],
  'debtors.*.expenses.rent': [min(0), max(30000)],
  'debtors.*.income.primary': [max(30000)],
  'debtors.*.income.secondaryEmployments[*].income': [max(30000)],
  'debtors.*.income.spousalSupport': [max(30000)],
  'debtors.*.income.pension': [max(30000)],
  'debtors.*.income.childSupport': [max(30000)],
  'debtors.*.activeLoans[*].remainingAmount': [
    (_: number, state: Data) => {
      const remainingAmount = getRemainingAmount(state)

      const maxFromAmountAndManuallyAdaptedAmount = Math.max(
        state.common.manuallyAdaptedAmount || 0,
        state.common.amount
      )

      if (remainingAmount > maxFromAmountAndManuallyAdaptedAmount) {
        return `Die Restschuld ist höher als Ihr angefragter Kreditbetrag (${formatToDeAmount(
          state.common.manuallyAdaptedAmount || state.common.amount
        )}). Sie können Ihren Wunschbetrag am Ende der Seite anpassen.`
      }

      return null
    }
  ]
}
