import FieldErrorStore from 'stores/FieldErrorStore'
import RootStore from 'stores/RootStore'
import { getKey } from 'utils/map'
import { getNextPageName } from 'utils/pages'
import { urlPathMap } from 'utils/url'

/**
 * We use a decoupled store instance here
 * because we don't want to show the errors to the user.
 */
async function getCurrentErrors(rootStore: RootStore) {
  return new FieldErrorStore(rootStore).check(await rootStore.traversal.put())
}

export async function getFurthest(rootStore: RootStore) {
  const {
    traversal,
    page: { getLastDisplayedPageName, getFirstDisplayedPageName },
    fieldErrors: { removePathValuesWithErrors }
  } = rootStore

  if (!traversal.traversalId) {
    return getFirstDisplayedPageName()
  }

  const fieldErrors = await getCurrentErrors(rootStore)
  const firstErrorPage = await fieldErrors.findFirstErrorPage()

  removePathValuesWithErrors(fieldErrors.errors)

  return firstErrorPage || getLastDisplayedPageName()
}

export function getSavedPage({
  page: { getPageIndex },
  navigation
}: RootStore) {
  const savedPage = navigation.currentPageInSession
  if (!savedPage) {
    return null
  }

  const pageIndex = getPageIndex(savedPage)
  if (pageIndex === -1) {
    navigation.setCurrentPageInSession(null)
    return null
  }

  return savedPage
}

/**
 * If there is an error before the wanted entry point,
 * we redirect to the errored page instead.
 */
export async function getEntryPointPageName(
  pageName: string,
  rootStore: RootStore
) {
  const {
    page: { getPageIndex }
  } = rootStore

  const wantedIndex = getPageIndex(pageName)

  const furthestPageName = await getFurthest(rootStore)
  const furthestIndex = furthestPageName ? getPageIndex(furthestPageName) : 0

  return skipPageIfFilled(
    wantedIndex <= furthestIndex ? pageName : furthestPageName,
    rootStore
  )
}

export async function isPageFilledByParams(
  pageName: string,
  rootStore: RootStore
) {
  const {
    page: { getPaths },
    urlParams
  } = rootStore

  const paths = await getPaths(pageName)

  return paths.every((path) => {
    const paramName = getKey(urlPathMap, path)
    if (!paramName) {
      return false
    }

    return urlParams[paramName] !== undefined
  })
}

export async function skipPageIfFilled(pageName: string, rootStore: RootStore) {
  const isPageFilled = await isPageFilledByParams(pageName, rootStore)
  if (!isPageFilled) {
    return pageName
  }

  return getNextPageName(pageName, rootStore) || pageName
}
