import { useLoadingMode } from 'stores/utils/hooks/useLoadingMode'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { observer } from 'mobx-react'
import { memo, PropsWithChildren } from 'react'
import { Theme } from '@mui/material'
import useEmotionStyles from 'hooks/useEmotionStyles'
import InitialSkeleton from '../SkeletonView/InitialSkeleton'

export enum LoadingMode {
  None,
  Initial,
  Blocking
}

const useBackdropStyles = (theme: Theme) => ({
  backdrop: {
    zIndex: 999999,
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    color: theme.palette.primary.main
  }
})

const LoadingProvider = observer(({ children }: PropsWithChildren<unknown>) => {
  const loadingMode = useLoadingMode()
  const { backdrop } = useEmotionStyles(useBackdropStyles)

  if (loadingMode === LoadingMode.Initial) {
    return <InitialSkeleton />
  }

  return (
    <>
      {children}
      <Backdrop
        className={backdrop}
        open={loadingMode === LoadingMode.Blocking}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
})

export default memo(LoadingProvider)
