import TV from '@mui/icons-material/TvOutlined'
import Search from '@mui/icons-material/SearchOutlined'
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined'
import ConnectWithoutContactOutlinedIcon from '@mui/icons-material/ConnectWithoutContactOutlined'
import RadioOutlinedIcon from '@mui/icons-material/RadioOutlined'
import SmartDisplayOutlinedIcon from '@mui/icons-material/SmartDisplayOutlined'
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'

const civilStatus = [
  { label: 'TV-Werbung', value: 'TV', icon: TV },
  { label: 'Suchmaschine (z.B. Google)', value: 'SUCHMASCHINE', icon: Search },
  {
    label: 'Banner-Werbung im Internet',
    value: 'WERBUNG',
    icon: CampaignOutlinedIcon
  },
  {
    label: 'Soziale Medien (z.B. Facebook)',
    value: 'SOZIALE',
    icon: ConnectWithoutContactOutlinedIcon
  },
  { label: 'Radio-Werbung', value: 'RADIO', icon: RadioOutlinedIcon },
  { label: 'Youtube', value: 'YOUTUBE', icon: SmartDisplayOutlinedIcon },
  {
    label: 'Empfohlen oder bekannte Marke',
    value: 'EMPFOHLEN',
    icon: GroupsOutlinedIcon
  }
]

export default civilStatus
