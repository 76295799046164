import { memo } from 'react'
import { Theme } from '@mui/material/styles'
import { Typography } from '@mui/material'
import useEmotionStyles from 'hooks/useEmotionStyles'

const styles = (theme: Theme) => ({
  legal: {
    width: '100%',
    marginTop: theme.spacing(2)
  }
})

type NoteProps = {
  legalText: string
}

const Note = ({ legalText }: NoteProps) => {
  const { legal } = useEmotionStyles(styles)

  return (
    <Typography className={legal} variant="caption">
      {legalText}
    </Typography>
  )
}

export default memo(Note)
