import { createTheme as createMuiTheme, Theme } from '@mui/material/styles'
import { toJS } from 'mobx'
import { useCallback } from 'react'
import { useStores } from './useStores'

function createTheme(theme: Theme) {
  return createMuiTheme(toJS(theme))
}

export function useCurrentTheme() {
  const {
    theme: { material, setMaterial }
  } = useStores()

  const setTheme = useCallback(
    (theme: Theme) => {
      setMaterial(theme)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setMaterial]
  )

  return {
    theme: createTheme(material),
    setTheme
  }
}
