import {
  broadcastToParent,
  RegisteredEventName
} from '@finanzcheck/catalyst-pollard'

import {
  AmplitudeEvent,
  getScope,
  EventProperty,
  UserProperty
} from './amplitude.interface'

let untrackedEvents: {
  event: AmplitudeEvent
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any
}[] = []

/**
 * This is needed to recover user properties that were set when Amplitude was disabled.
 * Apparently, the properties get dropped then (won't get added in the first place).
 */
/*  
  Added var as workaround to allow the hoisting of userProperties
  allowing the tests to initialize the variable otherwise the tests fail. 
*/
// eslint-disable-next-line vars-on-top, no-var
var userProperties: Partial<Record<UserProperty, unknown>> = {}

function setSegmentAmplitudeUserProperty(
  properties: Partial<Record<UserProperty, unknown>>
) {
  broadcastToParent({
    eventName: RegisteredEventName.callSegmentIdentify,
    data: { ...properties, team: 'catapult' }
  })
}

export function setUserProperties(
  properties: Partial<Record<UserProperty, unknown>>
) {
  setSegmentAmplitudeUserProperty(properties)
  userProperties = { ...userProperties, ...properties }
}

const globalEventProperties: Partial<Record<EventProperty, unknown>> = {}

export function setGlobalEventProperty<T>(property: EventProperty, value: T) {
  globalEventProperties[property] = value
}

export const trackInAmplitude = async <T>(
  event: AmplitudeEvent,
  payload?: T
) => {
  broadcastToParent({
    eventName: RegisteredEventName.tracking,
    data: {
      event,
      scope: getScope(event),
      team: 'catapult',
      ...globalEventProperties,
      ...payload
    }
  })
}

interface EventCollector<T> {
  key: string
  items: Array<T>
  timeout: number
}

const eventCollector: Partial<Record<AmplitudeEvent, EventCollector<unknown>>> =
  {}

function getEventCollector<T>(event: AmplitudeEvent, key: string) {
  const collector = eventCollector[event] as EventCollector<T> | undefined
  if (collector) {
    /**
     * If the key was changed,
     * we emit all the items we collected until now (with the old key).
     */
    if (collector.key !== key) {
      try {
        trackInAmplitude(event, {
          [collector.key]: collector.items
        })
      } finally {
        collector.items.length = 0
      }
    }

    return collector
  }

  const newCollector: EventCollector<T> = {
    key,
    items: [],
    timeout: window.setTimeout(async () => {
      clearTimeout(newCollector.timeout)

      try {
        await trackInAmplitude(event, {
          [newCollector.key]: newCollector.items
        })
      } finally {
        delete eventCollector[event]
      }
    }, 1000)
  }

  eventCollector[event] = newCollector

  return newCollector
}

/**
 * Use this method if you want to collect a list of 'events'
 * that are initiated at different places in the app.
 *
 * The list is then tracked as a single Amplitude event.
 */
export const collectListForAmplitude = <T>(
  event: AmplitudeEvent,
  key: string,
  item: T
) => getEventCollector<T>(event, key).items.push(item)
