import { logAndTrackError } from 'utils/log/logAndTrackError'
import { getCookie, COOKIE_KEY_FCID } from 'utils/cookie'
import { appEnvironment, AppEnvironment, getEnv } from 'utils/env'
import RootStore from 'stores/RootStore'
import { StartupCommand } from './command.interface'

/**
 * Sets new fcid to local storage and as cookie, if no fcid cookie present.
 * We don't add expiration date to new cookie, cause most browsers will remove it after session, anyway (3rd-party).
 */
class InitFcidCommand implements StartupCommand {
  name = 'InitFcid'
  dependencies = ['InitFrameHostData']
  timeout = 10000

  // eslint-disable-next-line class-methods-use-this
  execute = async (root: RootStore) => {
    if (getCookie(COOKIE_KEY_FCID)) {
      return
    }

    const fcid = root.fcid.ensureFcid()
    if (!fcid) {
      logAndTrackError(new Error(`Can't get fcid from localStorage.`))
      return
    }

    const domain = getEnv('REACT_APP_DOMAIN', true)
    let cookie = `FCID=${fcid}; domain=${domain};`
    if (
      appEnvironment !== AppEnvironment.Local &&
      appEnvironment !== AppEnvironment.Test
    ) {
      cookie += ' sameSite=none; secure;'
    }

    document.cookie = cookie
  }
}

export default InitFcidCommand
