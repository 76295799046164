import { RegisteredEventName } from '@finanzcheck/catalyst-pollard'
import BugsnagError, { Metadata } from './bugsnagError'

export default class PollardError extends BugsnagError {
  public eventName: RegisteredEventName

  constructor(message: string, eventName: RegisteredEventName) {
    super(message)

    this.name = 'PollardError'
    this.eventName = eventName
  }

  getMetadata(): Metadata {
    return {
      pollard: {
        eventName: this.eventName
      }
    }
  }
}
