import { action, observable, makeObservable, runInAction } from 'mobx'
import { flatten } from 'rambdax'
import { UserProperty } from 'utils/tracking/amplitude/amplitude.interface'
import { ConfigName, getConfig, getDisplayedPages } from 'config/utils/config'
import Bugsnag from '@bugsnag/js'
import {
  MAXIMUM_PROGRESS,
  MINIMUM_PROGRESS
} from 'components/ProgressIndicator'
import { logAndTrackError } from 'utils/log/logAndTrackError'
import ConfigError, { getConfigMetadata } from 'error/configError'
import PageError from 'error/pageError'
import {
  EntryPoint,
  LoadEntryPointCommand
} from 'startup/command/loadEntryPoint'
import { setUserProperties } from 'utils/tracking/amplitude'
import ConfigurationError from 'error/configurationError'
import { FORMFIELD_ERROR_CLASS_SELECTOR } from 'components/ErrorWrapper'
import { differenceInCalendarYears, parse } from 'date-fns'
import { executeAsync } from 'utils/performance'
import { NavigateFunction } from 'react-router-dom'

import { Config, LoadedConfig, Pages } from '../config/config.interface'
import { logSuccess } from '../utils/log'
import { scrollToElement, scrollToTop } from '../utils/pollard/scroll'
import {
  getNextPageName,
  getPreviousPageName,
  getDisplayedPageNames
} from '../utils/pages/index'
import RootStore from './RootStore'
import { LoadingMode } from '../components/LoadingProvider'

export function getFirstErrorWrapper() {
  const allErrorWrappers = document.querySelectorAll(
    FORMFIELD_ERROR_CLASS_SELECTOR
  )

  return allErrorWrappers.length > 0 ? allErrorWrappers.item(0) : null
}

interface PageInfo {
  paths: Record<string, string[]>
  knownPaths: string[]
  pageObject: Record<string, Record<string, string[]>>
}
class PageStore {
  private rootStore: RootStore

  private pageInfo: PageInfo | null = null

  public config: Config = { version: false }
  // FIXME this should be removed out of here also, and brought to NavigationStore
  public previousPageName: string | null = null
  public nextPageName: string | null = null
  public hiddenProgressIndicatorPages: string[] = []

  constructor(rootStore: RootStore) {
    makeObservable<PageStore>(this, {
      config: observable,
      previousPageName: observable,
      nextPageName: observable,
      setPageInfo: action,
      setConfig: action,
      setConfiguration: action,
      changeConfiguration: action,
      unsetConfiguration: action,
      setNavigation: action,
      hiddenProgressIndicatorPages: observable,
      hideProgressIndicatorOnPages: action
    })

    this.rootStore = rootStore
  }

  setNavigation = (pageName: string) => {
    this.previousPageName = getPreviousPageName(pageName, this.rootStore)
    this.nextPageName = getNextPageName(pageName, this.rootStore)
  }

  getPage = async (pageName: string | null) => {
    if (!pageName) {
      return {}
    }
    const pageInfo = await this.getPageInfo()
    if (!pageInfo) {
      return {}
    }

    return pageInfo.pageObject[pageName]
  }

  setPageInfo = (pages: Pages) => {
    const paths = Object.entries(pages).reduce(
      (previous, [pageName, categories]) => {
        const flattenCategories = flatten<string>(Object.values(categories))

        return {
          ...previous,
          [pageName]: flattenCategories
        }
      },
      {}
    )

    this.pageInfo = {
      paths,
      knownPaths: Array.from(new Set(flatten<string>(Object.values(paths)))),
      pageObject: pages
    }
  }

  getPageInfo = async (): Promise<PageInfo | null> => {
    if (!this.config.version) {
      return null
    }

    if (!this.pageInfo) {
      this.setPageInfo(
        (await import(`../config/static/${this.config.name}.json`)).default
      )
    }

    return this.pageInfo
  }

  getKnownPaths = async (): Promise<string[]> => {
    const pageInfo = await this.getPageInfo()
    if (!pageInfo) {
      return []
    }

    return pageInfo.knownPaths
  }

  setConfig = (config: LoadedConfig) => {
    runInAction(() => {
      this.config = config
    })

    this.rootStore.navigation.setCurrentConfigInSession(config.name)
  }

  includes = async (pageName: string, path: string) =>
    (await this.getPaths(pageName)).includes(path)

  findFirstPageWithPath = async (path: string) => {
    for (const pageName of getDisplayedPageNames(this.rootStore)) {
      // eslint-disable-next-line no-await-in-loop
      if (await this.includes(pageName, path)) {
        return pageName
      }
    }

    return undefined
  }

  isOnTermsAndConditionsPage = () => {
    const {
      navigation: { currentPageInSession }
    } = this.rootStore

    if (!currentPageInSession) {
      return false
    }

    return this.includes(currentPageInSession, 'common.hasAcceptedTerms')
  }

  getPageIndex = (pageName: string) =>
    getDisplayedPageNames(this.rootStore).indexOf(pageName)

  getCurrentIndex = () => {
    const {
      navigation: { currentPageInSession }
    } = this.rootStore

    if (!currentPageInSession) {
      return -1
    }

    return this.getPageIndex(currentPageInSession)
  }

  getFirstDisplayedPageName = () => this.getVisiblePageNameAt(0)

  getLastDisplayedPageName = () => {
    const pageNames = getDisplayedPageNames(this.rootStore)

    return pageNames[pageNames.length - 1]
  }

  isFirstDisplayedPage = (pageName: string | null) =>
    pageName === this.getFirstDisplayedPageName()

  isLastDisplayedPage = (pageName: string | null) =>
    pageName === this.getLastDisplayedPageName()

  getPaths = async (pageName: string) => {
    if (!pageName) {
      return []
    }

    const pageInfo = await this.getPageInfo()
    if (!pageInfo || !pageInfo.paths[pageName]) {
      return []
    }

    return pageInfo.paths[pageName]
  }

  unsetConfiguration = () => {
    this.config = { version: false }
    this.pageInfo = null
    this.previousPageName = null
    this.nextPageName = null
  }

  setConfiguration = async (config: LoadedConfig) => {
    await this.setConfig(config)

    logSuccess(`[Config loaded] ${config.name} v${config.version}`)

    executeAsync(() => {
      Bugsnag.addMetadata('taurineConfig', getConfigMetadata(config))

      setUserProperties({
        [UserProperty.TaurineConfiguration]: config.name
      })
    })
  }

  changeConfiguration = async (
    configName: ConfigName,
    navigate: NavigateFunction
  ) => {
    const {
      setLoadingMode,
      traversal: {
        data: {
          system: { advertisementId }
        }
      }
    } = this.rootStore

    setLoadingMode(LoadingMode.Blocking)

    try {
      await this.changeConfigurationHandler(
        await getConfig(configName),
        navigate
      )
    } catch (error) {
      logAndTrackError(
        new ConfigurationError('Could not change config', advertisementId)
      )
    } finally {
      setLoadingMode(LoadingMode.None)
    }
  }

  private changeConfigurationHandler = async (
    config: LoadedConfig,
    navigate: NavigateFunction
  ) => {
    const response = await this.rootStore.traversal.put()

    this.rootStore.fieldErrors.setFieldErrorsFromAxiosResponse(response)

    const firstPageWithErrors =
      await this.rootStore.fieldErrors.findFirstErrorPage(
        this.getCurrentIndex() + 1
      )

    if (firstPageWithErrors) {
      return
    }

    this.unsetConfiguration()

    await this.setConfiguration(config)

    if (config.onLoad) {
      config.onLoad(this.rootStore)
    }

    this.rootStore.setInitialEntryPoint(EntryPoint.FURTHEST)

    const command = new LoadEntryPointCommand()

    await command.execute(this.rootStore, navigate)

    scrollToTop(this.rootStore.isInIframe)
  }

  removeErrorsForNextPage = () => {
    const {
      navigation: { currentPageInSession }
    } = this.rootStore

    if (!this.config.version) {
      return
    }

    if (!currentPageInSession) {
      logAndTrackError(
        new PageError('The current page is not set', this.config, 'Not set')
      )
      return
    }

    const nextPage = getNextPageName(currentPageInSession, this.rootStore)

    if (nextPage) {
      const nextPageNotLeadPage =
        nextPage !== 'loadingLead' ? nextPage : 'contact' // skip leadpage as next page as it has no paths
      this.rootStore.fieldErrors.removePagePathValuesWithErrors(
        nextPageNotLeadPage
      )
      this.rootStore.fieldErrors.removePageErrors(nextPageNotLeadPage)
    }
  }

  getVisiblePageNameAt = (index: number) => {
    if (!this.config.version) {
      return ''
    }

    const pageName = getDisplayedPageNames(this.rootStore)[index]
    if (!pageName) {
      throw new ConfigError(`No page at index "${index}"`, this.config)
    }

    return pageName
  }

  // eslint-disable-next-line class-methods-use-this
  scrollToFirstError = (isInIframe: boolean) => {
    const firstErrorWrapper = getFirstErrorWrapper()
    if (firstErrorWrapper) {
      scrollToElement(firstErrorWrapper, isInIframe)
    }
  }

  getProgress = async (pageName: string) => {
    if (!this.config.version) {
      return MINIMUM_PROGRESS
    }

    const pageNames = Object.keys(this.config.pages)

    if (!pageNames.includes(pageName)) {
      return MINIMUM_PROGRESS
    }

    const indexHalf = pageNames.length * 0.5
    const minimumPathCount = 25

    let overflow = 0
    const pathCounts: Record<string, number> = await pageNames.reduce(
      async (previous, current, index) => {
        let count = (await this.getPaths(current)).filter((path) => {
          /**
           * At the moment countable paths are multiple paths in the json file.
           * Until we change them to something with a placeholder, like 'debtors.primary.activeLoans[n].type',
           * we have to filter them out here. Otherwise, the percentage is skewed.
           */
          const match = /\d/.exec(path)

          return match === null || +match[0] === 0
        }).length

        /**
         * To artificially increase the progress for the first half of the form,
         * we make sure that the fields per page are at least `minimumPathCount`.
         * To make up for added fields we have to add them to the total count later.
         * That's why we have an `overflow`. This value gets added to the total field count.
         */
        if (index <= indexHalf) {
          overflow += Math.max(0, minimumPathCount - count)
          count = Math.max(count, minimumPathCount)
        }
        const prev = await previous
        return {
          ...prev,
          [current]: count
        }
      },
      {}
    )

    const countAll = Object.values(pathCounts).reduce(
      (previous, current) => previous + current,
      overflow
    )

    let percentage = MINIMUM_PROGRESS
    for (const [name, count] of Object.entries(pathCounts)) {
      if (name === pageName) {
        break
      }

      const progress = Math.ceil((count / countAll) * 100)

      percentage = Math.min(
        percentage + Math.min(progress, MAXIMUM_PROGRESS),
        99 // 100% = click goToFinal CTA
      )
    }

    return percentage
  }

  checkUserAgeAndRedirect = async (navigate: NavigateFunction) => {
    let tooOld = false

    const {
      traversal: {
        data: {
          debtors: {
            primary: {
              personal: { birthday }
            }
          }
        }
      },
      navigation: {
        currentPageInSession,
        getPreviousNavigation,
        goToPreviousPage,
        goToNextPage
      }
    } = this.rootStore

    const parsedBirthdate = parse(birthday, 'yyyy-MM-dd', new Date())
    const debtorsAge = differenceInCalendarYears(new Date(), parsedBirthdate)

    if (debtorsAge > 60) {
      const page = await this.getPage(currentPageInSession || '')
      const currentPage = Object.keys(page)

      if (currentPage.length === 1) {
        const pages = getDisplayedPages(this.rootStore).map(
          ([pageName]) => pageName
        )
        const previousPage = getPreviousNavigation()
        if (
          previousPage &&
          currentPageInSession &&
          pages.indexOf(previousPage) > pages.indexOf(currentPageInSession)
        ) {
          await goToPreviousPage(navigate)
        } else {
          await goToNextPage(navigate)
        }
      }
      tooOld = true
    }

    return tooOld
  }

  hideProgressIndicatorOnPages = (pages: string[]) => {
    this.hiddenProgressIndicatorPages = pages
  }

  shouldHideProgressIndicator = (pageName?: string) => {
    const {
      navigation: { currentPageInSession }
    } = this.rootStore

    const page = pageName || currentPageInSession

    if (!page) {
      return true
    }

    return this.hiddenProgressIndicatorPages.includes(page)
  }

  getAllPathsOnPage = async () => {
    const paths = await this.getPage(
      this.rootStore.navigation.currentPageInSession
    )
    if (!paths) {
      return []
    }
    const allPathObjects = Object.values(paths)
    return flatten(allPathObjects)
  }
}

export default PageStore
