const clue = {
  occupation: {
    occupationGroup:
      'Wichtig: Wählen Sie eine Berufsgruppe aus der Liste aus, die Ihren aktuellen Beruf möglichst genau beschreibt. Dies kann sich positiv auf die Konditionen Ihres Kredits auswirken.',
    profession:
      'Die Angabe dient der Bank zum Abgleich mit Ihrem Einkommensnachweis. Ihr Arbeitgeber wird über Ihre Kreditabsichten zu keinem Zeitpunkt von Finanzcheck informiert.',
    isTemporary:
      'Wichtig: Steht auf Ihrer Gehaltsabrechnung ein Austrittsdatum (Renteneintritt ausgenommen), dann liegt aus Sicht der Bank ein befristetes Arbeitsverhältnis vor. Bei einem unbefristeten Arbeitsverhältnis bieten Banken meist günstigere Zinssätze an.',
    workingTime:
      'Bitte geben Sie hier an, wenn Sie aktuell in Kurzarbeit sind. Dies ist wichtig, damit Banken Ihnen passende Angebote machen können.'
  },
  income: {
    childSupport:
      'Falls gerichtlich festgelegt ist, dass Sie Unterhalt für Kinder erhalten, geben Sie bitte die Gesamtsumme dieser monatlichen Unterhaltseinkünfte an.'
  },
  expenses: {
    privateHealthInsuranceRate:
      'Nennen Sie uns bitte den monatlichen Beitrag für Ihre private Krankenversicherung, den Sie als Arbeitnehmer selbst zahlen. Wichtig: Nicht gemeint sind hier private Zusatzversicherungen für gesetzlich Versicherte.',
    cards:
      'Wer eine Kredit- oder EC-Karte besitzt, hat in der Vergangenheit bereits seine Bonität gegenüber einer Bank belegen können. Dies wirkt sich bei einer Bonitätsprüfung für einen Kredit positiv für Sie aus.'
  },
  activeLoans:
    'Wichtig: Baufinanzierungen zählen hier NICHT dazu. Bitte geben Sie hier vorhandene Ratenkredite, Dispos, Leasing und Rahmenkredite an.',
  activeLoan: {
    isDebtConversionWanted:
      'Durch eine Umschuldung können Sie häufig Ihre Ausgaben senken. Das spart Ihnen Geld und verbessert Ihre Haushaltsrechnung – und damit Ihre Bonität. Deshalb vergeben Banken hier oft besonders günstige Zinssätze.',
    iban: 'Ihre 22-stellige Kreditkontonummer finden Sie in Ihrem Online Banking/Ihrer Umsatzanzeige in der Detailansicht der abgebuchten Rate.'
  },
  loanPayout: {
    iban: 'Auf Vergleichsportalen wird immer Ihre IBAN benötigt, um persönliche Kreditangebote erstellen zu können.',
    bankCode:
      'Auf Vergleichsportalen wird immer Ihre Bankverbindung benötigt, um persönliche Kreditangebote erstellen zu können.'
  }
}

export default clue
