import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import { isRootObject, RootObject } from 'interfaces/ApolloResponse.interface'
import { logWarning } from 'utils/log'
import { getStorage } from 'utils/storage'
import { HttpStatusCode } from 'utils/url/status'
import { Interceptor } from '.'

const ACCESS_TOKEN_KEY = 'ffg_access'

const getAccessToken = () =>
  getStorage('localStorage').getItem(ACCESS_TOKEN_KEY)

const setAccessToken = (token: string) =>
  getStorage('localStorage').setItem(ACCESS_TOKEN_KEY, token)

export default class ApolloAuth implements Interceptor {
  private accessToken: string | null = null

  constructor() {
    this.accessToken = getAccessToken()
  }

  public static create() {
    return new ApolloAuth()
  }

  public apply = (instance: AxiosInstance) => {
    instance.interceptors.request.use((config: any) => {
      if (!this.accessToken) {
        return config
      }

      return {
        ...config,
        headers: {
          ...config.headers,
          'X-AccessToken': this.accessToken
        }
      }
    })

    instance.interceptors.response.use(
      (response: AxiosResponse<RootObject | { data: unknown }>) => {
        if (!response) {
          return Promise.reject()
        }

        if (isRootObject(response.data) && response.data?.result?.accessToken) {
          this.accessToken = response.data.result.accessToken
          setAccessToken(this.accessToken)
        }

        return response
      },
      async (error: AxiosError) => {
        if (!error.response) {
          return Promise.reject(error)
        }

        if (error.response.status === HttpStatusCode.Unauthorized) {
          logWarning(`AccessToken expired or invalid -> ${this.accessToken}`)
        }

        return Promise.reject(error)
      }
    )

    return instance
  }
}
