/**
 * @see http://ecma-international.org/ecma-262/6.0/#sec-number.max_safe_integer
 */
const FALLBACK_MAX_SAFE_INTEGER = 9007199254740991

export const MIN_SAFE_INTEGER =
  Number.MIN_SAFE_INTEGER || -FALLBACK_MAX_SAFE_INTEGER

export const MAX_SAFE_INTEGER =
  Number.MAX_SAFE_INTEGER || FALLBACK_MAX_SAFE_INTEGER

export interface ConversionResult {
  complete: boolean
  value: number | undefined
}

export function getNumberWithoutComma(value: string): string {
  const match = value.match(/^[^,]+/)

  if (match === null) {
    return ''
  }

  return match[0]
}

export function convertToInteger(value: string): ConversionResult {
  if (value.length === 0) {
    return {
      complete: true,
      value: undefined
    }
  }

  const cleanedNumber = getNumberWithoutComma(value).replace(/\D/g, '')
  if (cleanedNumber.length === 0) {
    return {
      complete: false,
      value: undefined
    }
  }

  const converted = parseInt(`${parseFloat(cleanedNumber)}`, 10)

  return {
    complete: `${converted}`.length === value.length,
    value: converted
  }
}

function removeWhitespace(formatted: string) {
  return formatted.replace(/\s([^\s]*)$/, '$1')
}

const CurrencyFormatter = (fractionDigits = 0, currencyDisplay = 'symbol') =>
  new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    currencyDisplay,
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits
  })

export function formatToDeAmount(
  value: string | number,
  fractionDigits = 0,
  withWhiteSpace = false,
  suffix = '',
  currencyDisplay = 'symbol'
): string {
  const currencyFormat = CurrencyFormatter(
    fractionDigits,
    currencyDisplay
  ).format(+value)

  const currencyWithWhiteSpaceDecided = withWhiteSpace
    ? currencyFormat
    : removeWhitespace(currencyFormat)

  const currencyWithSuffixDecided = currencyWithWhiteSpaceDecided.concat(suffix)

  return currencyWithSuffixDecided
}

const PercentageFormatter = new Intl.NumberFormat('de-DE', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})

export function formatToPercentage(value: string | number): string {
  return removeWhitespace(PercentageFormatter.format(+value).replace(' ', ''))
}

export const formatToPercentageNonIntl = (value: string | number): string =>
  (+value).toFixed(2).replace('.', ',').concat('%')

const IntegerFormatter = new Intl.NumberFormat('de-DE', {
  maximumFractionDigits: 0
})

export function formatToInteger(value: string | number): string {
  return IntegerFormatter.format(+value)
}

const DecimalFormatter = new Intl.NumberFormat('de-DE', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})

export function formatToDecimal(value: string | number): string {
  return DecimalFormatter.format(+value)
}

export function isMobileNumberValidOnGreen(value: string): boolean {
  if (value.length > 7 && value.length < 16) {
    return true
  }

  return false
}
