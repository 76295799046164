import { MINIMUM_PROGRESS } from 'components/ProgressIndicator'
import { useAsyncEffect } from 'hooks/useAsyncEffect'
import { observer } from 'mobx-react'
import {
  createContext,
  memo,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState
} from 'react'
import { useStores } from 'stores/utils/hooks/useStores'
import { executeAsync } from 'utils/performance'
import { setGlobalEventProperty } from 'utils/tracking/amplitude'
import { EventProperty } from 'utils/tracking/amplitude/amplitude.interface'

export interface TouchedInput {
  last: string | undefined
  next: string | undefined
}

interface ProgressStateContextType {
  percentage: number
  touchedInput: TouchedInput
  smavaIndex?: number
}

interface ProgressDispatchContextType {
  setTouchedInput: (touchedInput: TouchedInput) => void
}

export const ProgressStateContext = createContext<ProgressStateContextType>({
  percentage: MINIMUM_PROGRESS,
  touchedInput: { last: undefined, next: undefined }
})

function throwProgressProviderIsMissing() {
  throw new Error('The ProgressDispatchContext was not found!')
}

export const ProgressDispatchContext =
  createContext<ProgressDispatchContextType>({
    setTouchedInput: throwProgressProviderIsMissing
  })

const ProgressProvider = observer(
  ({ children }: PropsWithChildren<unknown>) => {
    const {
      page: { getProgress, getPageIndex, config },
      navigation: { currentPageInSession }
    } = useStores()

    const [percentage, setPercentage] = useState(MINIMUM_PROGRESS)

    useAsyncEffect(async () => {
      if (!currentPageInSession) {
        return
      }
      const progress = await getProgress(currentPageInSession)

      setPercentage(progress)
    }, [getProgress, currentPageInSession, config])

    const smavaIndex = useMemo(() => {
      if (!currentPageInSession) {
        return -1
      }

      return getPageIndex(currentPageInSession) - 1
    }, [currentPageInSession, getPageIndex])

    const [touchedInput, setTouchedInput] = useState<TouchedInput>({
      last: undefined,
      next: undefined
    })

    const onInputTouched = useCallback(
      (touched: TouchedInput) => {
        if (
          touchedInput.last === touched.last &&
          touchedInput.next === touched.next
        ) {
          return
        }

        setTouchedInput(touched)

        executeAsync(() => {
          setGlobalEventProperty(EventProperty.LastInputTouched, touched.last)
          setGlobalEventProperty(EventProperty.NextInputUntouched, touched.next)
        })
      },
      [touchedInput]
    )

    const state = useMemo(
      () => ({
        percentage,
        touchedInput,
        smavaIndex
      }),
      [percentage, touchedInput, smavaIndex]
    )

    const dispatch = useMemo(
      () => ({ setTouchedInput: onInputTouched }),
      [onInputTouched]
    )

    return (
      <ProgressStateContext.Provider value={state}>
        <ProgressDispatchContext.Provider value={dispatch}>
          {children}
        </ProgressDispatchContext.Provider>
      </ProgressStateContext.Provider>
    )
  }
)

export default memo(ProgressProvider)
