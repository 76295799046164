import { differenceInYears, format, parse } from 'date-fns'

export const yearsToMilliseconds = (years: number): number =>
  years * 31556952000 // 31556952000 = 1 year in milliseconds
export const monthsToMilliseconds = (month: number): number =>
  month * 2629746000 // 2629746000 = 1 month in milliseconds
export const daysToMilliseconds = (days: number): number => days * 86400000 // 86400000 = 1 day in milliseconds

export const formatDate = (date: Date, formatString = 'yyyy-MM-dd') => {
  try {
    return format(date, formatString)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('formatDate', error)

    return ''
  }
}

export const getDate = (dateString: string) => {
  if (dateString.length === 0) {
    return null
  }

  try {
    return new Date(dateString)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('getDate', error)

    return null
  }
}

// TODO We should consider moving getYearOptions() to Willy
/**
 * Get options for year select box
 * @param minYear Lower date limit
 * @param maxYear Upper date limit
 */
export const getYearOptions = (
  minYear: number,
  maxYear: number,
  reverse = true
) => {
  const years = [...Array.from({ length: maxYear - minYear + 1 })].map(
    (_, index) => {
      const year = minYear + index

      return { label: `${year}`, value: minYear + index }
    }
  )

  return reverse ? years.reverse() : years
}

export type TimeDifference = {
  year?: number
  month?: number
  day?: number
}

export const dateIsOlderThan = (
  date: Date | string,
  timeDifference: TimeDifference
): boolean => {
  const dateToCheck = date instanceof Date ? date : new Date(date)
  const { year = 0, month = 0, day = 0 } = timeDifference

  const differenceInMilliseconds =
    yearsToMilliseconds(year) +
    monthsToMilliseconds(month) +
    daysToMilliseconds(day)

  const dateToCompareToInMilliseconds =
    new Date().getTime() - differenceInMilliseconds

  return dateToCheck!.getTime() < dateToCompareToInMilliseconds
}

export const removeDayFromDateFormat = (date: string) =>
  date.replace(/^(\d{4})-(\d{2})-(\d{2})/, '$1-$2')

export type SplittedDateFormat = Record<string, string>

export const DATE_SEPARATOR_REGEX = /\.|-|\//g

export function getSeparator(formatString: string) {
  const separatorIndex = formatString.search(DATE_SEPARATOR_REGEX)
  if (separatorIndex === -1) {
    return undefined
  }

  return formatString[separatorIndex]
}

export function splitWithFormat(
  dateString: string,
  formatString: string
): { separator: string; splitted: SplittedDateFormat } | undefined {
  const separator = getSeparator(formatString)

  if (!separator) {
    return undefined
  }

  const splittedFormat = formatString.split(separator)
  const splitted = dateString.split(separator)

  return {
    separator,
    splitted: splittedFormat.reduce(
      (previous, current, index) => ({
        ...previous,
        [current]: splitted[index] || ''
      }),
      {}
    )
  }
}

export function isYearLast(formatString: string) {
  return /yyyy$/.test(formatString)
}

export function adjustYear(
  dateString: string,
  formatString: string,
  lowerLimit = 15
) {
  if (!isYearLast(formatString)) {
    return dateString
  }

  const formatter = splitWithFormat(dateString, formatString)
  if (!formatter) {
    return dateString
  }

  const values = Object.values(formatter.splitted)
  const year = values[values.length - 1]
  if (year.length !== 2) {
    return dateString
  }

  const currentYear = format(new Date(), 'yyyy')
  const yearNumber = +year
  const currentYearNumber = +currentYear.substring(2)
  const currentCentury = +currentYear.substring(0, 2)
  const previousCentury = currentCentury - 1

  if ([previousCentury, currentCentury].includes(yearNumber)) {
    return dateString
  }

  const fullYear = `${
    yearNumber <= currentYearNumber + lowerLimit
      ? currentCentury
      : previousCentury
  }${year}`

  values[values.length - 1] = fullYear

  return values.join(formatter.separator)
}

export function addLeadingZero(
  formatValue: string,
  dateValue: string,
  force = false
) {
  if (dateValue.length === 0 || dateValue.length === formatValue.length) {
    return dateValue
  }

  if (
    formatValue.includes('d') &&
    dateValue.length < formatValue.length &&
    (force || !['0', '1', '2', '3'].includes(dateValue[0]))
  ) {
    return `0${dateValue}`
  }

  if (
    formatValue.includes('M') &&
    dateValue.length < formatValue.length &&
    (force || !['0', '1'].includes(dateValue[0]))
  ) {
    return `0${dateValue}`
  }

  return dateValue
}

export function adjustFormat(dateString: string, formatString: string) {
  const formatter = splitWithFormat(dateString, formatString)
  if (
    !formatter ||
    Object.values(formatter.splitted).some((part) => part.length === 0)
  ) {
    return dateString
  }

  return adjustYear(
    Object.entries(formatter.splitted)
      .map(([formatValue, dateValue]) =>
        addLeadingZero(formatValue, dateValue, true)
      )
      .join(formatter.separator),
    formatString
  )
}

export function getToday() {
  const dateToday = new Date()

  dateToday.setHours(0, 0, 0, 0)

  return dateToday
}

export function getDifferenceInYearsForDateStrings(
  left: string,
  right: string
): number {
  const leftDate = getDate(left)
  const rightDate = getDate(right)

  if (!leftDate || !rightDate) {
    return 0
  }

  return differenceInYears(rightDate, leftDate)
}

export const getAge = (birthday: string): number => {
  const parsedBirthdate = parse(birthday, 'yyyy-MM-dd', new Date())
  const debtorsAge = differenceInYears(new Date(), parsedBirthdate)
  return debtorsAge
}
