import OptimizelyError from 'error/optimizelyError'
import { logAndTrackError } from 'utils/log/logAndTrackError'

const optimizelyErrorHandler = {
  /**
   * handleError
   *
   * Function which gets called when an error is thrown in the SDK
   * @param {Object} error - error object
   * @param {String} error.message - message of the error
   * @param {String} error.stack - stack trace for the error
   */
  handleError: (error: Error) => {
    logAndTrackError(new OptimizelyError(error?.message))
  }
}

export default optimizelyErrorHandler
