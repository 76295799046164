enum Position {
  EMPLOYEE = 'EMPLOYEE',
  EMPLOYEE_PUBLIC_SERVICE = 'EMPLOYEE_PUBLIC_SERVICE',
  WORKER = 'WORKER',
  CRAFTSMAN = 'CRAFTSMAN',
  MANAGER = 'MANAGER',
  PENSIONER = 'PENSIONER',
  RETIREE = 'RETIREE',
  EMPLOYEE_IN_PARENTAL_LEAVE = 'EMPLOYEE_IN_PARENTAL_LEAVE',
  EMPLOYEE_REDUCED_HOURS = 'EMPLOYEE_REDUCED_HOURS',
  EMPLOYEE_TEMPORARY_WORK = 'EMPLOYEE_TEMPORARY_WORK',
  EMPLOYEE_ABROAD = 'EMPLOYEE_ABROAD',
  EMPLOYEE_MINIJOB = 'EMPLOYEE_MINIJOB',
  EMPLOYEE_DOCTOR = 'EMPLOYEE_DOCTOR',
  EMPLOYEE_SICK = 'EMPLOYEE_SICK',
  WORKER_PUBLIC_SERVICE = 'WORKER_PUBLIC_SERVICE',
  WORKER_PARENTAL_LEAVE = 'WORKER_PARENTAL_LEAVE',
  UNEMPLOYED = 'UNEMPLOYED',
  APPRENTICE = 'APPRENTICE',
  OFFICER_LOWER_SERVICE = 'OFFICER_LOWER_SERVICE',
  OFFICER_UPPER_SERVICE = 'OFFICER_UPPER_SERVICE',
  OFFICER_HIGHER_SERVICE = 'OFFICER_HIGHER_SERVICE',
  OFFICER_MIDDLE_SERVICE = 'OFFICER_MIDDLE_SERVICE',
  BERUFSSOLDAT = 'berufssoldat',
  HOUSEWIFE = 'HOUSEWIFE',
  HELPER = 'HELPER',
  PUPIL = 'PUPIL',
  SELF_EMPLOYED_FREELANCER = 'SELF_EMPLOYED_FREELANCER',
  SELF_EMPLOYED_CEO = 'SELF_EMPLOYED_CEO',
  SELF_EMPLOYED_BUSINESSMAN = 'SELF_EMPLOYED_BUSINESSMAN',
  SOLDIER = 'SOLDIER',
  SOLDIER_TEMPORARY = 'SOLDIER_TEMPORARY',
  STUDENT = 'STUDENT',
  BOARD_MEMBER_OR_CEO = 'BOARD_MEMBER_OR_CEO',
  DRAFTEE = 'DRAFTEE'
}
export default Position
