export const loanSelections = [
  {
    min: 500,
    max: 15000,
    step: 250,
    categories: [
      'OTHER',
      'PRE_OWNED_CAR',
      'REFINANCING',
      'RENOVATION',
      'REAL_ESTATE_FINANCING'
    ],
    isMortgage: false
  },
  {
    min: 16000,
    max: 120000,
    step: 1000,
    categories: [
      'OTHER',
      'PRE_OWNED_CAR',
      'REFINANCING',
      'RENOVATION',
      'REAL_ESTATE_FINANCING'
    ],
    isMortgage: false
  },
  {
    min: 130000,
    max: 400000,
    step: 10000,
    categories: ['REAL_ESTATE_FINANCING', 'RENOVATION'],
    isMortgage: true
  },
  {
    min: 420000,
    max: 500000,
    step: 20000,
    categories: ['REAL_ESTATE_FINANCING', 'RENOVATION'],
    isMortgage: true
  },
  {
    min: 550000,
    max: 600000,
    step: 50000,
    categories: ['REAL_ESTATE_FINANCING', 'RENOVATION'],
    isMortgage: true
  },
  {
    min: 700000,
    max: 1000000,
    step: 100000,
    categories: ['REAL_ESTATE_FINANCING', 'RENOVATION'],
    isMortgage: true
  },
  {
    min: 2000000,
    max: 5000000,
    step: 1000000,
    categories: ['REAL_ESTATE_FINANCING', 'RENOVATION'],
    isMortgage: true
  }
]
