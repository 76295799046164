import { memo } from 'react'

import { observer } from 'mobx-react'

import Button from '@mui/material/Button'
import SaveIcon from '@mui/icons-material/Save'

interface SaveButtonProps {
  onClick: () => void
  variant: 'outlined' | 'contained'
  isSaveButtonEnabled: boolean
}

const SaveButton = observer(
  ({ onClick, variant, isSaveButtonEnabled }: SaveButtonProps) => (
    <Button
      size="large"
      fullWidth
      id="save-button"
      name="save-button"
      variant={variant}
      color="primary"
      disabled={!isSaveButtonEnabled}
      startIcon={<SaveIcon />}
      onClick={onClick}
    >
      Speichern
    </Button>
  )
)

export default memo(SaveButton)
