const countryShort = [
  { label: 'Belgien', value: 'BE' },
  { label: 'Deutschland', value: 'DE' },
  { label: 'Dänemark', value: 'DK' },
  { label: 'Frankreich', value: 'FR' },
  { label: 'Luxemburg', value: 'LU' },
  { label: 'Niederlande', value: 'NL' },
  { label: 'Österreich', value: 'AT' },
  { label: 'Polen', value: 'PL' },
  { label: 'Schweiz', value: 'CH' },
  { label: 'Tschechische Republik', value: 'CZ' },
  { label: 'Anderes Land', value: 'OTHER' }
]
export default countryShort
