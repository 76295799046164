import { range } from 'utils/array'
import InputSkeleton from './InputSkeleton'
import HeaderSkeleton from './HeaderSkeleton'

interface MultipleInputSkeletonProps {
  count: number
}

const MultipleInputSkeleton = ({ count }: MultipleInputSkeletonProps) => (
  <>
    <HeaderSkeleton />
    {range(count).map((value) => (
      <InputSkeleton key={`skeleton-input-${value}`} />
    ))}
  </>
)

export default MultipleInputSkeleton
