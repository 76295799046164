import Grid from '@mui/material/Grid'
import ButtonSkeleton from './ButtonSkeleton'

const NavigationButtonsSkeleton = () => {
  return (
    <Grid container spacing={1} direction={{ xs: 'column-reverse', sm: 'row' }}>
      <Grid item sm={4}>
        <ButtonSkeleton />
      </Grid>
      <Grid item sm={8}>
        <ButtonSkeleton />
      </Grid>
    </Grid>
  )
}

export default NavigationButtonsSkeleton
