import { FunctionComponent, memo, useEffect } from 'react'
import { FintecState } from '@finanzcheck/ti-fts-widget'
import { Banner, BannerType } from '@finanzcheck/teal-components'
import { Box } from '@mui/material'
import useEmotionStyles from 'hooks/useEmotionStyles'
import {
  RegisteredEventName,
  broadcastToParent
} from '@finanzcheck/catalyst-pollard'
import { DACEvent } from 'components/DigitalAccountCheck'
import { COOKIE_KEY_FCID, getCookie } from 'utils/cookie'
import { getOptimizelyTrackingAttribute } from 'utils/tracking'
import { useData } from 'stores/utils/hooks/useData'
import { trackInAmplitude } from 'utils/tracking/amplitude'
import { AmplitudeEvent } from 'utils/tracking/amplitude/amplitude.interface'

import DacHandIllustration from '../../../assets/images/dacInProgress.svg'
import DacHandIllustrationSmava from '../../../assets/images/dacInProgress_smava.svg'

const styles = () => ({
  dacWidgetBox: {
    '.xs2a-fts-logo': {
      display: 'none !important'
    }
  }
})

interface Props {
  state: FintecState
  setState?: (state: Extract<FintecState, 'agreed' | 'disagreed'>) => void
  widget: JSX.Element | null
  insertDACHandIcon?: boolean
  dacSmavaHandIcon?: boolean
  isSuccessMessageHidden?: boolean
  bankCodeLabel?: string
  bankCodePlaceholder?: string
}

const DacWidget: FunctionComponent<Props> = ({
  state,
  setState,
  widget,
  insertDACHandIcon,
  dacSmavaHandIcon,
  isSuccessMessageHidden,
  bankCodeLabel,
  bankCodePlaceholder
}) => {
  const { dacWidgetBox } = useEmotionStyles(styles)
  const { data } = useData()
  const trackWithOrigin = (event: AmplitudeEvent, payload = {}) => {
    trackInAmplitude(event, { origin: 'taurine', ...payload })
  }

  const trackDacStepView = (
    event: DACEvent | null,
    amplitudeEvent: AmplitudeEvent
  ) => {
    trackWithOrigin(amplitudeEvent)

    if (event) {
      broadcastToParent({
        eventName: RegisteredEventName.tracking,
        data: {
          event,
          context: {
            Optimizely: { userId: getCookie(COOKIE_KEY_FCID) },
            ...getOptimizelyTrackingAttribute(data)
          }
        }
      })
    }
  }

  useEffect(() => {
    if (setState && state === 'initial') {
      trackWithOrigin(AmplitudeEvent.OnDacStateChange, {
        state: 'agreed' as FintecState
      })
      broadcastToParent({
        eventName: RegisteredEventName.tracking,
        data: {
          event: DACEvent.STATE_CHANGE_AGREED,
          context: {
            Optimizely: { userId: getCookie(COOKIE_KEY_FCID) },
            ...getOptimizelyTrackingAttribute(data)
          }
        }
      })

      setState('agreed')
    }
  }, [setState, state, data])

  useEffect(() => {
    let isAccessConfirmationStepViewEventSent = false
    let isRegistrationSuccessfulStepViewEventSent = false

    const trackDacStepViews = (
      isAccessConfirmationStepTitleVisible: boolean | null,
      isRegistrationSuccessfulStepTitleVisible: boolean | null
    ) => {
      if (
        isAccessConfirmationStepTitleVisible &&
        !isAccessConfirmationStepViewEventSent
      ) {
        trackDacStepView(null, AmplitudeEvent.OnDacStepViewAccessConfirmation)
        isAccessConfirmationStepViewEventSent = true
      }

      if (
        isRegistrationSuccessfulStepTitleVisible &&
        !isRegistrationSuccessfulStepViewEventSent
      ) {
        trackDacStepView(
          null,
          AmplitudeEvent.OnDacStepViewRegistrationSuccessful
        )
        isRegistrationSuccessfulStepViewEventSent = true
      }
    }

    const callback = (mutationsList: MutationRecord[]) => {
      if (mutationsList.length > 0) {
        const headersList = document.querySelectorAll('h3')
        const headersArray = Array.from(headersList)
        const isAccessConfirmationStepTitleVisible = headersArray.some(
          (header) => header.innerHTML === 'Bestätigung des Kontozugriffs'
        )
        const isRegistrationSuccessfulStepTitleVisible = headersArray.some(
          (header) => header.innerHTML === 'Anmeldung erfolgreich'
        )

        trackDacStepViews(
          isAccessConfirmationStepTitleVisible,
          isRegistrationSuccessfulStepTitleVisible
        )

        const bankCodeLabelHtml = document.querySelector(
          'label[for="XS2A-bank_code"]'
        )
        const bankCodeInputHtml = document.querySelector('#XS2A-bank_code')

        if (bankCodeLabelHtml && bankCodeLabel) {
          if (bankCodeLabelHtml.innerHTML !== bankCodeLabel) {
            bankCodeLabelHtml.innerHTML = bankCodeLabel
          }
        }

        if (bankCodeInputHtml && bankCodePlaceholder) {
          if (
            bankCodeInputHtml.getAttribute('placeholder') !==
            bankCodePlaceholder
          ) {
            bankCodeInputHtml.setAttribute('placeholder', bankCodePlaceholder)
          }
        }

        if (insertDACHandIcon) {
          const paraList = document.querySelectorAll('p')
          const paraArray = Array.from(paraList)

          const isBankingParaVisible = paraArray.find((header) => {
            if (
              header.innerHTML.trim() ===
              'Bitte geben Sie den Kontozugriff in ihrer Banking App frei.'
            ) {
              return header
            }
            return false
          })
          const handImage = document.getElementById('dacHandId')
          if (isBankingParaVisible && !handImage) {
            const elem = document.createElement('img')
            if (dacSmavaHandIcon) {
              elem.setAttribute('src', DacHandIllustrationSmava)
            } else {
              elem.setAttribute('src', DacHandIllustration)
            }
            elem.setAttribute('id', 'dacHandId')
            elem.setAttribute('height', '150')
            elem.setAttribute('width', '230')
            elem.setAttribute('alt', 'Flower')
            isBankingParaVisible.insertAdjacentElement('afterend', elem)
            elem.style.display = 'flex'
            elem.style.margin = '0 auto'
          }
        }
      }
    }

    const taurineFintecWidgetObserver = new MutationObserver(callback)
    const taurineFintecWidget = document.querySelector('#taurine-fintec-widget')
    const config = { childList: true, subtree: true }

    if (taurineFintecWidget) {
      taurineFintecWidgetObserver.observe(taurineFintecWidget, config)
    }

    return () => {
      taurineFintecWidgetObserver.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (state === 'success') {
    if (isSuccessMessageHidden) {
      return null
    }

    return (
      <Box mb={2} data-testid="dac-widget-success">
        <Banner
          type={BannerType.SUCCESS}
          isIconVersionOutline
          title="Super"
          desc="Sie haben den Digitalen Kontoauszug bereits erfolgreich durchgeführt!"
        />
      </Box>
    )
  }

  return (
    <Box mb={1} mx={-2.5} data-testid="dac-widget" className={dacWidgetBox}>
      {widget}
    </Box>
  )
}

export default memo(DacWidget)
