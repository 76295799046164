import { isObservableArray, observable } from 'mobx'
import { Patch, Data } from '../../interfaces/ApolloResponse.interface'
import additionalPathMap, { SET_OP } from './additionalPatchMap'
import { FILTER_EMOJI_REGEX } from 'constants/components'

function removeFalsyValues<T>(list: Array<T | undefined>): T[] {
  return list.filter(Boolean) as T[]
}

export function getSafeValue<T>(value: T | undefined) {
  if (isObservableArray(value)) {
    return observable(removeFalsyValues<T>(value))
  }

  if (Array.isArray(value)) {
    return removeFalsyValues<T>(value as Array<T | undefined>)
  }

  if (typeof value === 'string') {
    const nonEmojiValue = value.replace(FILTER_EMOJI_REGEX, '')
    return nonEmojiValue
  }

  return value
}

export function generateSetPatch<T = unknown>(path: string, value: T): Patch {
  return {
    op: SET_OP,
    path: `/${path
      .replace(/\[/g, '/')
      .replace(/\]\./g, '/')
      .replace(/\./g, '/')}`,
    value
  }
}

export function addConditionalPatches(
  patches: Patch[],
  traversal: Data
): Patch[] {
  const newPatches = patches.reduce((carr, item) => {
    if (additionalPathMap[item.path]) {
      carr.push(additionalPathMap[item.path](traversal))
    }

    return carr
  }, [] as Patch[])

  return [...patches, ...newPatches]
}
