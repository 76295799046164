import RootStore from 'stores/RootStore'
import CommandError from 'error/commandError'
import DependencyCollectError from 'error/dependencyCollectError'
import DependencyTimeoutError from 'error/dependencyTimeoutError'
import { logAndTrackError } from 'utils/log/logAndTrackError'
import { logExecutionTime } from 'utils/performance'
import { rejectAfter, wait } from 'utils/promise'
import { trackInAmplitude } from 'utils/tracking/amplitude'
import { AmplitudeEvent } from 'utils/tracking/amplitude/amplitude.interface'
import { UrlParams } from 'utils/url'
import { NavigateFunction } from 'react-router-dom'
import { getEnv } from 'utils/env'
import ddfConfig from 'config/ddf-config'
import ddfSmavaConfig from 'config/ddf-smava-config'

import { StartupCommand } from './command/command.interface'

const collectDependenciesConfig = {
  maxRetries: 100,
  waitTime: 200
}

export default class StartupCommandPool {
  private rootStore: RootStore
  private urlParams: UrlParams
  private commands: Record<string, Promise<unknown>> = {}
  private navigate: NavigateFunction

  constructor(
    rootStore: RootStore,
    navigate: NavigateFunction,
    urlParams: UrlParams
  ) {
    this.rootStore = rootStore
    this.navigate = navigate
    this.urlParams = urlParams
  }

  private getDependencyStatuses = (dependencies: string[]) => {
    const deps = dependencies.reduce(
      (previous, current) => ({
        ...previous,
        [current]: this.commands[current] !== undefined
      }),
      {}
    )
    return deps
  }

  private hasCollectedDependencies = (dependencies: string[]) =>
    Object.keys(this.commands).filter((commandName) =>
      dependencies.includes(commandName)
    ).length === dependencies.length

  private collectDependencies = async (
    commandName: string,
    dependencies: string[]
  ) => {
    let checkCount = 0
    while (!this.hasCollectedDependencies(dependencies)) {
      // eslint-disable-next-line no-await-in-loop
      await wait(collectDependenciesConfig.waitTime)

      checkCount += 1

      if (checkCount >= collectDependenciesConfig.maxRetries) {
        return Promise.reject(
          new DependencyCollectError(
            commandName,
            this.getDependencyStatuses(dependencies)
          )
        )
      }
    }

    return Promise.resolve()
  }

  private waitForDependencies = async ({
    name,
    dependencies,
    timeout
  }: StartupCommand) => {
    if (dependencies.length === 0) {
      return Promise.resolve()
    }

    await this.collectDependencies(name, dependencies)

    return Promise.race([
      Promise.allSettled(
        dependencies.map((dependency) => this.commands[dependency])
      ),
      rejectAfter(
        timeout,
        () =>
          new DependencyTimeoutError(
            name,
            this.getDependencyStatuses(dependencies),
            timeout
          )
      )
    ])
  }

  private executeCommand = async ({ name, execute }: StartupCommand) => {
    const commandInPool = this.commands[name]

    if (commandInPool) {
      logAndTrackError(new CommandError(name, 'Command was already executed.'))

      return commandInPool
    }

    const promise = execute(this.rootStore, this.navigate, this.urlParams)

    this.commands[name] = promise

    return promise
  }

  run = async (command: StartupCommand) => {
    try {
      await this.waitForDependencies(command)

      await logExecutionTime(
        command.name,
        () => this.executeCommand(command),
        1000
      )
    } catch (error) {
      logAndTrackError(error as Error)

      if (error instanceof DependencyTimeoutError) {
        trackInAmplitude(AmplitudeEvent.LogDependencyTimeout, {
          command: command.name,
          dependencies: command.dependencies.join(',')
        })
        /* fall back to make sure taurine loads */
        if (
          command.name === 'LoadEntryPoint' ||
          command.name === 'LoadConfiguration'
        ) {
          const tenant = getEnv('REACT_APP_TAURINE_TENANT_NAME')
          this.rootStore.navigation.currentPageInSession = 'debtorsCount'
          this.rootStore.history.replace('debtorsCount')
          this.rootStore.page.config.version = '2.0'
          this.rootStore.page.config.pages =
            tenant === 'teal' ? ddfSmavaConfig.pages : ddfConfig.pages
          this.rootStore.page.config.name = 'ddf_smava'
          this.navigate('debtorsCount', { replace: true })
        }
      }
    }
  }
}
