import { AxiosError } from 'axios'
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react'
import { logError, logInfo, logSuccess } from 'utils/log'
import { getErrorResponse } from './utils'

export interface ErrorStateContextType {
  state: {
    error: Error | null
    message: string | null
  }
  hasCooldown: boolean
}

export const ErrorStateContext = createContext<ErrorStateContextType>({
  state: { error: null, message: null },
  hasCooldown: false
})

export interface ErrorDispatchContextType {
  setError: (error: AxiosError) => void
  clearError: () => void
}

function throwErrorProviderIsMissing() {
  throw new Error('The ErrorDispatchContextType was not found!')
}

export const ErrorDispatchContext = createContext<ErrorDispatchContextType>({
  setError: throwErrorProviderIsMissing,
  clearError: throwErrorProviderIsMissing
})

interface ErrorOptions {
  recoveryTime?: number
  onRecover?: () => boolean
}

export const ErrorProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [state, setState] = useState<ErrorStateContextType['state']>({
    error: null,
    message: null
  })
  const [hasCooldown, setCooldown] = useState(false)
  const errorTimer = useRef<number | null>(null)

  const clearError = useCallback(() => {
    setState({ error: null, message: null })
    setCooldown(false)
  }, [])

  const startTimer = useCallback(
    (options: ErrorOptions = {}) => {
      if (errorTimer.current) {
        clearTimeout(errorTimer.current)
        errorTimer.current = null
      }

      if (options.recoveryTime === undefined) {
        return
      }

      setCooldown(true)

      const recover = () => {
        if (!options.onRecover) {
          setCooldown(false)
          return
        }

        logInfo('Trying to recover from error..')

        if (!options.onRecover()) {
          startTimer(options)
          return
        }

        logSuccess('Successfully recovered from error.')

        clearError()
      }

      errorTimer.current = window.setTimeout(recover, options.recoveryTime)
    },
    [clearError]
  )

  const setError = useCallback(
    (error: AxiosError) => {
      const { message, onRecover, recoveryTime } = getErrorResponse(error)

      setState({ error, message })

      startTimer({ recoveryTime, onRecover })

      logError(message)
    },
    [startTimer]
  )

  const errorState = useMemo(
    () => ({ state, hasCooldown }),
    [hasCooldown, state]
  )

  const errorDispatchers = useMemo(
    () => ({
      clearError,
      setError
    }),
    [clearError, setError]
  )

  return (
    <ErrorStateContext.Provider value={errorState}>
      <ErrorDispatchContext.Provider value={errorDispatchers}>
        {children}
      </ErrorDispatchContext.Provider>
    </ErrorStateContext.Provider>
  )
}
