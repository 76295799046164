import { memo } from 'react'
import { range } from 'utils/array'
import useEmotionStyles from 'hooks/useEmotionStyles'
import { css, keyframes } from '@emotion/react'

export interface WaveProps {
  numberOfCircles?: number
}
const wave = keyframes({
  '0%, 60%, 100%': {
    transform: 'initial'
  },
  '30%': {
    transform: 'translateY(-0.5rem)'
  }
})

const styles = () => ({
  loadingIndicator: css({
    display: 'inline-block',
    width: '0.5rem',
    height: '0.5rem',
    borderRadius: '50%',
    marginRight: '3px',
    background: '#fff',
    animation: `${wave} 0.9s linear infinite`,
    '&:nth-of-type(2)': {
      animationDelay: '-0.6s'
    },
    '&:nth-of-type(3)': {
      animationDelay: '-0.4s'
    }
  })
})

const Wave = ({ numberOfCircles = 3 }: WaveProps) => {
  const { loadingIndicator } = useEmotionStyles(styles)

  return (
    <div>
      {range(numberOfCircles).map((number) => (
        <span key={number} className={loadingIndicator} />
      ))}
    </div>
  )
}

export default memo<WaveProps>(Wave)
