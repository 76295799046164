export enum PossibleVariants {
  CONTROL = 'control',
  VARIATION_1 = 'variation_1',
  VARIATION_2 = 'variation_2',
  VARIATION_3 = 'variation_3',
  VARIATION_A = 'variation_a',
  VARIATION_B = 'variation_b',
  VARIATION_C = 'variation_c',
  VARIATION_D = 'variation_d'
}
