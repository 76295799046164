import { useContext } from 'react'
import { ErrorDispatchContext, ErrorStateContext } from '.'

export function useErrorState() {
  return useContext(ErrorStateContext)
}

export function useSetError() {
  const { setError } = useContext(ErrorDispatchContext)

  return setError
}

export function useClearError() {
  const { clearError } = useContext(ErrorDispatchContext)

  return clearError
}
