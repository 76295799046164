import { getNetworkType } from 'utils/network'
import { UserProperty } from 'utils/tracking/amplitude/amplitude.interface'
import { isMobileViewport } from 'utils/device'
import RootStore from 'stores/RootStore'
import { logInfo } from 'utils/log'
import { COOKIE_KEY_FCID, getCookie } from 'utils/cookie'
import { UrlParams } from 'utils/url'
import { getEnv } from 'utils/env'
import { setUserProperties } from 'utils/tracking/amplitude'
import FcidStore from 'stores/FcidStore'
import Bugsnag from '@bugsnag/js'
import { NavigateFunction } from 'react-router-dom'

import { StartupCommand } from './command.interface'
import Package from '../../../package.json'

function setUserId(fcid: FcidStore) {
  const FCID = getCookie(COOKIE_KEY_FCID) || fcid.getFcid()
  if (!FCID) {
    return
  }

  Bugsnag.addMetadata('fcId', {
    fcId: FCID
  })

  logInfo(`[Amplitude] UserID set to FCID value (${FCID})`)

  logInfo(`[Bugsnag] fcId set as metadata`)
}

export class TrackUserDetailCommand implements StartupCommand {
  name = 'TrackUserDetail'
  dependencies = ['LoadConfiguration', 'InitFcid']
  timeout = 10000

  // eslint-disable-next-line class-methods-use-this
  execute = async (
    rootStore: RootStore,
    _navigate: NavigateFunction,
    urlParams: UrlParams
  ) => {
    setUserId(rootStore.fcid)

    const {
      traversal: {
        data: {
          system: { advertisementId, configurationId, affiliateId },
          debtors: { count }
        }
      },
      initialEntryPoint,
      navigation: { initiallySeenPage }
    } = rootStore

    const { backUrl } = urlParams

    setUserProperties({
      [UserProperty.TaurineVersion]: Package.version,
      [UserProperty.NetworkType]: getNetworkType(),
      [UserProperty.IsMobile]: isMobileViewport(),
      [UserProperty.Viewport]: window.innerWidth,
      [UserProperty.ViewportHeight]: window.innerHeight,
      [UserProperty.ViewportWidthXHeight]: `${window.innerWidth}x${window.innerHeight}`,
      [UserProperty.Origin]: window.document.referrer,
      [UserProperty.EntryPointInitial]: initialEntryPoint,
      [UserProperty.EntryPointPageName]: initiallySeenPage,
      [UserProperty.EntryPointParent]: initiallySeenPage,
      [UserProperty.AdvertisementID]: advertisementId,
      [UserProperty.ConfigurationID]: configurationId,
      [UserProperty.DebtorCount]: count,
      [UserProperty.AffiliateID]: affiliateId,
      [UserProperty.BackUrl]: backUrl,
      [UserProperty.Tenant]: getEnv('REACT_APP_TAURINE_TENANT_NAME'),
      [UserProperty.FcId]:
        getCookie(COOKIE_KEY_FCID) || rootStore.fcid.getFcid()
    })
  }
}

export default TrackUserDetailCommand
