import { AxiosRequestConfig, AxiosResponse } from 'axios'
import BugsnagError, { Metadata } from './bugsnagError'

function getConfigMetadata({
  data,
  baseURL,
  url,
  method,
  headers
}: AxiosRequestConfig) {
  const paths = JSON.parse(data).map(({ path }: { path: string }) => path)

  return {
    endpoint: {
      baseURL,
      url
    },
    method,
    headers,
    paths
  }
}

function getResponseMetadata(response: AxiosResponse) {
  return {
    status: response.status,
    statusText: response.statusText,
    data: response.data,
    headers: response.headers
  }
}

export default class ApiError<T> extends BugsnagError {
  public config: AxiosRequestConfig
  public response: AxiosResponse<T> | undefined

  constructor(
    message: string,
    config: AxiosRequestConfig,
    response?: AxiosResponse<T>
  ) {
    super(message)

    this.name = 'ApiError'
    this.config = config
    this.response = response
  }

  getMetadata(): Metadata {
    return {
      request: getConfigMetadata(this.config),
      response: this.response ? getResponseMetadata(this.response) : {}
    }
  }
}
