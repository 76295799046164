import { NavigateFunction } from 'react-router-dom'
import RootStore from 'stores/RootStore'
import { UrlParams } from 'utils/url'
import {
  InitFcid,
  GetTraversal,
  InitFfgConf,
  InitFrameHostData,
  InitPollard,
  InitReactions,
  LoadConfiguration,
  LoadEntryPoint,
  ResumeTraversal,
  TrackUserDetail,
  TrackVisit,
  InitNavigation,
  InitTheme
} from './command'
import { StartupCommand } from './command/command.interface'
import StartupCommandPool from './commandPool'

export const startupCommands: StartupCommand[] = [
  new InitPollard(),
  new InitFrameHostData(),
  new InitFcid(),
  new InitNavigation(),
  new ResumeTraversal(),
  new GetTraversal(),
  new LoadConfiguration(),
  new LoadEntryPoint(),
  new TrackUserDetail(),
  new TrackVisit(),
  new InitReactions(),
  new InitFfgConf(),
  new InitTheme()
]

export async function startApp(
  rootStore: RootStore,
  navigate: NavigateFunction,
  urlParams: UrlParams = {}
) {
  const commandPool = new StartupCommandPool(rootStore, navigate, urlParams)

  for (const command of startupCommands) {
    commandPool.run(command)
  }
}
