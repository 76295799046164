import { useEffect } from 'react'
import Typography from '@mui/material/Typography'
import useEmotionStyles from 'hooks/useEmotionStyles'
import * as CSSTypes from 'csstype'
import { trackInAmplitude } from '../utils/tracking/amplitude'
import { AmplitudeEvent } from '../utils/tracking/amplitude/amplitude.interface'

// ErrorFallback is used before Theme initialization, that's why values don't come from theme.
const styles = () => ({
  container: {
    margin: '32px',
    textAlign: 'center' as CSSTypes.Property.TextAlign
  },
  emoji: {
    fontSize: '1.75rem !important'
  },
  title: {
    fontSize: '1.5rem !important'
  },
  desc: {
    fontSize: '2rem !important',
    fontWeight: '500 !important'
  }
})

const ErrorFallback = () => {
  const { container, emoji, title, desc } = useEmotionStyles(styles)

  useEffect(() => {
    trackInAmplitude(AmplitudeEvent.ErrorPageShown)
  }, [])

  return (
    <div className={container}>
      <Typography className={emoji} component="p" variant="h1">
        <span role="img" aria-label="worried face">
          😟
        </span>
      </Typography>
      <Typography className={title} component="p" variant="h3">
        Entschuldigung, da ist etwas schief gelaufen.
      </Typography>
      <Typography className={desc} component="p" variant="h2">
        Wir arbeiten daran!
      </Typography>
    </div>
  )
}

export default ErrorFallback
