import DependencyError from './dependencyError'

export default class DependencyTimeoutError extends DependencyError {
  constructor(
    commandName: string,
    dependencies: Record<string, boolean>,
    timeout: number
  ) {
    super(
      commandName,
      dependencies,
      `At least one dependency timed out after ${timeout}ms`
    )

    this.name = 'DependencyTimeoutError'
  }
}
