import { Box, Typography } from '@mui/material'
import TrustElement from '../TrustElement'

interface Props {
  trustElementJustifyContent?: 'flex-start' | 'space-around'
  trustElementGap?: number
}

const IntroText = ({ trustElementJustifyContent, trustElementGap }: Props) => {
  return (
    <Box px={2} mb={2} mt={1} data-testid="introtext">
      <Typography variant="h5" color="primary" paragraph>
        <b>Unser Tipp:</b>
      </Typography>
      <Typography variant="body2" paragraph>
        Der digitale Kontoauszug ermittelt <b>automatisch</b> wichtige Angaben
        über Ein- und Ausgänge auf Ihrem Konto, damit Sie noch{' '}
        <b>schneller zu Ihrem persönlichen Kreditangebot</b> gelangen.
      </Typography>
      <Typography variant="body2" paragraph>
        Dafür werden Ihre Zugangsdaten <b>einmalig</b> und <b>verschlüsselt</b>{' '}
        von unserem Partner Tink Germany GmbH an Ihre Bank übermittelt.
      </Typography>
      <Typography variant="body2" paragraph>
        <b>
          Die Zugangsdaten für Ihr Online-Banking werden von uns <u>nicht</u>{' '}
          gespeichert!
        </b>
      </Typography>
      <TrustElement
        justifyContent={trustElementJustifyContent}
        gap={trustElementGap}
      />
    </Box>
  )
}

export default IntroText
