import {
  CONFIG_SECOND_DEBTOR,
  CONFIG_SECOND_DEBTOR_SMAVA
} from 'config/utils/config'
import { NavigateFunction } from 'react-router-dom'
import RootStore from 'stores/RootStore'
import { getEntryPointPageName, getSavedPage } from 'utils/entrypoint'
import { logInfo, logWarning } from 'utils/log'
import { UrlParams } from 'utils/url/url.interface'
import { PossibleVariants } from 'utils/tracking/optimizely/types'

import { StartupCommand } from './command.interface'

export enum EntryPoint {
  FIRST = 'first',
  VAS = 'vas',
  VAS_LEAD = 'vasLead',
  BASE_RECEIVED = 'baseReceived',
  DEBTOR_COUNT_AND_RELATIONSHIP_RECEIVED = 'debtorCountAndRelationshipReceived',
  CONTACT_RECEIVED = 'contactReceived',
  FURTHEST = 'furthest',
  FINAL = 'final',
  LEAD_SAVED = 'leadSaved',
  LEAD_ABANDONED = 'leadAbandoned',
  LEAD_ABORTED = 'leadAborted'
}

export class LoadEntryPointCommand implements StartupCommand {
  name = 'LoadEntryPoint'
  dependencies = ['LoadConfiguration', 'InitNavigation']
  timeout = 15000

  private handleFirst = async (
    rootStore: RootStore,
    navigate: NavigateFunction,
    forcedEntryPoint?: boolean
  ) => {
    const {
      page: { getFirstDisplayedPageName },
      navigation: { setInitiallySeenPage, setCurrentPageInSession },
      history
    } = rootStore
    const pageName = getFirstDisplayedPageName()

    if (forcedEntryPoint) {
      /* setting the page name if entry point */
      setCurrentPageInSession(pageName)
      history.replace(pageName)
      navigate(pageName)
      return
    }

    setInitiallySeenPage(pageName)
    await this.goToPage(getFirstDisplayedPageName(), rootStore, navigate)
  }

  private handleFurthest = async (
    rootStore: RootStore,
    navigate: NavigateFunction
  ) => {
    const {
      page: { getLastDisplayedPageName }
    } = rootStore

    await this.goToPage(getLastDisplayedPageName(), rootStore, navigate)
  }

  private goToPageWithPath =
    (path: string) =>
    async (rootStore: RootStore, navigate: NavigateFunction) => {
      const {
        page: { findFirstPageWithPath },
        navigation: { setInitiallySeenPage }
      } = rootStore
      const pageName = await findFirstPageWithPath(path)

      if (!pageName) {
        await this.handleFurthest(rootStore, navigate)
        return
      }

      setInitiallySeenPage(pageName)
      await this.goToPage(pageName, rootStore, navigate)
    }

  private handleFinal = async (
    rootStore: RootStore,
    navigate: NavigateFunction
  ) => {
    const {
      traversal: { finalize },
      page: { config }
    } = rootStore

    try {
      if (
        config.name !== CONFIG_SECOND_DEBTOR &&
        config.name !== CONFIG_SECOND_DEBTOR_SMAVA
      ) {
        await finalize()
        return
      }
      await this.handleFurthest(rootStore, navigate)
    } catch (error) {
      await this.handleFurthest(rootStore, navigate)
    }
  }

  private entryPoints: Record<
    EntryPoint,
    (rootStore: RootStore, navigate: NavigateFunction) => Promise<void>
  > = {
    [EntryPoint.FIRST]: this.handleFirst,
    [EntryPoint.VAS]: this.handleFirst,
    [EntryPoint.VAS_LEAD]: this.handleFirst,
    [EntryPoint.BASE_RECEIVED]: this.goToPageWithPath('debtors.count'),
    [EntryPoint.DEBTOR_COUNT_AND_RELATIONSHIP_RECEIVED]: this.goToPageWithPath(
      'debtors.primary.contact.email'
    ),
    [EntryPoint.CONTACT_RECEIVED]: this.goToPageWithPath(
      'debtors.primary.personal.birthday'
    ),
    [EntryPoint.FURTHEST]: this.handleFurthest,
    [EntryPoint.LEAD_ABANDONED]: this.handleFurthest,
    [EntryPoint.LEAD_ABORTED]: this.handleFurthest,
    [EntryPoint.LEAD_SAVED]: this.handleFurthest,
    [EntryPoint.FINAL]: this.handleFinal
  }

  // eslint-disable-next-line class-methods-use-this
  private goToPage = async (
    pageName: string,
    rootStore: RootStore,
    navigate: NavigateFunction
  ) => {
    const {
      history,
      navigation: { setCurrentPageInSession }
    } = rootStore

    // CAT_3162_BRAND_SOURCE_SMAVA_TEST remove after test
    // code is added to fix reload on the page because without traversal data reload takes to next page

    let resolvedPageName =
      pageName === 'brandTracking'
        ? 'brandTracking'
        : await getEntryPointPageName(pageName, rootStore)

    if (pageName !== resolvedPageName) {
      logWarning(`"${pageName}" resolved to "${resolvedPageName}"!`)
    }

    setCurrentPageInSession(resolvedPageName)

    if (resolvedPageName) {
      navigate(resolvedPageName, { replace: true })
    }

    history.replace(resolvedPageName)
  }

  execute = async (
    rootStore: RootStore,
    navigate: NavigateFunction,
    urlParams?: UrlParams
  ) => {
    const savedPage = getSavedPage(rootStore)
    const { initialEntryPoint } = rootStore
    const entryPoint = urlParams?.entryPoint

    if (savedPage && initialEntryPoint !== 'final') {
      logInfo(`Reloading current page "${savedPage}"..`)

      if (entryPoint) {
        rootStore.setInitialEntryPoint(entryPoint as EntryPoint)
      }

      await this.goToPage(savedPage, rootStore, navigate)

      return
    }

    if (entryPoint) {
      /* handling the entry point from url's handler */
      const handler = this.entryPoints[entryPoint]

      if (handler) {
        logInfo(`Loading entry point from url "${entryPoint}"..`)
        await handler(rootStore, navigate, true)
        return
      }
    }

    logInfo(`Loading entry point "${initialEntryPoint}"..`)

    const handler = this.entryPoints[initialEntryPoint]
    if (handler) {
      await handler(rootStore, navigate)
      return
    }

    await this.goToPage(initialEntryPoint, rootStore, navigate)
  }
}

export default LoadEntryPointCommand
