import { ConfigName } from 'config/utils/config'
import ConfigError from './configError'

export default class UnknownConfigError extends ConfigError {
  constructor(configName: string) {
    super(`Config "${configName}" does not exist`, {
      version: 'unknown',
      name: configName as ConfigName,
      pages: {}
    })

    this.name = 'UnknownConfigError'
  }
}
