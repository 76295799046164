import { getDisplayedPages } from '../../config/utils/config'
import RootStore from '../../stores/RootStore'

export function getPageNameFromPath(path: string) {
  return path.replace(/\//g, '')
}

export function getDisplayedPageNames(rootStore: RootStore) {
  return getDisplayedPages(rootStore).map(([pageName]) => pageName)
}

export function getNextPageName(
  currentPage: string,
  rootStore: RootStore
): string | null {
  const pageNames = getDisplayedPageNames(rootStore)
  const idx = pageNames.indexOf(currentPage)

  if (idx < 0 || idx >= pageNames.length - 1) {
    return null
  }

  return pageNames[idx + 1]
}

export function getPreviousPageName(
  currentPage: string,
  rootStore: RootStore
): string | null {
  const pageNames = getDisplayedPageNames(rootStore)
  const idx = pageNames.indexOf(currentPage)

  if (idx < 1) {
    return null
  }

  return pageNames[idx - 1]
}
