import { PaymentsTwoTone } from '@mui/icons-material'
import { useStores } from 'stores/utils/hooks/useStores'
import { observer } from 'mobx-react'
import ConditionalWrapper from 'components/ConditionalWrapper'
import { Nudge } from '../Nudge'

const DEFAULT_REFINANCING_TEXT =
  'Super! Banken bewerten es positiv, wenn Sie Kredite umschulden, anstatt einen neuen aufzunehmen.'
const DEFAULT_CAR_PURPOSE_TEXT =
  'Mit einer Fahrzeugfinanzierung können Sie besondere Konditionen von den Banken erhalten.'

const getTextByPurpose = (
  purpose: string,
  carPurposeText?: string,
  refinancingText?: string
) => {
  if (purpose === 'REFINANCING') {
    return refinancingText || DEFAULT_REFINANCING_TEXT
  }

  return carPurposeText || DEFAULT_CAR_PURPOSE_TEXT
}

interface Props {
  dependson: string
  carPurposeText?: string
  refinancingText?: string
}

export const SingleDebtorCountNudge = observer(
  ({ dependson, carPurposeText, refinancingText }: Props) => {
    const {
      traversal: {
        data: {
          common: { purpose }
        }
      }
    } = useStores()
    const text = getTextByPurpose(purpose, carPurposeText, refinancingText)

    return (
      <ConditionalWrapper dependson={dependson}>
        <Nudge text={text} Icon={PaymentsTwoTone} />
      </ConditionalWrapper>
    )
  }
)
