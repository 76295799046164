export const workingTimeReduced = [
  { label: 'Teilzeit', value: 'PART_TIME' },
  { label: 'Kurzarbeit', value: 'REDUCED_HOURS' }
]

const workingTime = [
  ...workingTimeReduced,
  { label: 'Vollzeit', value: 'FULL_TIME' }
]

export default workingTime
