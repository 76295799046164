import { memo } from 'react'
import schufaGreen from '../../assets/logos/schufa-green.svg'

interface Props {
  width?: string
}

const SchufaGreenLogo = ({ width }: Props): React.ReactElement => (
  <img src={schufaGreen} width={width} alt="100% Schufaneutral" />
)

export default memo(SchufaGreenLogo)
