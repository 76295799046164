/* eslint-disable no-useless-escape */
/* eslint-disable no-control-regex */
import { isValid } from 'date-fns'
import { Config } from 'config/config.interface'
import { FieldState } from 'final-form'
import { Data } from 'interfaces/ApolloResponse.interface'
import { ALL_RULES } from './validation.rules'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ValidationFunction<T = any> = (
  value: T,
  allValues: Data,
  config: Config,
  meta?: FieldState<unknown>
) => string | null | undefined

const emailValidationRegex =
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|"+"[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i

/**
 * Returns the rule(s) for the given path.
 *
 * @param {string} path The path, e.g. `debtors.primary.expenses.rent`
 */
export const getRulesForPath = (
  path: string,
  rules = ALL_RULES
): ValidationFunction[] => {
  // If the path matches exactly one path in the rules, we can simply return the rules.
  if (rules[path]) return rules[path]
  // In the rules object above, one can specify wildcard paths, e.g. debtors.*.whatever.
  // When this method is called, the actual path will be passed in, e.g. debtors.primary.whatever.
  // The wildcard path should match to both debtors.primary.whatever and debtor.secondary.whatever.
  // So, we first create a "mapping" of the paths in the rule object and the regex form of that path to
  // make it easier to test for the string match.
  const rulePaths = Object.keys(rules)
  // The mapping will contain arrays of declared shape, where the first element of the array...
  const wildcardRulesRegexMap: [RegExp, string][] = []
  // ... is merely the "regexed" form of the path/key...
  rulePaths
    .filter((p) => p.includes('*'))
    .forEach((p) => {
      const replacedPath = p
        .replace('.', '\\.')
        .replace(/\*/g, '.+')
        .replace('[', '\\[')
        .replace(']', '\\]')

      // ( resulting in, e.g. /^debtors\..*\.path$/ )
      const re = new RegExp(`^${replacedPath}$`, 'g')

      wildcardRulesRegexMap.push([re, p])
    })
  // ... so that it is possible to go through them and see if one matches.
  // In the case that it matches, the rules are collected in the "result"s array...
  const result = wildcardRulesRegexMap.map((entry) => {
    const [re, p] = entry

    if (re.test(path)) {
      return rules[p]
    }

    return []
  })

  // ... and, finally, merged in one array to be returned.
  return result.reduce((all, entry) => all.concat(entry), [])
}

export const progressiveDisclosureValidator = (validator: string) => {
  const validatorObject = {
    [VALIDATOR_LENGTH]: (value: string) => value && value.length,
    [VALIDATOR_DOB]: (value: string) => {
      if (value) {
        if (isValid(new Date(value))) {
          return true
        }
        const dobWithOutSeparator = value.replace('/_/g', '').trim()
        const dateArray = value.split('.')
        const newDate = `${dateArray[1]}/${dateArray[0]}/${dateArray[2]}`
        if (dobWithOutSeparator.length > 9 && isValid(new Date(newDate))) {
          return true
        }
      }
      return false
    },
    [VALIDATOR_PHONE]: (value: string) => value && value.length > 4,
    [VALIDATOR_EMAIL]: (value: string) =>
      value && emailValidationRegex.test(value)
  }
  return validatorObject[validator]
}

export const VALIDATOR_LENGTH = 'length'
export const VALIDATOR_DOB = 'dob'
export const VALIDATOR_PHONE = 'phone'
export const VALIDATOR_EMAIL = 'email'
