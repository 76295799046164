export default class TraversalError extends Error {
  public traversalId: string | null

  constructor(message: string, traversalId: string | null = null) {
    super(message)

    this.name = 'TraversalError'
    this.traversalId = traversalId
  }
}
