import { useRef, useEffect, useCallback, memo, useState } from 'react'
import { observer } from 'mobx-react'

import { useStores } from 'stores/utils/hooks/useStores'
import AccountType from 'utils/formDependencies/statics/enums/AccountType'
import { useField } from 'react-final-form'
import { usePatchedField } from 'stores/utils/hooks/usePatchedField'
import { trackInAmplitude } from 'utils/tracking/amplitude'
import { AmplitudeEvent } from 'utils/tracking/amplitude/amplitude.interface'
import { useInteraction } from 'hooks/useInteraction'
import { MIDDLE_AGE } from 'constants/components'

export enum InactivityType {
  PAYOUT_ACCOUNT_INACTIVITY,
  DAC_INACTIVITY
}

interface IdleDetectorProps {
  onNext: () => void
  timeout: number
  inactivityType?: InactivityType
  isTouchedIgnored?: boolean
  isSmava?: boolean
  debtorsAge: number
}

const IBAN_PATH = 'common.payoutAccount.iban'
const ACCOUNT_NUMBER_PATH = 'common.payoutAccount.accountNumber'
const BANKCODE_PATH = 'common.payoutAccount.bankCode'

const CreateFinalAfterInactivity = observer(
  ({
    onNext,
    timeout,
    inactivityType,
    isTouchedIgnored,
    isSmava,
    debtorsAge
  }: IdleDetectorProps) => {
    const {
      page: { isLastDisplayedPage },
      navigation: { currentPageInSession },
      fieldErrors: { isErrorOnFinalize },
      traversal: { dacWidgetState }
    } = useStores()
    const { hasInteracted, setHasInteracted } = useInteraction()
    const isLastPage = isLastDisplayedPage(currentPageInSession)

    const payoutAccountType = usePatchedField('common.payoutAccount.type', {})

    const ibanField = useField(IBAN_PATH, {})
    const ibanPatchedField = usePatchedField(IBAN_PATH, {})
    const accountNumberField = useField(ACCOUNT_NUMBER_PATH, {})
    const accountNumberPatchedField = usePatchedField(ACCOUNT_NUMBER_PATH, {})
    const bankCodePatchedField = usePatchedField(BANKCODE_PATH, {})
    const bankCodeField = useField(BANKCODE_PATH, {})

    const timeoutRef = useRef<number | undefined>(undefined)
    const [isTimeoutStarted, setIsTimeoutStarted] = useState(false)

    const createFinalAfterInactivityAfterEnteringPayountAccount =
      useCallback(() => {
        trackInAmplitude(
          AmplitudeEvent.FinalAfterInactivityAfterEnteringPayoutAccount
        )
        onNext?.()
      }, [onNext])

    const createFinalAfterInactivityAfterDACInitialization = useCallback(() => {
      trackInAmplitude(
        AmplitudeEvent.FinalAfterInactivityAfterDACInitialization
      )
      onNext?.()
    }, [onNext])

    const isIbanFieldReady =
      isLastPage &&
      payoutAccountType.input.value === AccountType.IBAN_BIC &&
      // iban field meta data
      (ibanField.meta.touched || isTouchedIgnored) &&
      !ibanField.meta.active &&
      // iban field value
      ibanField.input.value &&
      !ibanPatchedField.error

    const isAccountNumberFieldReady =
      isLastPage &&
      payoutAccountType.input.value === AccountType.ACCOUNTNUMBER_BANKCODE &&
      // account number field value
      accountNumberField.input.value &&
      !accountNumberPatchedField.error &&
      // bank code field value
      bankCodeField.input.value &&
      !bankCodePatchedField.error &&
      // account number field meta data
      (accountNumberField.meta.touched || isTouchedIgnored) &&
      !accountNumberField.meta.active &&
      // bank code field meta data
      (bankCodeField.meta.touched || isTouchedIgnored) &&
      !bankCodeField.meta.active

    const isPayoutAccountReady = isIbanFieldReady || isAccountNumberFieldReady

    const isPayoutAccountReadyAndIsDacInitialOrDisagreed =
      isPayoutAccountReady &&
      (dacWidgetState === 'initial' || dacWidgetState === 'disagreed')

    const isPayoutAccountReadyAndIsDacAgreed =
      isPayoutAccountReady && dacWidgetState === 'agreed'

    const createFinalAfterInactivity = useCallback(() => {
      if (
        inactivityType === InactivityType.PAYOUT_ACCOUNT_INACTIVITY &&
        isPayoutAccountReadyAndIsDacInitialOrDisagreed &&
        !isErrorOnFinalize
      ) {
        trackInAmplitude(
          AmplitudeEvent.FinalAfterInactivityAfterEnteringPayoutAccount
        )
        createFinalAfterInactivityAfterEnteringPayountAccount()
      }

      if (
        inactivityType === InactivityType.DAC_INACTIVITY &&
        isPayoutAccountReadyAndIsDacAgreed &&
        !isErrorOnFinalize
      ) {
        trackInAmplitude(
          AmplitudeEvent.FinalAfterInactivityAfterDACInitialization
        )
        createFinalAfterInactivityAfterDACInitialization()
      }
    }, [
      inactivityType,
      isPayoutAccountReadyAndIsDacInitialOrDisagreed,
      isErrorOnFinalize,
      isPayoutAccountReadyAndIsDacAgreed,
      createFinalAfterInactivityAfterEnteringPayountAccount,
      createFinalAfterInactivityAfterDACInitialization
    ])

    const stopTimeout = useCallback(() => {
      clearTimeout(timeoutRef.current)
      setIsTimeoutStarted(false)
    }, [setIsTimeoutStarted])

    const startTimeout = useCallback(() => {
      timeoutRef.current = window.setTimeout(() => {
        createFinalAfterInactivity()
        setIsTimeoutStarted(false)
      }, timeout)
      setIsTimeoutStarted(true)
    }, [createFinalAfterInactivity, setIsTimeoutStarted, timeout])

    const resetTimeout = useCallback(() => {
      stopTimeout()
      startTimeout()
    }, [stopTimeout, startTimeout])

    const isPayoutAccountFieldActive =
      ibanField.meta.active ||
      accountNumberField.meta.active ||
      bankCodeField.meta.active

    // active event is not caught by event listener, it needs to be done manually
    useEffect(() => {
      if (isTimeoutStarted && isPayoutAccountFieldActive) {
        stopTimeout()
      }

      if (!isTimeoutStarted && !isPayoutAccountFieldActive) {
        startTimeout()
      }
    }, [
      isPayoutAccountFieldActive,
      isTimeoutStarted,
      startTimeout,
      stopTimeout
    ])

    useEffect(() => {
      if (hasInteracted) {
        resetTimeout()
        setHasInteracted(false)
      }
    }, [hasInteracted, setHasInteracted, resetTimeout])

    useEffect(() => {
      /* condition for dac over middle age people ffg */
      if (!isSmava && dacWidgetState !== 'initial' && debtorsAge > MIDDLE_AGE) {
        stopTimeout()
      }
    }, [dacWidgetState])

    useEffect(() => {
      return () => stopTimeout()
    }, [stopTimeout])

    return null
  }
)

export default memo(CreateFinalAfterInactivity)
