import { Dependencies } from 'components/ConditionalField/dependencies'
import {
  ActiveLoansItem,
  Data,
  LoanType
} from '../../interfaces/ApolloResponse.interface'
import AccountType from '../../utils/formDependencies/statics/enums/AccountType'

export const EXCLUDED_LOAN_TYPES: LoanType[] = [
  LoanType.Balloon,
  LoanType.CreditLine,
  LoanType.CreditCard,
  LoanType.Overdraft
]

export const CREDITS_WITH_DISPOSITION_AMOUNT: LoanType[] = [
  LoanType.CreditLine,
  LoanType.Demand,
  LoanType.Overdraft,
  LoanType.CreditCard
]

export const CREDITS_WITHOUT_RATE = ['CREDIT_LINE', 'DEMAND_LOAN', 'CREDITCARD']

function isDispositionCredit(_: Data, loanItem: ActiveLoansItem): boolean {
  if (loanItem.type) {
    return loanItem.type === LoanType.Overdraft
  }
  return false
}

function showRateFields(state: Data, item: ActiveLoansItem): boolean {
  if (isDispositionCredit(state, item)) {
    return false
  }

  return !CREDITS_WITHOUT_RATE.includes(item.type)
}

function showOtherFields(state: Data, item: ActiveLoansItem): boolean {
  if (isDispositionCredit(state, item) || !item.isDebtConversionWanted) {
    return false
  }

  return true
}

function showDispositionAmount(_: Data, { type }: ActiveLoansItem): boolean {
  return CREDITS_WITH_DISPOSITION_AMOUNT.includes(type)
}

function showAmount(state: Data, item: ActiveLoansItem): boolean {
  if (isDispositionCredit(state, item)) {
    return false
  }

  return !showDispositionAmount(state, item)
}

const isBankAccountType = (
  _: Data,
  { bankAccount: { type } }: ActiveLoansItem
) => type === AccountType.ACCOUNTNUMBER_BANKCODE

const activeLoanDependencies: Dependencies<Data> = {
  amount: showAmount,
  dispositionAmount: showDispositionAmount,
  bankAccount: [
    showOtherFields,
    {
      iban: (_, { bankAccount: { type } }: ActiveLoansItem) =>
        type === AccountType.IBAN_BIC,
      accountNumber: isBankAccountType,
      bankCode: isBankAccountType,
      country: isBankAccountType
    }
  ],
  monthlyRate: showRateFields,
  endsOn: showRateFields,
  startedOn: showRateFields,
  isCommonLoanWithOtherDebtor: ({ debtors: { count } }) => count === 2,
  isDebtConversionWanted: (state, item: ActiveLoansItem) =>
    !isDispositionCredit(state, item),
  remainingAmount: showOtherFields
}

export default activeLoanDependencies
