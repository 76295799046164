import { MINIMUM_VEHICLE_AMOUNT_VALUE } from 'formInitialValues'
import { dateIsOlderThan } from 'utils/date'
import Position from 'utils/formDependencies/statics/enums/Position'
import { CivilStatus } from 'utils/formDependencies/statics/enums/CivilStatus'
import { CIVIL_STATUS_NUMBER_OF_ADULTS_MAP } from 'utils/civilStatusNumberOfAdultsMap'
import TraversalStore, { FieldChange } from '../../stores/TraversalStore'
import { Debtor } from '../../containers/PersonalForm/PersonalForm.interface'
import {
  LivingSituation,
  SelfUsedProperty,
  Vehicle
} from '../../interfaces/ApolloResponse.interface'
import {
  jobsWithMinijobOptions,
  jobsWithoutPreviousEmployment
} from '../../utils/formDependencies/statics/lists'
import { updateNumberOfResidents } from './handleNumberOfResidentsOrChildren'

export const reactionUpdateNumberOfResidentsBasedOnCivilStatus = (
  debtor: Debtor,
  civilStatus: CivilStatus | string,
  numberOfChildren: number,
  numberOfChildrenWithChildBenefits: number,
  numberOfResidents: number,
  handlePathFormFieldChange: <T = string>(
    path: string,
    fieldValue?: T | T[],
    patchTraversal?: boolean
  ) => void
) => {
  const newNumberOfResidents =
    CIVIL_STATUS_NUMBER_OF_ADULTS_MAP[civilStatus] + numberOfChildren

  updateNumberOfResidents(
    debtor,
    numberOfResidents,
    newNumberOfResidents,
    numberOfChildren,
    numberOfChildrenWithChildBenefits,
    handlePathFormFieldChange
  )
}

export const reactionResetRentalProperty = (
  type: 'NONE' | SelfUsedProperty['type'],
  handleMultiplePathFormFieldChanges: <T>(
    payload: FieldChange<T>[],
    patchTraversal?: boolean
  ) => void
) => {
  if (type === 'NONE') {
    handleMultiplePathFormFieldChanges([
      {
        path: 'debtors.primary.realEstate.rentalProperty.size',
        value: 0
      },
      {
        path: 'debtors.primary.income.rent',
        value: 0
      }
    ])
  }
}

export const reactionResetHasMiniJob = (
  debtor: Debtor,
  position: Position,
  handlePathFormFieldChange: <T = string>(
    path: string,
    fieldValue?: T | T[],
    patchTraversal?: boolean
  ) => void
) => {
  if (!jobsWithMinijobOptions.includes(position)) {
    handlePathFormFieldChange(`debtors.${debtor}.hasMinijob`, false)
  }
}

export const reactionClearSecondaryLivingSituation = (
  isCommonHousehold: boolean,
  secondaryLivingSituation: LivingSituation,
  handlePathFormFieldChange: <T = string>(
    path: string,
    fieldValue?: T | T[],
    patchTraversal?: boolean
  ) => void
) => {
  if (isCommonHousehold && secondaryLivingSituation) {
    handlePathFormFieldChange(
      'debtors.secondary.household.livingSituation',
      undefined
    )
  }
}

export const reactionClearMortgageRateUsedProperty = (
  traversal: TraversalStore,
  livingSituation: LivingSituation,
  debtor: Debtor
) => {
  if (
    livingSituation !== LivingSituation.Property &&
    traversal.data.debtors[debtor].expenses.mortgageRateUsedProperty > 0
  ) {
    traversal.handlePathFormFieldChange(
      `debtors.${debtor}.expenses.mortgageRateUsedProperty`,
      0
    )
  }
}

export const reactionUpdateHasPreviousEmployment = (
  traversal: TraversalStore,
  debtor: Debtor
) => {
  const {
    handlePathFormFieldChange,
    data: { debtors }
  } = traversal
  const { hasPreviousEmployment, currentEmployment } = debtors[debtor]
  const isJobWithoutPreviousEmployment = jobsWithoutPreviousEmployment.includes(
    currentEmployment.position
  )

  if (isJobWithoutPreviousEmployment && hasPreviousEmployment) {
    handlePathFormFieldChange(`debtors.${debtor}.hasPreviousEmployment`, false)

    return
  }

  if (!currentEmployment.since) {
    return
  }

  const isEmployedLongerThan2Years = dateIsOlderThan(currentEmployment.since, {
    year: 2
  })

  if (!isEmployedLongerThan2Years) {
    handlePathFormFieldChange(`debtors.${debtor}.hasPreviousEmployment`, true)

    return
  }

  if (hasPreviousEmployment) {
    handlePathFormFieldChange(`debtors.${debtor}.hasPreviousEmployment`, false)
  }
}

export const reactionSetAmoutVehiclePriceInitialPayment = (
  traversal: TraversalStore,
  vehicle: Vehicle
) => {
  const { price, initialPayment } = vehicle
  if (price < MINIMUM_VEHICLE_AMOUNT_VALUE) {
    traversal.setInitalPaymentErrorTriggered(false)
    return
  }
  const calculatedAmount = price - (initialPayment || 0)
  if (calculatedAmount < MINIMUM_VEHICLE_AMOUNT_VALUE) {
    traversal.setInitalPaymentErrorTriggered(true)
    const { handlePathFormFieldChange } = traversal
    handlePathFormFieldChange(
      'common.amount',
      MINIMUM_VEHICLE_AMOUNT_VALUE,
      false
    )
    handlePathFormFieldChange(
      'common.vehicle.initialPayment',
      vehicle.price - MINIMUM_VEHICLE_AMOUNT_VALUE,
      false
    )
  }
}
