import { useEffect, memo, useRef, useCallback } from 'react'
import { reaction } from 'mobx'
import { observer } from 'mobx-react'
import { DependencyProvider } from 'components/ConditionalField/dependencies'
import formDependencies from 'utils/formDependencies/formDependencies'
import { scrollToTop } from 'utils/pollard/scroll'
import { getFirstInput, getSkeletonElements } from 'utils/input'
import { useData } from 'stores/utils/hooks/useData'
import { useRootStore } from 'stores/utils/hooks/useRootStore'
import CategoryLayout from 'components/Layout/Category'
import { ConfigProps } from 'config/config.interface'
import { Outlet } from 'react-router-dom'
import PageComponents from './PageComponents'
import { IBAN_PATH } from 'hooks/usePayoutAccountValidation'

export type PageProps = ConfigProps

const Page = observer(
  ({
    options: {
      reactions,
      components,
      isFooterVisible = true,
      largeCategory = false
    },
    navigate
  }: PageProps) => {
    const { rootStore } = useRootStore()
    const {
      navigation: { stack },
      setIsFooterVisible
    } = rootStore
    const { data } = useData()

    useEffect(() => {
      if (!reactions) {
        return
      }

      reactions(rootStore).map(({ field, method }) => reaction(field, method))
    }, [reactions, rootStore])

    useEffect(() => {
      if (isFooterVisible) {
        return undefined
      }

      setIsFooterVisible(false)

      return () => {
        setIsFooterVisible(true)
      }
    }, [isFooterVisible, setIsFooterVisible])

    const categoryRef = useRef<HTMLDivElement | null>(null)

    const onObserve = useCallback<MutationCallback>(
      (mutationsList, formObserver) => {
        if (!categoryRef.current) {
          return
        }

        for (const { removedNodes } of mutationsList) {
          if (removedNodes.length === 0) {
            continue
          }

          if (getSkeletonElements(categoryRef.current)?.length === 0) {
            /* 
              getFirstInput() detects wrong element for some reason,
              likely, the MutationObserver gets lost because
              there are few `categoryRef` on some pages.
              As a hotfix/fallback, prevent scroll on focus is used
              (scrollToTop is added because safari and IE 
              doesn't support preventScroll param)
            */

            const firstInput = getFirstInput()

            // MuiSelect must not be autofocused (CAT-817)
            if (firstInput?.className.indexOf('MuiSelect-nativeInput') === -1) {
              if (firstInput.name.indexOf(IBAN_PATH) > -1) {
                return
              }
              firstInput?.focus({ preventScroll: true })
            }
            scrollToTop(rootStore.isInIframe)
            formObserver.disconnect()
            return
          }
        }
      },
      [rootStore.isInIframe]
    )

    useEffect(() => {
      if (stack.length === 1) {
        return undefined
      }

      if (!categoryRef.current) {
        return undefined
      }

      const formObserver = new MutationObserver(onObserve)

      formObserver.observe(categoryRef.current, {
        childList: true,
        subtree: true
      })

      return () => {
        formObserver.disconnect()
      }
    }, [onObserve, stack])

    return (
      <>
        <CategoryLayout ref={categoryRef} largeCategory={largeCategory}>
          <DependencyProvider state={data} dependencies={formDependencies}>
            <PageComponents navigate={navigate} components={components} />
          </DependencyProvider>
        </CategoryLayout>
        <Outlet />
      </>
    )
  }
)

export default memo(Page)
