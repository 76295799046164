import { Data } from 'interfaces/ApolloResponse.interface'
import { Debtor } from '../../../containers/PersonalForm/PersonalForm.interface'
import canHaveMinijob from './canHaveMinijob'
import isRetired from './isRetired'

const isEmployedOrHasMinijob =
  (debtor: Debtor = Debtor.PRIMARY) =>
  (state: Data): boolean => {
    if (isRetired(debtor)(state)) {
      return false
    }

    if (canHaveMinijob(debtor)(state) && !state.debtors[debtor].hasMinijob) {
      return false
    }

    return !!state.debtors[debtor].currentEmployment.position
  }

export default isEmployedOrHasMinijob
