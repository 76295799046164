import Grid from '@mui/material/Grid'
import { Skeleton } from '@mui/material'
import ButtonSkeleton from './ButtonSkeleton'

const RadioButtonsSkeletons = () => (
  <Grid container spacing={1} direction="row">
    <Grid item xs={5}>
      <Skeleton variant="rectangular" height={24} />
    </Grid>
    <Grid item xs={7} />
    <Grid item xs={6}>
      <ButtonSkeleton />
    </Grid>
    <Grid item xs={6}>
      <ButtonSkeleton />
    </Grid>
  </Grid>
)

export default RadioButtonsSkeletons
