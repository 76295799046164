import { AffiliateEventName, sendAffiliateEvent } from 'utils/affiliate'
import { AmplitudeEvent } from 'utils/tracking/amplitude/amplitude.interface'
import RootStore from 'stores/RootStore'
import { trackInAmplitude } from 'utils/tracking/amplitude'
import { StartupCommand } from './command.interface'

class TrackVisitCommand implements StartupCommand {
  name = 'TrackVisit'
  dependencies = ['InitFcid', 'InitNavigation', 'TrackUserDetail']
  timeout = 10000

  // eslint-disable-next-line class-methods-use-this
  execute = async (rootStore: RootStore) => {
    const {
      navigation: { currentPageInSession },
      traversal
    } = rootStore

    trackInAmplitude(AmplitudeEvent.VisitPage, {
      currentPage: currentPageInSession || window.location.pathname.slice(1)
    })

    sendAffiliateEvent(AffiliateEventName.Visit, traversal, rootStore)
  }
}

export default TrackVisitCommand
