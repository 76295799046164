import React from 'react'
import { Typography } from '@mui/material'
import { useClientDetails } from 'stores/utils/hooks/useClientDetails'
import { useText } from 'stores/utils/hooks/useText'
import TextLink from './TextLink'

export const LegalText = () => {
  const clientDetails = useClientDetails()
  const {
    text: { copy }
  } = useText()

  return (
    <Typography variant="caption">
      {copy?.dac}{' '}
      <TextLink link={clientDetails?.urls.dataProtection}>
        Weitere Informationen
      </TextLink>
      .
    </Typography>
  )
}
