import { useMemo, useState, useRef, useEffect, memo } from 'react'
import { observer } from 'mobx-react'
import { css, keyframes } from '@emotion/react'
import * as CSSTypes from 'csstype'
import { Theme } from '@mui/material/styles'
import LinearProgress, {
  LinearProgressProps
} from '@mui/material/LinearProgress'
import { useProgress } from 'stores/utils/hooks/useProgress'
import CategoryLayout from 'components/Layout/Category'
import { useStores } from 'stores/utils/hooks/useStores'
import useEmotionStyles from 'hooks/useEmotionStyles'
import useEmotionStyledComponent from 'hooks/useEmotionStyledComponent'
import MotivationalTextBelow from './MotivationalTextBelow'

export const MINIMUM_PROGRESS = 5
export const MAXIMUM_PROGRESS = 25

const PROGRESS_HEIGHT = 18
const SLIM_PROGRESS_HEIGHT = 8

const Progress = (props: LinearProgressProps & { height: number }) => {
  const { height } = props
  const StyledProgress = useEmotionStyledComponent<typeof LinearProgress>(
    (theme: Theme) => ({
      root: {
        height,
        borderRadius: 12,
        backgroundColor:
          theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
        '& span.MuiLinearProgress-bar': {
          borderRadius: 12,
          backgroundColor: theme.palette.success.main,
          animation: `$grow 2s ease`
        }
      }
    }),
    LinearProgress
  )
  return <StyledProgress {...props} />
}

const SlimProgress = (
  props: LinearProgressProps & {
    height: number
    borderRadius?: number | null
    isNormalWidthOnMobile?: boolean
  }
) => {
  const { height, borderRadius, isNormalWidthOnMobile } = props
  const StyledSlimProgress = useEmotionStyledComponent<typeof LinearProgress>(
    (theme: Theme) => ({
      root: {
        height,
        ...(borderRadius && { borderRadius }),
        ...(!isNormalWidthOnMobile && {
          [theme.breakpoints.down('sm')]: {
            width: '100vw',
            marginTop: theme.spacing(-3),
            marginLeft: theme.spacing(-2)
          }
        }),
        '&.MuiLinearProgress-bar': {
          backgroundColor: theme.palette.success.main,
          animation: `$grow 2s ease`
        }
      }
    }),
    LinearProgress
  )

  return <StyledSlimProgress {...props} />
}

interface Motivational {
  label: string
  condition: (percentage: number) => boolean
}

interface ProgressProps {
  motivationals?: Motivational[]
  withMotivationalText?: boolean
  isSlimProgress?: boolean
  isWithMotivationalTextBelow?: boolean
}

const MOTIVATIONALS_DEFAULT: Motivational[] = [
  {
    label: 'Fast geschafft!',
    condition: (percentage) => percentage >= 82
  },
  { label: 'Geschafft!', condition: (percentage) => percentage === 100 }
]

const getProgressMessage = (
  percentage: number,
  motivationals: Motivational[]
) => {
  const motivational = motivationals.find((m) => m.condition(percentage))

  if (motivational) {
    return motivational.label
  }

  return `${percentage}%`
}

interface StyleProps {
  percentage: number
  textWidth: number
}

const fadeIn = keyframes({
  '0%': {
    opacity: 0
  },
  '100%': {
    opacity: 1
  }
})

const styles = (theme: Theme, { percentage, textWidth }: StyleProps) => ({
  container: {
    position: 'relative' as CSSTypes.Property.Position
  },
  textContainer: {
    position: 'absolute' as CSSTypes.Property.Position,
    top: 0,
    color: '#FFFFFF', // theme.palette.secondary.dark,
    fontSize: '0.75rem',
    textAlign: 'right' as CSSTypes.Property.TextAlign,
    left: `calc(${percentage}%  - ${textWidth}px - ${theme.spacing(1)})`,
    transition: theme.transitions.create('left', { duration: 1000 }),
    transitionDelay: '1ms'
  },
  text: css({
    height: PROGRESS_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap' as CSSTypes.Property.WhiteSpace,
    animation: `${fadeIn} 2s`
  })
})

const ProgressIndicator = observer(
  ({
    motivationals = MOTIVATIONALS_DEFAULT,
    withMotivationalText,
    isSlimProgress,
    isWithMotivationalTextBelow
  }: ProgressProps) => {
    const { page } = useStores()

    const { percentage } = useProgress()

    const motivationalText = useMemo(
      () => getProgressMessage(percentage, motivationals),
      [percentage, motivationals]
    )

    const [textWidth, setTextWidth] = useState(0)
    const textRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
      if (!textRef.current) {
        return
      }

      setTextWidth(Math.ceil(textRef.current.offsetWidth))
    }, [motivationalText])

    const styleProps = useMemo(
      () => ({
        percentage,
        textWidth
      }),
      [percentage, textWidth]
    )
    const { container, textContainer, text } = useEmotionStyles<
      ReturnType<typeof styles>,
      StyleProps
    >(styles, styleProps)

    if (page.shouldHideProgressIndicator()) {
      return null
    }

    return (
      <CategoryLayout>
        <div className={container}>
          {isSlimProgress && (
            <SlimProgress
              variant="determinate"
              value={percentage}
              height={SLIM_PROGRESS_HEIGHT}
              borderRadius={isWithMotivationalTextBelow ? 2 : null}
              isNormalWidthOnMobile={isWithMotivationalTextBelow}
            />
          )}

          {!isSlimProgress && (
            <Progress
              variant="determinate"
              value={percentage}
              height={PROGRESS_HEIGHT}
            />
          )}

          {withMotivationalText && percentage > MINIMUM_PROGRESS && (
            <div className={textContainer}>
              <div className={text} ref={textRef}>
                {motivationalText}
              </div>
            </div>
          )}

          {isWithMotivationalTextBelow && (
            <MotivationalTextBelow percentage={percentage} label="" />
          )}
        </div>
      </CategoryLayout>
    )
  }
)

export default memo(ProgressIndicator)
