import { PropsWithChildren } from 'react'
import * as CSSTypes from 'csstype'
import { keyframes, Theme } from '@emotion/react'
import useEmotionStyles from 'hooks/useEmotionStyles'

interface VerticalProgressProps {
  isHighlighted?: boolean
  isDisabled?: boolean
  isFirst?: boolean
  isLast?: boolean
}

export const VerticalProgressWrapper = ({
  isHighlighted,
  isDisabled,
  isFirst,
  isLast,
  children
}: PropsWithChildren<VerticalProgressProps>) => {
  const { verticalProgressWrapperStyle, childrenStyle } =
    useEmotionStyles(styles)

  return (
    <div className={verticalProgressWrapperStyle}>
      <VerticalProgress
        isHighlighted={isHighlighted}
        isDisabled={isDisabled}
        isFirst={isFirst}
        isLast={isLast}
      />
      <div className={childrenStyle}>{children}</div>
      {/* the div below is styled, do not remove */}
      <div />
    </div>
  )
}

const VerticalProgress = ({
  isHighlighted,
  isDisabled,
  isFirst,
  isLast
}: VerticalProgressProps) => {
  const {
    verticalProgressStyle,
    highlighted,
    highlightPulse,
    disabled,
    defaultStyle,
    first,
    last
  } = useEmotionStyles(styles)

  return (
    <div
      className={`
        ${verticalProgressStyle} 
        ${!(isHighlighted || isDisabled) ? defaultStyle : ''} 
        ${isHighlighted ? highlighted : ''} 
        ${isDisabled ? disabled : ''}
        ${isFirst ? first : ''}
        ${isLast ? last : ''}
      `}
    >
      {isHighlighted && <div className={highlightPulse} />}
    </div>
  )
}

const fadeIn = keyframes({
  '0%': {
    opacity: 0.4
  },
  '100%': {
    opacity: 1
  }
})

const jump = keyframes({
  '0%': {
    transform: 'scale(1)',
    opacity: 1
  },
  '50%': {
    transform: 'scale(2)'
  },
  '100%': {
    transform: 'scale(1)',
    opacity: 1
  }
})

const pulse = keyframes({
  '0%': {
    transform: 'translate(-50%, -50%) scale(0)'
  },
  '10%': {
    opacity: 1
  },
  '25%': {
    opacity: 0.3
  },
  '50%, 100%': {
    transform: 'translate(-50%, -50%) scale(1)',
    opacity: 0
  }
})

const denude = keyframes({
  '0%': {
    transform: 'translateY(0)'
  },
  '100%': {
    transform: 'translateY(100%)'
  }
})

export const ANIMATION_DELAY_INTERVAL = 0.2
export const ANIMATION_TIME = 0.4

const styles = (theme: Theme) => ({
  childrenStyle: {
    width: '100%'
  },
  verticalProgressWrapperStyle: {
    display: 'flex',
    position: 'relative' as CSSTypes.Property.Position,
    overflow: 'hidden',
    opacity: 0.3,
    animation: `${fadeIn} ${ANIMATION_TIME}s forwards`,

    // not sure how to style nested class, hacky way below
    // icon from Category (green check mark)
    '& svg': {
      opacity: 0,
      animation: `${jump} ${ANIMATION_TIME}s forwards`
    },

    // progress cover layer
    '& > *': {
      '&:last-child': {
        position: 'absolute' as CSSTypes.Property.Position,
        backgroundColor: `rgba(255, 255, 255, 0.3)`,
        height: '100%',
        width: '50px',
        animation: `${denude} ${ANIMATION_DELAY_INTERVAL}s forwards ease`
      }
    },

    '&:nth-child(2)': {
      animationDelay: `${ANIMATION_DELAY_INTERVAL}s`,

      '& svg': {
        animationDelay: `${ANIMATION_DELAY_INTERVAL}s`
      },

      '& > *': {
        '&:last-child': {
          animationDelay: `${ANIMATION_DELAY_INTERVAL}s`
        }
      }
    },

    '&:nth-child(3)': {
      animationDelay: `${ANIMATION_DELAY_INTERVAL * 2}s`,

      '& svg': {
        animationDelay: `${ANIMATION_DELAY_INTERVAL * 2}s`
      },

      '& > *': {
        '&:last-child': {
          animationDelay: `${ANIMATION_DELAY_INTERVAL * 2}s`
        }
      }
    },

    '&:nth-child(4)': {
      animationDelay: `${ANIMATION_DELAY_INTERVAL * 3}s`,

      '& > *': {
        '&:last-child': {
          animationDelay: `${ANIMATION_DELAY_INTERVAL * 3}s`
        }
      }
    },

    '&:nth-child(5)': {
      animationDelay: `${ANIMATION_DELAY_INTERVAL * 4}s`,

      '& > *': {
        '&:last-child': {
          animationDelay: `${ANIMATION_DELAY_INTERVAL * 4}s`
        }
      }
    }
  },
  first: {
    '&::before': {
      visibility: 'hidden' as CSSTypes.Property.Visibility
    }
  },
  last: {
    '&::after': {
      visibility: 'hidden' as CSSTypes.Property.Visibility
    }
  },
  highlighted: {
    border: `3px solid ${theme.palette.primary[500]}`,

    '&::before': {
      background: theme.palette.common.black
    },

    '&::after': {
      background: theme.palette.grey[500]
    }
  },
  highlightPulse: {
    position: 'absolute' as CSSTypes.Property.Position,
    width: '50px',
    height: '50px',
    borderRadius: '100%',
    animation: `${pulse} 4s ease infinite`,
    animationDelay: `${ANIMATION_DELAY_INTERVAL * 4}s`,
    background: theme.palette.primary[300],
    transform: 'translate(-50%, -50%) scale(0)',
    zIndex: -1
  },
  disabled: {
    border: `3px solid ${theme.palette.grey[500]}`,

    '&::before': {
      background: theme.palette.grey[500]
    },

    '&::after': {
      background: theme.palette.common.white
    }
  },
  defaultStyle: {
    border: `3px solid ${theme.palette.common.black}`,

    '&::before': {
      background: theme.palette.common.black
    },

    '&::after': {
      background: theme.palette.common.black
    }
  },
  verticalProgressStyle: {
    padding: 8,
    margin: `${theme.spacing(2)} ${theme.spacing(2.5)} 0 ${theme.spacing(1.5)}`,
    borderRadius: '50%',
    width: 'fit-content',
    height: 'fit-content',
    background: theme.palette.common.white,

    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute' as CSSTypes.Property.Position,
      height: '100%',
      width: '2px',
      zIndex: -1,
      transform: 'translateX(-50%)'
    },

    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute' as CSSTypes.Property.Position,
      height: '100%',
      width: '2px',
      zIndex: -1,
      transform: 'translate(-50%, -100%)'
    }
  }
})
