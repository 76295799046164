import { Provider } from 'mobx-react'
import * as React from 'react'
import { appEnvironment, getEnv } from 'utils/env'
import Bugsnag, { Event } from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { LoadingMode } from 'components/LoadingProvider'
import { logError } from 'utils/log'
import CustomOptimizelyProvider from 'CustomOptimizelyProvider'
import { configure } from 'mobx'
import packageInfo from '../package.json'
import RootStore from './stores/RootStore'
import App from './App'
import ErrorFallback from './containers/ErrorFallback'

configure({
  // We cannot use `ifavailable` because of material-ui 😭
  useProxies: 'never'
})

const bugsnagApiKey = getEnv('REACT_APP_BUGSNAG_API_KEY', true)

Bugsnag.start({
  apiKey: bugsnagApiKey,
  plugins: [new BugsnagPluginReact(React)],
  releaseStage: appEnvironment,
  enabledReleaseStages: ['production', 'stage', 'shared'],
  appVersion: packageInfo.version,
  collectUserIp: false,
  onError: () => true
})

const bugsnagReactPlugin = Bugsnag.getPlugin('react')
if (!bugsnagReactPlugin) {
  throw new Error('Bugsnag: React plugin is missing')
}

const ErrorBoundary = bugsnagReactPlugin.createErrorBoundary(React)

const AppContainer = () => {
  const rootStore = React.useMemo(() => new RootStore(), [])

  const onError = React.useCallback(
    (event: Event) => {
      rootStore.setLoadingMode(LoadingMode.None)
      logError(event.errors[0].errorMessage)
    },
    [rootStore]
  )

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={onError}>
      <Provider rootStore={rootStore}>
        <CustomOptimizelyProvider>
          <App />
        </CustomOptimizelyProvider>
      </Provider>
    </ErrorBoundary>
  )
}

export default React.memo(AppContainer)
