import { getEnv } from 'utils/env'

let enabledFeatures: string[] | null = null

export function getEnabledFeatures(): string[] {
  if (!enabledFeatures) {
    enabledFeatures = (getEnv('REACT_APP_FEATURES', false) || '')
      .split(',')
      .map((feature) => feature.trim())
  }

  return enabledFeatures
}

export function isFeatureEnabled(featureName: string): boolean {
  return getEnabledFeatures().includes(featureName)
}
