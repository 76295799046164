const rdiType = [
  {
    label: 'Komplettschutz',
    hint: 'Im Fall von Arbeitslosigkeit, Arbeitsunfähigkeit oder Tod',
    value: 'UNEMPLOYMENT_DEATH_INABILITY'
  },
  {
    label: 'Standardschutz',
    hint: 'Im Fall von Arbeitsunfähigkeit oder Tod',
    value: 'DEATH_INABILITY'
  },
  {
    label: 'Todesfallschutz',
    hint: 'Nur im Todesfall',
    value: 'DEATH'
  },
  {
    label: 'Kein Schutz',
    hint: 'Ich trage alle Risiken selbst',
    value: 'NONE'
  }
]

export default rdiType
