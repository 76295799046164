import { Config } from 'config/config.interface'
import ConfigError from './configError'

export default class PageError extends ConfigError {
  public pageName: string

  constructor(message: string, config: Config, pageName: string) {
    super(message, config)

    this.name = 'PageError'
    this.pageName = pageName
  }

  getMetadata() {
    const configMeta = super.getMetadata()

    return {
      ...configMeta,
      taurineConfig: {
        ...configMeta.taurineConfig,
        pageName: this.pageName
      }
    }
  }
}
