const civilStatus = [
  { label: 'ledig', value: 'SINGLE' },
  { label: 'verheiratet', value: 'MARRIED' },
  { label: 'verwitwet', value: 'WIDOWED' },
  { label: 'geschieden', value: 'DIVORCED' },
  { label: 'eheähnliche Lebensgemeinschaft', value: 'PARTNERSHIP' },
  { label: 'getrennt lebend', value: 'SEPARATED' }
]

export default civilStatus
