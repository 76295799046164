import { useCallback, useEffect, useState } from 'react'

export function useInteraction() {
  const [hasInteracted, setHasInteracted] = useState(false)
  const onInteraction = useCallback(() => {
    setHasInteracted(true)
  }, [])

  // it seems that focus and blur listeners don't work as expected
  useEffect(() => {
    document.addEventListener('input', onInteraction)
    document.addEventListener('change', onInteraction)
    document.addEventListener('focus', onInteraction)
    document.addEventListener('blur', onInteraction)

    return () => {
      document.removeEventListener('input', onInteraction)
      document.removeEventListener('change', onInteraction)
      document.removeEventListener('focus', onInteraction)
      document.removeEventListener('blur', onInteraction)
    }
  }, [onInteraction])

  return { hasInteracted, setHasInteracted }
}
