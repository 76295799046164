import { Dependencies } from 'components/ConditionalField/dependencies'
import { isTenantSmava } from 'utils/url'
import { startOfDay, sub } from 'date-fns'
import { Debtor } from '../../containers/PersonalForm/PersonalForm.interface'
import secondaryEmploymentDependencies from '../../components/SecondaryEmployment/dependencyTree'
import canHaveMinijob from './conditions/canHaveMinijob'
import hasSecondDebtor from './conditions/hasSecondDebtor'
import {
  hasAnyProperty,
  hasRentalProperty,
  isSelfUsedProperty,
  paysAddCost,
  paysRent
} from './conditions/propertyConditions'
import {
  hasFurtherIncome,
  hasNoPrimaryIncome,
  canObtainSpousalSupport
} from './conditions/incomeConditions'
import isEmployedOrHasMinijob from './conditions/isEmployedOrHasMinijob'
import { isNotOlderThan } from './conditions/isOlderThan'
import isRetired from './conditions/isRetired'
import { isSelfEmployed } from './conditions/isSelfEmployed'
import nationalityNotDe from './conditions/nationalityNotDe'
import pensionIsTemporary from './conditions/pensionIsTemporary'
import showEmployer from './conditions/showEmployer'
import AccountType from './statics/enums/AccountType'
import {
  CreditChangeOption,
  Data
} from '../../interfaces/ApolloResponse.interface'
import activeLoanDependencies from '../../components/ActiveLoan/dependencyTree'
import { isLaterThanOrEqual, isNotInTheFuture } from './conditions/date'
import nationalityNotEu from './conditions/nationalityNotEU'
import isValidPayoutAccount from './conditions/isValidPayoutAccount'

function isBankCode(state: Data) {
  return state.common.payoutAccount.type === AccountType.ACCOUNTNUMBER_BANKCODE
}

const formDependencies: Dependencies<Data> = {
  fintecWidget: isValidPayoutAccount,
  'debtors.relationship': hasSecondDebtor,
  'debtors.isCommonHousehold': hasSecondDebtor,
  'debtors.isSameContactInformation': hasSecondDebtor,
  'debtors.isSameAddress': (state) =>
    !state.debtors.isCommonHousehold && hasSecondDebtor(state),
  'debtors.primary.hasAnyProperty': hasAnyProperty(Debtor.PRIMARY),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  'debtors.primary.activeLoans': activeLoanDependencies as any, // FIXME: Typings for sub trees
  'debtors.primary.personal.livingInGermanySince': nationalityNotEu(
    Debtor.PRIMARY
  ),
  'debtors.primary.currentEmployment.occupation': isEmployedOrHasMinijob(
    Debtor.PRIMARY
  ),
  'debtors.primary.currentEmployment.sector': isEmployedOrHasMinijob(
    Debtor.PRIMARY
  ),
  'debtors.primary.currentEmployment.employer': showEmployer(Debtor.PRIMARY),
  'debtors.primary.currentEmployment.since': isEmployedOrHasMinijob(
    Debtor.PRIMARY
  ),
  'debtors.primary.currentEmployment.workingTime': showEmployer(Debtor.PRIMARY),
  'debtors.primary.currentEmployment.isTemporary': showEmployer(Debtor.PRIMARY),
  'debtors.primary.currentEmployment.until': (state) =>
    state.debtors.primary.currentEmployment.isTemporary &&
    !isSelfEmployed(Debtor.PRIMARY)(state),
  'debtors.primary.currentEmployment.isExtended': (state) =>
    state.debtors.primary.currentEmployment.isTemporary &&
    !isSelfEmployed(Debtor.PRIMARY)(state),
  'debtors.primary.currentEmployment.isInProbationPeriod': (state) => {
    return (
      isNotOlderThan('debtors.primary.currentEmployment.since', {
        month: 6
      })(state) && !isSelfEmployed(Debtor.PRIMARY)(state)
    )
  },
  'debtors.primary.currentEmployment.selfEmployment': isSelfEmployed(
    Debtor.PRIMARY
  ),
  'debtors.primary.currentEmployment.isGermanSalaryAccount': ({
    debtors: {
      primary: {
        currentAddress,
        currentEmployment: { employer }
      }
    }
  }) => currentAddress.country !== 'DE' || employer.address.country !== 'DE',
  'debtors.primary.hasMinijob': canHaveMinijob(Debtor.PRIMARY),
  'debtors.primary.hasPreviousEmployment': isNotOlderThan(
    'debtors.primary.currentEmployment.since',
    { year: 2 }
  ),
  'debtors.primary.previousEmployment': (state) =>
    state.debtors.primary.hasPreviousEmployment &&
    isNotOlderThan('debtors.primary.currentEmployment.since', { year: 2 })(
      state
    ),
  'debtors.primary.pensionFund': isRetired(Debtor.PRIMARY),
  'debtors.primary.previousAddress': (state) => {
    const now = new Date()

    return (
      isLaterThanOrEqual(
        'debtors.primary.residentSince',
        startOfDay(sub(now, { years: 2 }))
      )(state) && isNotInTheFuture('debtors.primary.residentSince', now)(state)
    )
  },
  'debtors.primary.income.secondaryEmployments':
    secondaryEmploymentDependencies,
  'debtors.primary.income.primary': hasNoPrimaryIncome(Debtor.PRIMARY),
  'debtors.primary.income.isPrimaryIncomeStable': hasNoPrimaryIncome(
    Debtor.PRIMARY
  ),
  'debtors.primary.income.spousalSupport': canObtainSpousalSupport(
    Debtor.PRIMARY
  ),
  'debtors.primary.income.numberOfChildrenWithChildSupport': (state) =>
    !!state.debtors.primary.income.childSupport &&
    hasFurtherIncome(Debtor.PRIMARY)(state),
  'debtors.primary.income.childSupport': hasFurtherIncome(Debtor.PRIMARY),
  'debtors.primary.income.pension': hasFurtherIncome(Debtor.PRIMARY),
  'debtors.primary.income.pensions': isRetired(Debtor.PRIMARY),
  'debtors.primary.income.pensions.validUntil': pensionIsTemporary,
  'debtors.primary.income.rent': hasRentalProperty(Debtor.PRIMARY),
  'debtors.primary.income.minijob': (state) => state.debtors.primary.hasMinijob,
  'debtors.primary.income.isMinijobIncomeStable': ({
    debtors: {
      primary: { hasMinijob, income }
    }
  }) => hasMinijob && !!income.minijob,
  'debtors.primary.realEstate.selfUsedProperty': isSelfUsedProperty(
    Debtor.PRIMARY
  ),
  'debtors.primary.realEstate.rentalProperty.size': hasRentalProperty(
    Debtor.PRIMARY
  ),
  'debtors.primary.expenses.numberOfChildrenWithChildSupport': (state) =>
    state.debtors.primary.expenses &&
    !!state.debtors.primary.expenses.childSupport,
  'debtors.primary.expenses.rent': paysRent(Debtor.PRIMARY),
  'debtors.primary.expenses.additionalHousingCosts': paysAddCost(
    Debtor.PRIMARY
  ),
  'debtors.primary.expenses.mortgageRateUsedProperty': isSelfUsedProperty(
    Debtor.PRIMARY
  ),
  'debtors.primary.expenses.mortgageRateRentalProperty': hasRentalProperty(
    Debtor.PRIMARY
  ),
  'debtors.primary.expenses.isHomePurchaseSavingsUsedForMortgageRepayment': ({
    debtors: {
      primary: {
        expenses: { homePurchaseSavingsRate }
      }
    }
  }) => !!homePurchaseSavingsRate,
  'debtors.primary.expenses.isLifeInsuranceUsedForMortgageRepayment': ({
    debtors: {
      primary: {
        expenses: { lifeInsurance }
      }
    }
  }) => !!lifeInsurance,
  'debtors.primary.residentAlien.isResidencePermitTemporary': nationalityNotEu(
    Debtor.PRIMARY
  ),
  'debtors.primary.residentAlien.isWorkPermitTemporary': nationalityNotEu(
    Debtor.PRIMARY
  ),
  'debtors.primary.residentAlien.residencePermitValidUntil': ({
    debtors: {
      primary: {
        residentAlien: { isResidencePermitTemporary }
      }
    }
  }) => isResidencePermitTemporary,
  'debtors.primary.residentAlien.workPermitValidUntil': ({
    debtors: {
      primary: {
        residentAlien: { isWorkPermitTemporary }
      }
    }
  }) => isWorkPermitTemporary,
  'debtors.secondary': hasSecondDebtor,
  'debtors.secondary.hasAnyProperty': hasAnyProperty(Debtor.SECONDARY),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  'debtors.secondary.activeLoans': activeLoanDependencies as any, // FIXME: Typings for sub trees
  'debtors.secondary.personal.livingInGermanySince': nationalityNotDe(
    Debtor.SECONDARY
  ),
  'debtors.secondary.personal.civilStatus': (state) =>
    state.debtors.relationship !== 'SPOUSE' && !!state.debtors.relationship,
  'debtors.secondary.contact': ({ debtors: { isSameContactInformation } }) =>
    !isSameContactInformation,
  'debtors.secondary.currentAddress.country': ({
    debtors: { isCommonHousehold, isSameAddress }
  }) => !isCommonHousehold && !isSameAddress,
  'debtors.secondary.currentAddress.street': ({
    debtors: { isCommonHousehold, isSameAddress }
  }) => !isCommonHousehold && !isSameAddress,
  'debtors.secondary.currentAddress.houseNumber': ({
    debtors: { isCommonHousehold, isSameAddress }
  }) => !isCommonHousehold && !isSameAddress,
  'debtors.secondary.currentAddress.zipCode': ({
    debtors: { isCommonHousehold, isSameAddress }
  }) => !isCommonHousehold && !isSameAddress,
  'debtors.secondary.currentAddress.city': ({
    debtors: { isCommonHousehold, isSameAddress }
  }) => !isCommonHousehold && !isSameAddress,
  'debtors.secondary.previousAddress': (state) => {
    const now = new Date()

    return (
      isLaterThanOrEqual(
        'debtors.secondary.residentSince',
        startOfDay(sub(now, { years: 2 }))
      )(state) &&
      isNotInTheFuture('debtors.secondary.residentSince', now)(state)
    )
  },
  'debtors.secondary.household': ({ debtors: { isCommonHousehold } }) =>
    !isCommonHousehold,
  'debtors.secondary.realEstate.selfUsedProperty': (state) =>
    !state.debtors.isCommonHousehold &&
    isSelfUsedProperty(Debtor.SECONDARY)(state),
  'debtors.secondary.realEstate.rentalProperty.size': hasRentalProperty(
    Debtor.SECONDARY
  ),
  'debtors.secondary.pensionFund': isRetired(Debtor.SECONDARY),
  'debtors.secondary.currentEmployment.occupation': isEmployedOrHasMinijob(
    Debtor.SECONDARY
  ),
  'debtors.secondary.currentEmployment.sector': isEmployedOrHasMinijob(
    Debtor.SECONDARY
  ),
  'debtors.secondary.currentEmployment.employer': showEmployer(
    Debtor.SECONDARY
  ),
  'debtors.secondary.currentEmployment.since': isEmployedOrHasMinijob(
    Debtor.SECONDARY
  ),
  'debtors.secondary.currentEmployment.workingTime': showEmployer(
    Debtor.SECONDARY
  ),
  'debtors.secondary.currentEmployment.isTemporary': showEmployer(
    Debtor.SECONDARY
  ),
  'debtors.secondary.currentEmployment.until': (state) =>
    state.debtors.secondary.currentEmployment.isTemporary &&
    !isSelfEmployed(Debtor.SECONDARY)(state),
  'debtors.secondary.currentEmployment.isExtended': (state) =>
    state.debtors.secondary.currentEmployment.isTemporary &&
    !isSelfEmployed(Debtor.SECONDARY)(state),
  'debtors.secondary.currentEmployment.isInProbationPeriod': (state) => {
    return (
      isNotOlderThan('debtors.secondary.currentEmployment.since', { month: 6 })(
        state
      ) && !isSelfEmployed(Debtor.SECONDARY)(state)
    )
  },
  'debtors.secondary.currentEmployment.selfEmployment': isSelfEmployed(
    Debtor.SECONDARY
  ),
  'debtors.secondary.currentEmployment.isGermanSalaryAccount': ({
    debtors: {
      secondary: {
        currentAddress,
        currentEmployment: { employer }
      }
    }
  }) => currentAddress.country !== 'DE' && employer.address.country !== 'DE',
  'debtors.secondary.hasMinijob': canHaveMinijob(Debtor.SECONDARY),
  'debtors.secondary.hasPreviousEmployment': isNotOlderThan(
    'debtors.secondary.currentEmployment.since',
    { year: 2 }
  ),
  'debtors.secondary.previousEmployment': (state) =>
    state.debtors.secondary.hasPreviousEmployment &&
    isNotOlderThan('debtors.secondary.currentEmployment.since', { year: 2 })(
      state
    ),
  'debtors.secondary.expenses.rent': (state) =>
    !state.debtors.isCommonHousehold && paysRent(Debtor.SECONDARY)(state),
  'debtors.secondary.expenses.additionalHousingCosts': paysAddCost(
    Debtor.SECONDARY
  ),
  'debtors.secondary.expenses.mortgageRateUsedProperty': isSelfUsedProperty(
    Debtor.SECONDARY
  ),
  'debtors.secondary.expenses.mortgageRateRentalProperty': hasRentalProperty(
    Debtor.SECONDARY
  ),
  'debtors.secondary.expenses.numberOfChildrenWithChildSupport': ({
    debtors: {
      secondary: {
        expenses: { childSupport }
      }
    }
  }) => !!childSupport,
  'debtors.secondary.expenses.isHomePurchaseSavingsUsedForMortgageRepayment': ({
    debtors: {
      secondary: {
        expenses: { homePurchaseSavingsRate }
      }
    }
  }) => !!homePurchaseSavingsRate,
  'debtors.secondary.expenses.isLifeInsuranceUsedForMortgageRepayment': ({
    debtors: {
      secondary: {
        expenses: { lifeInsurance }
      }
    }
  }) => !!lifeInsurance,
  'debtors.secondary.residentAlien.isResidencePermitTemporary':
    nationalityNotDe(Debtor.SECONDARY),
  'debtors.secondary.residentAlien.isWorkPermitTemporary': nationalityNotDe(
    Debtor.SECONDARY
  ),
  'debtors.secondary.residentAlien.residencePermitValidUntil': ({
    debtors: {
      secondary: {
        residentAlien: { isResidencePermitTemporary }
      }
    }
  }) => isResidencePermitTemporary,
  'debtors.secondary.residentAlien.workPermitValidUntil': ({
    debtors: {
      secondary: {
        residentAlien: { isWorkPermitTemporary }
      }
    }
  }) => isWorkPermitTemporary,
  'debtors.secondary.income.secondaryEmployments':
    secondaryEmploymentDependencies,
  'debtors.secondary.income.primary': hasNoPrimaryIncome(Debtor.SECONDARY),
  'debtors.secondary.income.isPrimaryIncomeStable': hasNoPrimaryIncome(
    Debtor.SECONDARY
  ),
  'debtors.secondary.income.spousalSupport': canObtainSpousalSupport(
    Debtor.SECONDARY
  ),
  'debtors.secondary.income.numberOfChildrenWithChildSupport': (state) =>
    !!state.debtors.secondary.income.childSupport &&
    hasFurtherIncome(Debtor.SECONDARY)(state),
  'debtors.secondary.income.childSupport': hasFurtherIncome(Debtor.SECONDARY),
  'debtors.secondary.income.pension': hasFurtherIncome(Debtor.SECONDARY),
  'debtors.secondary.income.pensions': isRetired(Debtor.SECONDARY),
  'debtors.secondary.income.pensions.validUntil': pensionIsTemporary,
  'debtors.secondary.income.rent': hasRentalProperty(Debtor.SECONDARY),
  'debtors.secondary.income.minijob': ({
    debtors: {
      secondary: { hasMinijob }
    }
  }) => hasMinijob,
  'debtors.secondary.income.isMinijobIncomeStable': ({
    debtors: {
      secondary: { hasMinijob, income }
    }
  }) => hasMinijob && !!income.minijob,
  'common.manuallyAdaptedAmount': ({ common: { amountAdaption } }) =>
    amountAdaption === CreditChangeOption.other,
  'common.payoutAccount.iban': ({ common: { payoutAccount } }) =>
    payoutAccount.type === AccountType.IBAN_BIC,
  'common.payoutAccount.accountNumber': isBankCode,
  'common.payoutAccount.bankCode': isBankCode,
  'common.payoutAccount.country': ({ common: { payoutAccount } }) =>
    payoutAccount.type !== AccountType.IBAN_BIC,
  'common.loanPayoutDate': ({ common: { loanPayout } }) =>
    loanPayout === 'OWN_DATE',
  'common.rdi.isSecondaryDebtorIncluded': (state) => hasSecondDebtor(state),
  'common.vehicle.price': (state) =>
    !isTenantSmava() || state.common.purpose === 'PRE_OWNED_CAR',
  'common.amount': (state) =>
    !isTenantSmava() || state.common.purpose !== 'PRE_OWNED_CAR',
  'common.vehicle.initialPayment': (state) =>
    isTenantSmava() && state.common.purpose === 'PRE_OWNED_CAR'
}

export default formDependencies
