import axios, { AxiosRequestConfig, AxiosInstance } from 'axios'
import { getEnv } from '../utils/env'
import { ClientName } from './api.interface'
import { getInterceptors, InterceptorConfig } from './interceptors'

const DEFAULT_HEADERS = {
  'Content-type': 'application/json; charset=utf-8'
}

const clients: Record<ClientName, AxiosRequestConfig> = {
  apollo: {
    baseURL: getEnv('REACT_APP_APOLLO_API_URL', true),
    withCredentials: true,
    headers: DEFAULT_HEADERS
  },
  pluto: {
    baseURL: getEnv('REACT_APP_PLUTO_URL', true),
    headers: DEFAULT_HEADERS
  },
  paas: {
    baseURL: getEnv('REACT_APP_PAAS_URL', true),
    withCredentials: true,
    headers: DEFAULT_HEADERS
  }
}

const instances: Partial<Record<ClientName, AxiosInstance>> = {}

function applyInterceptors(
  instance: AxiosInstance,
  interceptorConfig: InterceptorConfig | undefined
) {
  if (!interceptorConfig) {
    return
  }

  const { interceptors, response } = interceptorConfig

  if (response) {
    instance.interceptors.response.use(response?.onSuccess, response?.onError)
  }

  if (interceptors) {
    for (const interceptor of interceptors) {
      interceptor.apply(instance)
    }
  }
}

function createInstance(name: ClientName) {
  const instance = axios.create(clients[name])

  instances[name] = instance

  applyInterceptors(instance, getInterceptors(name))

  return instance
}

export function getClient(name: ClientName) {
  const instance = instances[name]
  if (instance) {
    return instance
  }

  return createInstance(name)
}
