import Bugsnag from '@bugsnag/js'
import { AxiosError, AxiosResponse } from 'axios'
import ApiError from 'error/apiError'
import { logAndTrackError } from 'utils/log/logAndTrackError'
import ResponseError from 'error/responseError'
import { setUserProperties } from 'utils/tracking/amplitude'
import { UserProperty } from 'utils/tracking/amplitude/amplitude.interface'
import { HttpStatusCode } from 'utils/url/status'

function logClientId(response: AxiosResponse) {
  const clientId = response.headers['x-client-id']
  if (!clientId) {
    return
  }

  // We only need to set it when the client Id differs
  if (Bugsnag.getUser().id === clientId) {
    return
  }

  setUserProperties({
    [UserProperty.ClientID]: clientId
  })
  Bugsnag.setUser(clientId)
}

/**
 * @see https://github.com/axios/axios#handling-errors
 */
export async function handleInterceptedErrors<T>(
  error: AxiosError<T>,
  handlers: {
    onResponseError?: (response: AxiosResponse<T>) => void
    onRequestError?: (request: XMLHttpRequest) => void
    onSetupError?: (setupError: Error) => void
  } = {}
): Promise<void> {
  if (error.response) {
    logClientId(error.response)
    if (error.config && error.response.status !== HttpStatusCode.StatusGone) {
      logAndTrackError(new ResponseError(error.config, error.response))
    }

    if (handlers.onResponseError) {
      handlers.onResponseError(error.response)
    }

    return Promise.reject(error)
  }

  if (error.request) {
    if (error.config) {
      logAndTrackError(
        new ApiError(
          error.request.responseText ||
            `Request Error: ${JSON.stringify(error)}`,
          error.config
        )
      )
    }

    if (handlers.onRequestError) {
      handlers.onRequestError(error.request)
    }

    return Promise.reject(error)
  }

  if (handlers.onSetupError) {
    handlers.onSetupError(error)
  }

  return Promise.reject(error)
}

export function handleSuccess(
  response: AxiosResponse,
  onSuccess?: (response: AxiosResponse) => void
): AxiosResponse {
  logClientId(response)

  if (onSuccess) {
    onSuccess(response)
  }

  return response
}
