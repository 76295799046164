import { initPollardCommunication } from 'utils/pollard/init'
import { StartupCommand } from './command.interface'

class InitPollardCommand implements StartupCommand {
  name = 'InitPollard'
  dependencies = []
  timeout = 10000

  execute = async () => {
    initPollardCommunication(this.timeout)
  }
}

export default InitPollardCommand
