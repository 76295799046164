import { useClientDetails } from 'stores/utils/hooks/useClientDetails'

interface Props {
  height?: string
}

const GuenstigerGehtNicht = ({ height = '64px' }: Props) => {
  const clientDetails = useClientDetails()

  return (
    <img
      src={clientDetails?.logos?.guenstigerGehtNicht}
      alt="Ausgezeichnete Kundenzufriedenheit Logo"
      height={height}
      data-testid="guenstiger-geht-nicht-image"
    />
  )
}

export default GuenstigerGehtNicht
