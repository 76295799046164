import { Box, Link, Typography } from '@mui/material'
import LockIcon from '@mui/icons-material/Lock'
import { Theme } from '@emotion/react'
import useEmotionStyles from 'hooks/useEmotionStyles'

const styles = (theme: Theme) => ({
  lockIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2)
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),

    '&:hover': {
      textDecoration: 'none'
    }
  }
})

const TuevLink = () => {
  const { link, lockIcon } = useEmotionStyles(styles)

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ mt: { xs: 1, sm: 0 } }}
      pb={1}
      gap={1}
      width="100%"
    >
      <Link
        href="https://www.smava.de/kredit/tuev-zertifikat/"
        target="_blank"
        rel="noopener noreferrer"
        underline="hover"
        className={link}
      >
        <LockIcon color="disabled" className={lockIcon} />
        <Typography color="grey.600" variant="caption" marginBottom="0">
          TÜV-geprüfte Verbindung
        </Typography>
      </Link>
    </Box>
  )
}

export { TuevLink }
