import { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Theme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { Gender } from 'components/PathGenderSelect'
import CategoryLayout from 'components/Layout/Category'
import { useStores } from 'stores/utils/hooks/useStores'
import RootStore from 'stores/RootStore'
import { EntryPoint } from 'startup/command/loadEntryPoint'
import { useRootStore } from 'stores/utils/hooks/useRootStore'
import useEmotionStyles from 'hooks/useEmotionStyles'
import * as CSSTypes from 'csstype'
import Backlink from './Backlink'
import CheckmarkIcon from '../BestOfferBox/CheckmarkIcon.svg'

const returningVisitorStyles = (theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column' as CSSTypes.Property.FlexDirection,
    alignItems: 'stretch',
    justifyContent: 'center',
    textAlign: 'center' as CSSTypes.Property.TextAlign,
    color: theme.palette.primary.main
  },
  headline: {
    marginBottom: theme.spacing(1)
  },
  checkmarkIcon: {
    marginBottom: theme.spacing(1),
    alignSelf: 'center'
  },
  salutation: {
    textTransform: 'capitalize' as CSSTypes.Property.TextTransform
  }
})

const salutationMap: Record<Gender, string> = {
  MALE: 'Herr',
  FEMALE: 'Frau'
}

function isReturningVisitor({
  initialEntryPoint,
  navigation: { currentPageInSession },
  navigation: { initiallySeenPage }
}: RootStore) {
  if (!initialEntryPoint) {
    return false
  }

  if (initiallySeenPage !== currentPageInSession) {
    return false
  }

  return [
    EntryPoint.FURTHEST,
    EntryPoint.LEAD_ABANDONED,
    EntryPoint.LEAD_ABORTED,
    EntryPoint.LEAD_SAVED
  ].includes(initialEntryPoint)
}

function shouldShowReturningVisitorText(rootStore: RootStore) {
  const {
    initialEntryPoint,
    traversal: { traversalId }
  } = rootStore

  if (!traversalId) {
    return false
  }

  if (
    initialEntryPoint === EntryPoint.VAS ||
    initialEntryPoint === EntryPoint.VAS_LEAD
  ) {
    return true
  }

  return isReturningVisitor(rootStore)
}

const ReturningVisitor = observer(() => {
  const { rootStore } = useRootStore()
  const {
    traversal: { data },
    navigation: { stack }
  } = useStores()
  const { gender, lastname } = data.debtors.primary.personal

  const { container, checkmarkIcon, salutation, headline } = useEmotionStyles(
    returningVisitorStyles
  )

  const [isTextVisible, setTextVisible] = useState(() =>
    shouldShowReturningVisitorText(rootStore)
  )

  useEffect(() => {
    if (isTextVisible && stack.length > 1) {
      setTextVisible(false)
    }
  }, [isTextVisible, stack.length])

  if (!isTextVisible) {
    return null
  }

  return (
    <CategoryLayout>
      <div className={container}>
        <img alt="checkmark" src={CheckmarkIcon} className={checkmarkIcon} />

        <Typography
          variant="h2"
          component="span"
          color="primary"
          className={headline}
        >
          Willkommen zurück,
          {salutationMap[gender] && (
            <>
              {' '}
              {salutationMap[gender]}{' '}
              <span className={salutation}>{lastname}</span>!
            </>
          )}
        </Typography>

        <Typography variant="body1" color="grey.900">
          Sie können Ihren Kreditvergleich nun fortsetzen.
        </Typography>

        <Backlink />
      </div>
    </CategoryLayout>
  )
})

export default ReturningVisitor
