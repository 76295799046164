import { lazy, Suspense, memo } from 'react'
import { ConfigOptions } from 'config/config.interface'
import { useRootStore } from 'stores/utils/hooks/useRootStore'
import InputSkeleton from 'components/SkeletonView/InputSkeleton'
import { Observer } from 'mobx-react'
import { NavigateFunction } from 'react-router-dom'

interface PageComponentsProps {
  components: ConfigOptions['components']
  navigate: NavigateFunction
}

const PageComponents = ({ components, navigate }: PageComponentsProps) => {
  const { rootStore } = useRootStore()

  return (
    <>
      {Object.entries(components)
        .filter(
          ([, configItem]) =>
            !configItem.condition || configItem.condition(rootStore)
        )
        .map(([name, config]) => {
          if ('component' in config) {
            return (
              <Observer key={`static-component-${name}`}>
                {() => <config.component {...config.props} />}
              </Observer>
            )
          }

          const LazyComponent = lazy(config.lazy)
          const lazySkeleton = config.skeleton ? (
            <config.skeleton />
          ) : (
            <InputSkeleton />
          )

          return (
            <Observer key={`lazy-component-${name}`}>
              {() => (
                <Suspense fallback={lazySkeleton}>
                  <LazyComponent {...config.props} navigate={navigate} />
                </Suspense>
              )}
            </Observer>
          )
        })}
    </>
  )
}

export default memo(PageComponents)
