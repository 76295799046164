import occupation from '../lists/occupation'
import SecondaryEmployment from '../lists/secondary_employment_type'
import yesNoEnum from '../lists/yes_no_enum'

const secondaryEmployment = {
  income: {
    label: 'Nettoeinkommen aus Nebentätigkeit',
    tooltip:
      'Bitte geben Sie das niedrigste Netto-Monatseinkommen der letzten 6 Monate aus Ihrer Nebentätigkeit an.',
    placeholder: 'z.B. 450',
    errorMap: {
      missing: 'Bitte teilen Sie uns Ihr Einkommen mit.',
      invalid: 'Bitte korrigieren Sie den Wert.',
      wrong_format: 'Bitte geben Sie einen gültigen Wert an.'
    }
  },
  isExtended: {
    label:
      'Wurde der Arbeitsvertrag Ihrer Nebenbeschäftigung beim aktuellen Arbeitgeber schon mindestens einmal verlängert?',
    tooltip: '',
    placeholder:
      'Wurde der Arbeitsvertrag Ihrer Nebenbeschäftigung beim aktuellen Arbeitgeber schon mindestens einmal verlängert?',
    enums: yesNoEnum,
    errorMap: {
      missing:
        'Bitte teilen Sie uns mit, ob Ihr Arbeitsvertrag beim aktuellen Arbeitgeber schon mindestens einmal verlängert wurde.'
    }
  },
  isIncomeProvable: {
    label: 'Sind die Einnahmen aus der Nebentätigkeit belegbar?',
    tooltip: '',
    placeholder: 'Sind die Einnahmen aus der Nebentätigkeit belegbar?',
    enums: yesNoEnum,
    errorMap: {
      missing: 'Bitte teilen Sie uns mit, ob die Einnahmen belegbar sind.'
    }
  },
  isIncomeStable: {
    label:
      'Sind die Einnahmen aus der Nebentätigkeit in den letzten 3 Monaten konstant gewesen?',
    tooltip: '',
    placeholder:
      'Sind die Einnahmen aus der Nebentätigkeit in den letzten 3 Monaten konstant gewesen?',
    errorMap: {
      missing: 'Bitte teilen Sie uns mit, ob die Einnahmen konstant waren.'
    }
  },
  isInProbationPeriod: {
    label: 'Befinden Sie sich bei Ihrer Nebenbeschäftigung in der Probezeit?',
    tooltip: '',
    placeholder:
      'Befinden Sie sich bei Ihrer Nebenbeschäftigung in der Probezeit?',
    enums: yesNoEnum,
    errorMap: {
      missing:
        'Bitte teilen Sie uns mit, ob Sie sich in der Probezeit befinden.'
    }
  },
  isTemporary: {
    label: 'Ist Ihre Nebenbeschäftigung befristet?',
    tooltip: '',
    placeholder: 'Ist Ihre Nebenbeschäftigung befristet?',
    enums: yesNoEnum,
    errorMap: {
      missing:
        'Bitte teilen Sie uns mit, ob die Nebenbeschäftigung befristet ist.'
    }
  },
  occupation: {
    label: 'Tätigkeit bei der Nebenbeschäftigung',
    tooltip: '',
    placeholder: 'Tätigkeit auswählen',
    options: occupation,
    _name: 'occupation.json', // TODO What's that?
    errorMap: {
      missing: 'Bitte wählen Sie eine Tätigkeit aus.'
    }
  },
  since: {
    label: 'Seit wann üben Sie die Nebentätigkeit aus?',
    tooltip: '',
    placeholder: 'Seit wann üben Sie die Nebentätigkeit aus?',
    errorMap: {
      missing: 'Bitte geben Sie ein Datum an.',
      invalid: 'Bitte korrigieren Sie das Datum.',
      wrong_format: 'Bitte geben Sie ein gültiges Datum an (MM.JJJJ).'
    }
  },
  type: {
    label: 'Um welche Art von Nebenbeschäftigung handelt es sich?',
    tooltip: '',
    placeholder: 'Um welche Art von Nebenbeschäftigung handelt es sich?',
    options: SecondaryEmployment,
    errorMap: {
      missing: 'Bitte wählen Sie die Art der Nebenbeschäftigung.'
    }
  },
  until: {
    label: 'Befristet bis',
    tooltip: '',
    placeholder: 'Nebenbeschäftigung befristet bis',
    errorMap: {
      missing: 'Bitte geben Sie ein Datum an.',
      invalid: 'Bitte korrigieren Sie das Datum.',
      wrong_format: 'Bitte geben Sie ein gültiges Datum an (.JJJJ).',
      out_of_range: 'Das Datum für die Befristung muss in der Zukunft liegen.'
    }
  },
  singleInfoLabel: 'Anzahl beruflicher Nebentätigkeiten'
}

export default secondaryEmployment
