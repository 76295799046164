import { AmplitudeEvent } from 'utils/tracking/amplitude/amplitude.interface'
import { logError, logInfo, logStackTrace, logWarning } from 'utils/log'
import { trackInAmplitude } from 'utils/tracking/amplitude'

export async function logExecutionTime<Args, R>(
  tag: string,
  method: (...args: Args[]) => Promise<R>,
  threshold = 0
): Promise<R> {
  const startTime = window.performance.now()

  try {
    return await method()
  } finally {
    const executionTime = window.performance.now() - startTime

    if (executionTime > threshold) {
      trackInAmplitude(AmplitudeEvent.LogExecutionTime, {
        tag,
        executionTime: executionTime.toFixed(2)
      })

      logWarning(`[${tag}] ${executionTime.toFixed(2)}ms`)
    } else {
      logInfo(`[${tag}] ${executionTime.toFixed(2)}ms`)
    }
  }
}

export async function executeAsync<Args, R>(method: (...args: Args[]) => R) {
  return new Promise((resolve, reject) => {
    try {
      resolve(method())
    } catch (error) {
      logStackTrace()
      logError(`Async execution failed 😞`)

      reject(error)
    }
  })
}
