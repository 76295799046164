import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Benefit, { BENEFIT_LIST } from './Benefit'

const Benefits = () => {
  return (
    <Container maxWidth="lg">
      <Grid
        container
        spacing={{ xs: 3, sm: 5 }}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        {BENEFIT_LIST.map(({ caption, description, icon }) => (
          <Grid item key={caption}>
            <Benefit caption={caption} description={description} icon={icon} />
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default Benefits
