import { pick } from 'dot-object'
import { Data } from 'interfaces/ApolloResponse.interface'

/**
 * @function isLaterThanOrEqual
 * @description Is date field value later than or equal to 2nd argument.
 * @param path string
 * @param compareTo Date
 * @returns bool
 */
export const isLaterThanOrEqual =
  (path: string, compareTo: Date) =>
  (state: Data): boolean => {
    const value = pick(path, state)

    return new Date(value).getTime() >= compareTo.getTime()
  }

export const isNotInTheFuture =
  (path: string, compareTo: Date) => (state: Data) => {
    const value = pick(path, state)

    return new Date(value).getTime() <= compareTo.getTime()
  }
