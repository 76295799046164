import {
  FormControlLabel,
  RadioGroup,
  styled,
  Typography,
  useTheme
} from '@mui/material'
import RadioSmava from 'components/Smava/Radio'
import { FintecState } from '@finanzcheck/ti-fts-widget'
import { ChangeEvent, useState } from 'react'
import { useStores } from 'stores/utils/hooks/useStores'
import { observer } from 'mobx-react'
import { DACEmbeddedStep } from './DACEmbeddedStep'
import { NonDacEmbeddedStep } from './NonDacEmbeddedStep'

const StyledErrorTypography = styled(Typography)(({ theme }) => ({
  color: `${theme.palette.error.main}`
}))

interface Props {
  state: FintecState
  setState?: (state: Extract<FintecState, 'agreed' | 'disagreed'>) => void
  widget: JSX.Element | null
  insertDACHandIcon?: boolean
  dacSmavaHandIcon?: boolean
  isSuccessMessageHidden?: boolean
  bankCodeLabel?: string
  bankCodePlaceholder?: string
  fontWeight: string
  bgColor: string
  ctaText: string
}

export const DACChoiceWithEmbeddedSteps = observer((params: Props) => {
  const {
    traversal: { setOverMiddleAge3107DacOption },
    fieldErrors: { isErrorOnOverMiddleAgeDACField, setOverMiddleAgeFieldError }
  } = useStores()
  const theme = useTheme()
  const [selectedOption, updateSelectedOption] = useState<string>('')

  const updatePrefferedDacOption = ({
    target: { value: newValue }
  }: ChangeEvent<HTMLInputElement>) => {
    updateSelectedOption(newValue)
    setOverMiddleAge3107DacOption(newValue)
    setOverMiddleAgeFieldError(false)
  }

  return (
    <>
      <RadioGroup onChange={updatePrefferedDacOption}>
        <Typography
          variant="body2"
          style={{
            marginBottom: '8px',
            fontWeight: params.fontWeight
          }}
        >
          Weiter mit dem einmaligen Online-Banking-Login?
        </Typography>
        <FormControlLabel
          sx={{ margin: 0 }}
          control={
            <RadioSmava
              borderRadius="8px"
              choice={{
                label:
                  'Ja, ich möchte das einmalige Online-Banking-Login nutzen',
                value: 'schnell-dac'
              }}
              bgcolor={params.bgColor}
              isSelected={selectedOption === 'schnell-dac'}
              hasError={isErrorOnOverMiddleAgeDACField}
              padding={1}
            >
              <DACEmbeddedStep
                {...params}
                initDAC={selectedOption === 'schnell-dac'}
              />
            </RadioSmava>
          }
          label={''}
        />
        <FormControlLabel
          sx={{ margin: 0 }}
          control={
            <RadioSmava
              borderRadius="8px"
              choice={{
                label:
                  'Nein, ich möchte kein einmaliges Online-Banking-Login nutzen',
                value: 'ohne-schnell-dac'
              }}
              isSelected={selectedOption === 'ohne-schnell-dac'}
              hasError={isErrorOnOverMiddleAgeDACField}
              padding={1}
            >
              <>
                {selectedOption === 'ohne-schnell-dac' ? (
                  <NonDacEmbeddedStep ctaText={params.ctaText} />
                ) : null}
              </>
            </RadioSmava>
          }
          label={''}
        />
      </RadioGroup>
      {isErrorOnOverMiddleAgeDACField && (
        <StyledErrorTypography variant="body2">
          Bitte wählen Sie eine Option aus, um fortzufahren
        </StyledErrorTypography>
      )}
    </>
  )
})
