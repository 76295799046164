import { RegisteredEventName } from '@finanzcheck/catalyst-pollard'

import pollard from 'utils/pollard'
import { logSuccess } from 'utils/log'
import RootStore from 'stores/RootStore'
import { isTenantSmava } from 'utils/url'
import { StartupCommand } from './command.interface'
import { Debtor } from 'containers/PersonalForm/PersonalForm.interface'

export type CurrentNavigationPayload = {
  system: string
  location: string
  currentConfig: string
  currentPageName: string
  initiallySeenPage: string
}

class InitNavigation implements StartupCommand {
  name = 'InitNavigation'
  dependencies = ['InitPollard']
  timeout = 5000

  // eslint-disable-next-line class-methods-use-this
  execute = async ({
    navigation: {
      setCurrentPageInSession,
      setInitiallySeenPage,
      setCurrentConfigInSession,
      setParentUrl,
      setEmailFieldDisabled,
      setTenantContext
    }
  }: RootStore) => {
    const data: CurrentNavigationPayload | undefined = await pollard.request({
      eventName: RegisteredEventName.getCurrentNavigation
    })

    if (!data) {
      return
    }

    setCurrentPageInSession(data.currentPageName)
    setInitiallySeenPage(data.initiallySeenPage)
    setCurrentConfigInSession(data.currentConfig)
    await setEmailFieldDisabled(Debtor.PRIMARY)
    if (isTenantSmava()) {
      await setParentUrl()
      await setTenantContext()
    }
    logSuccess(`Received parent navigation state: ${JSON.stringify(data)}`)
  }
}

export default InitNavigation
