import { getEnv } from 'utils/env'
import { set } from 'dot-object'
import { logAndTrackError } from 'utils/log/logAndTrackError'
import InvalidDomainError from 'error/invalidDomainError'
import {
  getDefaultAffiliateId,
  getClientUuid,
  UrlParams,
  urlPathMap
} from 'utils/url'
import Position from 'utils/formDependencies/statics/enums/Position'
import AccountType from 'utils/formDependencies/statics/enums/AccountType'
import isNotAffiliate from 'utils/formDependencies/conditions/isNotAffiliate'

import {
  CreditChangeOption,
  Data,
  LivingSituation
} from './interfaces/ApolloResponse.interface'
import civilStatus from './texts/common/lists/civil_status'
import householdLivingSituation from './texts/common/lists/household_living_situation'

export const DEFAULT_CONFIGURATION_ID = getEnv('REACT_APP_CONFIG_ID', true)
export const DEFAULT_TAURINE_AMOUNT = getEnv(
  'REACT_APP_TAURINE_DEFAULT_AMOUNT',
  true
)

export const DEFAULT_COUNTRY = 'DE'
export const MINIMUM_VEHICLE_AMOUNT_VALUE = 500

const clientUuid = getClientUuid()
interface InitialValues {
  system: DeepPartial<Data['system']>
  common: DeepPartial<Data['common']>
  debtors: DeepPartial<Data['debtors']>
}

if (!clientUuid) {
  logAndTrackError(new InvalidDomainError())
}

export const formInitialValues: InitialValues = {
  system: {
    advertisementId: DEFAULT_CONFIGURATION_ID,
    configurationId: DEFAULT_CONFIGURATION_ID,
    affiliateId: getDefaultAffiliateId(),
    clientUuid
  },
  common: {
    amountAdaption: CreditChangeOption.nothing,
    amount: parseInt(DEFAULT_TAURINE_AMOUNT, 10),
    term: 84,
    purpose: 'OTHER',
    payoutAccount: {
      type: AccountType.IBAN_BIC,
      country: DEFAULT_COUNTRY
    },
    salaryAccount: {},
    loanPayout: 'AS_SOON_AS_POSSIBLE',
    payoutAccountHolder: 'PRIMARY',
    rateDueOn: 1,
    vehicle: {
      type: 'CAR',
      powerUnit: 'PS',
      price: 20000
    },
    rdi: {
      isSecondaryDebtorIncluded: false
    }
  },
  debtors: {
    count: 1,
    isSameContactInformation: true,
    isCommonHousehold: true,
    isSameAddress: true,
    primary: {
      contact: {},
      residentAlien: {
        isResidencePermitTemporary: false,
        isWorkPermitTemporary: false
      },
      hasMinijob: false,
      activeLoans: [],
      numberOfActiveLoans: 0,
      hasPreviousEmployment: false,
      personal: {
        nationality: DEFAULT_COUNTRY,
        countryOfBirth: DEFAULT_COUNTRY,
        civilStatus: civilStatus[0].value
      },
      pensionFund: {
        address: {
          country: DEFAULT_COUNTRY
        },
        name: ''
      },
      household: {
        livingSituation: householdLivingSituation[0].value as LivingSituation,
        numberOfResidents: 1,
        numberOfChildren: 0
      },
      realEstate: {
        rentalProperty: {
          type: 'NONE'
        }
      },
      previousEmployment: {
        isGermanSalaryAccount: true,
        employer: {
          address: {
            country: DEFAULT_COUNTRY
          },
          name: ''
        }
      },
      currentEmployment: {
        position: Position.EMPLOYEE,
        workingTime: 'FULL_TIME',
        isTemporary: false,
        isExtended: false,
        isInProbationPeriod: false,
        isGermanSalaryAccount: true,
        employer: {
          address: {
            country: DEFAULT_COUNTRY
          }
        },
        selfEmployment: {
          isDoubleBookkeepingRequired: false,
          address: {
            country: DEFAULT_COUNTRY
          }
        },
        isIncomeProvable: true
      },
      currentAddress: {
        country: DEFAULT_COUNTRY
      },
      previousAddress: {
        country: DEFAULT_COUNTRY
      },
      income: {
        spousalSupport: 0,
        pension: 0,
        pensions: [],
        childSupport: 0,
        hasFurtherIncome: false,
        isPrimaryIncomeStable: true,
        numberOfChildrenWithChildBenefits: 0,
        secondaryEmployments: [],
        numberOfSecondaryEmployments: 0,
        interest: 0,
        numberOfPensions: 0,
        numberOfChildrenWithChildSupport: 0
      },
      expenses: {
        cards: 'DEBITCARD_CREDITCARD',
        homePurchaseSavingsRate: 0,
        lifeInsurance: 0,
        privateHealthInsuranceRate: 0,
        spousalSupport: 0,
        childSupport: 0,
        numberOfChildrenWithChildSupport: 0,
        hasCar: false,
        mortgageRateUsedProperty: 0,
        mortgageRateRentalProperty: 0,
        additionalHousingCosts: 0,
        isHomePurchaseSavingsUsedForMortgageRepayment: false,
        isLifeInsuranceUsedForMortgageRepayment: false
      }
    },
    secondary: {
      residentAlien: {
        isResidencePermitTemporary: false,
        isWorkPermitTemporary: false
      },
      pensionFund: {
        address: {
          country: DEFAULT_COUNTRY
        },
        name: ''
      },
      hasMinijob: false,
      activeLoans: [],
      numberOfActiveLoans: 0,
      hasPreviousEmployment: false,
      income: {
        spousalSupport: 0,
        pension: 0,
        pensions: [],
        numberOfPensions: 0,
        childSupport: 0,
        hasFurtherIncome: false,
        isPrimaryIncomeStable: true,
        secondaryEmployments: [],
        numberOfSecondaryEmployments: 0,
        numberOfChildrenWithChildBenefits: 0,
        interest: 0,
        numberOfChildrenWithChildSupport: 0
      },
      realEstate: {
        rentalProperty: {
          type: 'NONE'
        }
      },
      expenses: {
        cards: 'DEBITCARD_CREDITCARD',
        homePurchaseSavingsRate: 0,
        lifeInsurance: 0,
        privateHealthInsuranceRate: 0,
        spousalSupport: 0,
        childSupport: 0,
        numberOfChildrenWithChildSupport: 0,
        hasCar: false,
        hasBike: false,
        mortgageRateUsedProperty: 0,
        mortgageRateRentalProperty: 0,
        additionalHousingCosts: 0,
        isHomePurchaseSavingsUsedForMortgageRepayment: false,
        isLifeInsuranceUsedForMortgageRepayment: false
      },
      personal: {
        countryOfBirth: DEFAULT_COUNTRY,
        nationality: DEFAULT_COUNTRY,
        civilStatus: civilStatus[0].value
      },
      household: {
        numberOfResidents: 1,
        numberOfChildren: 0
      },
      previousEmployment: {
        isGermanSalaryAccount: true,
        employer: {
          address: {
            country: DEFAULT_COUNTRY
          },
          name: ''
        }
      },
      currentEmployment: {
        position: Position.EMPLOYEE,
        workingTime: 'FULL_TIME',
        isTemporary: false,
        isExtended: false,
        isInProbationPeriod: false,
        isGermanSalaryAccount: true,
        employer: {
          address: {
            country: DEFAULT_COUNTRY
          }
        },
        selfEmployment: {
          isDoubleBookkeepingRequired: false,
          address: {
            country: DEFAULT_COUNTRY
          }
        },
        isIncomeProvable: true
      },
      currentAddress: {
        country: DEFAULT_COUNTRY
      },
      previousAddress: {
        country: DEFAULT_COUNTRY
      }
    }
  }
}

export default function getInitialFormValues(urlParams: UrlParams = {}) {
  const initialValues = formInitialValues

  if (!isNotAffiliate(urlParams.context)) {
    delete initialValues.system.affiliateId
  }

  for (const [name, value] of Object.entries(urlParams)) {
    if (value === undefined) {
      continue
    }

    const path = urlPathMap[name]
    if (!path) {
      continue
    }

    set(path, value, initialValues)
  }

  const {
    system: { advertisementId, configurationId }
  } = initialValues

  /*
   * It is better to use the advertisementId as a fallback if set.
   * This will be used before a traversal was created, e.g. in affiliate implementations (first page).
   */
  if (advertisementId && configurationId === getEnv('REACT_APP_CONFIG_ID')) {
    initialValues.system.configurationId = advertisementId
  }

  return initialValues as Data
}
