import { memo } from 'react'
import { Theme } from '@mui/material/styles'
import { keyframes } from '@emotion/css'
import * as CSSTypes from 'csstype'

import useEmotionStyles from 'hooks/useEmotionStyles'
import { ANIMATION_DELAY_INTERVAL } from 'components/ProgressCategories/VerticalProgress'
import { Logo, bankLogos } from '../LoadingLead/Logos'
import SkeletonFakeOffer from './SkeletonFakeOffer'

const FAKE_BANKS = [
  'targobank',
  'ingDiba',
  'commerzbank',
  'dkb',
  'postbank',
  'deutscheBank'
]

const getBankNames = (
  fakeBankListNames?: string[],
  fakeOffersNumber?: number
): string[] => {
  if (fakeBankListNames) {
    return fakeBankListNames
  }
  return fakeOffersNumber ? FAKE_BANKS.slice(0, fakeOffersNumber) : FAKE_BANKS
}

interface FakeOffersProps {
  skeletonsNumber?: number
  fakeOffersNumber?: number
  isAnimated?: boolean
  fakeBankListNames?: string[]
  areSkeletonsWithLabels?: boolean
  isLogoCentered?: boolean
  isLogoStatic?: boolean
  skeletonBorderRadius?: number
  isLastLogoHiddenOnMobile?: boolean
  areSkeletonsSingle?: boolean
  isGridView?: boolean
  isLogoCarousel?: boolean
  isUsingSmavaCdnUrl?: boolean
}

const FakeOffers = ({
  skeletonsNumber,
  fakeOffersNumber,
  isAnimated,
  fakeBankListNames,
  isLogoCentered,
  isLogoStatic,
  areSkeletonsWithLabels,
  skeletonBorderRadius,
  isLastLogoHiddenOnMobile,
  areSkeletonsSingle,
  isGridView,
  isLogoCarousel,
  isUsingSmavaCdnUrl
}: FakeOffersProps) => {
  const {
    root,
    fakeOffer,
    fakeOfferTitle,
    fakeOfferCenterTitle,
    fakeOfferStaticTitle,
    fakeOfferGridViewElement,
    fakeOfferGridView,
    fakeOfferLogoCarousel,
    fakeOfferLogoCarouselSlide,
    fakeOfferLogoCarouselSlideWrapper
  } = useEmotionStyles<
    ReturnType<typeof styles>,
    { isLastLogoHiddenOnMobile: boolean }
  >(styles, {
    isLastLogoHiddenOnMobile: isLastLogoHiddenOnMobile || !fakeBankListNames
  })

  const getLogoClassName = () => {
    if (isLogoCentered) {
      return fakeOfferCenterTitle
    }

    if (isLogoStatic) {
      return fakeOfferStaticTitle
    }

    return fakeOfferTitle
  }

  const fakeBanks = getBankNames(fakeBankListNames, fakeOffersNumber)

  return (
    <div className={root}>
      {isLogoCarousel && (
        <div className={fakeOfferGridViewElement}>
          <div className={fakeOfferLogoCarousel}>
            {fakeBanks.map((fakeBank) => {
              const logoId = String(bankLogos[fakeBank])

              return (
                <div
                  className={fakeOfferLogoCarouselSlideWrapper}
                  key={bankLogos[fakeBank]}
                >
                  <div className={fakeOfferLogoCarouselSlide}>
                    <Logo
                      logoId={logoId}
                      bankName={fakeBank}
                      isUsingSmavaCdnUrl={isUsingSmavaCdnUrl}
                    />
                  </div>
                </div>
              )
            })}
          </div>

          <SkeletonFakeOffer
            areSkeletonsWithLabels={areSkeletonsWithLabels}
            borderRadius={skeletonBorderRadius}
            isAnimated={isAnimated}
            skeletonsNumber={skeletonsNumber}
            areSkeletonsSingle={areSkeletonsSingle}
          />
        </div>
      )}

      {!isLogoCarousel && (
        <div className={isGridView ? fakeOfferGridView : ''}>
          {fakeBanks.map((fakeBank) => {
            const logoId = String(bankLogos[fakeBank])
            const fakeOfferClassName = isGridView
              ? fakeOfferGridViewElement
              : fakeOffer

            return (
              <div className={fakeOfferClassName} key={bankLogos[fakeBank]}>
                <div className={getLogoClassName()}>
                  <Logo
                    logoId={logoId}
                    bankName={fakeBank}
                    isUsingSmavaCdnUrl={isUsingSmavaCdnUrl}
                  />
                </div>

                <SkeletonFakeOffer
                  areSkeletonsWithLabels={areSkeletonsWithLabels}
                  borderRadius={skeletonBorderRadius}
                  isAnimated={isAnimated}
                  skeletonsNumber={skeletonsNumber}
                  areSkeletonsSingle={areSkeletonsSingle}
                />
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default memo(FakeOffers)

const fadeIn = keyframes({
  '0%': {
    opacity: 0
  },
  '100%': {
    opacity: 1
  }
})

// please adjust the values if we add or remove the logos
const move = keyframes({
  '0%': {
    transform: 'translateY(0)'
  },
  '25%': {
    transform: 'translateY(-100%)'
  },
  '50%': {
    transform: 'translateY(-200%)'
  },
  '75%': {
    transform: 'translateY(-300%)'
  },
  '87.5%': {
    transform: 'translateY(-350%)'
  },
  '87.501%': {
    transform: 'translateY(50%)'
  },
  '100%': {
    transform: 'translateY(0)'
  }
})

const FAKE_OFFERS_DELAY_INTERVAL = 0.1

const styles = (
  theme: Theme,
  {
    isLastLogoHiddenOnMobile
  }: { isLastLogoHiddenOnMobile: boolean; isBorder?: boolean }
) => ({
  root: {
    position: 'relative' as CSSTypes.Property.Position,
    width: '100%',
    marginTop: theme.spacing(4)
  },

  fakeOfferGridView: {
    display: 'grid',
    gap: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr'
    }
  },
  fakeOfferGridViewElement: {
    position: 'relative' as CSSTypes.Property.Position,
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: '2px',
    overflow: 'hidden',
    zIndex: 1,
    opacity: 0,
    animation: `${fadeIn} 1s forwards`,

    // the animations timing are closely connected to VerticalProgress use, please have it mind to refactor it if there is a need to it somewhere else
    '&:nth-child(1)': {
      animationDelay: `${
        ANIMATION_DELAY_INTERVAL * 4 + FAKE_OFFERS_DELAY_INTERVAL
      }s`
    },

    '&:nth-child(2)': {
      animationDelay: `${
        ANIMATION_DELAY_INTERVAL * 4 + FAKE_OFFERS_DELAY_INTERVAL * 2
      }s`
    },

    '&:nth-child(3)': {
      animationDelay: `${
        ANIMATION_DELAY_INTERVAL * 4 + FAKE_OFFERS_DELAY_INTERVAL * 3
      }s`
    },

    '&:nth-child(4)': {
      animationDelay: `${
        ANIMATION_DELAY_INTERVAL * 4 + FAKE_OFFERS_DELAY_INTERVAL * 4
      }s`
    }
  },

  fakeOffer: {
    position: 'relative' as CSSTypes.Property.Position,
    marginBottom: theme.spacing(4),
    boxShadow: '1px 2px 4px rgb(0 2 6 / 20%)',
    borderRadius: '5px',
    zIndex: 1,

    '&:nth-last-child(2)': {
      ...(isLastLogoHiddenOnMobile && {
        marginBottom: 0
      }),

      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(4)
      }
    },

    '&:last-child': {
      display: isLastLogoHiddenOnMobile ? 'none' : 'block',

      [theme.breakpoints.up('md')]: {
        display: 'block',
        margin: 0
      }
    }
  },
  fakeOfferTitle: {
    width: '100%',
    padding: `${theme.spacing(2)} ${theme.spacing(2.5)}`,
    background: theme.palette.grey[50],

    '& img': {
      height: '30px'
    },

    [theme.breakpoints.up('md')]: {
      position: 'absolute' as CSSTypes.Property.Position,
      background: 'none'
    }
  },

  fakeOfferCenterTitle: {
    padding: `${theme.spacing(2)} ${theme.spacing(2.5)} 0`,
    textAlign: 'center' as CSSTypes.Property.TextAlign,

    '& img': {
      height: '30px'
    }
  },

  fakeOfferStaticTitle: {
    width: '100%',
    padding: `${theme.spacing(2)} ${theme.spacing(2.5)}`,
    background: theme.palette.grey[50],

    '& img': {
      height: '30px'
    }
  },

  fakeOfferLogoCarousel: {
    height: '68px',
    animation: `${move} 4s ease-in-out infinite`,

    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  fakeOfferLogoCarouselSlideWrapper: {
    width: '100%',
    padding: `${theme.spacing(2)} ${theme.spacing(2.5)}`,
    background: theme.palette.grey[50]
  },
  fakeOfferLogoCarouselSlide: {
    '& img': {
      height: '30px'
    }
  }
})
