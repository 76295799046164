import Skeleton from '@mui/material/Skeleton'
import useEmotionStyles from 'hooks/useEmotionStyles'
import { memo } from 'react'

const styles = () => ({
  rounded: {
    borderRadius: 12
  }
})

const ProgressIndicatorSkeleton = ({ isRect }: { isRect?: boolean }) => {
  const { rounded } = useEmotionStyles(styles)

  return <Skeleton className={isRect ? '' : rounded} height={18} />
}

export default memo(ProgressIndicatorSkeleton)
