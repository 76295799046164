import { AxiosInstance, InternalAxiosRequestConfig } from 'axios'
import TraversalStore from 'stores/TraversalStore'
import { setUserProperties } from 'utils/tracking/amplitude'
import { UserProperty } from 'utils/tracking/amplitude/amplitude.interface'
import { Interceptor } from '.'

const UPDATE_METHODS = ['patch', 'put']

function isTraversalUpdateRequest({
  url = '',
  method
}: InternalAxiosRequestConfig) {
  if (!url.startsWith('/traversal')) {
    return false
  }

  return method && UPDATE_METHODS.includes(method.toLowerCase())
}

/**
 * This interceptor will create a traversal on demand.
 * If a traversal is or was created, it will eject itself.
 */
export default class TraversalCreator implements Interceptor {
  private traversal: TraversalStore

  constructor(traversal: TraversalStore) {
    this.traversal = traversal
  }

  public static create(traversal: TraversalStore) {
    return new TraversalCreator(traversal)
  }

  private intercept =
    (eject: () => void) => async (config: InternalAxiosRequestConfig) => {
      if (this.traversal.traversalId) {
        eject()

        return config
      }

      if (!isTraversalUpdateRequest(config)) {
        return config
      }

      const {
        data: { result }
      } = await this.traversal.create()

      if (result?.data?.system) {
        setUserProperties({
          [UserProperty.AffiliateID]: result.data.system.affiliateId,
          [UserProperty.ConfigurationID]: result.data.system.configurationId
        })
      }

      /* keep or remove after smava is moved to paas as well */
      this.traversal.trackTraversalEvent()

      eject()

      /**
       * Only overwrite the data for PUT requests
       * because patch requests require another signature and result in errors (400)..
       */
      const data =
        config.method?.toLowerCase() === 'put' ? result?.data : config.data

      return {
        ...config,
        data,
        url: `/traversal/${this.traversal.data.system.advertisementId}/${this.traversal.traversalId}`
      }
    }

  public apply = (instance: AxiosInstance) => {
    const interceptorId = instance.interceptors.request.use(
      this.intercept(() => {
        instance.interceptors.request.eject(interceptorId)
      })
    )

    return instance
  }
}
