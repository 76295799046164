import GeneralSkeleton from './GeneralSkeleton'
import FooterWithBoxesSkeleton from './FooterWithBoxesSkeleton'
import VersionDisplay from '../VersionDisplay'

const SkeletonView = () => (
  <>
    <GeneralSkeleton />
    <FooterWithBoxesSkeleton />
    <VersionDisplay />
  </>
)

export default SkeletonView
