import { AxiosInstance } from 'axios'

import { getCookie, COOKIE_KEY_FCID } from 'utils/cookie'
import { logAndTrackError } from 'utils/log/logAndTrackError'
import FcidStore from 'stores/FcidStore'
import { AFFILIATE } from 'constants/components'
import { UrlParams } from 'utils/url/url.interface'

import { Interceptor } from '.'

const FCID_HEADER_KEY = 'X-FcId'

/**
 * Adds fcid header to all Apollo requests, if cookie not available (e.g. Safari in 3rd party host)
 */
export default class ApolloFcid implements Interceptor {
  private fcidStore: FcidStore
  private urlParams?: UrlParams

  constructor(fcid: FcidStore, urlParams: UrlParams) {
    this.fcidStore = fcid
    this.urlParams = urlParams
  }

  public static create(fcid: FcidStore, urlParams: UrlParams) {
    return new ApolloFcid(fcid, urlParams)
  }

  public apply(instance: AxiosInstance): AxiosInstance {
    instance.interceptors.request.use(async (config: any) => {
      if (getCookie(COOKIE_KEY_FCID)) {
        return config
      }

      if (this.fcidStore.getFcid()) {
        // set header (i.e. fallback for cookie)
        return {
          ...config,
          headers: {
            ...config.headers,
            [FCID_HEADER_KEY]: this.fcidStore.getFcid()
          }
        }
      }

      /* incase fcId was not present in cookies or was not generate before */
      const fcId = this.fcidStore.ensureFcid()
      if (fcId) {
        // set header (i.e. fallback for cookie)
        return {
          ...config,
          headers: {
            ...config.headers,
            [FCID_HEADER_KEY]: fcId
          }
        }
      }

      const makeFcIdLogToBugSnag = this.urlParams?.context !== AFFILIATE

      if (makeFcIdLogToBugSnag) {
        logAndTrackError(new Error('fcid not set in interceptor'))
      }
      return config
    })

    return instance
  }
}
