import { useCallback, memo } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import { observer } from 'mobx-react'
import { NavigateFunction } from 'react-router-dom'
import CategoryLayout from 'components/Layout/Category'
import { scrollToTop } from '../../utils/pollard/scroll'
import { useRootStore } from '../../stores/utils/hooks/useRootStore'
import { useStores } from '../../stores/utils/hooks/useStores'

interface Props {
  pageName: string
  navigate: NavigateFunction
}

const StyledHint = styled('p')(({ theme }) => ({
  padding: '1rem',
  border: `0.0625rem solid ${theme.palette.error.main}`,
  borderRadius: '0.125rem',
  fontSize: '1rem',
  color: theme.palette.error.main,
  textAlign: 'center'
}))

const PreviousPageErrors = observer(({ pageName, navigate }: Props) => {
  const { rootStore } = useRootStore()
  const { history, page } = useStores()

  // TODO: Use page title from metadata :)
  const pageTitle = `Seite ${page.getPageIndex(pageName) + 1}`

  const jumpToPageWithError = useCallback(() => {
    history.push(pageName)
    navigate(pageName)
    scrollToTop(rootStore.isInIframe)
  }, [history, pageName, navigate, rootStore.isInIframe])

  return (
    <CategoryLayout>
      <StyledHint>
        {`Um Angebote zu erhalten, beheben Sie bitte zunächst die Fehler auf "${pageTitle}".`}
      </StyledHint>
      <Button
        name="previous-page-with-errors"
        size="large"
        fullWidth
        variant="contained"
        color="secondary"
        startIcon={<ArrowBackIcon />}
        onClick={jumpToPageWithError}
      >
        {`Zurück zu ${pageTitle}`}
      </Button>
    </CategoryLayout>
  )
})

export default memo(PreviousPageErrors)
