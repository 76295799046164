const legalForm = [
  { label: 'Einzelfirma', value: 'SOLE_PROPRIETORSHIP' },
  { label: 'Freiberufler', value: 'FREELANCER' },
  { label: 'Gewerbetreibender', value: 'BUSINESSMAN' },
  { label: 'GmbH / UG / AG / KGaA', value: 'LIMITED_LIABILITY' },
  { label: 'OHG / KG / GbR', value: 'GENERAL_PARTNERSHIP' },
  { label: 'eingetragener Kaufmann', value: 'REGISTERED_MERCHANT' },
  { label: 'Sonstige', value: 'OTHER' }
]

export default legalForm
