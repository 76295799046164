import { AxiosInstance, AxiosResponse } from 'axios'
import TraversalStore from 'stores/TraversalStore'
import { executeAsync } from 'utils/performance'
import { copy } from 'dot-object'
import { RootObject } from 'interfaces/ApolloResponse.interface'
import { Interceptor } from '.'

const ignoredUrls = ['heartbeat', 'activity']

function urlIsIgnored(url: string) {
  return new RegExp(ignoredUrls.join('|')).test(url)
}

export default class TraversalUpdater implements Interceptor {
  private traversalStore: TraversalStore
  private allowedPaths: string[]

  constructor(traversalStore: TraversalStore, allowedPaths: string[]) {
    this.traversalStore = traversalStore
    this.allowedPaths = allowedPaths
  }

  public static create(traversalStore: TraversalStore, allowedPaths: string[]) {
    return new TraversalUpdater(traversalStore, allowedPaths)
  }

  public apply = (instance: AxiosInstance) => {
    instance.interceptors.response.use(
      (response: AxiosResponse<RootObject>) => {
        if (!response) {
          return Promise.reject()
        }

        const {
          data: { result },
          config
        } = response

        if (!result?.data || urlIsIgnored(config.url || '')) {
          return response
        }

        executeAsync(() => {
          for (const path of this.allowedPaths) {
            copy(path, path, result.data, this.traversalStore.data)
          }
        })

        return response
      }
    )

    return instance
  }
}
