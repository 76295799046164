export async function rejectAfter(ms: number, onTimeout: () => Error) {
  return new Promise((_, reject) => {
    const id = setTimeout(() => {
      clearTimeout(id)
      reject(onTimeout())
    }, ms)
  })
}

export async function wait(ms: number): Promise<void> {
  return new Promise((resolve) => {
    const id = setTimeout(() => {
      clearTimeout(id)
      resolve()
    }, ms)
  })
}
