import CssBaseline from '@mui/material/CssBaseline'
import {
  ThemeProvider as MuiThemeProvider,
  Theme,
  StyledEngineProvider
} from '@mui/material/styles'
import { useCurrentTheme } from 'stores/utils/hooks/useCurrentTheme'
import { observer } from 'mobx-react'
import { memo, PropsWithChildren } from 'react'
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const ThemeProvider = observer(({ children }: PropsWithChildren<unknown>) => {
  const { theme } = useCurrentTheme()

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <EmotionThemeProvider theme={theme}>
          <CssBaseline>{children}</CssBaseline>
        </EmotionThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  )
})

export default memo(ThemeProvider)
