import { dateIsOlderThan } from 'utils/date'

const MIN_NUMBER_OF_VALID_DATE = 7 // length of 'YYYY.MM'
export const hasSameWorkForLongerThanTwoYears = (since?: string) => {
  if (!since || since.length < MIN_NUMBER_OF_VALID_DATE) {
    return false
  }

  return dateIsOlderThan(since, { year: 2 })
}
