import { useForm } from 'react-final-form'

import { mapErrorMessages } from '../../../utils/errorMessages'
import { useStores } from './useStores'
import { useMeta } from './useMeta'

export function useError(path: string) {
  const { fieldErrors } = useStores()
  const form = useForm()
  const { errorMap } = useMeta(path)

  return {
    error: [
      mapErrorMessages(fieldErrors.get(path), errorMap),
      form.getFieldState(path)?.error
    ]
      .filter(Boolean)
      .join(' ')
  }
}
