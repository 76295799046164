const pension = [
  { label: 'Altersrente - gesetzlich', value: 'RETIREMENT_PENSION' },
  { label: 'Altersrente - privat', value: 'PERSONAL_PENSION' },
  { label: 'Erwerbsminderungsrente', value: 'REDUCTION_IN_EARNING_CAPACITY' },
  { label: 'Betriebsrente', value: 'OCCUPATIONAL_PENSION' },
  { label: 'Berufsunfähigkeitsrente', value: 'OCCUPATIONAL_DISABILITY' },
  { label: 'Erwerbsunfähigkeitsrente', value: 'OCCUPATIONAL_INVALIDITY' },
  { label: 'Unfallversicherungsrente', value: 'ACCIDENT_INSURANCE' },
  { label: 'Waisenrente', value: 'ORPHANS_PENSION' },
  { label: 'Witwenrente', value: 'WIDOWS_PENSION' },
  { label: 'Pension', value: 'PENSION' }
]

export default pension
