import { AppEnvironment, appEnvironment } from 'utils/env'
import { isTenantSmava } from 'utils/url'
import { clientDetails as clientDetailsFinanzcheck } from './finanzcheck'
import { clientDetails as clientDetailsSmava } from './smava'

export const getClientDetails = () => {
  if (isTenantSmava()) {
    return (
      clientDetailsSmava[appEnvironment] ||
      clientDetailsSmava[AppEnvironment.Shared]
    )
  }

  return (
    clientDetailsFinanzcheck[appEnvironment] ||
    clientDetailsFinanzcheck[AppEnvironment.Shared]
  )
}
