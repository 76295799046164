import { memo } from 'react'

import CreateFinalAfterInactivity, {
  InactivityType
} from './containers/CreateFinalAfterInactivity'
import { useRootStore } from 'stores/utils/hooks/useRootStore'
import {
  CONFIG_DDF_SEPARATED_DAC,
  CONFIG_DDF_SMAVA_SEPARATED_DAC
} from 'config/utils/config'
import { getAge } from 'utils/date'
import { determineTimeout, LONG_TIMEOUT } from 'InactivityContainer.helpers'

type InactivityProps = {
  handleSubmit: () => void
  isSmava: boolean
}

const InactivityContainer = ({ handleSubmit, isSmava }: InactivityProps) => {
  const {
    rootStore: {
      page: { config },
      traversal: {
        data: {
          debtors: {
            primary: {
              personal: { birthday }
            }
          }
        }
      }
    }
  } = useRootStore()

  const isTouchedIgnored =
    config.name === CONFIG_DDF_SMAVA_SEPARATED_DAC ||
    config.name === CONFIG_DDF_SEPARATED_DAC

  const debtorsAge = getAge(birthday)
  const timeout = determineTimeout(isSmava, debtorsAge, config.name)

  if (!timeout) {
    return null
  }

  return (
    <>
      <CreateFinalAfterInactivity
        timeout={timeout}
        inactivityType={InactivityType.PAYOUT_ACCOUNT_INACTIVITY}
        onNext={handleSubmit}
        isTouchedIgnored={isTouchedIgnored}
        isSmava={isSmava}
        debtorsAge={debtorsAge}
      />
      <CreateFinalAfterInactivity
        timeout={LONG_TIMEOUT}
        inactivityType={InactivityType.DAC_INACTIVITY}
        onNext={handleSubmit}
        isTouchedIgnored={isTouchedIgnored}
        isSmava={isSmava}
        debtorsAge={debtorsAge}
      />
    </>
  )
}

export default memo(InactivityContainer)
