import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import { ClientName } from '../api.interface'
import ApolloAuth from './apolloAuth'
import { handleInterceptedErrors, handleSuccess } from './utils'

export interface Interceptor {
  apply: (instance: AxiosInstance) => AxiosInstance
}

export interface InterceptorConfig {
  response?: {
    onSuccess?: (
      response: AxiosResponse<unknown>
    ) => AxiosResponse<unknown> | Promise<AxiosResponse<unknown>>
    onError?: (error: AxiosError<unknown>) => void
  }
  interceptors?: Interceptor[]
}

const responseInterceptors: Partial<Record<ClientName, InterceptorConfig>> = {
  apollo: {
    response: {
      onSuccess: (response) => handleSuccess(response),
      onError: async (error) => handleInterceptedErrors(error)
    },
    interceptors: [ApolloAuth.create()]
  },
  pluto: {
    response: {
      onSuccess: (response) => handleSuccess(response),
      onError: async (error) => handleInterceptedErrors(error)
    }
  },
  paas: {
    response: {
      onSuccess: (response) => handleSuccess(response),
      onError: async (error) => handleInterceptedErrors(error)
    }
  }
}

export function getInterceptors(client: ClientName) {
  return responseInterceptors[client]
}
