import { Data } from 'interfaces/ApolloResponse.interface'
import { Debtor } from '../../../containers/PersonalForm/PersonalForm.interface'
import { jobsRetired } from '../statics/lists'

const isRetired =
  (debtor: Debtor = Debtor.PRIMARY) =>
  (state: Data) =>
    jobsRetired.includes(state.debtors[debtor].currentEmployment.position)

export default isRetired
