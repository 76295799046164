import { observer } from 'mobx-react'
import { useMemo, useRef, useEffect, useCallback, memo } from 'react'
import { useProgress } from 'stores/utils/hooks/useProgress'
import { useRootStore } from 'stores/utils/hooks/useRootStore'
import { useStores } from 'stores/utils/hooks/useStores'
import { getClient } from 'api'
import { retry } from 'api/utils/retry'
import { logInfo, logWarning } from 'utils/log'
import { HttpStatusCode } from 'utils/url/status'

interface HeartbeatRequest {
  entryPoint: string
  progress: number
  formConfig: string
  lastPageSeen: string
  lastInputTouched: string | undefined
  nextInputUntouched: string | undefined
}

interface HeartbeatProps {
  threshold?: number
}

let timeout: number | null = null
function queue(ping: () => void, threshold: number) {
  if (timeout) {
    clearTimeout(timeout)
    timeout = null
  }

  timeout = window.setTimeout(ping, threshold)
}

const Heartbeat = observer(({ threshold = 10000 }: HeartbeatProps) => {
  const {
    rootStore: { initialEntryPoint }
  } = useRootStore()
  const {
    page: { config },
    navigation: { currentPageInSession },
    traversal: {
      traversalId,
      data: {
        system: { advertisementId }
      }
    }
  } = useStores()
  const { percentage, touchedInput } = useProgress()

  const apolloClient = useMemo(() => getClient('apollo'), [])

  const formConfig = config.version ? config.name : ''

  const heartbeatRequest = useMemo<HeartbeatRequest>(
    () => ({
      entryPoint: initialEntryPoint as string,
      formConfig,
      lastPageSeen: currentPageInSession || '',
      progress: percentage,
      lastInputTouched: touchedInput.last,
      nextInputUntouched: touchedInput.next
    }),
    [
      initialEntryPoint,
      formConfig,
      currentPageInSession,
      percentage,
      touchedInput.last,
      touchedInput.next
    ]
  )

  /**
   * The ref is needed,
   * so we always have the latest values when actually sending the ping :)
   */
  const requestRef = useRef(heartbeatRequest)
  useEffect(() => {
    requestRef.current = heartbeatRequest
  }, [heartbeatRequest])

  const postHeartbeat = useCallback(
    (
      currentAdvertisementId: string,
      currentTraversalId: string,
      ignoreFocus = false
    ) => {
      if (!ignoreFocus && !document.hasFocus()) {
        logWarning('Document is not focused. Skipping heartbeat..')

        return Promise.resolve()
      }

      logInfo('Sending heartbeat..')

      return retry(
        apolloClient,
        {
          method: 'post',
          url: `/heartbeat/${currentAdvertisementId}/${currentTraversalId}`,
          data: requestRef.current
        },
        {
          maxRetryCount: 5,
          retryInterval: 2000,
          condition: {
            error: (error) =>
              error?.response?.status === HttpStatusCode.NotFound
          }
        }
      )
    },
    [apolloClient]
  )

  const ping = useCallback(
    (
      currentAdvertisementId: string,
      currentTraversalId: string,
      ignoreFocus = false
    ) => {
      try {
        postHeartbeat(currentAdvertisementId, currentTraversalId, ignoreFocus)
      } finally {
        queue(() => ping(currentAdvertisementId, currentTraversalId), threshold)
      }
    },
    [postHeartbeat, threshold]
  )

  useEffect(() => {
    if (!traversalId || !config.version || !currentPageInSession) {
      return
    }

    ping(advertisementId, traversalId, true)
  }, [advertisementId, config.version, currentPageInSession, ping, traversalId])

  return null
})

export default memo(Heartbeat)
