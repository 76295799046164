import {
  broadcastToParent,
  RegisteredEventName
} from '@finanzcheck/catalyst-pollard'
import { getClient } from 'api'
import ApolloFcid from 'api/interceptors/apolloFcid'
import { AxiosInstance } from 'axios'
import { FcidItem, generate, isValid } from 'utils/fcid'
import { logInfo } from 'utils/log'

import { logAndTrackError } from 'utils/log/logAndTrackError'
import { UrlParams } from 'utils/url/url.interface'

import { FrameHostData } from './TraversalStore'

class FcidStore {
  public fcid?: FcidItem = {} as FcidItem
  private apolloClient: AxiosInstance
  private urlParams: UrlParams

  constructor(urlParams: UrlParams) {
    this.apolloClient = getClient('apollo')
    this.urlParams = urlParams
    this.setInterceptors()
  }

  getFcid = (): string => {
    if (typeof this.fcid === 'undefined') {
      return ''
    }
    return this.fcid?.id
  }

  setFcidFromFrameHost = (data: FrameHostData) => {
    const { FCID } = data
    if (FCID?.id) {
      this.setFcid(FCID)
    }
  }

  /**
   * Get a fcid from Parent local storage.
   * If no valid fcid item is found, a new item is set to parent local storage and its id returned.
   * New items get ttl of one year (doesn't really make sense, but all fcid-cookies still do it like that).
   * Null should only be returned, if writing to local storage fails.
   *
   * @returns string | null
   */
  ensureFcid(): string | null {
    const item = this.fcid
    if (!item?.id) {
      return this.createNew()
    }

    if (!isValid(item)) {
      return this.createNew()
    }

    return item.id
  }

  private setInterceptors = () => {
    ApolloFcid.create(this, this.urlParams).apply(this.apolloClient)
  }

  private setFcid = (fcid: FcidItem | undefined) => {
    this.fcid = fcid
  }

  private createNew(): string | null {
    const item = generate()

    try {
      this.setFcid(item)
      this.toFrameHost()
    } catch (error) {
      logAndTrackError(new Error(`error ${error} persisting fcid item`))
      return null
    }
    return item.id
  }

  private toFrameHost(): void {
    const data = {
      FCID: this.fcid
    }
    logInfo(`Sending FCID to parent: ${JSON.stringify(data, null, 2)}`)
    broadcastToParent({
      eventName: RegisteredEventName.updateCurrentImodTraversal,
      data
    })
  }
}

export default FcidStore
