import { getTheme, GetThemeProps, Tenant } from '@finanzcheck/teal-mui-theme'
import { Theme, createTheme } from '@mui/material'
import ThemeFetchError from 'error/themeFetchError'
import { observable, action, makeObservable } from 'mobx'
import { mergeDeepRight } from 'rambdax'
import { THEME_OPTIONS } from 'theme/material'
import { isProductionEnvironment } from 'utils/env'
import { logAndTrackError } from 'utils/log/logAndTrackError'
import { getCurrentTheme } from 'utils/storage/sessionStorage'

class ThemeStore {
  public material = createTheme({})

  constructor() {
    makeObservable(this, {
      material: observable,
      setMaterial: action,
      fetchTheme: action
    })

    if (isProductionEnvironment) {
      return
    }

    const currentTheme = getCurrentTheme()
    if (currentTheme) {
      this.setMaterial(currentTheme)
    }
  }

  fetchTheme = async (tenant: Tenant) => {
    try {
      const themeOptions: GetThemeProps = {
        options: THEME_OPTIONS
      }

      const themeState = getTheme(tenant, themeOptions)
      this.setMaterial(themeState as Theme)
    } catch (error) {
      logAndTrackError(new ThemeFetchError(error as string))
    }
  }

  setMaterial = (theme: DeepPartial<Theme> | Theme) => {
    this.material = mergeDeepRight(this.material, theme) as Theme
  }
}

export default ThemeStore
