import { createContext, PropsWithChildren, useMemo, useState } from 'react'

type IdleState = { input: string } | false

export interface UserStateContextType {
  idleState: IdleState
}

export interface UserDispatchContextType {
  setIdleState: (idleState: IdleState) => void
}

export const UserStateContext = createContext<UserStateContextType>({
  idleState: false
})

function throwUserProviderIsMissing() {
  throw new Error('The UserDispatchContext was not found!')
}

export const UserDispatchContext = createContext<UserDispatchContextType>({
  setIdleState: throwUserProviderIsMissing
})

export const UserProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [idleState, setIdleState] = useState<IdleState>(false)
  const state = useMemo(() => ({ idleState }), [idleState])
  const dispatch = useMemo(() => ({ setIdleState }), [setIdleState])

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  )
}
