import { createContext, PropsWithChildren, useContext, useMemo } from 'react'
import { checkDependencies } from './checkDependencies'

export type DependencyCheck<T> = (state: T, leafValue: any) => boolean

export type DependencyCheckOrTree<T> =
  | DependencyCheck<T>
  | Record<string, DependencyCheck<T> | Record<string, DependencyCheck<T>>>

export type Dependencies<T> = Record<
  string,
  DependencyCheckOrTree<T> | DependencyCheckOrTree<T>[]
>

export type FlatDependencies<T> = Record<string, DependencyCheckOrTree<T>>

export interface Props<T> {
  state: T | any
  dependencies: Dependencies<T>
}

export const DependencyContext = createContext({
  state: {},
  dependencies: {}
})

export const DependencyProvider = <T,>({
  children,
  ...value
}: PropsWithChildren<Props<T>>): JSX.Element => (
  <DependencyContext.Provider value={value}>
    {children}
  </DependencyContext.Provider>
)

export const useConditionalRender = (path: string): boolean => {
  const { state, dependencies } = useContext(DependencyContext)

  return useMemo(
    () => checkDependencies(path, state, dependencies),
    [path, state, dependencies]
  )
}
