import { AppEnvironment, appEnvironment, getEnv } from 'utils/env'

export const COOKIE_KEY_FCID = 'FCID'

export const getCookie: (name: string) => string | undefined | null = (
  name
) => {
  const cookie = document.cookie
    ?.split('; ')
    ?.find((strCookie) => strCookie.startsWith(name))

  if (!cookie) {
    return null
  }

  return cookie.split('=')?.[1]
}

export const setCookie = (name: string, value: unknown) => {
  const domain = getEnv('REACT_APP_DOMAIN', true)
  let cookie = `${name}=${value}; domain=${domain};`
  if (
    appEnvironment !== AppEnvironment.Local &&
    appEnvironment !== AppEnvironment.Test
  ) {
    cookie += ' sameSite=none; secure;'
  }
  document.cookie = cookie || ''
}
