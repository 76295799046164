import Position from './enums/Position'

export const jobsWithMinijobOptions = [
  Position.HOUSEWIFE,
  Position.UNEMPLOYED,
  Position.PUPIL,
  Position.STUDENT
]

export const jobsSelfEmployed = [
  Position.SELF_EMPLOYED_BUSINESSMAN,
  Position.SELF_EMPLOYED_FREELANCER,
  Position.SELF_EMPLOYED_CEO
]

export const jobsWithoutEmployer = [
  Position.RETIREE,
  Position.PENSIONER,
  ...jobsSelfEmployed
]

export const jobsRetired = [Position.RETIREE, Position.PENSIONER]

export const jobsNudgeRelevant = [
  Position.EMPLOYEE_DOCTOR,
  Position.EMPLOYEE_PUBLIC_SERVICE,
  Position.WORKER_PUBLIC_SERVICE,
  Position.OFFICER_LOWER_SERVICE,
  Position.OFFICER_MIDDLE_SERVICE,
  Position.OFFICER_HIGHER_SERVICE,
  Position.OFFICER_UPPER_SERVICE,
  Position.MANAGER,
  Position.SOLDIER,
  Position.SOLDIER_TEMPORARY,
  Position.CRAFTSMAN
]

export const jobsWithoutPreviousEmployment = [
  ...jobsWithMinijobOptions,
  ...jobsRetired
]
