import { Data } from 'interfaces/ApolloResponse.interface'
import RootStore from 'stores/RootStore'
import { DebtorRelationship } from '../statics/enums/DebtorRelationship'

export const isNotSpouseDataRequired = ({
  debtors: { relationship, count }
}: Data) => {
  if (count === 1) {
    return true
  }

  return relationship !== DebtorRelationship.SPOUSE
}

const isNotSpouseDataRequiredFunc = ({ traversal: { data } }: RootStore) =>
  isNotSpouseDataRequired(data)

export default isNotSpouseDataRequiredFunc
